import React, { useState } from "react";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import agentIcon from "../../../images/icon-chat-agent.svg";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { useDispatch, useSelector } from "react-redux";
import { closeAgentBusy } from "../state/operators";
import { TextLight } from "../../../base/BaseText";
import BaseCard from "../../../base/BaseCard/BaseCard";
import { Translate, useTranslate } from "../../../helpers/languageUtils";

const ChatUnresolved = () => {
  const t = useTranslate();
  const [disabled, setDisabled] = useState(false);
  const { chatReason } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const contentText =
    chatReason === "Invalid Name" ||
    chatReason === "Invalid Nric" ||
    chatReason === "Invalid Imei"
      ? "Validation"
      : "Generic";

  const handleCloseClick = () => {
    dispatch(closeAgentBusy(t("CloseButton")));
    setDisabled(true);
  };

  return (
    <BaseCard>
      <BaseIcon className="w-24 h-24" icon={agentIcon} name="Agent" center />
      <div className="text-center text-default leading-7 text-srgray-primary">
        <Translate i18nKey={`Chat.ChatUnresolved.${contentText}`}>
          <TextLight>text</TextLight>
        </Translate>
      </div>
      <BaseButton
        text={t("CloseButton")}
        onClick={handleCloseClick}
        disabled={disabled}
        className="mx-auto w-11r mt-8"
      />
    </BaseCard>
  );
};

export default ChatUnresolved;
