import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import SubmitCardDetails from "./SubmitCardDetails";
import SubmitCardDetailsNew from "./SubmitCardDetailsNew";
import IncorrectCardDetails from "./IncorrectCardDetails";
import PaymentOptions from "./PaymentOptions";
import CODForCC from "./CODForCC";
import { CLIENT } from "../../../helpers/constants";

const View = ({ showComponent }) => {
  const client = useSelector((state) => state.app.client);
  const isSingtel = client === CLIENT.SINGTEL;
  let isBGBT = isSingtel
    ? process.env.REACT_APP_SINGTEL_IS_BGBT === "true"
    : process.env.REACT_APP_CELCOM_IS_BGBT === "true";

  return (
    <>
      {showComponent === "ShowPaymentOptions" && <PaymentOptions />}
      {showComponent === "SubmitCardDetails" && (isBGBT ? (<SubmitCardDetailsNew />) : (<SubmitCardDetails />))}
      {showComponent === "IncorrectCardDetails" && <IncorrectCardDetails />}
      {showComponent === "COD_FOR_CREDIT_CARD" && <CODForCC />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
};

export default View;
