import React from "react";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
// import agentIcon from "../../../images/icon-chat-agent.svg";
import agentIcon from "../../../images/img-agent.png";

const AgentConnected = () => {
  return (
    <div className="w-full my-8">
      <BaseIcon className="w-24 h-24" icon={agentIcon} name="Agent" center />
      <div className="text-center text-default leading-7 text-srgray-primary">
        Agent has joined and
        <br /> will be ready to chat in a minute.
      </div>
    </div>
  );
};

export default AgentConnected;
