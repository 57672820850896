import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import { TextLight, TextMedium } from "../../../base/BaseText";

import NotesIcon from "../../../images/icon-requirements.svg";
import { importantNoteLoaded } from "../state/operators/importantNoteOperator";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { useSelector } from "react-redux";
import { CLIENT } from "../../../helpers/constants";

const ImportantNote = () => {
  const dispatch = useDispatch();
  const t = useTranslate();

  useEffect(() => {
    dispatch(importantNoteLoaded());
  }, []);

  const client = useSelector((state) => state.app.client);
  const isSingtel = client === CLIENT.SINGTEL;

  const repairOption = useSelector((state) =>
    state.journeyMessages.repairOption
  );

  return (
    <BaseCard>
      <BaseIcon icon={NotesIcon} name="Important Note" center />
      <BaseLabel>{t("ImportantNote.Title")}</BaseLabel>
      <BaseLabel className="text-left text-1.3">
        <TextLight>
          <ul className="list-inside list-decimal">
            <li>
              <div className="pl-8 -mt-8">
              <Translate i18nKey={`${isSingtel ? "ImportantNote.Content.PresentDevice" : `ImportantNote.${repairOption}.Content.PresentDevice`}`}>
                  You will need to present your device to our courier who will
                  verify (i) your identity and (ii) the IMEI of your device at
                  the time of collection and return.
                  <br />
                  <br />
                </Translate>
              </div>
            </li>
            <li>
              <div className="pl-8 -mt-8">
              <Translate i18nKey={`${isSingtel ? "ImportantNote.Content.BackupDevice" : `ImportantNote.${repairOption}.Content.BackupDevice`}`}>
                  Please remember to back up your device's content and perform
                  the following steps before handing the device to our courier.{" "}
                  <br />
                  <br />
                  <TextMedium>Step (1)</TextMedium>: Delete all user content and
                  data from your phone.
                  <br />
                  <TextMedium>Step (2)</TextMedium>: Disable all activation
                  locks (eg. FMiP), personal security pins and passwords from
                  the phone.
                  <br />
                  <TextMedium>Step (3)</TextMedium>: Perform a factory reset.{" "}
                  <br />
                  <br />
                  <TextMedium>Note:</TextMedium> Any remaining content will be
                  deleted at the time of repair.
                </Translate>
              </div>
            </li>
          </ul>
        </TextLight>
      </BaseLabel>
    </BaseCard>
  );
};

export default ImportantNote;
