import React from "react";
import CameraIcon from "../../../images/icon-camera.svg";
import PowerIcon from "../../../images/icon-power-button.svg";
import LiquidExposeIcon from "../../../images/icon-liquid-expose.svg";
import PhysicalDamageIcon from "../../../images/icon-damage.svg";
import AppsIcon from "../../../images/icon-apps.svg";
import { TextLight, TextMedium } from "../../../base/BaseText";
import BaseLink from "../../../base/BaseLink/BaseLink";

const CameraWorking = {
  icon: CameraIcon,
  title: (
    <TextLight>
      My device's camera is in good working condition and it's still able to
      take photos.
    </TextLight>
  ),
};

const PowerOn = {
  icon: PowerIcon,
  title: <TextLight>My device is <TextMedium>able</TextMedium> to power on.</TextLight>,
};

const LiquidExposure = {
  icon: LiquidExposeIcon,
  title: (
    <TextLight>
      My device has <TextMedium>not</TextMedium> been exposed to any liquid
    </TextLight>
  ),
};

const PhysicalDamage = {
  icon: PhysicalDamageIcon,
  title: (
    <TextLight>
      My device does <TextMedium>not</TextMedium> have any other physical damage
      other than just a damaged screen. (for example device being bent)
    </TextLight>
  ),
};

const ActivtionLockOff = {
  icon: AppsIcon,
  title: <TextLight>I have <TextMedium>turned off</TextMedium> all activation locks on my device (e.g. Find My iPhone, Google Lock, Samsung Knox, etc <BaseLink className="text-srgreen-secondary cursor-pointer"><TextMedium>Click here</TextMedium></BaseLink> for guides).</TextLight>,
};

export default {
  CameraWorking,
  PowerOn,
  LiquidExposure,
  PhysicalDamage,
  ActivtionLockOff
};
