import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseInformationBox from "../../../base/BaseInformationBox";
import BaseInput from "../../../base/BaseInput/BaseInput";
import BaseLabel from "../../../base/BaseLabel";
import { TextLight } from "../../../base/BaseText";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";

import { changeAddressSchedule } from "../state/operators/scheduleOperator";
import PickUpAddressIcon from "../../../images/icon-address.svg";
import CelcomDeliveryIcon from "../../../images/icon-delivery-celcom.svg";
import { isEmpty } from "../../../helpers/formatUtils";
import BaseDropdown from "../../../base/BaseDropdown/BaseDropdown";
import { CLIENT } from "../../../helpers/constants";
import { Translate, useTranslate } from "../../../helpers/languageUtils";

const ChangeAddress = ({ isPickup }) => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const which = isPickup ? "Pick-up" : "Return";
  const where = isPickup ? "Pickup from" : "Deliver to";

  const [disabled, setDisabled] = useState(false);
  const [unitNumber, setUnitNumber] = useState("");
  const [buildingName, setBuildingName] = useState("");
  // for celcom blockNumber is state province
  const [blockNumber, setBlockNumber] = useState("");
  // for celcom streetName is city
  const [streetName, setStreetName] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const client = useSelector((state) => state.app.client);

  const stateProvinceOptions = [
    { id: 1, text: "Johor" },
    { id: 2, text: "Kedah" },
    { id: 3, text: "Kelantan" },
    { id: 4, text: "Kuala Lumpur" },
    { id: 5, text: "Labuan" },
    { id: 6, text: "Melaka" },
    { id: 7, text: "Negeri Sembilan" },
    { id: 8, text: "Pahang" },
    { id: 9, text: "Perak" },
    { id: 10, text: "Perlis" },
    { id: 11, text: "Pulau Pinang" },
    { id: 12, text: "Putrajaya" },
    { id: 13, text: "Sabah" },
    { id: 14, text: "Sarawak" },
    { id: 15, text: "Selangor" },
    { id: 16, text: "Terengganu" },
  ];

  const handlePostalCodeChange = (code) => {
    if (!isNaN(code.trim())) setPostalCode(code.trim());
  };

  const handleSubmit = () => {
    const textPrint = t("SubmitButton");
    setDisabled(true);
    const address = {
      unitNumber: unitNumber.trim(), //add1
      buildingName: buildingName.trim(), //add2
      blockNumber: blockNumber.trim(), //state
      streetName: streetName.trim(), //dist
      postalCode, //pin
    };
    dispatch(
      changeAddressSchedule(
        address,
        isPickup,
        isPickup
          ? t("ChangeAddress.LabelPickUpAddress")
          : t("ChangeAddress.LabelReturnAddress")
      )
    );
  };

  const clearAllFields = () => {
    setUnitNumber("");
    setBuildingName("");
    setBlockNumber("");
    setStreetName("");
    setPostalCode("");
  };

  const shouldntSubmit =
    disabled ||
    isEmpty(unitNumber) ||
    isEmpty(blockNumber) ||
    isEmpty(streetName) ||
    isEmpty(postalCode);

  return (
    <BaseCard>
      <BaseIcon
        icon={isPickup ? PickUpAddressIcon : CelcomDeliveryIcon}
        name="Tracking"
        center
      />
      <BaseLabel>
        <Translate i18nKey="ChangeAddress.Title">
          <TextLight>Enter New {{ which }} Address </TextLight>
        </Translate>
      </BaseLabel>
      <BaseLabel className="text-left my-0 mt-4">
        <span className="font-extrabold">
          <Translate i18nKey="ChangeAddress.Input.Address1.Label" />
        </span>
      </BaseLabel>
      <BaseInput
        className="mb-2"
        placeholder={t("ChangeAddress.Input.Address1.Placeholder")}
        value={unitNumber}
        onChange={setUnitNumber}
        disabled={disabled}
        full
        autoFocus
      />
      <BaseLabel className="text-left my-0 mt-4">
        <span className="font-extrabold">
          <Translate i18nKey="ChangeAddress.Input.Address2.Label" />
        </span>
      </BaseLabel>
      <BaseInput
        className="my-2"
        placeholder={t("ChangeAddress.Input.Address2.Placeholder")}
        value={buildingName}
        onChange={setBuildingName}
        disabled={disabled}
        full
      />
      <BaseLabel className="text-left my-0 mt-4">
        <span className="font-extrabold">
          <Translate i18nKey="ChangeAddress.Input.State.Label" />
        </span>
      </BaseLabel>
      {client === CLIENT.SINGTEL ? (
        <BaseInput
          className="my-2"
          placeholder={t("ChangeAddress.Input.State.Placeholder")}
          value={blockNumber}
          onChange={setBlockNumber}
          disabled={disabled}
          full
        />
      ) : (
        <BaseDropdown
          placeHolder={t("ChangeAddress.Input.State.Placeholder")}
          value={blockNumber}
          onChange={setBlockNumber}
          options={stateProvinceOptions}
          disabled={disabled}
          identifier="text"
          printer="text"
          className="w-full"
        />
      )}
      <BaseLabel className="text-left my-0 mt-4">
        <span className="font-extrabold">
          <Translate i18nKey="ChangeAddress.Input.District.Label" />
        </span>
      </BaseLabel>
      <BaseInput
        className="my-2"
        placeHolder={t("ChangeAddress.Input.District.Placeholder")}
        value={streetName}
        onChange={setStreetName}
        disabled={disabled}
        full
      />
      {/* <BaseDropdown
        placeHolder={t("ChangeAddress.Input.District.Placeholder")}
        value={streetName}
        onChange={setStreetName}
        options={districtOptions}
        disabled={disabled}
        identifier="text"
        printer="text"
        className="w-full"
      /> */}
      <BaseLabel className="text-left my-0 mt-4">
        <span className="font-extrabold">
          <Translate i18nKey="ChangeAddress.Input.PostalCode.Label" />
        </span>
      </BaseLabel>
      <BaseInput
        className="mt-2"
        placeholder={t("ChangeAddress.Input.PostalCode.Placeholder")}
        value={postalCode}
        onChange={handlePostalCodeChange}
        disabled={disabled}
        full
        maxLength={6}
        onEnterPress={shouldntSubmit ? () => {} : handleSubmit}
      />
      {client === CLIENT.SINGTEL ? (
      <BaseInformationBox>
        <Translate i18nKey="ChangeAddress.Information">
          <TextLight>
            Please note that we’re unable to {{ where }} shopping malls, coffee
            shops, hawker centers, so kindly provide proper residential or
            office address here.
          </TextLight>
        </Translate>
      </BaseInformationBox>
      ) : ""
}
      <BaseButtonGroup>
        <BaseButton
          text={t("ClearButton")}
          onClick={clearAllFields}
          disabled={disabled}
        />
        <BaseButton
          text={t("SubmitButton")}
          onClick={handleSubmit}
          disabled={shouldntSubmit}
          clicked={disabled}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ChangeAddress.propTypes = {
  isPickup: PropTypes.bool,
};

ChangeAddress.defaultProps = {
  isPickup: true,
};

export default ChangeAddress;
