import React from "react";
import classNames from "classnames";
import Timestamp from "./Timestamp";
import { isEmpty } from "../helpers/formatUtils";
import { Translate, useTranslate } from "../helpers/languageUtils";
import { TextMedium } from "../base/BaseText";

const TextMessage = ({ source, data, time }) => {
  const t = useTranslate();
  const isUser = source === "User";
  const isSystem = source === "System" || source === "Agent";

  return (
    <div
      className={classNames(
        "app__message",
        { "app__message-left": isSystem },
        { "app__message-right": isUser }
      )}
    >
      <Timestamp time={time}>
        <div
          className={classNames(
            "app__message-content",
            { "app__message-received": isSystem },
            { "app__message-send": isUser }
          )}
        >
          {!isEmpty(data) && !isEmpty(data.key)
            ? isSystem ? <Translate
            i18nKey={data.key}
            components={{ m: <TextMedium /> }}
            values={{ ...data.values }}
          /> : t(data.key, { ...data.values })
            : data}
        </div>
      </Timestamp>
    </div> 
  );
};

export default TextMessage;
