import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import TrackingIcon from "../../../images/icon-confirm-address-details.svg";
import { TextLight } from "../../../base/BaseText";
import { changePURSchedule, redirectToConfirmPhone } from "../state/operators/scheduleOperator";
import BaseTable, {
  BaseTR,
  BaseTH,
  BaseTD,
} from "../../../base/BaseTable/BaseTable";
import { getFormmatedDate } from "../../../helpers/dateUtils";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { CLIENT } from "../../../helpers/constants";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";

const ConfirmDetails = () => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const client = useSelector((state) => state.app.client);
  const scheduleData = useSelector((state) => state.claim.schedule);
  const { isAfterCutoffError } = useSelector((state) => state.claim.schedule);

  const determinDetails = useSelector(
    (state) => state.serviceRequest.determineIncidentDetails
  );

  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );
  const isChangeSchedule = enquiryOption === EnumEnquiryModules.ChangeSchedule;

  const tableHeaders = [
    { key: "pickupAddress", text: t("ConfirmAddress.LabelforPickup") },
    { key: "returnAddress", text: t("ConfirmAddress.LabelforReturn") },
    { key: "pickupDateTime", text: t("ConfirmAddress.LabelforPickupDateTime") },
    { key: "returnDateTime", text: t("ConfirmAddress.LabelforReturnDateTime") },
  ];

  const getAddressText = (address) => {
    const {
      Address1,
      Address2,
      Address3,
      City,
      PostalCode,
      StateProvinceCode,
    } = address;
    return `${Address1} ${Address2} ${Address3} ${
      client === CLIENT.SINGTEL ? City : StateProvinceCode
    } ${PostalCode}`;
  };

  // for Repair Stauts other than Scheduled system doesn't allow to change pick-up details so showing same details here

  const tableData = [
    {
      pickupAddress: getAddressText(scheduleData.selectedPickupAddress),
      returnAddress: getAddressText(scheduleData.selectedDeliveryAddress),
      pickupDateTime:
        isChangeSchedule &&
        determinDetails.RepairRequest.RepairStatus !== "SCHEDULED"
          ? `${getFormmatedDate(
              determinDetails.ShippingOrderOutbound.ExpectedDeliveryDate,
              "d MMM yyyy"
            )} ${
              determinDetails.ShippingOrderOutbound.DeliveryWindow === "16H"
                ? "12pm - 4pm"
                : determinDetails.ShippingOrderOutbound.DeliveryWindow === "12H"
                ? "9am - 12pm"
                : ""
            }`
          : `${getFormmatedDate(
              scheduleData.selectedPickupDate,
              "d MMM yyyy"
            )} ${scheduleData.selectedPickupSlot}`,
      // pickupDateTime: `${getFormmatedDate(
      //   scheduleData.selectedPickupDate,
      //   "d MMM yyyy"
      // )} ${scheduleData.selectedPickupSlot}`,
      returnDateTime: `${getFormmatedDate(
        scheduleData.selectedDeliveryDate,
        "d MMM yyyy"
      )} ${scheduleData.selectedDeliverySlot}`,
    },
  ];

  const [disabled, setDisabled] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState("");

  const handleConfirmDetails = (status) => {
    const textPrint = t(`${status}Button`);
    setConfirmationStatus(status);
    setDisabled(true);

    if(isChangeSchedule)
      dispatch(changePURSchedule(status, textPrint));
    else
      dispatch(redirectToConfirmPhone(status, textPrint, isAfterCutoffError));
  };

  return (
    <BaseCard>
      <BaseIcon icon={TrackingIcon} name="Tracking" center />
      <BaseLabel>
        <Translate i18nKey="ConfirmScheduleDetails.Title">
          <TextLight>
            Please confirm the details of
            <br /> pick up and return schedule of your device
          </TextLight>
        </Translate>
      </BaseLabel>
      <BaseTable title={t("ConfirmScheduleDetails.Table.Title")}>
        {tableHeaders.map((header) => (
          <BaseTR key={header.key}>
            <BaseTH>{header.text}</BaseTH>
            {tableData.map((data, i) => (
              <BaseTD key={`${header.key}_${i}`}>{data[header.key]}</BaseTD>
            ))}
          </BaseTR>
        ))}
      </BaseTable>
      <BaseButtonGroup>
        {/* {!scheduleData.addressUpdated && ( */}
        <BaseButton
          text={isChangeSchedule ? t("CancelButton") : t("ChangeButton")}
          onClick={() => handleConfirmDetails(isChangeSchedule ? "Cancel" : "Change")}
          disabled={disabled}
          clicked={confirmationStatus === "Change"}
        />
        {/* )} */}
        <BaseButton
          text={t("ConfirmButton")}
          onClick={() => handleConfirmDetails("Confirm")}
          disabled={disabled}
          clicked={confirmationStatus === "Confirm"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmDetails;
