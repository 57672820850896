import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import { sleep } from "../../../../helpers/addJourneyMessages";
import { endFlow } from "../../../../actions/flowWindow";
import { CLIENT } from "../../../../helpers/constants";

export const importantNoteLoaded = () => async (dispatch, getStore) => {
  const { enquiryOption } = getStore().journeyMessages;
  const client = getStore().app.client;
  const isSingtel = client === CLIENT.SINGTEL;
  switch (enquiryOption) {
    case EnumEnquiryModules.ChangeSchedule:
      await sleep(10000);
      dispatch(endFlow());
      break;
    case EnumEnquiryModules.InWarrantyRequest:
      await sleep(30000);
      dispatch(endFlow());
      break;
    case EnumEnquiryModules.NewRequest:
    case EnumEnquiryModules.ResumeRequest:
      if (!isSingtel) {
        await sleep(30000);
        dispatch(endFlow());
      }
      break;
    default:
      break;
  }
};
