import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseDropdown from "../../../base/BaseDropdown/BaseDropdown";
import VerifyPhoneIcon from "../../../images/icon-verify-phone.svg";
import { isEmpty } from "../../../helpers/formatUtils";
import {
  updateEnrolledDevice,
  getDeviceMakeModelOptions,
} from "../state/operators/deviceConfirmationOperators";
import BaseInput from "../../../base/BaseInput/BaseInput";
import { ReactComponent as FileUploadImg} from '../../../images/file-upload.svg';
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { TextLight } from "../../../base/BaseText";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { createUserMessage } from "../../../helpers/createMessage";
import { addJourneyMessages, sleep } from "../../../helpers/addJourneyMessages";

const ChooseEnrolledDevice = ({ selectedimei = '' }) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const { exchangeType } = useSelector((state) => state.journeyMessages)
  const deviceData = useSelector((state) => state.claim.deviceConfirmation);
  const { makeOptions, modelOptions } = deviceData;  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [imei, setImei] = useState(selectedimei);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedFile, setSelectedFile] = useState(null)
  const [base64, setBase64] = useState(null)
  const exchangeOptions = t("ExchangeOption.ExchangeOptions",{ returnObjects: true });

  const handleSubmit = async () => {
    setSelectedOption('Submit')
    const fileName = selectedFile.name.replace(/\s/g, "");
    const deviceData = {
      make,
      model,
      imei,
      file: {
        fileId: "file-0",
        name: fileName,
        fileType: selectedFile.type,
        fileSize: selectedFile.size,
        data: base64
      },
    }
    await dispatch(addJourneyMessages(createUserMessage('TEXT', t("SubmitButton"))))
    await dispatch(updateEnrolledDevice(deviceData, t("SystemMessage.NewDeviceRegistered")));
  }

  const hadleCancel = async () => {
    setSelectedOption('Cancel')
    await dispatch(addJourneyMessages(createUserMessage('TEXT', t("CancelButton"))))
    await sleep(1000)
    // dispatch(endFlow())
    window.location.reload();
  }


  useEffect(() => {
    dispatch(getDeviceMakeModelOptions());
  }, []);

  const handleMakeChange = (makeId) => {
    dispatch(getDeviceMakeModelOptions(makeId));
    setMake(makeId);
    setModel("");
  };

  const handleModelChange = (modelId) => {
    setModel(modelId);
  };

  const handleImeiChange = (value) => {
    if (!isNaN(value)) setImei(value);
  };


  const handleFileChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    console.log("reader", reader)
    console.log("file", file)
    if (reader !== undefined && file !== undefined) {
      reader.onloadend = (readerEvent) => {
        setBase64(readerEvent.target.result)
        setSelectedFile(file)
      }
      reader.readAsDataURL(file);
    }
  }

  const shouldntSubmit =
    !!selectedOption ||
    isEmpty(make) ||
    isEmpty(model) ||
    isEmpty(imei) ||
    !(selectedFile && selectedFile.name) ||
    imei.length !== 15;

  return (
    <BaseCard>
      <BaseIcon icon={VerifyPhoneIcon} name="Choose device icon" center />
      <BaseLabel className="text-center">
        <Translate i18nKey={exchangeType === exchangeOptions[2] ? 'ChooseEnrolledDevice.Title2' : 'ChooseEnrolledDevice.Title1'}>
          <TextLight>
          </TextLight>
        </Translate>
      </BaseLabel>
      <BaseLabel className="my-0 mt-4 text-left">
        <span className="font-extrabold">
          <Translate i18nKey="ChooseEnrolledDevice.Label.DeviceMake" />
        </span>
      </BaseLabel>
      <BaseDropdown
        className="w-full"
        placeHolder={t("ChooseEnrolledDevice.Input.SelectMake")}
        value={make}
        onChange={handleMakeChange}
        options={makeOptions}
        disabled={!!selectedOption || isEmpty(makeOptions)}
        identifier="Make"
        printer="Make"
      />
      <BaseLabel className="my-0 mt-4 text-left">
        <span className="font-extrabold">
          <Translate i18nKey="ChooseEnrolledDevice.Label.DeviceModel" />
        </span>
      </BaseLabel>
      <BaseDropdown
        className="w-full"
        placeHolder={t("ChooseEnrolledDevice.Input.SelectModel")}
        value={model}
        onChange={handleModelChange}
        options={modelOptions}
        disabled={!!selectedOption || isEmpty(modelOptions)}
        identifier="ModelId"
        printer="PopularName"
      />
      <BaseLabel className="my-0 mt-4 text-left">
        <span className="font-extrabold">
          <Translate i18nKey="ChooseEnrolledDevice.Label.Imei" />
        </span>
      </BaseLabel>
      <BaseInput
        placeholder={t("ChooseEnrolledDevice.Input.EnterImei")}
        value={imei}
        onChange={handleImeiChange}
        className="w-full mt-2 mb-4"
        maxLength={15}
        disabled={!!selectedOption}
      />
      <div className="my-2">
        <label className="flex flex-col rounded-lg border-4 border-dashed w-full p-3 group text-center cursor-pointer ">
          {selectedFile ?
            <div className="text-left">
              <span className="text-blue1 text-12px">{selectedFile.name}</span>
              <div className="bar bg-red1 h-1"></div>
            </div>
            :
            <p className="pointer-none  text-srgreen-tertiary flex items-center justify-center"><FileUploadImg /><span className="ml-2 text-xl">{t("ChooseEnrolledDevice.UploadDoc")}</span></p>}
          <input type="file" className="hidden" onChange={handleFileChange} />
        </label>
      </div>

      <BaseLabel className="text-center">
      <TextLight className="mt-2 mb-4 text-center text-xl">
        {t("ChooseEnrolledDevice.Note")}
      </TextLight>
      </BaseLabel>
      <BaseButtonGroup className="mt-4">
        <BaseButton
          onClick={hadleCancel}
          clicked={selectedOption === "Cancel"}
          disabled={!!selectedOption}
          text={t("CancelButton")}
        >
        </BaseButton>
        <BaseButton
          onClick={handleSubmit}
          clicked={selectedOption === "Submit"}
          disabled={shouldntSubmit}
          text={t("SubmitButton")}
        >
        </BaseButton>
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ChooseEnrolledDevice;
