import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  selectedPickupDate: "",
  selectedPickupSlot: "",
  selectedDeliveryDate: "",
  selectedDeliverySlot: "",
  selectedPickupAddress: "",
  selectedDeliveryAddress: "",
  registeredAddress: "",
  pickUpAddressUpdated: false,
  deliveryAddressUpdated: false,
  pickupOptions: [],
  isCampaignTimingEligible: false,
  isAfterCutoffError: false,
};

const scheduleReducer = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    setPickupOptions(state, action) {
      state.pickupOptions = action.payload;
    },
    setPickupDateTime(state, action) {
      state.selectedPickupDate = action.payload.date;
      state.selectedPickupSlot = action.payload.slot;
    },
    setDeliveryDateTime(state, action) {
      state.selectedDeliveryDate = action.payload.date;
      state.selectedDeliverySlot = action.payload.slot;
    },
    setPickupAddress(state, action) {
      state.selectedPickupAddress = action.payload.address;
      state.pickUpAddressUpdated = action.payload.isUpdated
    },
    setDeliveryAddress(state, action) {
      state.selectedDeliveryAddress = action.payload.address;
      state.deliveryAddressUpdated = action.payload.isUpdated
    },
    setRegisteredAddress(state, action) {
      state.registeredAddress = action.payload;
    },
    callStandardizeAddressApi(state, action) {
      state.address = action.payload;
    },
    callStandardizeAddressApiSuccess(state, action) {
      state.address = action.payload;
    },
    callStandardizeAddressApiFailure(state, action) {
      state.error = action.payload;
    },
    callServiceOrderApi(state, action) {
      state.serviceOrder = action.payload;
    },
    callServiceOrderApiSuccess(state, action) {
      state.serviceOrder = action.payload;
    },
    callServiceOrderApiFailure(state, action) {
      state.error = action.payload;
    },
    callCreateRepairRequestApi(state, action) {
      state.createRepairRequest = action.payload;
    },
    callCreateRepairRequestApiSuccess(state, action) {
      state.createRepairRequest = action.payload;
    },
    callCreateRepairRequestApiFailure(state, action) {
      state.error = action.payload;
    },
    callGetShippingApi(state, action) {
      state.getShipping = action.payload;
    },
    callGetShippingApiSuccess(state, action) {
      state.getShipping = action.payload;
    },
    callGetShippingApiFailure(state, action) {
      state.error = action.payload;
    },
    callUpdateShippingApi(state, action) {
      state.updateShipping = action.payload;
    },
    callUpdateShippingApiSuccess(state, action) {
      state.updateShipping = action.payload;
    },
    callUpdateShippingApiFailure(state, action) {
      state.error = action.payload;
    },
    callUpdatePURScheduleApi(state, action) {
      state.updatePURSchedule = action.payload;
    },
    callUpdatePURScheduleApiSuccess(state, action) {
      state.updatePURSchedule = action.payload;
    },
    callUpdatePURScheduleApiFailure(state, action) {
      state.error = action.payload;
    },
    setisCampaignTimingEligible(state, action) {
      state.isCampaignTimingEligible = action.payload;
    },
    setIsAfterCutoffError(state, action) {
      state.isAfterCutoffError = action.payload;
    }
  },
});

const { actions, reducer } = scheduleReducer;
export const {
  resetStore,
  setPickupOptions,
  setPickupDateTime,
  setDeliveryDateTime,
  setPickupAddress,
  setDeliveryAddress,
  setRegisteredAddress,
  callStandardizeAddressApi,
  callServiceOrderApi,
  callCreateRepairRequestApi,
  callGetShippingApi,
  callUpdateShippingApi,
  callUpdatePURScheduleApi,
  setisCampaignTimingEligible,
  setIsAfterCutoffError
} = actions;
export default reducer;
