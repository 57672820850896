import {callInitializeApi} from "./../reducers/sessionReducer";

const initializeApi = (client, language) => ({
    type: callInitializeApi.toString(),
    meta: {
        async: true,
        blocking: true,
        path: `/incidentidentification/v1/initialize`,
        method: "POST",
        body: {
            InitializeApplicationRequest: {
              Client: client,
              Language: language,
            }
        }
    },
});

export default initializeApi;