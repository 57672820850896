import React, { useState } from "react";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
// import agentIcon from "../../../images/icon-chat-agent.svg";
import agentIcon from "../../../images/img-agent.png";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { useDispatch } from "react-redux";
import { closeAgentBusy, retryChat } from "../state/operators";
import BaseCard from "../../../base/BaseCard/BaseCard";
import { useTranslate } from "../../../helpers/languageUtils";

const AgentsBusy = () => {
  const t = useTranslate();
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleCloseClick = () => {
    dispatch(closeAgentBusy(t("CloseButton")));
    setDisabled(true);
  };

  const handleRetryClick = () => {
    dispatch(retryChat(t("RetryButton")));
    setDisabled(true);
  };

  return (
    <BaseCard>
      <BaseIcon className="w-24 h-24" icon={agentIcon} name="Agent" center />
      <div className="text-center text-default leading-7 text-srgray-primary">
        All the agents are busy at the moment. Please try again later.
        <br />
        Thank you for connecting Screen Repair Service
      </div>
      <BaseButtonGroup className="mt-6">
        <BaseButton
          text={t("CloseButton")}
          onClick={handleCloseClick}
          disabled={disabled}
        />
        <BaseButton
          text={t("RetryButton")}
          onClick={handleRetryClick}
          clicked={disabled}
          disabled={disabled}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default AgentsBusy;
