import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
// import {APIProvider, Map, AdvancedMarker} from '@vis.gl/react-google-maps';
import PropTypes from "prop-types";
// import generateUniqueId from "../../../helpers/generateUniqueId";

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "118px",
};

class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14}
        containerStyle={containerStyle}
        initialCenter={{
          lat: this.props.latitude,
          lng: this.props.longitude,
        }}
      >
        <Marker onClick={this.onMarkerClick} name={"Current location"} />
      </Map>
      // <APIProvider apiKey={`${process.env.REACT_APP_GMAPS_KEY}`}>
      //   <Map
      //     defaultCenter={{lat: parseFloat(this.props.latitude ? this.props.latitude : 0), lng: parseFloat(this.props.longitude ? this.props.longitude : 0)}}
      //     defaultZoom={15}
      //     mapId={`map-${generateUniqueId()}`}
      //     style={containerStyle}
      //   >
      //     <AdvancedMarker position={{lat: parseFloat(this.props.latitude ? this.props.latitude : 0), lng: parseFloat(this.props.longitude ? this.props.longitude : 0)}} />
      //   </Map>
      // </APIProvider>
    );
  }
}

MapContainer.defaultProps = {
  latitude: 0,
  longitude: 0,
};

MapContainer.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
};

export default GoogleApiWrapper({
  apiKey: `${process.env.REACT_APP_GMAPS_KEY}`,
})(MapContainer);

// export default MapContainer;
