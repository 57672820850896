import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import { cancelServiceRequest } from "../../../../actions/serviceRequestApi";
import updateVisitor from "../../../Appsync/updateVisitor";
import CONSTANTS, { ACTIVITY } from "../../../../helpers/constants";
import { serviceUnavailable } from "../../../../actions/flowWindow";
import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import { isEmpty } from "../../../../helpers/formatUtils";

export const confirmIfRequestCollected = (status, subreason) => (
  dispatch,
  getStore
) => {
  const { CacheId } = getStore().session.sessionData.Configurations;
  const enquiryOption = getStore().journeyMessages.enquiryOption;

  const { ServiceRequestId, CustomerCaseId } =
    enquiryOption === EnumEnquiryModules.CancelRequest
      ? getStore().serviceRequest.determineIncidentDetails
      : getStore().serviceRequest.serviceRequestDetails;

  const {
    ServiceOrder,
    RepairRequest,
    ChargeOrder,
  } = getStore().serviceRequest.determineIncidentDetails;

  let ServiceOrderId = "";
  // get service order id if available
  if (!isEmpty(getStore().claim.schedule.serviceOrder)) {
    // get it from service order api response
    ServiceOrderId = getStore().claim.schedule.serviceOrder
      .CreateServiceOrderResults.ServiceOrderId;
  } else if (!isEmpty(ServiceOrder)) {
    // get it from determine incident api response
    ServiceOrderId = ServiceOrder.ServiceOrderId;
  }

  // default cancel action
  let cancelAction = "CancelWorkingClaim";

  if (
    ChargeOrder &&
    RepairRequest &&
    (RepairRequest.RepairStatus === "RESERVED" ||
      RepairRequest.RepairStatus === "SCHEDULED")
  ) {
    // cancel action if claim is submitted
    cancelAction = "Cancelsubmitclaim";
  }

  const note = "Request Cancelled by User";
  // let module = "ENQUIRY_OPTIONS", showComponent;
  let module = "CANCEL_REPAIR",
    showComponent = "requestCancelled";
  // if (status === "No") {
  //   module = "CANCEL_REPAIR";
  //   showComponent = "requestCancelled";
  // }
  dispatch(
    cancelServiceRequest(
      CacheId,
      ServiceRequestId,
      note,
      subreason,
      CustomerCaseId,
      ServiceOrderId,
      cancelAction
    )
  ).catch((err) => dispatch(serviceUnavailable()));

  dispatch(
    updateVisitor({
      lastActivity: ACTIVITY.CANCEL_REQUEST,
      journeyStatus: CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.COMPLETED,
      // journeyCmpltd: true
    })
  );

  if (status) {
    dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));
  }

  dispatch(
    addJourneyMessages([createMessage(module, "system", { showComponent })])
  );
};

export const discardCancelRequest = (status) => async (dispatch) => {
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));
  await sleep(2000);
  dispatch(
    addJourneyMessages([createMessage("ENQUIRY_OPTIONS", "system", {})])
  );
};

export const cancelDPRequest = (status, subreason) => async (
  dispatch,
  getStore
) => {
  const { CacheId } = getStore().session.sessionData.Configurations;
  const { ServiceRequestId, CustomerCaseId } = getStore().serviceRequest.determineIncidentDetails

  const {
    ServiceOrder,
    RepairRequest,
    ChargeOrder,
  } = getStore().serviceRequest.determineIncidentDetails;

  let ServiceOrderId = "";
  // get service order id if available
  if (!isEmpty(getStore().claim.schedule.serviceOrder)) {
    // get it from service order api response
    ServiceOrderId = getStore().claim.schedule.serviceOrder
      .CreateServiceOrderResults.ServiceOrderId;
  } else if (!isEmpty(ServiceOrder)) {
    // get it from determine incident api response
    ServiceOrderId = ServiceOrder.ServiceOrderId;
  }

  // default cancel action
  let cancelAction = "CancelWorkingClaim";

  if (
    ChargeOrder &&
    RepairRequest &&
    (RepairRequest.RepairStatus === "RESERVED" ||
      RepairRequest.RepairStatus === "SCHEDULED")
  ) {
    // cancel action if claim is submitted
    cancelAction = "Cancelsubmitclaim";
  }

  const note = "Request Cancelled by User";

  if (status) {
    dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));
  }

  await dispatch(
    cancelServiceRequest(
      CacheId,
      ServiceRequestId,
      note,
      subreason,
      CustomerCaseId,
      ServiceOrderId,
      cancelAction
    )
  ).catch((err) => {
    // dispatch(serviceUnavailable())
    console.log("cancelServiceRequest error:", err)
  });

  dispatch(
    updateVisitor({
      lastActivity: ACTIVITY.CANCEL_DP_REQUEST
    })
  );

  dispatch(
    addJourneyMessages([
      createMessage("DEVICE_CONFIRMATION", "system", {
        showComponent: "deviceList",
      }),
    ])
  );
};
