import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseDropdown from "../../../base/BaseDropdown/BaseDropdown";
import { TextDanger, TextLight, TextMedium } from "../../../base/BaseText";
import BaseInformationBox from "../../../base/BaseInformationBox";

import CalendarCheckedIcon from "../../../images/icon-calendar-selected.svg";
import { changeDateTimeSchedule } from "../state/operators/scheduleOperator";
import { getDeliveryDates } from "../../../helpers/dateUtils";
import { isEmpty } from "../../../helpers/formatUtils";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { CLIENT } from "../../../helpers/constants";
import { INCIDENT_BATTERY_REPLACEMENT } from "../../../components/EnquiryOptions/Constants";

const ChangeDateTime = ({ isPickup }) => {
  const { caseType } = useSelector((state) => state.journeyMessages);
  const t = useTranslate();
  const dispatch = useDispatch();
  let dateOptions = [];
  const [slotOptions, setSlotOptions] = useState([]);
  const which = isPickup ? "Pick-up" : "Return";

  const [disabled, setDisabled] = useState(false);
  const [date, setDate] = useState("");
  const [slot, setSlot] = useState("");

  const pickupOptions = useSelector(
    (state) => state.claim.schedule.pickupOptions
  );
  const pickupDate = useSelector(
    (state) => state.claim.schedule.selectedPickupDate
  );
  const pickupSlot = useSelector(
    (state) => state.claim.schedule.selectedPickupSlot
  );
  const selectedDeliveryAddress = useSelector(
    (state) => state.claim.schedule.selectedDeliveryAddress
  );
  const client = useSelector((state) => state.app.client);

  const isCampaignTimingEligible = useSelector(
    (state) => state.claim.schedule.isCampaignTimingEligible
  );
  const returnSLA = client === CLIENT.SINGTEL && isCampaignTimingEligible ? "1-2 business days" : "2 business days"

  if (isPickup) {
    dateOptions = pickupOptions.map((obj) => {
      return {
        id: obj.date,
        text: new Date(obj.date).toDateString(),
        slots: obj.slots,
      };
    });
  } else {
    const deliveryOptions = client === CLIENT.SINGTEL && isCampaignTimingEligible
      ? getDeliveryDates(
        pickupDate,
        selectedDeliveryAddress.PostalCode,
        client,
        pickupSlot,
        true,
        true)
      : getDeliveryDates(
        pickupDate,
        selectedDeliveryAddress.PostalCode,
        client
      );

    dateOptions = deliveryOptions.map((obj) => {
      return {
        id: obj.date,
        text: new Date(obj.date).toDateString(),
        slots: obj.slots,
      };
    });
  }

  const handleDateChange = (dateStr) => {
    setSlot("");
    setDate(dateStr);
    let opt = dateOptions.find((o) => o.id === dateStr);
    setSlotOptions(opt.slots);
  };

  const handleSubmit = () => {
    const textPrint = t("SubmitButton");
    setDisabled(true);
    dispatch(
      changeDateTimeSchedule(
        date,
        slot,
        isPickup,
        textPrint,
        isPickup
          ? t("ChangeDateTime.LabelNewPickUpDateTime")
          : t("ChangeDateTime.LabelNewReturnDateTime")
      )
    );
  };

  const clearAllFields = () => {
    setDate("");
    setSlot("");
  };

  const shouldntSubmit = disabled || isEmpty(date) || isEmpty(slot);
  const isSingtel = client === CLIENT.SINGTEL;
  const { ClientProductSkuNbr } = useSelector((state) => state.validation?.verifyAgreement?.VerifyAgreementsResults?.Agreement);
  const isSingtelStarter = isSingtel && (ClientProductSkuNbr && ClientProductSkuNbr.toLowerCase().indexOf("starter") > -1)

  return (
    <BaseCard>
      <BaseIcon icon={CalendarCheckedIcon} name="Calendar" center />
      <BaseLabel>
        <Translate i18nKey="ChangeDateTime.Title">
          <TextLight>Select New {{which}} Date and Time</TextLight>
        </Translate>
      </BaseLabel>
      {/* <BaseLabel className="my-0 mt-4 text-left">
        <span className="font-extrabold">
          <Translate i18nKey="ChangeDateTime.DateLabel" />
          <TextLight></TextLight>
        </span>
      </BaseLabel> */}
      <BaseDropdown
        placeHolder={
          isPickup
            ? t("ChangeDateTime.Input.PickupDate")
            : t("ChangeDateTime.Input.DeliveryDate")
        }
        value={date}
        onChange={handleDateChange}
        options={dateOptions}
        disabled={disabled}
      />
      {/* <BaseLabel className="my-0 mt-4 text-left">
        <span className="font-extrabold">
          <Translate i18nKey="ChangeDateTime.TimeslotLabel" />
          <TextLight></TextLight>
        </span>
      </BaseLabel> */}
      <BaseDropdown
        placeHolder={
          isPickup
            ? t("ChangeDateTime.Input.PickupSlot")
            : t("ChangeDateTime.Input.DeliverySlot")
        }
        value={slot}
        onChange={setSlot}
        options={slotOptions}
        disabled={disabled || isEmpty(slotOptions)}
        pureArray={true}
      />
      {client === CLIENT.SINGTEL ? (
        <BaseInformationBox>
          <Translate i18nKey={isSingtelStarter 
            ? "ChangeDateTime.InformationBatteryReplacement" 
            : caseType === INCIDENT_BATTERY_REPLACEMENT ? "ChangeDateTime.InformationBatteryReplacement" : "ChangeDateTime.Information"}>
            <TextLight>
              Please note that the Screen Repair service will be completed in{" "}
              <TextMedium>{{returnSLA}}</TextMedium> from the date the device
              is picked up.
            </TextLight>
          </Translate>
          {isPickup && <TextDanger>
          <Translate i18nKey="ConfirmDateTime.AdditionalInfo" />
          {/* <Translate i18nKey="ConfirmDateTime.AdditionalInfoNoXiaomi" /> */}
        </TextDanger>}
        </BaseInformationBox>
      ) : (
        ""
      )}
      <BaseButtonGroup>
        <BaseButton
          text={t("ClearButton")}
          onClick={clearAllFields}
          disabled={disabled}
        />
        <BaseButton
          text={t("SubmitButton")}
          onClick={handleSubmit}
          disabled={shouldntSubmit}
          clicked={disabled}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ChangeDateTime.propTypes = {
  isPickup: PropTypes.bool,
};

ChangeDateTime.defaultProps = {
  isPickup: true,
};

export default ChangeDateTime;
