import React from "react";
import { useSelector } from "react-redux";
import { TextLight } from "../../base/BaseText";
import IncorrectMdnIcon from "../../images/icon-incorrect-mdn.svg";
import IncorrectScreen from "../../components/IncorrectScreen";
import { Translate } from "../../helpers/languageUtils";

const IncorrectMdn = () => {
  const agreementFailedAttempt = useSelector(
    (state) => state.validation.agreement.AgreementFailedAttempt
  );
  const messageKey = agreementFailedAttempt > 2 ? "MdnTerminate" : "Mdn";

  return (
    <IncorrectScreen icon={IncorrectMdnIcon} name="Incorrect Mdn">
      <Translate i18nKey={`IncorrectMessage.${messageKey}`}>
        <TextLight>
          We regret to inform you that the mobile number you've provided doesn't
          match the details on our record.
        </TextLight>
      </Translate>
    </IncorrectScreen>
  );
};

export default IncorrectMdn;
