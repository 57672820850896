import { setfailureDescriptiveText, setTroubleshootAnswer } from "../reducers/troubleshootingReducer";
import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  API_PROGRESS,
  COMPONENTS,
  PROGRESS_DATA,
  ACTIVITY,
  CLIENT,
} from "../../../../helpers/constants";
import {
  clearApiProgress,
  updateApiProgress,
  updateFlowProgress,
} from "../../../../actions/flowWindow";
import updateVisitor from "../../../Appsync/updateVisitor";
import { confirmIfRequestCollected } from "./cancelRequestOperator";
import { INCIDENT_BATTERY_REPLACEMENT } from "../../../../components/EnquiryOptions/Constants";

export const submitTroubleshootAnswer = (
  Que,
  status,
  key,
  Reason,
  textPrint
) => async (dispatch, getStore) => {
  const { caseType } = getStore().journeyMessages;
  const client = getStore().app.client;
  const isSingtel = client === CLIENT.SINGTEL;
  const ClientProductSkuNbr = getStore().validation?.verifyAgreement?.VerifyAgreementsResults?.Agreement?.ClientProductSkuNbr;
  const isSingtelStarter = isSingtel && (ClientProductSkuNbr && ClientProductSkuNbr.toLowerCase().indexOf("starter") > -1)

  const value = status === ( isSingtel ? "Confirm" : "Yes" );
  let showComponent = "";
  let module = "TROUBLESHOOTING";

  await dispatch(addJourneyMessages([
    createMessage("TEXT", "user", textPrint)]));

  if (!value) {

    dispatch(confirmIfRequestCollected("", Reason));

    let message = {};

    // show swap option for Celcom SUR
    if(!isSingtel){
      message = createMessage("DEVICE_CONFIRMATION", "system", {
        showComponent: "deviceNotEligible",
      });
    }

    dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.UnableToProcessRequest",
        }),
        message,
      ])
    );

    return;
  }

  switch (key) {
    case "CameraWorking":
      showComponent = "PowerOn";
      break;
    case "PowerOn":
      showComponent = "LiquidExposure";
      break;
    case "LiquidExposure":
      if(isSingtel) {
        showComponent = "PhysicalDamage";
      } else {
        // for celcom show all repair checklists
        showComponent = "TroubleshootChecklist";
      }
      break;
    case "PhysicalDamage":
      await dispatch(initiateTermsAndCondition());
      module = "TERMS_AND_CONDITIONS";
      break;
    case "BatteryTriage":
      await dispatch(initiateTermsAndCondition());
      module = "TERMS_AND_CONDITIONS";
      break;
    default:
      showComponent = "CameraWorking";
      break;
  }
  dispatch(updateVisitor({ lastActivity: ACTIVITY.TROUBLESHOOT }));
  if (!isSingtelStarter) {
    if (caseType !== INCIDENT_BATTERY_REPLACEMENT) {
      dispatch(saveTroubleshootAnswer(Que, value, key));
    }    
  }
  dispatch(
    addJourneyMessages([
      createMessage(module, "system", { showComponent }),
    ])
  );
};

export const submitfailureDescriptiveText = (
  failureDescriptiveText,
  textPrint
) => async (dispatch, getStore) => {
  
  await dispatch(addJourneyMessages([
    createMessage("TEXT", "user", textPrint)]));

  dispatch(savefailureDescriptiveText(failureDescriptiveText));
  dispatch(updateVisitor({ lastActivity: ACTIVITY.TROUBLESHOOT }));
  await dispatch(initiateTermsAndCondition());
  dispatch(
    addJourneyMessages([
      createMessage("TERMS_AND_CONDITIONS", "system"),
    ])
  );
};

const initiateTermsAndCondition = () => async (dispatch, getStore) => {
  const initial = getStore().journeyMessages.flowProgress.percentage;
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.TERMS_N_CONDITIONS.title,
          PROGRESS_DATA.TERMS_N_CONDITIONS.count,
          initial
        )
      );
      await dispatch(
        updateApiProgress(
          API_PROGRESS.TROUBLESHOOT,
          40,
          COMPONENTS.TROUBLESHOOT,
          0
        )
      );
      await sleep(1000);
      await dispatch(
        updateApiProgress(
          API_PROGRESS.TROUBLESHOOT_SUCCESS,
          100,
          COMPONENTS.TROUBLESHOOT,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());
}

const saveTroubleshootAnswer = (Que, Ans, key) => (dispatch) => {
  dispatch({
    type: setTroubleshootAnswer.toString(),
    payload: { Que, Ans, key },
  });
};

const savefailureDescriptiveText = (failureDescriptiveText) => (dispatch) => {
  dispatch({
    type: setfailureDescriptiveText.toString(),
    payload: failureDescriptiveText,
  });
};
