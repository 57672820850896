import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  client: "",
  language: "en",
};

const homeReducer = createSlice({
  name: "app",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    setClient(state, action) {
      state.client = action.payload;
    },
    setLanguage(state, action) {
      state.language = action.payload;
    },
  },
});

const { actions, reducer } = homeReducer;
export const { setClient, setLanguage } = actions;
export default reducer;
