import {
  callAuthorizeCreditCardApi,
  callChargeOrderApi,
  callGetPciTokenApi,
  callProcessTransactionApi,
} from "../reducers/paymentReducer";

export const getPciToken = (cacheId, amount) => ({
  type: callGetPciTokenApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/physicalassetfulfillment/v1/pcitoken",
    method: "POST",
    body: {
      SecurityTokenParameters: {
        SessionId: cacheId,
        AuthorizationAmount: amount ? parseFloat(amount) : 1,
        isSURClaim: true
      },
    },
  },
});

export const processTransaction = (ProcessTransactionRequest) => ({
  type: callProcessTransactionApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/physicalassetfulfillment/v1/processtransaction",
    method: "POST",
    body: {
      ProcessTransactionRequest,
    },
  },
});

export const chargeOrder = (CreateChargeOrderParameters) => ({
  type: callChargeOrderApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/physicalassetfulfillment/v1/chargeorder",
    method: "POST",
    body: {
      CreateChargeOrderParameters,
    },
  },
});

export const chargeOrderV2 = (CreateChargeOrderParameters) => ({
  type: callChargeOrderApi.toString(),
  meta: {
    async: true,
    blocking: true,
    //path: "/physicalassetfulfillment/v2/chargeorder",
    path: "/physicalassetfulfillment/v1/chargeorder",
    method: "POST",
    body: {
      CreateChargeOrderParameters,
    },
  },
});

export const submitOrder = (cacheId) => ({
  type: callProcessTransactionApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/physicalassetfulfillment/v1/submitorder",
    method: "POST",
    body: {
      SubmitServiceOrderParameters : {
        SessionId: cacheId,
      },
    },
  },
});

export const submitOrderV2 = (cacheId) => ({
  type: callProcessTransactionApi.toString(),
  meta: {
    async: true,
    blocking: true,
    //path: "/physicalassetfulfillment/v2/submitorder",
    path: "/physicalassetfulfillment/v1/submitorder",
    method: "POST",
    body: {
      SubmitServiceOrderParameters : {
        SessionId: cacheId,
      },
    },
  },
});

export const authorizeCreditCardApi = (AuthorizeCreditCardRequest) => ({
  type: callAuthorizeCreditCardApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/physicalassetfulfillment/v1/card/authorize",
    method: "POST",
    body: {
      AuthorizeCreditCardRequest,
    },
  },
});
