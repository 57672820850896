import React from "react";
import PropTypes from "prop-types";
import AskMdn from "./AskMdn";
import IncorrectMdn from "./IncorrectMdn";
import ReVerifyMdn from "./ReVerifyMdn";
import AskName from "./AskName";
import IncorrectName from "./IncorrectName";
import AskNric from "./AskNric";
import IncorrectNric from "./IncorrectNric";

const View = ({ showComponent }) => {
  return (
    <>
      {showComponent === "mdn" && <AskMdn />}
      {showComponent === "IncorrectMdn" && <IncorrectMdn />}
      {showComponent === "ReVerifyMdn" && <ReVerifyMdn />}
      {showComponent === "AskName" && <AskName />}
      {showComponent === "IncorrectName" && <IncorrectName />}
      {showComponent === "AskNric" && <AskNric />}
      {showComponent === "IncorrectNric" && <IncorrectNric />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired
};

export default View;
