import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseCard from "../../../base/BaseCard/BaseCard";
// import BaseCheckbox from "../../../base/BaseCheckbox/BaseCheckbox";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
// import BaseInformationBox from "../../../base/BaseInformationBox";
// import BaseInput from "../../../base/BaseInput/BaseInput";
import BaseLabel from "../../../base/BaseLabel";
import { TextMedium, TextRegular } from "../../../base/BaseText";
// import BaseTextarea from "../../../base/BaseTextarea/BaseTextarea";
import { isEmpty } from "../../../helpers/formatUtils";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import ChecklistIcon from "../../../images/icon-apps.svg";
// import { submitfailureDescriptiveText } from "../state/operators/troubleshootingOperator";
import {
  addJourneyMessages,
} from "../../../helpers/addJourneyMessages";
import createMessage from "../../../helpers/createMessage";
import { submitTroubleshootAnswer } from "../state/operators/troubleshootingOperator";

const BatteryTriage = () => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const [disable, setDisable] = useState(true);
  const [freezeWindow, setFreezeWindow] = useState(false);
  const [triageQuestionsAnswers, setTriageQuestionsAnswers] = useState([]);

  useEffect(() => {
    const triageQuestions = t("BatteryReplacement.TriageQuestions", {
      returnObjects: true,
    });
    setTriageQuestionsAnswers(triageQuestions.map((e) => ({ label: e.Question, answer: "", expected: e.Expected })));
  }, []);

  const handleQuestionClick = (index, value) => {
    const newList = [...triageQuestionsAnswers];
    const newElement = { ...newList[index] };
    newElement.answer = value;
    newList[index] = newElement;
    setTriageQuestionsAnswers([...newList]);

    if (!isEmpty(newList.filter((e) => !e.answer))) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  const handleNext = (textPrint) => {
    setDisable(true);
    setFreezeWindow(true);
    // const selectedChecklistValues = checklistValues
    //   .filter((e) => e.checked)
    //   .map((e) => e.label)
    //   .join("|");

    // const failureDescriptiveText = !isEmpty(selectedChecklistValues) ? !isEmpty(otherIssue) ? selectedChecklistValues.concat("|", otherIssue) : selectedChecklistValues : otherIssue;

    // console.log(`failureDescriptiveText is ${failureDescriptiveText}`);

    // dispatch(submitfailureDescriptiveText(failureDescriptiveText, textPrint));

    if (!isEmpty(triageQuestionsAnswers.filter((e) => e.answer !== e.expected))) {
      dispatch(
        addJourneyMessages([
          createMessage("DEVICE_CONFIRMATION", "system", {
            showComponent: "deviceNotEligible",
          }),
        ])
      );
    } else {
      dispatch(
        submitTroubleshootAnswer(
          "",
          "Confirm",
          "BatteryTriage",
          "",
          "Confirm"
        )
      );
    }
  };

  return (
    <BaseCard>
      <BaseIcon icon={ChecklistIcon} name="Checklist" center />

      <BaseLabel className={"mt-1 mb-4 font-bold"}>
        <Translate i18nKey={`BatteryReplacement.TriageTitle`}>
          <TextMedium></TextMedium>
        </Translate>
      </BaseLabel>

      {/* <BaseLabel className={"my-1"}>
        <Translate i18nKey={`Troubleshoot.CheckList.SubTitle`}>
          <TextMedium></TextMedium>
        </Translate>
      </BaseLabel> */}

      <div className="mx-auto w-full survey-checklist">
        {triageQuestionsAnswers.map((e, index) => (
          <div className="flex justify-between py-4">
            <div className="my-auto text-xl">
              <TextRegular>{e.label}</TextRegular>
            </div>
            
            <div className="flex flex-row items-center justify-center pl-4 lg:mr-5">
                <BaseButton
                  className="w-8 h-8 lg:w-10 lg:h-10 mr-2 lg:mr-2 xs:text-sm lg:text-base xs:p-0.25 lg:p-1"
                  style={{ padding: "0", minWidth: "unset", lineHeight: "2rem", verticalAlign: "top" }}
                  disabled={freezeWindow}
                  clicked={(triageQuestionsAnswers[index]["answer"] === "N")}
                  onClick={() => { handleQuestionClick(index, "N") }}
                  baseButtonStyle={{ padding: "5px", boxSizing: "content-box", minWidth: "unset", margin: "0", marginRight: "10px" }}
                  text={'N'}
                />
                <BaseButton
                  className="w-8 h-8 lg:w-10 lg:h-10 ml-2 lg:ml-2 xs:text-sm lg:text-base xs:p-0.25 lg:p-1"
                  style={{ padding: "0", minWidth: "unset", lineHeight: "2rem", verticalAlign: "top" }}
                  disabled={freezeWindow}
                  clicked={(triageQuestionsAnswers[index]["answer"] && triageQuestionsAnswers[index]["answer"] !== "N")}
                  onClick={() => { handleQuestionClick(index, "Y") }}
                  baseButtonStyle={{ padding: "5px", boxSizing: "content-box", minWidth: "unset", margin: "0" }}
                  text={'Y'}
                />
              </div>
          </div>
        ))}
      </div>
      {/* <div
        className={`text-center my-4 text-srgreen-primary text-default underline cursor-pointer ${
          freezeWindow ? "pointer-events-none" : ""
        }`}
        onClick={() => openOtherIssueBox()}
      >
        <TextMedium>{t("Troubleshoot.CheckList.OtherIssue")}</TextMedium>
      </div> */}

      {/* {showOtherIssueBox && (
        <div>
          <BaseLabel className={"my-1 text-left"}>
            <Translate i18nKey={`Troubleshoot.CheckList.OtherIssueTitle`}>
              <TextMedium></TextMedium>
            </Translate>
          </BaseLabel>

          <BaseTextarea
            name="otherIssues"
            placeholder={t("Troubleshoot.CheckList.OtherIssuePlaceholder")}
            value={otherIssue}
            disabled={freezeWindow}
            onChange={handleOtherIssueChange}
          />

          <BaseInformationBox>
            <Translate i18nKey="Troubleshoot.CheckList.Note">
              <TextLight>
                <TextLight></TextLight>
                <TextMedium>Note:</TextMedium>
              </TextLight>
            </Translate>
          </BaseInformationBox>
        </div>
      )} */}

      <BaseButtonGroup>
        <BaseButton
          onClick={() => handleNext(t("ConfirmButton"))}
          disabled={disable}
          clicked={disable}
          text={t("ConfirmButton")}
        ></BaseButton>
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default BatteryTriage;
