import { isEmpty } from "./formatUtils";
import generateUniqueId from "./generateUniqueId";

export default function createMessage(type, author, data) {
  if(type === "TEXT" && isEmpty(data))
    return;
  const messageAuthor = author.toLowerCase() === "user" ? "User" : "System";
  let source =
    author.toLowerCase() === "user"
      ? "User"
      : author.toLowerCase() === "agent"
      ? "Agent"
      : "System";

  return {
    messageId: generateUniqueId(),
    type,
    author: messageAuthor,
    data: data ? data : null,
    source: source,
    time: new Date().toISOString(),
  };
}

export function createUserMessage(type, data) {
  return createMessage(type, 'User', data)
}

export function createSystemMessage(type, data) {
  return createMessage(type, 'System', data)
}
