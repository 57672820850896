import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseDropdown from "../../../base/BaseDropdown/BaseDropdown";
import { TextRegular } from "../../../base/BaseText";

import CalendarCheckedIcon from "../../../images/icon-calendar-selected.svg";
import { isEmpty } from "../../../helpers/formatUtils";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { appointmentSelected } from "../state/operators/RepairOptionsOperator";
import { useEffect } from "react";
import { INCIDENT_PATH_TYPE } from "../../../helpers/constants";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";

const BookAppointment = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  
  const [dateOptions, setDateOptions] = useState([]);
  const [slotOptions, setSlotOptions] = useState([]);

  const [disabled, setDisabled] = useState(false);
  const [date, setDate] = useState("");
  const [slot, setSlot] = useState("");

  const getAppointmentDate = (AppointmentDateTime) => {
    return AppointmentDateTime.split("T")[0];
  };

  const getAppointmentSlot = (AppointmentDateTime) => {
    return AppointmentDateTime.split("T")[1].split(".")[0];
  };

  const getDateObj = (options, selectedAppointmentDate) => {
    return options.find((o) => o.id === selectedAppointmentDate);
  }

  const appointments = useSelector(
    (state) => state.claim.walkIn.appointments.AppointmentSlotsResponse
  );

  const incidentPathType = useSelector((state) => state.serviceRequest.type);

  const determinDetails = useSelector(
    (state) => state.serviceRequest.determineIncidentDetails
  );
  const isNoActionRequired =
    incidentPathType === INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED;

  const walkInData = useSelector((state) => state.claim.walkIn);

  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );

  const isChangeSchedule = enquiryOption === EnumEnquiryModules.ChangeSchedule;

  const selectedAppointmentDate =
    isNoActionRequired || isChangeSchedule
      ? getAppointmentDate(determinDetails.RepairRequest.AppointmentDate)
      : walkInData && walkInData.selectedAppointmentDate
      ? walkInData.selectedAppointmentDate
      : "";

  const selectedAppointmentSlot =
    isNoActionRequired || isChangeSchedule
      ? getAppointmentSlot(determinDetails.RepairRequest.AppointmentDate)
      : walkInData && walkInData.selectedAppointmentSlot
      ? walkInData.selectedAppointmentSlot
      : "";

  useEffect(() => {
    const options = appointments.map((obj) => {
      return {
        id: obj.AvailableDate,
        text: new Date(obj.AvailableDate).toDateString(),
        slots: obj.TimeSlots,
      };
    });

    if (options && options.length > 0) {
      if (selectedAppointmentDate && !isEmpty(getDateObj(options, selectedAppointmentDate))) {
        // set preselected value
        setDate(selectedAppointmentDate);
        setDateOptions(options);
        const opt = options.find((o) => o.id === selectedAppointmentDate);
        setSlotOptions(opt.slots);
        setSlot(selectedAppointmentSlot);
      } else {
        setDateOptions(options);
        setDate(options[0].id);
        setSlotOptions(options[0].slots);
        setSlot(options[0].slots[0]);
      }
    }
  }, []);

  const handleDateChange = (dateStr) => {
    setSlot("");
    setDate(dateStr);
    const opt = dateOptions.find((o) => o.id === dateStr);
    setSlotOptions(opt.slots);
    setSlot(opt.slots[0]);
  };

  const handleSubmit = () => {
    const textPrint = t("SubmitButton");
    setDisabled(true);
    dispatch(appointmentSelected(textPrint, date, slot));
  };

  const shouldntSubmit = disabled || isEmpty(date) || isEmpty(slot);

  return (
    <BaseCard>
      <BaseIcon icon={CalendarCheckedIcon} name="Calendar" center />
      <BaseLabel>
        <Translate i18nKey="WalkIn.SelectAppointment.title">
          <TextRegular>
            Please select your preferred
            <br /> <TextRegular>available appointment below</TextRegular>
          </TextRegular>
        </Translate>
      </BaseLabel>

      <BaseDropdown
        placeHolder={t("WalkIn.SelectAppointment.labelDate")}
        value={date}
        onChange={handleDateChange}
        options={dateOptions}
        disabled={disabled}
      />

      <BaseDropdown
        placeHolder={t("WalkIn.SelectAppointment.labelTimeSlot")}
        value={slot}
        onChange={setSlot}
        options={slotOptions}
        disabled={disabled || isEmpty(slotOptions)}
        pureArray={true}
      />

      <BaseButtonGroup>
        <BaseButton
          text={t("WalkIn.SelectAppointment.appointmentButton")}
          onClick={handleSubmit}
          disabled={shouldntSubmit}
          clicked={disabled}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default BookAppointment;
