import { createSlice } from "@reduxjs/toolkit";
import { INCEDENT_TYPES } from "../../../../helpers/constants";
// import { 
//   INCIDENT_BATTERY_REPLACEMENT, 
//   INCIDENT_SCREEN_REPAIR, 
//   INCIDENT_SCREEN_REPAIR_WARRANTY 
// } from "../../../../components/EnquiryOptions/Constants";

let initialState = {
  authorizeCreditCardFailedAttempt : 0,
  ccFailedResolvedByChat: false,
  ccAuthFailedResolvedByChat: false,
};

// const setServiceFeeByPeril = (serviceFees = []) => {

//   const ScreenRepair = {...(serviceFees.find(sf => sf.IncidentType === INCIDENT_SCREEN_REPAIR.toUpperCase()).Fees), 
//     ...{TotalAmountWithDiscount: (serviceFees.find(sf => sf.IncidentType === INCIDENT_SCREEN_REPAIR.toUpperCase()).TotalAmountWithDiscount)}};

//   const ScreenRepairWarranty = serviceFees.find(sf => sf.IncidentType === INCIDENT_SCREEN_REPAIR_WARRANTY.toUpperCase()).Fees;
  
//   const BatteryReplacement = serviceFees.find(sf => sf.IncidentType === INCIDENT_BATTERY_REPLACEMENT.toUpperCase()).Fees;

//   const BatScrReplacement = [ScreenRepair, BatteryReplacement].reduce((acc, peril) => {
//     const { FeeAmount, TaxAmount, TotalAmount, FeeType, TotalAmountWithDiscount } = peril;
//     const type = acc.FeeType ? `${acc.FeeType}-${FeeType}` : FeeType;
//     const fee = (acc.FeeAmount ? parseFloat(acc.FeeAmount) + parseFloat(FeeAmount) : FeeAmount).toString();
//     const tax = (acc.TaxAmount ? parseFloat(acc.TaxAmount) + parseFloat(TaxAmount) : TaxAmount).toString();
//     const total = (acc.TotalAmount ? parseFloat(acc.TotalAmount) + parseFloat(TotalAmount) : TotalAmount).toString();
    
//     const totalWithDiscount = (acc.TotalAmountWithDiscount ? parseFloat(acc.TotalAmountWithDiscount) + parseFloat(TotalAmount) : TotalAmountWithDiscount || TotalAmount).toString();
    
//     acc = { ...peril, FeeType: type, FeeAmount: fee, TaxAmount: tax, TotalAmount: total, TotalAmountWithDiscount: totalWithDiscount };
    
//     console.log("acc", acc)
//     return acc;
//   }, {})
  
//   return {
//     ScreenRepair,
//     ScreenRepairWarranty,
//     BatteryReplacement,
//     BatScrReplacement
//   }
// }

const paymentReducer = createSlice({
  name: "payment",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    savePaymentMethod(state, action) {
      state.paymentMethod = action.payload;
    },
    setCCDetails(state, action) {
      state.ccmask = action.payload.ccmask;
      state.ccname = action.payload.ccname;
    },
    setPaymentData(state, action) {
      state.isCardValid = action.payload;
    },
    setPaymentVerificationAttempt(state, action) {
      state.paymentVerificationAttempt = action.payload;
    },
    callGetServiceFeeApi(state, action) {
      state.serviceFee = {};
    },
    callGetServiceFeeApiSuccess(state, action) {
      // if (Array.isArray(action.payload.GetServiceFeeResponse.ServiceFee.ServiceFee)) {
      //   state.serviceFee = action.payload.GetServiceFeeResponse.ServiceFee.ServiceFee[0];
      // } else {
      //   state.serviceFee = action.payload.GetServiceFeeResponse.ServiceFee.ServiceFee;
      // }
      const {
        GetServiceFeeResponse: { ServiceFee },
      } = action.payload;

      if (Array.isArray(action.payload.GetServiceFeeResponse.ServiceFee.ServiceFee)) {
        let ServiceFeeData = {...ServiceFee?.ServiceFee[0]}
        let FeesData = {...ServiceFeeData.Fees};
        state.serviceFee = { ...ServiceFeeData, ...FeesData};
      } else {
        let ServiceFeeData = {...ServiceFee?.ServiceFee}
        let FeesData = {...ServiceFeeData.Fees};
        state.serviceFee = { ...ServiceFeeData, ...FeesData};
      }
    },
    callGetServiceFeeApiFailure(state, action) {
      state.error = action.payload;
    },
    callGetPciTokenApi(state, action) {
      state.pciToken = {};
    },
    callGetPciTokenApiSuccess(state, action) {
      state.pciToken = action.payload;
    },
    callGetPciTokenApiFailure(state, action) {
      state.error = action.payload;
    },
    callProcessTransactionApi(state, action) {
      state.processTransaction = {};
    },
    callProcessTransactionApiSuccess(state, action) {
      state.processTransaction = action.payload;
    },
    callProcessTransactionApiFailure(state, action) {
      state.error = action.payload;
    },
    callChargeOrderApi(state, action){
      state.chargeOrder = {};
    },
    callChargeOrderApiSuccess(state, action) {
      state.chargeOrder = action.payload;
    },
    callChargeOrderApiFailure(state, action) {
      state.error = action.payload;
    },
    setPaymentResolvedByChat(state, action) {
      state.ccFailedResolvedByChat = action.payload;
    },
    callGetEnrolledServiceFeeApi(state, action) {
      state.serviceFee = {};
    },
    // callGetEnrolledServiceFeeApiSuccess(state, action) {
    //   state.serviceFee = action.payload.GetServiceFeeResponse.ServiceFees.find(
    //     (f) => f.IncidentType === (window.location.href.toString().includes("/my") ? INCEDENT_TYPES.FAULT_REPAIR : INCEDENT_TYPES.SCREEN_REPAIR)
    //   ).Fees;
    //   const { SubscriptionDetails: { MobileDeviceNumber, ProgramName }, ServiceFees } = action.payload.GetServiceFeeResponse;
    //   state.serviceFees = { ...state.serviceFees, [MobileDeviceNumber]: ServiceFees, ProgramName }
    // },
    callGetEnrolledServiceFeeApiSuccess(state, action) {
      const { SubscriptionDetails: { MobileDeviceNumber, ProgramName }, ServiceFees } = action.payload.GetServiceFeeResponse;

      state.serviceFee = action.payload.GetServiceFeeResponse.ServiceFees.find(
        (f) => f.IncidentType === (window.location.href.toString().includes("/my") 
        ? INCEDENT_TYPES.FAULT_REPAIR 
        : ProgramName && ProgramName.toLowerCase().indexOf("starter") > -1 ? INCEDENT_TYPES.BATTERY_REPLACEMENT : INCEDENT_TYPES.SCREEN_REPAIR)
      ).Fees;
      
      state.serviceFees = { ...state.serviceFees, [MobileDeviceNumber]: ServiceFees, ProgramName }
    },
    callGetEnrolledServiceFeeApiFailure(state, action) {
      state.error = action.payload;
    },
    callAuthorizeCreditCardApi(state, action) {
      state.authorizeCC = {};
    },
    callAuthorizeCreditCardApiSuccess(state, action) {
      state.authorizeCC = action.payload;
      // state.authorizeCCFailedAttempt = state.PaymentFailedAttempt + 1
    },
    callAuthorizeCreditCardApiFailure(state, action) {
      state.error = action.payload;
    },
    updateAuthorizeCreditCardFailedAttempt(state, action) {
      state.authorizeCreditCardFailedAttempt = action.payload;
    },
    setCCAuthResolvedByChat(state, action) {
      state.ccAuthFailedResolvedByChat = action.payload;
    },
    setServiceFee(state, action) {
      state.serviceFee = action.payload;
    }
  },
});

const { actions, reducer } = paymentReducer;
export const {
  resetStore,
  savePaymentMethod,
  setPaymentData,
  setPaymentVerificationAttempt,
  callGetServiceFeeApi,
  callGetPciTokenApi,
  setCCDetails,
  callProcessTransactionApi,
  callChargeOrderApi,
  setPaymentResolvedByChat,
  callGetEnrolledServiceFeeApi,
  callAuthorizeCreditCardApi,
  updateAuthorizeCreditCardFailedAttempt,
  setCCAuthResolvedByChat,
  setServiceFee
} = actions;
export default reducer;
