import React from "react";
import { useSelector } from "react-redux";
import { TextLight } from "../../base/BaseText";
import IncorrectNricIcon from "../../images/icon-incorrect-nric.svg";
import IncorrectScreen from "../../components/IncorrectScreen";
import { Translate } from "../../helpers/languageUtils";

const IncorrectName = () => {
  const verificationFailedAttempt = useSelector(
    (state) => state.validation.verification.VerificationFailedAttempt
  );
  const messageKey = verificationFailedAttempt > 2 ? "NricTerminate" : "Nric";

  return (
    <IncorrectScreen icon={IncorrectNricIcon} name="Incorrect Nric">
      <Translate i18nKey={`IncorrectMessage.${messageKey}`}>
        <TextLight>
          We regret to inform you that the NRIC/FIN number you've provided
          doesn't match the details on our record.
        </TextLight>
      </Translate>
    </IncorrectScreen>
  );
};

export default IncorrectName;
