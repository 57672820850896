import React, { useState } from "react";
import { useDispatch } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextLight, TextMedium } from "../../../base/BaseText";

import NpsIcon from "../../../images/icon-nps.svg";
import { submitRequestForSurvey } from "../state/operators/npsOperator";
import { Translate, useTranslate } from "../../../helpers/languageUtils";

const RequestForSurvey = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = () => {
    setDisabled(true);
    dispatch(submitRequestForSurvey());
  };

  return (
    <BaseCard>
      <BaseIcon icon={NpsIcon} name="Rate our service" center />
      <BaseLabel>
        <TextMedium>
          <Translate i18nKey="RequestForSurvey.Title">
            Your feedback is important to us
          </Translate>
        </TextMedium>
      </BaseLabel>
      <BaseLabel>
        <Translate i18nKey="RequestForSurvey.Content">
          <TextLight>
            We will be honored if you can take a moment of your time to rate our
            service.
          </TextLight>
        </Translate>
      </BaseLabel>
      <BaseButtonGroup>
        <BaseButton
          text={t("RequestForSurvey.Button")}
          onClick={handleSubmit}
          disabled={disabled}
          clicked={disabled}
          autoFocus
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default RequestForSurvey;
