import {
  addJourneyMessages, sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import { endFlow } from "../../../../actions/flowWindow";

export const onCloseClick = (text) => async (dispatch, getStore) => {
  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", text)
    ])
  );

  await sleep(2000);
  dispatch(endFlow());
};
