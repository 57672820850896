import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";
import i18n from "../i18n";
import { CLIENT, LANGUAGE } from "./constants";
import { isEmpty } from "./formatUtils";

export const useTranslate = () => {
  const client = useSelector((state) => state.app.client);
  const clientName = isEmpty(client) ? CLIENT.CELCOM : client;
  const { t } = useTranslation([`${clientName}-translation`]);
  return t;
};

export const Translate = ({ children, i18nKey, ...rest }) => {
  const client = useSelector((state) => state.app.client);
  const clientName = isEmpty(client) ? CLIENT.CELCOM : client;
  return (
    <Trans ns={`${clientName}-translation`} i18nKey={i18nKey} {...rest}>
      {children}
    </Trans>
  );
};

export const changeLanguage = (language) => {
  i18n.changeLanguage(language);
};

export const setInitialLanguage = (clientName) => {
  const lang =
    clientName === CLIENT.SINGTEL ? LANGUAGE.ENGLISH : LANGUAGE.BAHASA;
  return lang;
};
