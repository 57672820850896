import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import { TextLight } from "../../../base/BaseText";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseTable, {
  BaseTR,
  BaseTD,
  BaseTH,
} from "../../../base/BaseTable/BaseTable";
import ConfirmIcon from "../../../images/icon-confirm.svg";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import {
  confirmRequestDetailsSubmission,
  cancelRepairDetailsConfirmation,
  showEnquiryOptions
} from "../state/operators/repairDetailsOperator";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";
import { getDeliverySlot, getFormatedTime, getFormmatedDate } from "../../../helpers/dateUtils";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { CLIENT, INCIDENT_PATH_TYPE } from "../../../helpers/constants";
import { isEmpty } from "../../../helpers/formatUtils";

const ConfirmRepairDetails = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const getAddressText = (address) => {
    const {
      Address1,
      Address2,
      Address3,
      City,
      PostalCode,
      StateProvinceCode,
    } = address;
    return `${Address1} ${Address2 || ""} ${Address3 || ""} 
      ${City} ${PostalCode} ${!isSingtel ? StateProvinceCode : ""}`;
  };

  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );

  const { type : incidentPathType,  serviceRequestDetails}  = useSelector((state) => state.serviceRequest);

  const isCancelRequest = enquiryOption === EnumEnquiryModules.CancelRequest;
  const isChangeSchedule = enquiryOption === EnumEnquiryModules.ChangeSchedule;
  const client = useSelector((state) => state.app.client);
  const isSingtel = client === CLIENT.SINGTEL;
  const currency = isSingtel ? "SGD" : "RM";

  const determinDetails = useSelector(
    (state) => state.serviceRequest.determineIncidentDetails
  );
  const isNoActionRequired =
    incidentPathType === INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED;
  let determinePickupSlot = "";
  let determineDeliverySlot = getDeliverySlot()[0]; // at the moment rerurn slot is fixed as "2pm - 6pm"
  if (isNoActionRequired || isCancelRequest || isChangeSchedule) {
    const slotH = determinDetails.ShippingOrderOutbound.DeliveryWindow;
    determinePickupSlot =
      slotH === "16H"
        ? "12pm - 4pm"
        : slotH === "12H"
          ? "9am - 12pm"
          : slotH === "14H"
            ? determinDetails.ShippingMethodType === "Same Day"
              ? "12pm - 2pm"
              : "10am - 2pm"
            : "";
  }

  const scheduleData = useSelector((state) => state.claim.schedule);

  const repairOption = useSelector((state) =>
    isNoActionRequired || isCancelRequest || isChangeSchedule
      ? state.serviceRequest.determineIncidentDetails.RepairRequest
          .RepairRequestType
      : state.journeyMessages.repairOption
  ) || "PUR";
  const isWalkIn = repairOption === "WALKIN";

  const walkInData = useSelector((state) => state.claim.walkIn);

  const { selectedAsset } = useSelector(
    (state) => state.claim.deviceConfirmation
  );

  const deviceName =
    isNoActionRequired || isCancelRequest || isChangeSchedule
      ? `${determinDetails.ClaimedAsset.Make} ${determinDetails.ClaimedAsset.Model}`
      : `${selectedAsset.Make.Name} ${selectedAsset.Model.Name}`;

  let paymentMethod = useSelector((state) => state.claim.payment.paymentMethod);
  if (isNoActionRequired || isCancelRequest || isChangeSchedule)
    paymentMethod = determinDetails.ChargeOrder.PaymentMethodType;

  const pickupAddress = !isWalkIn ? isNoActionRequired || isCancelRequest || isChangeSchedule
    ? determinDetails.ShippingOrderOutbound.Address
    : scheduleData.selectedPickupAddress : "";

  const pickupDate = !isWalkIn ? isNoActionRequired || isCancelRequest || isChangeSchedule
    ? determinDetails.ShippingOrderOutbound.ExpectedDeliveryDate
    : scheduleData.selectedPickupDate : "";

  const pickupSlot = !isWalkIn ? isNoActionRequired || isCancelRequest || isChangeSchedule
    ? determinePickupSlot
    : scheduleData.selectedPickupSlot : "";

  const returnAddress = !isWalkIn ? isNoActionRequired || isCancelRequest || isChangeSchedule
  ? determinDetails.ShippingOrderInbound.Address
  : scheduleData.selectedDeliveryAddress : "";

  const returnDate = !isWalkIn ? isNoActionRequired || isCancelRequest || isChangeSchedule
    ? determinDetails.ShippingOrderInbound.ExpectedDeliveryDate
    : scheduleData.selectedDeliveryDate : "";

  const returnSlot = !isWalkIn ? isNoActionRequired || isCancelRequest || isChangeSchedule
    ? determineDeliverySlot
    : scheduleData.selectedDeliverySlot : "";

  const customerCaseNumber = useSelector((state) =>
  isNoActionRequired || isCancelRequest || isChangeSchedule
      ? state.serviceRequest.determineIncidentDetails.CustomerCaseNumber
      : state.serviceRequest.serviceRequestDetails.CustomerCaseNumber
  );
  const srFee = useSelector((state) =>
  isNoActionRequired || isCancelRequest || isChangeSchedule
      ? state.serviceRequest.determineIncidentDetails.ServiceFee
      : state.claim.payment.serviceFee.TotalAmountWithDiscount || state.claim.payment.serviceFee.TotalAmount
  );

  const { WarrantyType } = useSelector((state) =>
    isNoActionRequired || isCancelRequest || isChangeSchedule
      ? state.serviceRequest.determineIncidentDetails.ServiceRequest
      : incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST &&
        serviceRequestDetails.Actions !== "CaptureIncidentDetails"
      ? state.serviceRequest.serviceRequestDetails
      : state.claim.termsAndConditions.processIncident.CreateIncidentResults
          .CustomerCase.ServiceRequest
  );
  const isInWarranty = enquiryOption === EnumEnquiryModules.InWarrantyRequest || isEmpty(WarrantyType) ? srFee === 0 : WarrantyType === "IW";


  const repairCenterLocation = isWalkIn ?
    isNoActionRequired || isCancelRequest || isChangeSchedule
      ? `${
          determinDetails.RepairRequest.ServiceProviderName
        }, ${getAddressText(determinDetails.ShippingAddress)}`
      : walkInData && walkInData.selectedStore
      // ? `${walkInData.selectedStore.SERVICE_PROVIDER_NAME}, 
      // ${walkInData.selectedStore.ADDRESS_LINE_1} ${walkInData.selectedStore.ADDRESS_LINE_2 || ""} ${walkInData.selectedStore.ADDRESS_LINE_3 || ""} ${walkInData.selectedStore.CITY_NAME} ${walkInData.selectedStore.POSTAL_CODE} ${walkInData.selectedStore.STATE_PROVINCE_NAME}`
      ? `${walkInData.selectedStore.SERVICE_PROVIDER_NAME}, 
      ${walkInData.selectedStore.ADDRESS_LINE_1} ${walkInData.selectedStore.ADDRESS_LINE_2 || ""} ${walkInData.selectedStore.ADDRESS_LINE_3 || ""} ${walkInData.selectedStore.CITY_NAME} ${walkInData.selectedStore.POSTAL_CODE}`
      : "" : "";

  const appointmentDateTime = isWalkIn ?
    isNoActionRequired || isCancelRequest || isChangeSchedule
      ? `${getFormmatedDate(
          determinDetails.RepairRequest.AppointmentDate,
          "d MMM yyyy"
        )}, ${getFormatedTime(
          determinDetails.RepairRequest.AppointmentDate,
          true,
          "hh mm A"
        )}`
      : walkInData && walkInData.selectedAppointmentDate
      ? `${getFormmatedDate(
          walkInData.selectedAppointmentDate,
          "d MMM yyyy"
        )} ${walkInData.selectedAppointmentSlot}`
      : "" : "";

  const tableHeaders = [
    {
      key: "srNo",
      text: t("ConfirmAddress.LableforRequestNumber"),
      hideForNoAction: false,
    },
    {
      key: "device",
      text: t("ConfirmAddress.LableforDevice"),
      hideForNoAction: isSingtel ? true : false,
    },
    {
      key: "requestType",
      text: t("ConfirmAddress.LabelforRequestType"),
      hideForNoAction: false,
    },
    {
      key: "pickupAddress",
      text: t("ConfirmAddress.LabelforPickup"),
      // hideForNoAction: isSingtel
      //   ? false
      //   : isWalkIn
      //     ? true
      //     : false,
      hideForNoAction: isWalkIn
          ? true
          : false,
    },
    {
      key: "returnAddress",
      text: t("ConfirmAddress.LabelforReturn"),
      hideForNoAction:
        isWalkIn
          ? true
          : false,
    },
    {
      key: "pickupDateTime",
      text: t("ConfirmAddress.LabelforPickupDateTime"),
      // hideForNoAction: isSingtel
      // ? false
      // : isWalkIn
      // ? true
      // : false,
      hideForNoAction: isWalkIn
        ? true
        : false,
    },
    {
      key: "returnDateTime",
      text: t("ConfirmAddress.LabelforReturnDateTime"),
      hideForNoAction:
      isWalkIn
          ? true
          : false,
    },
    {
      key: "repairCenterLocation",
      text: t("ConfirmAddress.LabelforRepairCenterLocation"),
      // hideForNoAction: isSingtel
      //   ? true
      //   : isWalkIn
      //   ? false
      //   : true,
      hideForNoAction: isWalkIn
        ? false
        : true,
    },
    {
      key: "appointmentDateTime",
      text: t("ConfirmAddress.LabelforAppointmentDateTime"),
      // hideForNoAction: isSingtel
      //   ? true
      //   : isWalkIn
      //   ? false
      //   : true,
      hideForNoAction: isWalkIn
        ? false
        : true,
    },
    {
      key: "srFee",
      text: t("ConfirmAddress.LabelforRepairFee"),
      hideForNoAction: isEmpty(`${srFee}`) ? true : false,
    },
    {
      key: "paidWith",
      text: t("ConfirmAddress.LabelforPaidWith"),
      hideForNoAction: isSingtel || isInWarranty ? true : false,
    }
  ];

  const tableData = [
    {
      srNo: customerCaseNumber,
      pickupAddress: pickupAddress ? getAddressText(pickupAddress) : "",
      returnAddress: returnAddress ? getAddressText(returnAddress) : "",
      pickupDateTime: pickupDate
        ? `${getFormmatedDate(pickupDate, "d MMM yyyy")} ${pickupSlot}`
        : "",
      returnDateTime: returnDate
      ? `${getFormmatedDate(returnDate, "d MMM yyyy")} ${returnSlot}`
      : "",
      srFee: `${currency} ${srFee} ${t("ConfirmAddress.SSTText")}`,
      device: deviceName,
      requestType: repairOption === "PUR" ? t("ProgressData.PUR.title") : t("ProgressData.WALK_IN.title"),
      repairCenterLocation: repairCenterLocation,
      appointmentDateTime: appointmentDateTime,
      paidWith: !isEmpty(paymentMethod) && paymentMethod === "CRE" ? t("PaymentOptions.Button.Card") : t("PaymentOptions.Button.COD"),
    },
  ];

  const handleSubmit = (text) => {
    setDisabled(true);
    dispatch(confirmRequestDetailsSubmission(text, paymentMethod, repairOption));
  };

  const handleCancelClick = () => {
    const cancelText = t("CancelButton");
    setDisabled(true);
    if (isChangeSchedule) {
      dispatch(showEnquiryOptions(cancelText));
    } else {
      dispatch(cancelRepairDetailsConfirmation(cancelText));
    }
  };

  return (
    <BaseCard>
      <BaseIcon icon={ConfirmIcon} name="confirm" center />
      <BaseLabel>
        <Translate i18nKey={`ConfirmRepairDetails.${enquiryOption}.Title`}>
          <TextLight>
            Please confirm the
            <br />
            Screen Repair Request
          </TextLight>
        </Translate>
      </BaseLabel>
      <BaseTable
        title={t(`ConfirmRepairDetails.${enquiryOption}.TableTitle`)}
        verticle
      >
        {tableHeaders.map((header) => {
          if (header.hideForNoAction) {
            return;
          }
          return (
            <BaseTR key={header.key}>
              <BaseTH>{header.text}</BaseTH>
              {tableData.map((data, i) => (
                <BaseTD key={`${header.key}_${i}`}>{data[header.key]}</BaseTD>
              ))}
            </BaseTR>
          );
        })}
      </BaseTable>
      {!isSingtel && (
        <BaseLabel>
          <Translate i18nKey={`ConfirmRepairDetails.TaxInfo`}>
            <TextLight>*(which will be charged if applicable)</TextLight>
          </Translate>
        </BaseLabel>
      )}
      <BaseButtonGroup>
        {[
          EnumEnquiryModules.NewRequest,
          EnumEnquiryModules.InWarrantyRequest,
          EnumEnquiryModules.ResumeRequest,
          EnumEnquiryModules.ChangeSchedule,
        ].includes(enquiryOption) && (
          <BaseButton
            text={t("CancelButton")}
            onClick={handleCancelClick}
            disabled={disabled}
            autoFocus
          />
        )}
        <BaseButton
          className={isChangeSchedule ? "text-xl" : ""}
          text={t(`ConfirmRepairDetails.${enquiryOption}.Button`)}
          onClick={() =>
            handleSubmit(t(`ConfirmRepairDetails.${enquiryOption}.Button`))
          }
          disabled={disabled}
          clicked={disabled}
          autoFocus
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmRepairDetails;
