import { callGetFulFillmentApi, callSetFulFillmentApi } from "../modules/RepairClaim/state/reducers/termsAndConditionsReducer";

export const getFulFillmentApi = (
  CacheId,
  ServiceRequestId,
) => ({
  type: callGetFulFillmentApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/physicalassetfulfillment/v1/getfulfillmentoptions`,
    method: "POST",
    body: {
      GetFulfillmentOptionParameters: {
        SessionId: CacheId,
        ServiceRequestId: ServiceRequestId,
      },
    },
  },
});

export const setFulFillmentApi = (
  CacheId,
  ServiceRequestId,
  repairOptionType
) => ({
  type: callSetFulFillmentApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/physicalassetfulfillment/v1/fulfillmentoptions`,
    method: "POST",
    body: {
      SetFulfillmentOptionParameters: {
        SessionId: CacheId,
        // FulfillmentOption: "SCRNREP",
        FulfillmentOption: repairOptionType,
        ServiceRequestId: ServiceRequestId,
      }
    },
  },
});
