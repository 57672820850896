import { addJourneyMessages, sleep } from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  setPickupAddress,
  setDeliveryAddress,
  setPickupDateTime,
  setDeliveryDateTime,
  setPickupOptions,
  setRegisteredAddress,
  setisCampaignTimingEligible,
} from "../reducers/scheduleReducer";
import { API_PROGRESS, COMPONENTS, PROGRESS_DATA, ACTIVITY, CLIENT } from "../../../../helpers/constants";
import {
  updateApiProgress,
  clearApiProgress,
  updateFlowProgress,
  serviceUnavailable,
  endFlow,
} from "../../../../actions/flowWindow";
import { getPickupDates, getDeliveryDates, getFormmatedDate, isToday, getFormatedTime } from "../../../../helpers/dateUtils";
import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import updateVisitor from "../../../Appsync/updateVisitor";
import {
  standardizeAddressApi,
  serviceOrderApi,
  getShippingAddressApi,
  updateShippingAddressApi,
  createRepairRequestApi,
  updatePURScheduleApi,
} from "../actions/addressApi";
import { updateContactDetailsData } from "./contactsOperator";
import { isEmpty } from "../../../../helpers/formatUtils";
import { getFulFillmentApi, setFulFillmentApi } from "../../../../actions/fulfillment";
import { updateAppointment } from "../../../../actions/walkInApi";
import moment from "moment";
import { INCIDENT_BATTERY_REPLACEMENT } from "../../../../components/EnquiryOptions/Constants";

export const confirmationOfDateAndTime = (status, isPickup, textPrint) => async (dispatch, getStore) => {
  const client = getStore().app.client;

  dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));

  if (status === "Cancel") {
    await sleep(2000);
    dispatch(endFlow());
    return
  }

  let showComponent;
  showComponent =
    status === "Keep"
      ? isPickup
        ? client === CLIENT.SINGTEL
          ? "changeDeliveryAddress"
          : "confirmDeliveryAddress"
        : "confirmDetails"
      : status === "Change"
        ? isPickup
          ? "changePickupDateTime"
          : "changeDeliveryDateTime"
        : "";

  const initial = getStore().journeyMessages.flowProgress.percentage;

  const flowTitle =
    status === "Keep"
      ? isPickup
        ? client === CLIENT.SINGTEL
          ? PROGRESS_DATA.NEW_RETURN_ADDRESS.title
          : PROGRESS_DATA.CONFIRM_RETURN_ADDRESS.title
        : PROGRESS_DATA.CONFIRM_SCHEDULE.title
      : status === "Change"
        ? isPickup
          ? PROGRESS_DATA.NEW_PICKUP_DATE_TIME.title
          : PROGRESS_DATA.CHANGE_RETURN_DATE_TIME.title
        : "";

  const flowCount =
    status === "Keep"
      ? isPickup
        ? client === CLIENT.SINGTEL
          ? PROGRESS_DATA.NEW_RETURN_ADDRESS.count
          : PROGRESS_DATA.CONFIRM_RETURN_ADDRESS.count
        : PROGRESS_DATA.CONFIRM_SCHEDULE.count
      : status === "Change"
        ? isPickup
          ? PROGRESS_DATA.NEW_PICKUP_DATE_TIME.count
          : PROGRESS_DATA.CHANGE_RETURN_DATE_TIME.count
        : "";

  dispatch(updateFlowProgress(flowTitle, flowCount, initial));

  if (["changeDeliveryAddress", "confirmDeliveryAddress"].includes(showComponent)) {
    dispatch(
      updateFlowProgress(
        PROGRESS_DATA.CONFIRM_RETURN_ADDRESS.title,
        PROGRESS_DATA.CONFIRM_RETURN_ADDRESS.count,
        initial
      )
    );
  } else if (showComponent === "confirmDetails") {
    dispatch(updateFlowProgress(PROGRESS_DATA.CONFIRM_SCHEDULE.title, PROGRESS_DATA.CONFIRM_SCHEDULE.count, initial));
  }
  dispatch(
    addJourneyMessages([
      createMessage("PICKUP_AND_DELIVERY", "system", { showComponent }),
    ])
  );
};

export const confirmationOfAddress = (status, isPickup, postalCode, textPrint) => async (dispatch, getStore) => {
  const client = getStore().app.client;
  dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));

  if (status === "Cancel") {
    await sleep(2000);
    dispatch(endFlow());
    return
  }

  let showComponent;
  showComponent =
    status === "Confirm"
      ? isPickup
        ? "confirmPickupDateTime"
        : "confirmDeliveryDateTime"
      : status === "Change"
        ? isPickup
          ? "changePickupAddress"
          : "changeDeliveryAddress"
        : "";

  const flowTitle =
    status === "Confirm"
      ? isPickup
        ? PROGRESS_DATA.CONFIRM_PICKUP_DATE_TIME.title
        : PROGRESS_DATA.CONFIRM_RETURN_DATE_TIME.title
      : isPickup
        ? PROGRESS_DATA.NEW_PICKUP_ADDRESS.title
        : PROGRESS_DATA.NEW_RETURN_ADDRESS.title;

  const flowCount =
    status === "Confirm"
      ? isPickup
        ? PROGRESS_DATA.CONFIRM_PICKUP_DATE_TIME.count
        : PROGRESS_DATA.CONFIRM_RETURN_DATE_TIME.count
      : isPickup
        ? PROGRESS_DATA.NEW_PICKUP_ADDRESS.count
        : PROGRESS_DATA.NEW_RETURN_ADDRESS.count;

  const initial = getStore().journeyMessages.flowProgress.percentage;

  dispatch(updateFlowProgress(flowTitle, flowCount, initial));

  if (status === "Confirm") {
    await dispatch(updateApiProgress(API_PROGRESS.VERIFY_ADDRESS, 40, COMPONENTS.UPDATE_ADDRESS, 0));

    if (client === CLIENT.SINGTEL) {
      const { CacheId } = getStore().session.sessionData.Configurations;
      const addressObj = {
        PostalCode: postalCode,
      };
      // API call to validate address here
      const changeAddressResponse = await dispatch(standardizeAddressApi(CacheId, true, addressObj, false)).catch(
        (err) => dispatch(serviceUnavailable())
      );

      if (isEmpty(changeAddressResponse)) {
        return;
      }

      // After API call
      await dispatch(updateApiProgress(API_PROGRESS.UPDATE_PICKUP_ADDRESS_SUCCESS, 100, COMPONENTS.UPDATE_ADDRESS, 40));
      await sleep(1000);
      await dispatch(clearApiProgress());

      // check if address is valid
      if (changeAddressResponse.QASResult.isValidAddress) {
        dispatch(addJourneyMessages([createMessage("PICKUP_AND_DELIVERY", "system", { showComponent })]));
      } else {
        // address is not valid
        // show change address component
        showComponent = isPickup ? "changePickupAddress" : "changeDeliveryAddress";

        dispatch(
          addJourneyMessages([
            createMessage("TEXT", "system", "Your address is invalid. Please Enter new Address"),
            createMessage("PICKUP_AND_DELIVERY", "system", { showComponent }),
          ])
        );
      }
    } else {
      const { selectedPickupAddress, selectedDeliveryAddress } = getStore().claim.schedule;
      const address = isPickup ? selectedPickupAddress : selectedDeliveryAddress;
      const { Address1, Address2, Address3, City, PostalCode, StateProvinceCode } = address;
      const addressObj = {
        Address1,
        Address2,
        Address3,
        PostalCode,
        City,
        StateProvinceCode: !isEmpty(StateProvinceCode) ? StateProvinceCode : Address3, // for celcom blocknumber is state province
        Country: "MY",
        CountryCode: "MY",
        AddressId: "",
        Standardized: true,
        StandardizedSourceName: "StubbedQAS",
        BaseAddressHeaderId: "c7730733f6bea936403e881fed322e26",
      };

      if (isPickup) {
        await dispatch(
          updateApiProgress(
            API_PROGRESS.UPDATE_PICKUP_ADDRESS_SUCCESS,
            100,
            COMPONENTS.UPDATE_ADDRESS,
            40
          )
        );
        await sleep(1000);
        await dispatch(clearApiProgress());
       dispatch(savePickupAddress(addressObj, true));

        // call fullfilment api here for Celcom SUR
        const determinDetails = getStore().serviceRequest.determineIncidentDetails;

        const { CacheId } = getStore().session.sessionData.Configurations;

        const { RepairRequest, ServiceOrder } = determinDetails;

        if (isEmpty(ServiceOrder) || isEmpty(RepairRequest)) {

          const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;

          await dispatch(
            updateApiProgress(
              API_PROGRESS.TROUBLESHOOT,
              40,
              COMPONENTS.TERMS_N_CONDITIONS
            )
          );

          const getFulFillmentApiResponse = await dispatch(
            getFulFillmentApi(CacheId, ServiceRequestId)
          ).catch((err) => dispatch(serviceUnavailable()));

          // not allowing to go ahead if response is empty
          if (isEmpty(getFulFillmentApiResponse)) {
            return;
          }

          const setFulFillmentApiResponse = await dispatch(
            setFulFillmentApi(CacheId, ServiceRequestId, "PUR")
          ).catch((err) => dispatch(serviceUnavailable()));

          // not allowing to go ahead if response is empty
          if (isEmpty(setFulFillmentApiResponse)) {
            return;
          }

          await dispatch(
            updateApiProgress(
              API_PROGRESS.TROUBLESHOOT_SUCCESS,
              100,
              COMPONENTS.TERMS_N_CONDITIONS,
              40
            )
          );
          await sleep(1000);
          await dispatch(clearApiProgress());
        }
      } else {
        await dispatch(
          updateApiProgress(
            API_PROGRESS.UPDATE_DELIVERY_ADDRESS_SUCCESS,
            100,
            COMPONENTS.UPDATE_ADDRESS,
            40
          )
        );
        await sleep(1000);
        await dispatch(clearApiProgress());
        dispatch(saveDeliveryAddress(addressObj, true));
      }

      dispatch(addJourneyMessages([createMessage("PICKUP_AND_DELIVERY", "system", { showComponent })]));
    }
  } else {
    dispatch(addJourneyMessages([createMessage("PICKUP_AND_DELIVERY", "system", { showComponent })]));
  }
};

export const changeAddressSchedule = (address, isPickup, changeLabel) => async (dispatch, getStore) => {
  const enquiryOption = getStore().journeyMessages.enquiryOption;
  const isChangeSchedule = enquiryOption === EnumEnquiryModules.ChangeSchedule;

  let showComponent = "";
  const { unitNumber, buildingName, blockNumber, streetName, postalCode } = address;
  // const addressText = `${unitNumber} ${buildingName} ${streetName} ${blockNumber}  ${postalCode}`;
  const addressText = `${unitNumber} ${streetName} ${blockNumber}  ${postalCode}`;
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", isChangeSchedule ? <span>{changeLabel} <br/> {addressText}</span> : addressText)]));

  const { CacheId } = getStore().session.sessionData.Configurations;
  const client = getStore().app.client;
  const { selectedPickupAddress, selectedPickupDate, selectedDeliveryAddress } = getStore().claim.schedule;

  let addressObj = {};
  if (client === CLIENT.SINGTEL) {
    addressObj = {
      Address1: `${unitNumber}, ${buildingName}`,
      Address2: blockNumber,
      Address3: streetName,
      PostalCode: postalCode,
      City: "Singapore",
      StateProvinceCode: "Singapore",
      Country: "Singapore",
      CountryCode: "SGP",
      AddressId: "",
    };

    // call standardrize api
    await dispatch(
      updateApiProgress(
        isPickup ? API_PROGRESS.UPDATE_PICKUP_ADDRESS : API_PROGRESS.UPDATE_DELIVERY_ADDRESS,
        40,
        COMPONENTS.UPDATE_ADDRESS,
        0
      )
    );

    // API call to change address here
    const changeAddressResponse = await dispatch(standardizeAddressApi(CacheId, false, addressObj, false)).catch(
      (err) => dispatch(serviceUnavailable())
    );

    // not allowing to go ahead if response is empty
    if (isEmpty(changeAddressResponse)) {
      return;
    }

    // After API call
    await dispatch(
      updateApiProgress(
        isPickup ? API_PROGRESS.UPDATE_PICKUP_ADDRESS_SUCCESS : API_PROGRESS.UPDATE_DELIVERY_ADDRESS_SUCCESS,
        100,
        COMPONENTS.UPDATE_ADDRESS,
        40
      )
    );
    await sleep(1000);
    await dispatch(clearApiProgress());

    // check for is address valid
    if (changeAddressResponse.QASResult.isValidAddress) {
      // save correct address
      const address = changeAddressResponse.QASResult.AddressResult.CorrectedAddress;

      addressObj = {
        Address1: address.AddressLine1,
        Address2: address.AddressLine2,
        Address3: address.AddressLine3,
        PostalCode: address.PostalCode,
        City: address.CityName,
        StateProvinceCode: address.StateProvinceCode,
        Country: address.Country,
        CountryCode: address.CountryCode,
        AddressId: "",
        Standardized: address.Standardized,
        StandardizedSourceName: address.StandardizedSourceName,
        BaseAddressHeaderId: address.BaseAddressHeaderId,
      };

      if (isPickup) {
        await dispatch(savePickupAddress(addressObj, true));
        showComponent = "confirmPickupDateTime";
      } else {
        await dispatch(saveDeliveryAddress(addressObj, true));
        showComponent = "confirmDeliveryDateTime";
      }
    } else if (!changeAddressResponse.QASResult.isValidAddress) {
      // address is not valid
      // check retry
      if (changeAddressResponse.QASResult.addressValidationAttemptCount < 4) {
        dispatch(
          addJourneyMessages([createMessage("TEXT", "system", "Your address is invalid. Please Enter new Address")])
        );
        // change address component
        showComponent = isPickup ? "changePickupAddress" : "changeDeliveryAddress";
      } else {
        // show incorrect address component
        showComponent = isPickup ? "incorrectPickupAddress" : "incorrectDeliveryAddress";
      }
    }
  } else {
    addressObj = {
      Address1: unitNumber, //01
      Address2: buildingName, //test
      Address3: streetName, //text input
      PostalCode: postalCode,
      City: streetName,
      StateProvinceCode: blockNumber, // for celcom blocknumber is state province //dropdown
      Country: "MY",
      CountryCode: "MY",
      AddressId: "",
      Standardized: true,
      StandardizedSourceName: "StubbedQAS",
      BaseAddressHeaderId: "c7730733f6bea936403e881fed322e26",
    };

    if (isPickup) {
      await dispatch(savePickupAddress(addressObj, true));
      await dispatch(updateSchedule(postalCode, selectedDeliveryAddress.PostalCode));
      showComponent = "confirmPickupDateTime";
       // call fullfilment api here for Celcom SUR
       const determinDetails = getStore().serviceRequest.determineIncidentDetails;

       const { CacheId } = getStore().session.sessionData.Configurations;
 
       const { RepairRequest, ServiceOrder } = determinDetails;
 
       if (isEmpty(ServiceOrder) || isEmpty(RepairRequest)) {

        const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;

         await dispatch(
           updateApiProgress(
             API_PROGRESS.TROUBLESHOOT,
             40,
             COMPONENTS.TERMS_N_CONDITIONS
           )
         );
 
         const getFulFillmentApiResponse = await dispatch(
           getFulFillmentApi(CacheId, ServiceRequestId)
         ).catch((err) => dispatch(serviceUnavailable()));
 
         // not allowing to go ahead if response is empty
         if (isEmpty(getFulFillmentApiResponse)) {
           return;
         }
 
         const setFulFillmentApiResponse = await dispatch(
           setFulFillmentApi(CacheId, ServiceRequestId, "PUR")
         ).catch((err) => dispatch(serviceUnavailable()));
 
         // not allowing to go ahead if response is empty
         if (isEmpty(setFulFillmentApiResponse)) {
           return;
         }
 
         await dispatch(
           updateApiProgress(
             API_PROGRESS.TROUBLESHOOT_SUCCESS,
             100,
             COMPONENTS.TERMS_N_CONDITIONS,
             40
           )
         );
         await sleep(1000);
         await dispatch(clearApiProgress());
       }
    } else {
      await dispatch(saveDeliveryAddress(addressObj, true));
      await dispatch(updateSchedule(selectedPickupAddress.PostalCode, postalCode, selectedPickupDate));
      showComponent = "confirmDeliveryDateTime";
    }
  }

  const flowTitle = isPickup
    ? PROGRESS_DATA.CONFIRM_PICKUP_DATE_TIME.title
    : PROGRESS_DATA.CONFIRM_RETURN_DATE_TIME.title;

  const flowCount = isPickup
    ? PROGRESS_DATA.CONFIRM_PICKUP_DATE_TIME.count
    : PROGRESS_DATA.CONFIRM_RETURN_DATE_TIME.count;

  const initial = getStore().journeyMessages.flowProgress.percentage;

  dispatch(updateFlowProgress(flowTitle, flowCount, initial));

  dispatch(
    updateVisitor({
      lastActivity: isPickup ? ACTIVITY.UPDATE_PICKUP_ADDRESS : ACTIVITY.UPDATE_DELIVERY_ADDRESS,
    })
  );
  dispatch(addJourneyMessages([createMessage("PICKUP_AND_DELIVERY", "system", { showComponent })]));
};

export const changeDateTimeSchedule = (date, slot, isPickup, textPrint, changeLabel) => async (dispatch, getStore) => {
  const enquiryOption = getStore().journeyMessages.enquiryOption;
  const isChangeSchedule = enquiryOption === EnumEnquiryModules.ChangeSchedule;
  let showComponent = "";
  await dispatch(
    addJourneyMessages([
      createMessage(
        "TEXT",
        "user",
        isChangeSchedule ? (
          <span>
            {changeLabel} <br />
            {getFormmatedDate(date, "d MMM yyyy")}
            <br />
            {slot}{" "}
          </span>
        ) : (
          textPrint
        )
      ),
    ])
  );
  let flowTitle = "";
  let flowCount = 0;
  await dispatch(updateApiProgress(API_PROGRESS.UPDATE_SCHEDULE, 40, COMPONENTS.UPDATE_SCHEDULE, 0));
  const initial = getStore().journeyMessages.flowProgress.percentage;
  const client = getStore().app.client;
  const { selectedDeliveryAddress } = getStore().claim.schedule;
  const { isCampaignTimingEligible } = getStore().claim.schedule;

  if (isPickup) {
    if (isCampaignTimingEligible) {
      await dispatch(savePickupDateTime(date, slot));

      const deliveryOptions = getDeliveryDates(date, selectedDeliveryAddress.PostalCode, client, slot, true, true);
      const deliverySlot = deliveryOptions[0].slots[0];

      dispatch(saveDeliveryDateTime(deliveryOptions[0].date, deliverySlot));
    } else {
      const deliveryOptions = getDeliveryDates(date, selectedDeliveryAddress.PostalCode, client);
      const deliverySlot = deliveryOptions[0].slots[0];
      dispatch(saveDeliveryDateTime(deliveryOptions[0].date, deliverySlot));
      await dispatch(savePickupDateTime(date, slot));
    }
    
    showComponent = client === CLIENT.SINGTEL ? "changeDeliveryAddress" : "confirmDeliveryAddress";

    flowTitle =
      client === CLIENT.SINGTEL ? PROGRESS_DATA.NEW_RETURN_ADDRESS.title : PROGRESS_DATA.CONFIRM_RETURN_ADDRESS.title;
    flowCount =
      client === CLIENT.SINGTEL ? PROGRESS_DATA.NEW_RETURN_ADDRESS.count : PROGRESS_DATA.CONFIRM_RETURN_ADDRESS.count;
  } else {
    await dispatch(saveDeliveryDateTime(date, slot));
    showComponent = "confirmDetails";

    flowTitle = PROGRESS_DATA.CONFIRM_SCHEDULE.title;
    flowCount = PROGRESS_DATA.CONFIRM_SCHEDULE.count;
  }
  await dispatch(updateApiProgress(API_PROGRESS.UPDATE_SCHEDULE_SUCCESS, 100, COMPONENTS.UPDATE_SCHEDULE, 40));
  await sleep(1500);
  await dispatch(clearApiProgress());

  dispatch(updateFlowProgress(flowTitle, flowCount, initial));

  dispatch(
    updateVisitor({
      lastActivity: isPickup ? ACTIVITY.UPDATE_PICKUP_SCHEDULE : ACTIVITY.UPDATE_DELIVERY_SCHEDULE,
    })
  );
  dispatch(addJourneyMessages([createMessage("PICKUP_AND_DELIVERY", "system", { showComponent })]));
};

// export const confirmationOfDetails = (status, textPrint) => async (dispatch, getStore) => {
//   await dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));
//   const enquireyOption = getStore().journeyMessages.enquiryOption;
//   const client = getStore().app.client;
//   const countryName = client === CLIENT.SINGTEL ? "Singapore" : "Malaysia";
//   let showComponent = client === CLIENT.SINGTEL ? "changePickupAddress" : "confirmPickupAddress",
//     module = "PICKUP_AND_DELIVERY";

//   let flowTitle =
//     client === CLIENT.SINGTEL ? PROGRESS_DATA.NEW_PICKUP_ADDRESS.title : PROGRESS_DATA.CONFIRM_PICKUP_ADDRESS.title;
//   let flowCount =
//     client === CLIENT.SINGTEL ? PROGRESS_DATA.NEW_PICKUP_ADDRESS.count : PROGRESS_DATA.CONFIRM_PICKUP_ADDRESS.count;

//   if (status === "Confirm") {
//     module =
//       enquireyOption === EnumEnquiryModules.ChangeSchedule
//         ? "IMPORTANT_NOTE"
//         : [
//             EnumEnquiryModules.NewRequest,
//             EnumEnquiryModules.InWarrantyRequest,
//             EnumEnquiryModules.ResumeRequest,
//           ].includes(enquireyOption)
//         ? "CONTACT_CONFIRMATION"
//         : "";
//     dispatch(updateVisitor({ lastActivity: ACTIVITY.CONFIRM_SCHEDULE }));
//   }
//   if (module === "CONTACT_CONFIRMATION") {
//     await dispatch(updateContactDetailsData());
//     await dispatch(updateApiProgress(API_PROGRESS.UPDATE_SCHEDULE, 40, COMPONENTS.UPDATE_SCHEDULE, 0));

//     const client = getStore().app.client;
//     const { selectedAsset } = getStore().claim.deviceConfirmation;
//     const { CacheId } = getStore().session.sessionData.Configurations;
//     const { InteractionLineId } = getStore().session.interactionData.Interaction;
//     const { ServiceRequestId, CustomerCaseId } = getStore().serviceRequest.serviceRequestDetails;
//     const {
//       selectedPickupAddress,
//       selectedDeliveryAddress,
//       selectedPickupDate,
//       selectedPickupSlot,
//       selectedDeliveryDate,
//       selectedDeliverySlot,
//     } = getStore().claim.schedule;
//     const { firstName, lastName } = getStore().validation.inputData;
//     const customerName = `${firstName} ${lastName}`;

//     const { RepairRequest, ServiceOrder } = getStore().serviceRequest.determineIncidentDetails;

//     let ServiceOrderId;

//     // check for resume if service order is called before or not
//     // call service order api
//     if (isEmpty(ServiceOrder)) {
//       const repairDescription = client === CLIENT.SINGTEL ? "Singtel Screen Repair" : "Celcom Screen Repair";

//       let phoneList = [
//         {
//           ServiceOrderLineType: "PHN",
//           Quantity: 1,
//           AssetCatalogId: selectedAsset.AssetCatalog.AssetCatalogId,
//           VendorItemId: "",
//           Priority: "MNDTRY",
//           VenderItemType: "PHN",
//           VenderItemDescription: "PHONE",
//           VendorData: {
//             IsLikeForLike: true,
//             IsSimCard: false,
//             IsInKit: false,
//             IsInStock: true,
//           },
//         },
//       ];

//       const CreateServiceOrderParameters = {
//         SessionId: CacheId,
//         ApprovedServiceFeeChange: false,
//         InteractionLineId: InteractionLineId,
//         ServiceOrder: {
//           FulfillmentMethodType: "SCRNREP",
//           RepairDescription: repairDescription,
//           ServiceRequestId: ServiceRequestId,
//           CustomerCaseId: CustomerCaseId,
//           ServiceOrderLines: {
//             ServiceOrderLine: [...phoneList],
//           },
//         },
//       };

//       // call service order api
//       const serviceOrderResponse = await dispatch(serviceOrderApi(CreateServiceOrderParameters)).catch((err) =>
//         dispatch(serviceUnavailable())
//       );

//       // not allowing to go ahead if response is empty
//       if (isEmpty(serviceOrderResponse)) {
//         return;
//       }

//       ServiceOrderId = serviceOrderResponse.CreateServiceOrderResults.ServiceOrderId;
//     } else {
//       ServiceOrderId = ServiceOrder.ServiceOrderId;
//     }

//     if (isEmpty(RepairRequest)) {
//       // call create repair request

//       const CreateRepairRequestParameters = {
//         SessionId: CacheId,
//         CustomerCaseId: CustomerCaseId,
//         ServiceRequestId: ServiceRequestId,
//         ServiceOrderId: ServiceOrderId,
//         RepairRequestType: "PUR",
//       };

//       let repairRequestResponse = await dispatch(createRepairRequestApi(CreateRepairRequestParameters)).catch((err) =>
//         dispatch(serviceUnavailable())
//       );

//       // not allowing to go ahead if response is empty
//       if (isEmpty(repairRequestResponse)) {
//         return;
//       }
//     }

//     // call get shipping order api
//     let shippingAddressResponse = await dispatch(getShippingAddressApi(CacheId)).catch((err) =>
//       dispatch(serviceUnavailable())
//     );

//     // not allowing to go ahead if response is empty
//     if (isEmpty(shippingAddressResponse)) {
//       return;
//     }

//     // prepare update shipping request params
//     let UpdateShippingOrderParameters = {
//       EntityUpdateOptions: {
//         ReturnEntity: true,
//       },
//       SessionId: CacheId,
//       ServiceOrderId: ServiceOrderId,
//       ServiceRequestId: ServiceRequestId,
//       ShippingOrder: {
//         ShippingMethodType: isToday(selectedPickupDate) ? "Same Day" : "Next Day",
//         CarrierNumber: "347",
//         SignatureRequired: true,
//         RequestedShipmentDate: getFormmatedDate(new Date(selectedPickupDate), "yyyy-mm-dd", "-"),
//         ExpectedDeliveryDate: getFormmatedDate(new Date(selectedPickupDate), "yyyy-mm-dd", "-"),
//         DeliveryWindow: selectedPickupSlot === "9am - 12pm" ? "12H" : "16H",
//         Remark: "",
//         ReceiverName: customerName,
//         ReceiverAddress: {
//           AddressLine1: selectedPickupAddress.Address1,
//           AddressLine2: selectedPickupAddress.Address2,
//           AddressLine3: selectedPickupAddress.Address3,
//           CityName: selectedPickupAddress.City,
//           StateProvinceCode: selectedPickupAddress.StateProvinceCode,
//           PostalCode: selectedPickupAddress.PostalCode,
//           Country: selectedPickupAddress.Country ? selectedPickupAddress.Country : countryName,
//           CountryCode: selectedPickupAddress.CountryCode,
//           Standardized: selectedPickupAddress.Standardized ? selectedPickupAddress.Standardized : true,
//           StandardizedSourceName: selectedPickupAddress.StandardizedSourceName
//             ? selectedPickupAddress.StandardizedSourceName
//             : "QAS",
//           BaseAddressHeaderId: selectedPickupAddress.BaseAddressHeaderId
//             ? selectedPickupAddress.BaseAddressHeaderId
//             : "109f4afca0ecfbf50235f0d5e890495e3d4c78abab6289782e81ea7319eb0d4fb21851a3bf6af03c6df5a08032f63649",
//           IsOverridden: false,
//           AddressId: selectedPickupAddress.AddressId,
//         },
//       },
//       InboundShippingOrder: {
//         ShippingMethodType: "2BD",
//         CarrierNumber: "347",
//         SignatureRequired: true,
//         RequestedShipmentDate: getFormmatedDate(new Date(selectedDeliveryDate), "yyyy-mm-dd", "-"),
//         ExpectedDeliveryDate: getFormmatedDate(new Date(selectedDeliveryDate), "yyyy-mm-dd", "-"),
//         DeliveryWindow: "18H",
//         Remark: "",
//         ReceiverName: customerName,
//         ReceiverAddress: {
//           AddressLine1: selectedDeliveryAddress.Address1,
//           AddressLine2: selectedDeliveryAddress.Address2,
//           AddressLine3: selectedDeliveryAddress.Address3,
//           CityName: selectedDeliveryAddress.City,
//           StateProvinceCode: selectedDeliveryAddress.StateProvinceCode,
//           PostalCode: selectedDeliveryAddress.PostalCode,
//           Country: selectedDeliveryAddress.Country ? selectedDeliveryAddress.Country : countryName,
//           CountryCode: selectedDeliveryAddress.CountryCode,
//           Standardized: selectedDeliveryAddress.Standardized ? selectedDeliveryAddress.Standardized : true,
//           StandardizedSourceName: selectedDeliveryAddress.StandardizedSourceName
//             ? selectedDeliveryAddress.StandardizedSourceName
//             : "QAS",
//           BaseAddressHeaderId: selectedDeliveryAddress.BaseAddressHeaderId
//             ? selectedDeliveryAddress.BaseAddressHeaderId
//             : "109f4afca0ecfbf50235f0d5e890495e3d4c78abab6289782e81ea7319eb0d4fb21851a3bf6af03c6df5a08032f63649",
//           IsOverridden: false,
//           AddressId: selectedDeliveryAddress.AddressId,
//         },
//       },
//     };

//     // call update shipping api
//     let updateShippingAddressResponse = await dispatch(updateShippingAddressApi(UpdateShippingOrderParameters)).catch(
//       (err) => dispatch(serviceUnavailable())
//     );

//     // not allowing to go ahead if response is empty
//     if (isEmpty(updateShippingAddressResponse)) {
//       return;
//     }

//     await dispatch(updateApiProgress(API_PROGRESS.UPDATE_SCHEDULE_SUCCESS, 100, COMPONENTS.UPDATE_SCHEDULE, 40));
//     await sleep(1500);
//     await dispatch(clearApiProgress());

//     showComponent = "ConfirmMobileNumber";

//     flowTitle = PROGRESS_DATA.CONFIRM_CONTACT.title;
//     flowCount = PROGRESS_DATA.CONFIRM_CONTACT.count;
//   }
//   const initial = getStore().journeyMessages.flowProgress.percentage;
//   dispatch(updateFlowProgress(flowTitle, flowCount, initial));
//   await dispatch(addJourneyMessages([createMessage(module, "system", { showComponent })]));
// };

export const createPickupOptions = (client, pincode, startDate = new Date(), isDeviceAppleSamsung, isSurCampaign) => async (dispatch, getStore) => {
  // const cutoffTime = process.env.REACT_APP_SUR_CAMPAIGN_CUTOFF_TIME || '12:00';
  // const format = 'hh:mm:ss';
  // const nowtime = moment();
  // const beforeTime = moment(cutoffTime, format);

  // const isCampaignTimingEligible = isSurCampaign && nowtime.isBefore(beforeTime) && isDeviceAppleSamsung;
  const is1DayTAT = isDeviceAppleSamsung;
  const isCampaignTimingEligible = (isSurCampaign && isDeviceAppleSamsung) || is1DayTAT;

  dispatch(setisCampaignTimingEligible(isCampaignTimingEligible))

  const makeName = getStore().validation?.verification?.enrolledAsset?.Make?.Name.toUpperCase();
  const options = getPickupDates(startDate, client, pincode, makeName, isCampaignTimingEligible);
  dispatch({
    type: setPickupOptions.toString(),
    payload: options,
  });
  return options;
};

export const initializePickupSchedule = (isChangeCancelAppointment=false, isPickupDone=false) => async (dispatch, getStore) => {
  const {
    Type,
    ClaimedAsset,
  } = getStore().serviceRequest.determineIncidentDetails;

  const { isDeviceAppleSamsung = false, selectedAsset } = getStore().claim?.deviceConfirmation;

  const isSelectedAssetAppleSamsung = selectedAsset?.Make?.Name?.toLowerCase() === "apple" || selectedAsset?.Make?.Name?.toLowerCase() === "samsung";

  const isAssetFromDetermineAppleSamsung = Type === "ResumeServiceRequest" && ClaimedAsset && ClaimedAsset.Make 
  ? (ClaimedAsset.Make.toLowerCase() === "apple" || ClaimedAsset.Make.toLowerCase() === "samsung") 
  : false

  const determineCaseType = getStore().serviceRequest?.determineIncidentDetails?.Servicerequests?.[0]?.IncidentType;
  const journeyCaseType = getStore().journeyMessages.caseType;
  let caseType = Type === "StartServiceRequest" ? journeyCaseType : (determineCaseType || journeyCaseType);
  
  const surCampaignEnd = process.env.REACT_APP_SUR_CAMPAIGN_END;
  const isSurCampaignEndDateActive = moment().isSameOrBefore(moment(surCampaignEnd), "day")
  const isSurCampaign = (process.env.REACT_APP_SUR_CAMPAIGN_ENABLED === "true" && isSurCampaignEndDateActive) && caseType !== "Battery Replacement"
  const determineDetails = getStore().serviceRequest.determineIncidentDetails;
  let address = isChangeCancelAppointment
    ? determineDetails.ShippingOrderOutbound.Address
    : getStore().claim?.deviceConfirmation?.selectedAgreement?.EnrolledAddress;
  
  const deliveryAddress = isChangeCancelAppointment
    ? determineDetails?.ShippingOrderInbound?.Address || determineDetails?.ShippingOrderOutbound?.Address
    : getStore().claim?.deviceConfirmation?.selectedAgreement?.EnrolledAddress;

  if (isEmpty(address))
    address = getStore().validation.agreement[0]?.EnrolledAddress;
  
  await dispatch(savePickupAddress(address));
  await dispatch(saveDeliveryAddress(deliveryAddress || address));
  await dispatch(saveRegisteredAddress(address));
  // if (!isChangeCancelAppointment)
  await dispatch(
    updateSchedule(
      address.PostalCode,
      address.PostalCode,
      isPickupDone
        ? // getDateFromUtcTimeZone(
        //     8,
            new Date(
              determineDetails.ShippingOrderOutbound.ExpectedDeliveryDate
            )
          // )
        : undefined,
        isPickupDone,
        (isAssetFromDetermineAppleSamsung || isDeviceAppleSamsung || isSelectedAssetAppleSamsung), 
        isSurCampaign
    )
  );
  return;
};

export const updateSchedule = (pincode, destPincode, selectedPickupDate, setPickUpDate=false, isDeviceAppleSamsung=false, isSurCampaign=false) => async (dispatch, getStore) => {
  const client = getStore().app.client;
  const isDelivery = !isEmpty(selectedPickupDate);
  let pickupDate = selectedPickupDate;
  let pickupSlot = "";
  if (!isDelivery || setPickUpDate) {
    const pickupOptions = await dispatch(createPickupOptions(client, pincode, selectedPickupDate, isDeviceAppleSamsung, isSurCampaign));
    pickupSlot = pickupOptions[0].slots[0];
    await dispatch(savePickupDateTime(pickupOptions[0].date, pickupSlot));
    pickupDate = pickupOptions[0].date;
  }

  const deliveryOptions = getDeliveryDates(pickupDate, destPincode, client, pickupSlot, isDeviceAppleSamsung, isSurCampaign);
  const deliverySlot = deliveryOptions[0].slots[0];
  await dispatch(saveDeliveryDateTime(deliveryOptions[0].date, deliverySlot));
  return;
};

export const confirmRegisteredAddress = (status, isPickup, textPrint) => async (dispatch, getStore) => {
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));
  if (status === "No") {
    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.UnableToProcessRequest",
        }),
      ])
    );
  } else {
    const {
      Address1: unitNumber,
      Address2: blockNumber,
      Address3,
      City,
      PostalCode: postalCode,
    } = getStore().claim.deviceConfirmation.selectedAgreement.EnrolledAddress;
    const address = {
      unitNumber,
      blockNumber,
      streetName: `${Address3}, ${City}`,
      postalCode,
    };
    dispatch(changeAddressSchedule(address, isPickup));
  }
};

export const saveDeliveryDateTime = (date, slot) => async (dispatch) => {
  await dispatch({
    type: setDeliveryDateTime.toString(),
    payload: { date, slot },
  });
  return date;
};

export const savePickupDateTime = (date, slot) => async (dispatch) => {
  await dispatch({
    type: setPickupDateTime.toString(),
    payload: { date, slot },
  });
  return date;
};

export const savePickupAddress =
  (address, isUpdated = false) =>
    async (dispatch) => {
      await dispatch({
        type: setPickupAddress.toString(),
        payload: { address, isUpdated },
      });
      return address;
    };

export const saveDeliveryAddress =
  (address, isUpdated = false) =>
    async (dispatch) => {
      await dispatch({
        type: setDeliveryAddress.toString(),
        payload: { address, isUpdated },
      });
      return address;
    };

export const saveRegisteredAddress = (address) => async (dispatch) => {
  await dispatch({
    type: setRegisteredAddress.toString(),
    payload: address,
  });
  return address;
};

// Now this is only used to call APIs instead of redirection
export const confirmationOfDetails = () => async (dispatch, getStore) => {
  await dispatch(updateContactDetailsData());

  const client = getStore().app.client;
  const countryName = client === CLIENT.SINGTEL ? "Singapore" : "MY";
  const { selectedAsset } = getStore().claim.deviceConfirmation;
  const { CacheId } = getStore().session.sessionData.Configurations;
  const { InteractionLineId } = getStore().session.interactionData.Interaction;
  const { ServiceRequestId, CustomerCaseId } = getStore().serviceRequest.serviceRequestDetails;
  const {
    selectedPickupAddress,
    selectedDeliveryAddress,
    selectedPickupDate,
    selectedPickupSlot,
    selectedDeliveryDate,
  } = getStore().claim.schedule;
  const { firstName, lastName } = getStore().validation.inputData;
  const customerName = `${firstName} ${lastName}`;

  const { RepairRequest, ServiceOrder } = getStore().serviceRequest.determineIncidentDetails;
  const repairOption = getStore().journeyMessages.repairOption;
  const isWalkIn = repairOption === "WALKIN";
  const isSingtel = client === CLIENT.SINGTEL;
  const {
    selectedAppointmentDate,
    selectedAppointmentSlot,
    selectedStore,
  } = getStore().claim.walkIn;

  await dispatch(updateApiProgress(isWalkIn ? API_PROGRESS.UPDATE_WALKIN_SCHEDULE : API_PROGRESS.UPDATE_SCHEDULE, 40, COMPONENTS.UPDATE_SCHEDULE, 0));

  let ServiceOrderId;

  // check for resume if service order is called before or not
  // call service order api
  if (isEmpty(ServiceOrder)) {   
    const Type = getStore()?.serviceRequest?.type; 
    const determineCaseType = getStore().serviceRequest?.determineIncidentDetails?.Servicerequests?.[0]?.IncidentType;
    const journeyCaseType = getStore().journeyMessages.caseType;
    let caseType = Type === "StartServiceRequest" ? journeyCaseType : (determineCaseType || journeyCaseType);
    let singtelFulfillmentType = caseType === INCIDENT_BATTERY_REPLACEMENT ? "BTRREP" : "SCRNREP"

    // const repairDescription = isSingtel ? "Singtel Screen Repair" : isWalkIn ? "Celcom Fault Repair Walk-In" : "Celcom Fault Repair";
    const repairDescription = isSingtel 
    ? isWalkIn ? `Singtel ${caseType} Walk-In` : `Singtel ${caseType}`
    : isWalkIn ? "Celcom Fault Repair Walk-In" : "Celcom Fault Repair";

    let phoneList = [
      {
        ServiceOrderLineType: "PHN",
        Quantity: 1,
        AssetCatalogId: selectedAsset.AssetCatalog.AssetCatalogId,
        VendorItemId: "",
        Priority: "MNDTRY",
        VenderItemType: "PHN",
        VenderItemDescription: "PHONE",
        VendorData: {
          IsLikeForLike: true,
          IsSimCard: false,
          IsInKit: false,
          IsInStock: true,
        },
      },
    ];

    const CreateServiceOrderParameters = {
      SessionId: CacheId,
      ApprovedServiceFeeChange: false,
      InteractionLineId: InteractionLineId,
      ServiceOrder: {
        FulfillmentMethodType: isSingtel ? singtelFulfillmentType : "REPAIR",
        RepairDescription: repairDescription,
        ServiceRequestId: ServiceRequestId,
        CustomerCaseId: CustomerCaseId,
        ServiceOrderLines: {
          ServiceOrderLine: [...phoneList],
        },
      },
    };

    // call service order api
    const serviceOrderResponse = await dispatch(serviceOrderApi(CreateServiceOrderParameters)).catch((err) =>
      dispatch(serviceUnavailable())
    );

    // not allowing to go ahead if response is empty
    if (isEmpty(serviceOrderResponse)) {
      return false;
    }

    ServiceOrderId = serviceOrderResponse.CreateServiceOrderResults.ServiceOrderId;
  } else {
    ServiceOrderId = ServiceOrder.ServiceOrderId;
  }

  const appointmentDate = `${selectedAppointmentDate} ${selectedAppointmentSlot}`;

  if (isEmpty(RepairRequest)) {
    // call create repair request

    const CreateRepairRequestParameters = {
      SessionId: CacheId,
      CustomerCaseId: CustomerCaseId,
      ServiceRequestId: ServiceRequestId,
      ServiceOrderId: ServiceOrderId,
      // RepairRequestType: isSingtel ? "PUR" : repairOption,
      RepairRequestType: repairOption || "PUR",
      AppointmentDate: isWalkIn ? `${selectedAppointmentDate} ${getFormatedTime(appointmentDate, false, "hh:mm", ":")}` : undefined,
      ServiceProviderId: isWalkIn ? selectedStore.SERVICE_PROVIDER_ID : undefined,
      SoftAllocationTime: isWalkIn ? selectedAppointmentSlot : undefined,
    };

    let repairRequestResponse = await dispatch(createRepairRequestApi(CreateRepairRequestParameters)).catch((err) =>
      dispatch(serviceUnavailable())
    );

    // not allowing to go ahead if response is empty
    if (isEmpty(repairRequestResponse)) {
      return false;
    }
  } else if (isWalkIn) {
    // call update appointment api here
    const UpdateRepairRequestParameter = {
      SessionId: CacheId,
      CustomerCaseId: CustomerCaseId,
      ServiceRequestId: ServiceRequestId,
      ServiceOrderId: ServiceOrderId,
      RepairRequestType: "WALKIN",
      AppointmentDate: `${selectedAppointmentDate} ${getFormatedTime(appointmentDate, false, "hh:mm", ":")}`,
      RepairStatus: "RESERVED",
      UpdateAction: "CHANGEINPROGRESSAPPOINTMENT",
      ServiceProviderId: selectedStore
        ? selectedStore.SERVICE_PROVIDER_ID
        : RepairRequest.ServiceProviderId,
    };

    let updateAppointmentResponse = await dispatch(
      updateAppointment(UpdateRepairRequestParameter)
    ).catch((err) => dispatch(serviceUnavailable()));

    // not allowing to go ahead if response is empty
    if (isEmpty(updateAppointmentResponse)) {
      return false;
    }
  }

  // call get shipping order api
  // let shippingAddressResponse = await dispatch(getShippingAddressApi(CacheId)).catch((err) =>
  //   dispatch(serviceUnavailable())
  // );
  let shippingAddressResponse = null;
  let retrycount = 0;
  while(retrycount < 2) {
    shippingAddressResponse = await dispatch(getShippingAddressApi(CacheId))
    .catch((err) =>
      { return null }
    );

    if (shippingAddressResponse === null) {
      retrycount++;
    } else {
      retrycount = 2;
    }
  }
  // not allowing to go ahead if response is empty
  if (isEmpty(shippingAddressResponse)) {
    dispatch(serviceUnavailable())
    return false;
  }

  // prepare update shipping request params
  let UpdateShippingOrderParameters = {};

  if (isWalkIn) {
    // for WalkIn
    const QASAddress = {
      AddressLine1: selectedStore.ADDRESS_LINE_1,
      AddressLine2: selectedStore.ADDRESS_LINE_2,
      AddressLine3: selectedStore.ADDRESS_LINE_3,
      CityName: selectedStore.CITY_NAME,
      StateProvinceCode: selectedStore.STATE_PROVINCE_NAME,
      PostalCode: selectedStore.POSTAL_CODE,
      Country: countryName,
      // CountryCode: selectedStore.COUNTRY_NAME,
      CountryCode: countryName === "Singapore" ? "SGP" : "MY",
      Standardized: true,
      StandardizedSourceName: "QAS",
      BaseAddressHeaderId: "f0a10cc5bb433defefce2ce24001e2d4" || selectedStore.ADDRESS_ID,
      IsOverridden: false,
      AddressId: selectedStore.ADDRESS_ID,
      IsOverridden: false,
    };

    UpdateShippingOrderParameters = {
      EntityUpdateOptions: {
        ReturnEntity: true,
      },
      SessionId: CacheId,
      ServiceOrderId: ServiceOrderId,
      ServiceRequestId: ServiceRequestId,
      ShippingOrder: {
        ShippingMethodType: isToday(selectedAppointmentDate)
          ? "Same Day"
          : "Next Day",
        CarrierNumber: "347",
        SignatureRequired: true,
        RequestedShipmentDate: getFormmatedDate(
          new Date(selectedAppointmentDate),
          "yyyy-mm-dd",
          "-"
        ),
        ExpectedDeliveryDate: getFormmatedDate(
          new Date(selectedAppointmentDate),
          "yyyy-mm-dd",
          "-"
        ),
        DeliveryWindow: selectedAppointmentSlot
          .substring(0, selectedAppointmentSlot.length - 2)
          .replace(new RegExp(":", "g"), ""),
        Remark: "",
        ReceiverName: selectedStore.SERVICE_PROVIDER_NAME,
        ReceiverAddress: QASAddress,
      },
    };
  } else {
    // for PUR
    UpdateShippingOrderParameters = {
      EntityUpdateOptions: {
        ReturnEntity: true,
      },
      SessionId: CacheId,
      ServiceOrderId: ServiceOrderId,
      ServiceRequestId: ServiceRequestId,
      ShippingOrder: {
        ShippingMethodType: isToday(selectedPickupDate) ? "Same Day" : "Next Day",
        CarrierNumber: "347",
        SignatureRequired: true,
        RequestedShipmentDate: getFormmatedDate(new Date(selectedPickupDate), "yyyy-mm-dd", "-"),
        ExpectedDeliveryDate: getFormmatedDate(new Date(selectedPickupDate), "yyyy-mm-dd", "-"),
        // DeliveryWindow: selectedPickupSlot === "9am - 12pm" ? "12H" : "16H",
        DeliveryWindow: selectedPickupSlot === "12pm - 2pm" || selectedPickupSlot === "10am - 2pm"
            ? "14H"
            : selectedPickupSlot === "9am - 12pm"
              ? "12H"
              : selectedPickupSlot === "2pm - 6pm" ? "18H" : "16H",
        Remark: "",
        ReceiverName: customerName,
        ReceiverAddress: {
          AddressLine1: selectedPickupAddress.Address1,
          AddressLine2: selectedPickupAddress.Address2,
          AddressLine3: selectedPickupAddress.Address3,
          CityName: selectedPickupAddress.City,
          StateProvinceCode: selectedPickupAddress.StateProvinceCode,
          PostalCode: selectedPickupAddress.PostalCode,
          Country: selectedPickupAddress.Country ? selectedPickupAddress.Country : countryName,
          CountryCode: selectedPickupAddress.CountryCode,
          Standardized: selectedPickupAddress.Standardized ? selectedPickupAddress.Standardized : true,
          StandardizedSourceName: selectedPickupAddress.StandardizedSourceName
            ? selectedPickupAddress.StandardizedSourceName
            : "QAS",
          BaseAddressHeaderId: selectedPickupAddress.BaseAddressHeaderId
            ? selectedPickupAddress.BaseAddressHeaderId
            : "109f4afca0ecfbf50235f0d5e890495e3d4c78abab6289782e81ea7319eb0d4fb21851a3bf6af03c6df5a08032f63649",
          IsOverridden: false,
          AddressId: selectedPickupAddress.AddressId,
        },
      },
      InboundShippingOrder: {
        // ShippingMethodType: "2BD",
        ShippingMethodType: isSingtel && (selectedPickupSlot === "12pm - 2pm" || selectedPickupSlot === "10am - 2pm") ? "1BD" : "2BD",
        CarrierNumber: "347",
        SignatureRequired: true,
        RequestedShipmentDate: getFormmatedDate(new Date(selectedDeliveryDate), "yyyy-mm-dd", "-"),
        ExpectedDeliveryDate: getFormmatedDate(new Date(selectedDeliveryDate), "yyyy-mm-dd", "-"),
        DeliveryWindow: "18H",
        Remark: "",
        ReceiverName: customerName,
        ReceiverAddress: {
          AddressLine1: selectedDeliveryAddress.Address1,
          AddressLine2: selectedDeliveryAddress.Address2,
          AddressLine3: selectedDeliveryAddress.Address3,
          CityName: selectedDeliveryAddress.City,
          StateProvinceCode: selectedDeliveryAddress.StateProvinceCode,
          PostalCode: selectedDeliveryAddress.PostalCode,
          Country: selectedDeliveryAddress.Country ? selectedDeliveryAddress.Country : countryName,
          CountryCode: selectedDeliveryAddress.CountryCode,
          Standardized: selectedDeliveryAddress.Standardized ? selectedDeliveryAddress.Standardized : true,
          StandardizedSourceName: selectedDeliveryAddress.StandardizedSourceName
            ? selectedDeliveryAddress.StandardizedSourceName
            : "QAS",
          BaseAddressHeaderId: selectedDeliveryAddress.BaseAddressHeaderId
            ? selectedDeliveryAddress.BaseAddressHeaderId
            : "109f4afca0ecfbf50235f0d5e890495e3d4c78abab6289782e81ea7319eb0d4fb21851a3bf6af03c6df5a08032f63649",
          IsOverridden: false,
          AddressId: selectedDeliveryAddress.AddressId,
        },
      },
    };
  }

  // call update shipping api
  let updateShippingAddressResponse = await dispatch(updateShippingAddressApi(UpdateShippingOrderParameters)).catch(
    (err) => dispatch(serviceUnavailable())
  );

  // not allowing to go ahead if response is empty
  if (isEmpty(updateShippingAddressResponse)) {
    return false;
  }

  await dispatch(updateApiProgress(isWalkIn ? API_PROGRESS.UPDATE_WALKIN_SCHEDULE_SUCCESS : API_PROGRESS.UPDATE_SCHEDULE_SUCCESS, 100, COMPONENTS.UPDATE_SCHEDULE, 40));
  await sleep(1500);
  await dispatch(clearApiProgress());

  return true
}

// This method is used as redirect to appropriate flow and confirmationOfDetails will only used as API call
export const redirectToConfirmPhone = (status, textPrint, isAfterCutoffError = false) => async (dispatch, getStore) => {
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));

  const enquiryOption = getStore().journeyMessages.enquiryOption;
  const client = getStore().app.client;
  const isSingtel = client === CLIENT.SINGTEL;
  let showComponent = isSingtel ? "changePickupAddress" : "confirmPickupAddress";
  let module = "PICKUP_AND_DELIVERY";
  let flowTitle =
    isSingtel ? PROGRESS_DATA.NEW_PICKUP_ADDRESS.title : PROGRESS_DATA.CONFIRM_PICKUP_ADDRESS.title;
  let flowCount =
    isSingtel ? PROGRESS_DATA.NEW_PICKUP_ADDRESS.count : PROGRESS_DATA.CONFIRM_PICKUP_ADDRESS.count;

  const options = [
    EnumEnquiryModules.NewRequest,
    EnumEnquiryModules.InWarrantyRequest,
    EnumEnquiryModules.ResumeRequest,
    EnumEnquiryModules.NewRequestBatteryReplacement,
  ];

  if (isSingtel && isAfterCutoffError) {
    let updateShippingOrderResponse = await dispatch(updateShippingOrderNewSchedule())

    if (!updateShippingOrderResponse) return;

    module = "REPAIR_DETAILS";
    showComponent = "";
  } else {
    if (status === "Confirm") {
      module =
        enquiryOption === EnumEnquiryModules.ChangeSchedule
          ? "IMPORTANT_NOTE"
          : options.includes(enquiryOption)
            ? "CONTACT_CONFIRMATION"
            : "";
  
      dispatch(updateVisitor({ lastActivity: ACTIVITY.CONFIRM_SCHEDULE }));
    }
  
    if (module === "CONTACT_CONFIRMATION") {
      showComponent = isSingtel ? "ConfirmMobileNumber" : "ChangeMobileNumber";
      flowTitle = PROGRESS_DATA.CONFIRM_CONTACT.title;
      flowCount = PROGRESS_DATA.CONFIRM_CONTACT.count;
    }
  }

  const initial = getStore().journeyMessages.flowProgress.percentage;
  dispatch(updateFlowProgress(flowTitle, flowCount, initial));
  await dispatch(addJourneyMessages([createMessage(module, "system", { showComponent })]));
};

export const updateShippingOrderNewSchedule = () => async (dispatch, getStore) => {
  await dispatch(
    updateApiProgress(
      API_PROGRESS.UPDATE_SCHEDULE,
      40,
      COMPONENTS.UPDATE_SCHEDULE,
      0
    )
  );
  const { CacheId } = getStore().session.sessionData.Configurations;

  const {
    ServiceRequestId,
  } = getStore().serviceRequest.serviceRequestDetails;

  const {
    selectedPickupAddress,
    selectedDeliveryAddress,
    selectedPickupDate,
    selectedPickupSlot,
    selectedDeliveryDate,
    serviceOrder
  } = getStore().claim.schedule;

  const { firstName, lastName } = getStore().validation.inputData;
  const customerName = `${firstName} ${lastName}`;

  const client = getStore().app.client;
  const isSingtel = client === CLIENT.SINGTEL;
  const countryName = client === CLIENT.SINGTEL ? "Singapore" : "MY";

  const UpdateShippingOrderParameters = {
    EntityUpdateOptions: {
      ReturnEntity: true,
    },
    SessionId: CacheId,
    ServiceOrderId: serviceOrder.CreateServiceOrderResults.ServiceOrderId,
    ServiceRequestId: ServiceRequestId,
    ShippingOrder: {
      ShippingMethodType: isToday(selectedPickupDate) ? "Same Day" : "Next Day",
      CarrierNumber: "347",
      SignatureRequired: true,
      RequestedShipmentDate: getFormmatedDate(new Date(selectedPickupDate), "yyyy-mm-dd", "-"),
      ExpectedDeliveryDate: getFormmatedDate(new Date(selectedPickupDate), "yyyy-mm-dd", "-"),
      DeliveryWindow: selectedPickupSlot === "12pm - 2pm" || selectedPickupSlot === "10am - 2pm"
          ? "14H"
          : selectedPickupSlot === "9am - 12pm"
            ? "12H"
            : selectedPickupSlot === "2pm - 6pm" ? "18H" : "16H",
      Remark: "",
      ReceiverName: customerName,
      ReceiverAddress: {
        AddressLine1: selectedPickupAddress.Address1,
        AddressLine2: selectedPickupAddress.Address2,
        AddressLine3: selectedPickupAddress.Address3,
        CityName: selectedPickupAddress.City,
        StateProvinceCode: selectedPickupAddress.StateProvinceCode,
        PostalCode: selectedPickupAddress.PostalCode,
        Country: selectedPickupAddress.Country ? selectedPickupAddress.Country : countryName,
        CountryCode: selectedPickupAddress.CountryCode,
        Standardized: selectedPickupAddress.Standardized ? selectedPickupAddress.Standardized : true,
        StandardizedSourceName: selectedPickupAddress.StandardizedSourceName
          ? selectedPickupAddress.StandardizedSourceName
          : "QAS",
        BaseAddressHeaderId: selectedPickupAddress.BaseAddressHeaderId
          ? selectedPickupAddress.BaseAddressHeaderId
          : "109f4afca0ecfbf50235f0d5e890495e3d4c78abab6289782e81ea7319eb0d4fb21851a3bf6af03c6df5a08032f63649",
        IsOverridden: false,
        AddressId: selectedPickupAddress.AddressId,
      },
    },
    InboundShippingOrder: {
      ShippingMethodType: isSingtel && (selectedPickupSlot === "12pm - 2pm" || selectedPickupSlot === "10am - 2pm") ? "1BD" : "2BD",
      CarrierNumber: "347",
      SignatureRequired: true,
      RequestedShipmentDate: getFormmatedDate(new Date(selectedDeliveryDate), "yyyy-mm-dd", "-"),
      ExpectedDeliveryDate: getFormmatedDate(new Date(selectedDeliveryDate), "yyyy-mm-dd", "-"),
      DeliveryWindow: "18H",
      Remark: "",
      ReceiverName: customerName,
      ReceiverAddress: {
        AddressLine1: selectedDeliveryAddress.Address1,
        AddressLine2: selectedDeliveryAddress.Address2,
        AddressLine3: selectedDeliveryAddress.Address3,
        CityName: selectedDeliveryAddress.City,
        StateProvinceCode: selectedDeliveryAddress.StateProvinceCode,
        PostalCode: selectedDeliveryAddress.PostalCode,
        Country: selectedDeliveryAddress.Country ? selectedDeliveryAddress.Country : countryName,
        CountryCode: selectedDeliveryAddress.CountryCode,
        Standardized: selectedDeliveryAddress.Standardized ? selectedDeliveryAddress.Standardized : true,
        StandardizedSourceName: selectedDeliveryAddress.StandardizedSourceName
          ? selectedDeliveryAddress.StandardizedSourceName
          : "QAS",
        BaseAddressHeaderId: selectedDeliveryAddress.BaseAddressHeaderId
          ? selectedDeliveryAddress.BaseAddressHeaderId
          : "109f4afca0ecfbf50235f0d5e890495e3d4c78abab6289782e81ea7319eb0d4fb21851a3bf6af03c6df5a08032f63649",
        IsOverridden: false,
        AddressId: selectedDeliveryAddress.AddressId,
      },
    },
  };
 
  // call update shipping api
  let updateShippingAddressResponse = await dispatch(
    updateShippingAddressApi(UpdateShippingOrderParameters)
  ).catch((err) => {
    dispatch(serviceUnavailable());
    return null;
  }
  );
  if (isEmpty(updateShippingAddressResponse)) {
    await dispatch(clearApiProgress());
    return false;
  };

  await dispatch(
    updateApiProgress(
      API_PROGRESS.UPDATE_SCHEDULE_SUCCESS,
      100,
      COMPONENTS.UPDATE_SCHEDULE,
      40
    )
  );
  await sleep(1500);
  await dispatch(clearApiProgress());
  return true;
};

export const changePURScheduleFlow = () => async (dispatch, getStore) => {
  const { RepairRequest } = getStore().serviceRequest.determineIncidentDetails;

  // for status scheduled allow customer to change both pickup and return details
  // for any other status only allow to change return details
  const showComponent =
    RepairRequest && RepairRequest.RepairStatus === "SCHEDULED"
      ? "confirmPickupAddress"
      : "confirmDeliveryAddress";

  dispatch(
    addJourneyMessages([
      createMessage("PICKUP_AND_DELIVERY", "system", { showComponent }),
    ])
  );
};

export const changePURSchedule = (status, textPrint) => async (dispatch, getStore) => {

  await dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));

  if (status === "Confirm") {
    const { CacheId } = getStore().session.sessionData.Configurations;
    const {
      ServiceRequest,
      CustomerCaseId,
      RepairRequest,
      ServiceOrder,
      ShippingOrderInbound,
      ShippingOrderOutbound,
    } = getStore().serviceRequest.determineIncidentDetails;
    const {
      selectedPickupAddress,
      selectedDeliveryAddress,
      selectedPickupDate,
      selectedPickupSlot,
      selectedDeliveryDate,
    } = getStore().claim.schedule;

    let CreateRepairScheduleParameters = {
      CustomerCaseId: CustomerCaseId,
      InboundShippingOrder: {
        ShippingOrderId: ShippingOrderInbound.ShippingOrderId,
        ShippingMethodType: "2BD",
        RequestedDeliveryDate: getFormmatedDate(
          new Date(selectedDeliveryDate),
          "yyyy-mm-dd",
          "-"
        ),
        DeliveryWindow: "18H",
        ReceiverAddress: {
          AddressLine1: selectedDeliveryAddress.Address1,
          AddressLine2: selectedDeliveryAddress.Address2,
          AddressLine3: selectedDeliveryAddress.Address3,
          CityName: selectedDeliveryAddress.City,
          StateProvinceCode: selectedDeliveryAddress.StateProvinceCode,
          PostalCode: selectedDeliveryAddress.PostalCode,
          Country: selectedDeliveryAddress.Country,
          CountryCode: selectedDeliveryAddress.CountryCode,
          Standardized: false,
        },
      },
      RepairRequestId: RepairRequest.RepairRequestId,
      RepairRequestType: "PUR",
      RepairStatus: RepairRequest.RepairStatus,
      SessionId: CacheId,
      ServiceOrderId: ServiceOrder.ServiceOrderId,
      ServiceRequestId: ServiceRequest.ServiceRequestId,
    };

    // update pick-up details only for status type scheduled
    if (RepairRequest.RepairStatus === "SCHEDULED") {
      CreateRepairScheduleParameters.OutboundShippingOrder = {
        ShippingOrderId: ShippingOrderOutbound.ShippingOrderId,
        ShippingMethodType: isToday(selectedPickupDate)
          ? "Same Day"
          : "Next Day",
        CarrierNumber: "347",
        RequestedPickupDate: getFormmatedDate(
          new Date(selectedPickupDate),
          "yyyy-mm-dd",
          "-"
        ),
        // DeliveryWindow: selectedPickupSlot === "9am - 12pm" ? "12H" : "16H",
        DeliveryWindow: selectedPickupSlot === "12pm - 2pm" || selectedPickupSlot === "10am - 2pm"
            ? "14H"
            : selectedPickupSlot === "9am - 12pm"
              ? "12H"
              : selectedPickupSlot === "2pm - 6pm" ? "18H" : "16H",
        ReceiverAddress: {
          AddressLine1: selectedPickupAddress.Address1,
          AddressLine2: selectedPickupAddress.Address2,
          AddressLine3: selectedPickupAddress.Address3,
          CityName: selectedPickupAddress.City,
          StateProvinceCode: selectedPickupAddress.StateProvinceCode,
          PostalCode: selectedPickupAddress.PostalCode,
          Country: selectedPickupAddress.Country,
          CountryCode: selectedPickupAddress.CountryCode,
          Standardized: false,
        },
      };
    }

    await dispatch(
      updateApiProgress(
        API_PROGRESS.UPDATE_SCHEDULE,
        40,
        COMPONENTS.UPDATE_SCHEDULE,
        0
      )
    );

    // call update shipping api
    let updatePURScheduleResponse = await dispatch(
      updatePURScheduleApi(CreateRepairScheduleParameters)
    ).catch((err) => dispatch(serviceUnavailable()));

    // not allowing to go ahead if response is empty
    if (isEmpty(updatePURScheduleResponse)) {
      return;
    }

    await dispatch(
      updateApiProgress(
        API_PROGRESS.UPDATE_SCHEDULE_SUCCESS,
        100,
        COMPONENTS.UPDATE_SCHEDULE,
        40
      )
    );
    await sleep(1500);
    await dispatch(clearApiProgress());

    dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.UpdatePURConfirmation",
        }),
        createMessage("TEXT", "system", {
          key: "SystemMessage.RequestSMSConfirmation",
        }),
      ])
    );

    await sleep(8000);
    dispatch(endFlow());

  } else {
    await sleep(2000);
    dispatch(endFlow());
  }

};
