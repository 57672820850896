import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  resetReduxStore,
  toggleFlowWindowVisibility,
} from "../../actions/flowWindow";
import Flow from "../../container/Flow";

import celcomLogo from "../../images/img-celcom-logo.svg";
import asurionLogo from "../../images/logo.svg";
import asurionLogoMobile from "../../images/logo.svg";
import sevice2uLogo from "../../images/img-service2u-icon.svg";
//import asurionLogoNew from "../../images/img-asurion-logo.svg";
//import chatIcon from "../../images/icon-ask-me.svg";

import celcomLogoV2 from "../../images/v2/home/celcom/celcomdigi-logo.svg";
import sevice2uLogoV2 from "../../images/v2/home/celcom/s2u-logo.svg";

import Footer from "./Footer";
import HomeBody from "./HomeBody";
import { setAppClient, setAppLanguage } from "../../operators/homeOperator";
import { setInitialLanguage, Translate, useTranslate } from "../../helpers/languageUtils";
import { sleep } from "../../helpers/addJourneyMessages";
import { CLIENT, LANGUAGE } from "../../helpers/constants";
import LanguageSelector from "./LanguageSelector";
import { TextBold, TextMedium } from "../../base/BaseText";
import { showMaintenanceText } from "../../helpers/dateUtils";
import Config from "../../modules/Appsync/Config";
import MIXPANEL from "../../mixpanel/mixpanelActions";
import sendMixpanelEvent from "../../mixpanel/sendMixpanelEvent";
import EVENTLIST from "../../mixpanel/EVENTLIST";

import closeButtonWhite from "../../images/home/close-white.svg";

import moment from "moment";

const Home = () => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const showFlowWindow = useSelector(
    (state) => state.journeyMessages.showFlowWindow
  );
  const client = useSelector((state) => state.app.client);
  const language = useSelector((state) => state.app.language);
  const [loading, setLoading] = useState(true);



  const setInitialClientAndLanguage = async () => {
    let clientName = window.location.pathname.replace("/", "");
    clientName =
      clientName.toUpperCase() === CLIENT.MY_CELCOM
        ? CLIENT.CELCOM
        : clientName;

    //MIxpanel only for Singtel
    if (clientName === CLIENT.SINGTEL) {
      MIXPANEL.init(Config['singtel'].WPAPIServer.mixpanel);
      //MIXPANEL.identify(visitorId);
      MIXPANEL.register_once({ "Carrier": "Singtel", "Partner Name": "Singtel" });
      dispatch(sendMixpanelEvent(EVENTLIST.SCREEN_REPAIR_ROUTE, { source: 'Direct' }));
    }
    if ((clientName === CLIENT.SINGTEL) ||
      (clientName === CLIENT.CELCOM)) {
      const language = setInitialLanguage(clientName);
      dispatch(setAppClient(clientName));
      dispatch(setAppLanguage(language));
      await sleep(1000);
      setLoading(false);
    } else {
      // redirect to asurion page for any other route
      redirectToAsurion();
    }
  };

  const redirectToAsurion = () => {
    window.location.href = "https://www.asurion.com/";
  };

  useEffect(() => {
    setInitialClientAndLanguage();
  }, []);

  const onToggleFlowWindow = () => {
    dispatch(toggleFlowWindowVisibility(!showFlowWindow));
    dispatch(resetReduxStore());
  };

  const battRepCampaignEnd = client === CLIENT.SINGTEL ? process.env.REACT_APP_SINGTEL_BATT_CAMPAIGN_END : "";
  const isBattRepEndDateActive = client === CLIENT.SINGTEL ? moment().isSameOrBefore(moment(battRepCampaignEnd), "day") : false;
  const isBattRepCampaign = client === CLIENT.SINGTEL ? isBattRepEndDateActive : false;
  const [isToastVisible, setisToastVisible] = useState(true);
  const isSingtelToastEnabled = process.env.REACT_APP_SINGTEL_TOAST_ENABLED === "true" && isBattRepCampaign;

  const onCloseToast = () => {
    setisToastVisible(!isToastVisible);
  }

  return (
    <Fragment>
      <div className="app__home">
        {client === CLIENT.SINGTEL && isSingtelToastEnabled && isToastVisible &&
          <div style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            background: "#6700B8",
            padding: "15px",
            zIndex: "49",
          }}>
            <div className="relative">
              <img src={closeButtonWhite} onClick={onCloseToast} 
              style={{ 
                width: "24px", 
                height: "24px",
                padding: "0",
                position: "absolute",
                top: "0",
                right: "0",
                cursor: "pointer"
              }} 
              />
              <p className="text-white text-center font-bold mb-2" style={{ fontSize: "18px" }}>
                🎂 MOBILESWOP TURNS 10! 🎂
                <br />
                To celebrate, new MobileSwop subscribers* will have access to our battery replacement service for ONLY $79! 🎉
              </p>
              <p className="text-white text-center" style={{ fontSize: "14px" }}>
                *Exclusive for new MobileSwop Unlimited Premium and MobileSwop Family subscribers from Aug 1 - Oct 31 2024
              </p>
            </div>
          </div>
        }          
        <header className={`app__home-header ${client === CLIENT.CELCOM ? "celcomdigi" : ""}`}>
          {client === CLIENT.SINGTEL ? (
            <>
              <div className="hidden lg:flex justify-between w-4/5 mx-auto lg:p-4 s1600:p-6 ">
                <img src={asurionLogo} alt="Logo" />
              </div>
              <div className="lg:hidden flex justify-between w-4/5 mx-auto">
                <img src={asurionLogoMobile} alt="Logo" />
              </div>                  
            </>
          ) : (
            <>
              <div className="hidden lg:flex justify-between w-4/5 mx-auto lg:p-4 lg1:px-18 md:px-10 s1600:p-6 ">
                <div className="self-end flex gap-10 h-full">
                    <>
                      <img src={celcomLogoV2} alt="Logo" className="self-center p-0 h-full" />
                      <img src={sevice2uLogoV2} alt="Logo" className="self-center p-0 h-90%" />
                    </>
                </div>
                <div className="flex">
                  <LanguageSelector className="self-center pr-8x" />
                  {/* <img
                    src={celcomLogo}
                    alt="Logo"
                    className="self-end pb-0 w-48"
                  /> */}
                </div>
              </div>
              <div className="lg:hidden text-center w-full mx-auto pb-0">
                  <div className="flex justify-between">
                    <div className="flex flex-col justify-center align-center pl-4 pt-2">
                      <img
                        src={celcomLogoV2}
                        alt="Logo"
                        className="mx-auto w-56 self-center p-0"
                      />
                      <img src={sevice2uLogoV2} alt="Logo" className="py-0 w-48 self-end mb-4" />
                    </div>
                    <LanguageSelector className="self-center ml-auto mr-4" />
                  </div>
              </div>
            </>
          )}
        </header>
        {showMaintenanceText() && (
          <div>
            <marquee className="pt-2 text-3xl font-DINPro-Bold">
              <Translate i18nKey="SystemMessage.MaintenanceText">
              </Translate>
            </marquee>
          </div>
        )}
        <div
          className={classNames({
            "hidden lg:block xl:block": showFlowWindow,
          })}
        >
          {!loading && (
            <>
              <HomeBody onGetStarted={onToggleFlowWindow} />
              <Footer
                client={client === CLIENT.CELCOM ? CLIENT.MY_CELCOM : client}
                language={language}
              />
            </>
          )}
        </div>
      </div>

      {showFlowWindow ? (
        <Flow onToggleFlowWindow={onToggleFlowWindow} />
      ) : (
        <></>
        // <div
        //   className="fixed right-0 bottom-0 cursor-pointer mr-8 mb-8 s1600:mb-12"
        //   onClick={onToggleFlowWindow}
        // >
        //   <img src={chatIcon}></img>
        // </div>
      )}
    </Fragment>
  );
};

export default Home;
