import { changeLanguage } from "../helpers/languageUtils";
import { setClient, setLanguage } from "../reducers/homeReducer";

export const setAppClient = (client) => (dispatch) => {
  dispatch({
    type: setClient.toString(),
    payload: client,
  });
};

export const setAppLanguage = (language) => (dispatch) => {
  changeLanguage(language);
  dispatch({
    type: setLanguage.toString(),
    payload: language,
  });
};
