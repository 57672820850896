import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage, { createSystemMessage } from "../../../../helpers/createMessage";
import {
  API_PROGRESS,
  COMPONENTS,
  PROGRESS_DATA,
  ACTIVITY,
  CLIENT,
} from "../../../../helpers/constants";
import {
  updateApiProgress,
  clearApiProgress,
  updateFlowProgress,
  serviceUnavailable,
} from "../../../../actions/flowWindow";
import {
  setPaymentVerificationAttempt,
  setCCDetails,
  savePaymentMethod,
  setPaymentResolvedByChat,
  setCCAuthResolvedByChat,
} from "../reducers/paymentReducer";
import updateVisitor from "../../../Appsync/updateVisitor";
import { do_encrypt } from "../../../../helpers/xdr-encrypt";
import {
  getMaskedCCNum,
  getFormattedUserName,
  isEmpty,
} from "../../../../helpers/formatUtils";
import {
  processTransaction,
  getPciToken,
  chargeOrder,
  chargeOrderV2
} from "../actions/paymentApi";
import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import { udpateChatInputVisibility } from "../../../Appsync/state/operators";
import { setChatReason } from "../../../Appsync/state/reducers";
import { PaymentClient, Env } from "@backoffice/fast-payments-client-js-sdk";
import { sendAppsyncMessage } from "./../../../Appsync/sendAppsyncMessage";

export const submitSelectedPaymentOption = (
  paymentOption,
  optionLabel
) => async (dispatch, getStore) => {
  if (!isEmpty(optionLabel)) {
    await dispatch(
      addJourneyMessages([createMessage("TEXT", "user", optionLabel)])
    );
  }

  // save payment method
  dispatch(savePaymentMethod(paymentOption));

  const { CacheId } = getStore().session.sessionData.Configurations;
  let ServiceOrderId;

  const enquiryOption = getStore().journeyMessages.enquiryOption;

  // check for Service Order Id

  if (!isEmpty(getStore().claim.schedule.serviceOrder)) {
    ServiceOrderId = getStore().claim.schedule.serviceOrder
      .CreateServiceOrderResults.ServiceOrderId;
  } else if (enquiryOption === EnumEnquiryModules.ResumeRequest) {
    // get service order from determine incident response as service order is called before
    ServiceOrderId = getStore().serviceRequest.determineIncidentDetails
      .ServiceOrder.ServiceOrderId;
  }
  
  const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;

  const { selectedPickupAddress } = getStore().claim.schedule;

  if (paymentOption === "COD") {
    // call charge Order

    await dispatch(
      updateApiProgress(API_PROGRESS.COD, 40, COMPONENTS.PAYMENT, 0)
    );

    const CreateChargeOrderParameters = {
      SessionId: CacheId,
      ServiceOrderId: ServiceOrderId,
      ServiceRequestId: ServiceRequestId,
      ChargeOrder: {
        PaymentMethodType: "COD",
        ChargeOrderStatus: "PREAUTH",
        AddressId: selectedPickupAddress.AddressId,
        AdditionalChargeAuth: "false",
        ServiceOrderId: ServiceOrderId,
      },
    };

    // charge Order api
    // const chargeOrderResponse = await dispatch(
    //   chargeOrder(CreateChargeOrderParameters)
    // ).catch((err) => dispatch(serviceUnavailable()));

    // // not allowing to go ahead if response is empty
    // if (isEmpty(chargeOrderResponse)) {
    //   return;
    // }
    let chargeOrderResponse = null;
    let retrycount = 0;
    while (retrycount < 2) {
      chargeOrderResponse = await dispatch(chargeOrder(CreateChargeOrderParameters))
        .catch((err) => { return null }
        );

      if (chargeOrderResponse === null) {
        retrycount++;
      } else {
        retrycount = 2;
      }
    }
    // not allowing to go ahead if response is empty
    if (isEmpty(chargeOrderResponse)) {
      dispatch(serviceUnavailable())
      return;
    }

    if (chargeOrderResponse.CreateChargeOrderResults &&
      chargeOrderResponse.CreateChargeOrderResults.Holds &&
      chargeOrderResponse.CreateChargeOrderResults.Holds.length > 0) {

      if (chargeOrderResponse.CreateChargeOrderResults.Holds.find(e => e.HoldType === "IMEIMSMTCHD") ||
      chargeOrderResponse.CreateChargeOrderResults.Holds.find(e => e.HoldType === "MDLMSMTCHD")) {
        await dispatch(setChatReason("IMEI/Model Mismatch Hold"));
        dispatch(udpateChatInputVisibility(true));
      } else {
        dispatch(
          addJourneyMessages([
            createMessage("DEVICE_CONFIRMATION", "system", {
              showComponent: "deviceHold",
            }),
          ])
        );
      }
  
      return;
    }

    // Update Api progress
    await dispatch(
      updateApiProgress(
        API_PROGRESS.COD_SUCCESS,
        100,
        COMPONENTS.PAYMENT,
        40
      )
    );
    await sleep(1000);
    await dispatch(clearApiProgress());

    dispatch(updateVisitor({ lastActivity: ACTIVITY.PAYMENT }));
    const initial = getStore().journeyMessages.flowProgress.percentage;
    dispatch(
      updateFlowProgress(
        PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.title,
        PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.count,
        initial
      )
    );

    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.ReviewRepairRequest",
        }),
        createMessage("REPAIR_DETAILS", "system", ""),
      ])
    );
  } else {
    // show Card component
    dispatch(showCCComponent());
  }
};

export const submitPaymentDetails = (
  name,
  cardNumber,
  expiryMonth,
  expiryYear,
  cvv,
  cardType,
  textPrint
) => async (dispatch, getStore) => {
  await dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", textPrint),
      createMessage("TEXT", "system", {
        key: "SystemMessage.ValidatingCardDetails",
      }),
    ])
  );
  await dispatch(
    updateApiProgress(API_PROGRESS.PAYMENT, 40, COMPONENTS.PAYMENT, 0)
  );

  const { CacheId } = getStore().session.sessionData.Configurations;
  try {
    await dispatch(getPciToken(CacheId), 1);
  } catch(err) {
    console.log("PCIToken API error:", err)
  }

  await sleep(2000);

  const {
    PCIToken,
    Encryptionkey,
    ReferenceId,
  } = getStore().claim?.payment?.pciToken?.SecurityToken || {};

  if (!PCIToken) {
    console.log("Error in PCIToken API")
    await dispatch(clearApiProgress());
    dispatch(serviceUnavailable())
    return;
  }

  let paymentVerificationAttempt =
    getStore().claim.payment.paymentVerificationAttempt || 0;
  paymentVerificationAttempt++;

  await dispatch({
    type: setPaymentVerificationAttempt.toString(),
    payload: paymentVerificationAttempt,
  });

  let ccformask = getMaskedCCNum(cardNumber);
  let ccmask = ccformask.replace(/(\w{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");

  // await dispatch(setCCDetails(ccmask));
  dispatch(saveCCDetails(ccmask, name));

  const repairOption = getStore().journeyMessages.repairOption;
  const isWalkIn = repairOption === "WALKIN";

  const PostalCode = isWalkIn ? getStore().validation.verification.PostalCode : ( getStore().serviceRequest.serviceRequestDetails.PostalCode || getStore().claim.schedule?.registeredAddress?.PostalCode );
  // const {
  //   PCIToken,
  //   Encryptionkey,
  //   ReferenceId,
  // } = getStore().claim.payment.pciToken.SecurityToken;

  //const { CacheId } = getStore().session.sessionData.Configurations;

  let ServiceOrderId;

  const enquiryOption = getStore().journeyMessages.enquiryOption;

  // check for Service Order Id

  if (!isEmpty(getStore().claim.schedule.serviceOrder)) {
    ServiceOrderId = getStore().claim.schedule.serviceOrder
      .CreateServiceOrderResults.ServiceOrderId;
  } else if (enquiryOption === EnumEnquiryModules.ResumeRequest) {
    // get service order from determine incident response as service order is called before
    ServiceOrderId = getStore().serviceRequest.determineIncidentDetails
      .ServiceOrder.ServiceOrderId;
  }

  const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;

  const {
    selectedPickupAddress,
    selectedDeliveryAddress,
  } = getStore().claim.schedule;

  const { ccFailedResolvedByChat } = getStore().claim.payment;

  const customerName = getFormattedUserName(name);

  const client = getStore().app.client;
  const isSingtel = client === CLIENT.SINGTEL;
  
  if (isSingtel) { //BGV3
    let PanData = window.encodeURIComponent(
      do_encrypt(cardNumber, cvv, Encryptionkey)
    )

    //processTransaction START
    // const ProcessTransactionRequest = {
    //   SecurityToken: PCIToken,
    //   ExpMonth: expiryMonth,
    //   ExpYear: expiryYear,
    //   AppName: "iCareAPAC",
    //   FirstName: customerName.firstName,
    //   LastName: customerName.lastName,
    //   PanType: "CC",
    //   Zip: PostalCode,
    //   PanData: PanData
    // };

    // const processTransactionResponse = await dispatch(
    //   processTransaction(ProcessTransactionRequest)
    // ).catch((err) => {
    //   return "API_ERROR"
    // });
    //processTransaction END

    //fast-payment SDK start
    console.log("process.env.REACT_APP_BG_TRANSACTION_ENV", process.env.REACT_APP_BG_TRANSACTION_ENV)
    const paymentClient = new PaymentClient({
      env:
        process.env.REACT_APP_BG_TRANSACTION_ENV === "PROD"
          ? Env["prod-apac"]
          : Env["qa-apac"],
    });

    const { mdn, EmailAddress } = getStore().validation.inputData;

    const billingContact = {
      name: {
        first: customerName.firstName,
        last: customerName.lastName,
      },
      address: {
        address1: undefined,
        address2: undefined,
        city: undefined,
        state: undefined,
        country: "SG",
        zip: PostalCode || "819663",
      },
      locale: {
        countryCodeISO3166: "SG",
        languageCodeISO639: "en",
      },
      contactInfo: {
        phone: mdn,
        email: EmailAddress || "",
      },
    };

    const creditCardInfo = {
      number: cardNumber,
      securityCode: cvv,
      expiration: {
        month: expiryMonth,
        year: expiryYear,
      },
    };

    let processFastPaymentResponse = null;

    try {
      await paymentClient.addSession(PCIToken, {
        encryptionKey: Encryptionkey,
        appName: "ENRPORTAL",
        currency: "SGD",
      });
      await paymentClient.addBillingContactInfo(billingContact);
      await paymentClient.addCreditCardInfo(creditCardInfo);

      processFastPaymentResponse = await paymentClient.processPayment();
      console.log("processFastPayment Response:", processFastPaymentResponse);
    } catch (err) {
      console.log("processFastPayment Error:", err)

      let loggedErrorResponse = "";
      try {
        loggedErrorResponse = err.toString();
      } catch (err) {
        console.log("error:", err)
      }
      const message = createMessage("TEXT", "system", loggedErrorResponse);
      await dispatch(sendAppsyncMessage({ ...message, content: message.data }));
    }
    //fast-payment SDK END
    
    // let isProcessTransactionSuccess = processTransactionResponse !== "API_ERROR" || !isEmpty(processTransactionResponse);
    let isProcessFastPaymentSuccess = !isEmpty(processFastPaymentResponse);

    // let res = isProcessTransactionSuccess ? processTransactionResponse.ProcessTransactionResponse : "";
    // let code = res.split(",")[0];
    // let rtn_code = code.length ? code.split(":")[1].trim() : "";

    // if (!isProcessFastPaymentSuccess) {
    //   dispatch(serviceUnavailable())
    //   return;
    // }

    if (isProcessFastPaymentSuccess) {
      dispatch(savePaymentMethod("CRE"));

      //let PanData = window.encodeURIComponent(do_encrypt(cardNumber, cvv, Encryptionkey))

      const ChargeOrder = {
        PaymentMethodType: "CRE",
        ChargeOrderStatus: "PREAUTH",
        AddressId: selectedPickupAddress.AddressId,
        AdditionalChargeAuth: "false",
        ChargeOrderCardBrand: cardType,
        ChargeOrderCardType: "CREDIT",
        PCIToken: PCIToken,
        CardCheckNumber: parseInt(cvv),
        CardHolderFirstName: customerName.firstName,
        CardHolderLastName: customerName.lastName,
        ExpYear: expiryYear,
        ExpMonth: expiryMonth,
        ZipCode: PostalCode || "819663",
        EncryptedPanData: PanData,
        ReferenceId: ReferenceId,
        IsPromotionDiscount: false,
        ServiceOrderId: ServiceOrderId,
      };

      // charge Order api
      const CreateChargeOrderParameters = {
        SessionId: CacheId,
        ServiceOrderId: ServiceOrderId,
        ServiceRequestId: ServiceRequestId,
        isSURClaim: true,
        ChargeOrder: ChargeOrder,
      };

      // // charge Order api
      // const chargeOrderResponse = await dispatch(
      //   chargeOrderV2(CreateChargeOrderParameters)
      // ).catch((err) => dispatch(serviceUnavailable()));

      // // not allowing to go ahead if response is empty
      // if (isEmpty(chargeOrderResponse)) {
      //   return;
      // }
      let chargeOrderResponse = null;
      let retrycount = 0;
      while (retrycount < 2) {
        chargeOrderResponse = await dispatch(chargeOrderV2(CreateChargeOrderParameters))
          .catch((err) => { return null }
          );
  
        if (chargeOrderResponse === null) {
          retrycount++;
        } else {
          retrycount = 2;
        }
      }
      // not allowing to go ahead if response is empty
      if (isEmpty(chargeOrderResponse)) {
        dispatch(serviceUnavailable())
        return;
      }

      if (chargeOrderResponse.CreateChargeOrderResults &&
        chargeOrderResponse.CreateChargeOrderResults.Holds &&
        chargeOrderResponse.CreateChargeOrderResults.Holds.length > 0) {
        
        if (chargeOrderResponse.CreateChargeOrderResults.Holds.find(e => e.HoldType === "IMEIMSMTCHD") ||
          chargeOrderResponse.CreateChargeOrderResults.Holds.find(e => e.HoldType === "MDLMSMTCHD")) {
          await dispatch(setChatReason("IMEI/Model Mismatch Hold"));
          dispatch(udpateChatInputVisibility(true));
        } else {
          dispatch(
            addJourneyMessages([
              createMessage("DEVICE_CONFIRMATION", "system", {
                showComponent: "deviceHold",
              }),
            ])
          );
        }
    
        return;
      }

      // Update Api progress
      await dispatch(
        updateApiProgress(
          API_PROGRESS.PAYMENT_SUCCESS,
          100,
          COMPONENTS.PAYMENT,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      dispatch(updateVisitor({ lastActivity: ACTIVITY.PAYMENT }));
      const initial = getStore().journeyMessages.flowProgress.percentage;
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.title,
          PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.count,
          initial
        )
      );
      await dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.CardValidatedSuccessfully",
          }),
          createMessage("TEXT", "system", {
            key: "SystemMessage.ReviewRepairRequest",
          }),
          createMessage("REPAIR_DETAILS", "system", ""),
        ])
      );
    } else {
      await dispatch(
        updateApiProgress(
          API_PROGRESS.PAYMENT_SUCCESS,
          100,
          COMPONENTS.PAYMENT,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      await dispatch(
        addJourneyMessages([
          createMessage("PAYMENT", "system", {
            showComponent: "IncorrectCardDetails",
          }),
        ])
      );
      if (paymentVerificationAttempt < 3) {
        dispatch(showCCComponent());
      } else if (!ccFailedResolvedByChat) {
        await dispatch(setChatReason("Invalid Card"));
        dispatch(udpateChatInputVisibility(true));
      } else {
        // show COD option
        await dispatch(
          addJourneyMessages([
            createSystemMessage("PAYMENT", { showComponent: "COD_FOR_CREDIT_CARD" }),
          ])
        );
      }
    }
  } else { //Celcom BGV3
    let PanData = window.encodeURIComponent(
      do_encrypt(cardNumber, cvv, Encryptionkey)
    )
    
    // const ProcessTransactionRequest = {
    //   SecurityToken: PCIToken,
    //   ExpMonth: expiryMonth,
    //   ExpYear: expiryYear,
    //   AppName: "iCareAPAC",
    //   FirstName: customerName.firstName,
    //   LastName: customerName.lastName,
    //   PanType: "CC",
    //   Zip: PostalCode,
    //   PanData: PanData,
    // };

    // const processTransactionResponse = await dispatch(
    //   processTransaction(ProcessTransactionRequest)
    // ).catch((err) => { return "API_ERROR" });

    //fast-payment SDK start
    console.log("process.env.REACT_APP_BG_TRANSACTION_ENV", process.env.REACT_APP_BG_TRANSACTION_ENV)
    const paymentClient = new PaymentClient({
      env:
        process.env.REACT_APP_BG_TRANSACTION_ENV === "PROD"
          ? Env["prod-apac"]
          : Env["qa-apac"],
    });

    const { mdn, EmailAddress } = getStore().validation.inputData;

    const billingContact = {
      name: {
        first: customerName.firstName,
        last: customerName.lastName,
      },
      address: {
        address1: undefined,
        address2: undefined,
        city: undefined,
        state: undefined,
        country: "MY",
        zip: PostalCode || "64000",
      },
      locale: {
        countryCodeISO3166: "MY",
        languageCodeISO639: "en",
      },
      contactInfo: {
        phone: mdn,
        email: EmailAddress || "",
      },
    };

    const creditCardInfo = {
      number: cardNumber,
      securityCode: cvv,
      expiration: {
        month: expiryMonth,
        year: expiryYear,
      },
    };

    let processFastPaymentResponse = null;

    try {
      await paymentClient.addSession(PCIToken, {
        encryptionKey: Encryptionkey,
        appName: "ENRPORTAL",
        currency: "MYR",
      });
      await paymentClient.addBillingContactInfo(billingContact);
      await paymentClient.addCreditCardInfo(creditCardInfo);

      processFastPaymentResponse = await paymentClient.processPayment();
      console.log("processFastPayment Response:", processFastPaymentResponse);
    } catch (err) {
      console.log("processFastPayment Error:", err)
      
      let loggedErrorResponse = "";
      try {
        loggedErrorResponse = err.toString();
      } catch (err) {
        console.log("error:", err)
      }
      const message = createMessage("TEXT", "system", loggedErrorResponse);
      await dispatch(sendAppsyncMessage({ ...message, content: message.data }));
    }
    //fast-payment SDK END

    // // not allowing to go ahead if response is empty
    // if (isEmpty(processTransactionResponse)) {
    //   return;
    // }

    // let res = processTransactionResponse.ProcessTransactionResponse;
    // let [code, msg] = res.split(",");

    // let rtn_code = code.split(":")[1].trim();

    // if (!isProcessTransactionSuccess && !isProcessFastPaymentSuccess) {
    //   dispatch(serviceUnavailable())
    //   return;
    // }

    //let isProcessTransactionSuccess = processTransactionResponse !== "API_ERROR" || !isEmpty(processTransactionResponse);
    let isProcessFastPaymentSuccess = !isEmpty(processFastPaymentResponse);

    // let res = isProcessTransactionSuccess ? processTransactionResponse.ProcessTransactionResponse : "";
    // let code = res.split(",")[0];
    // let rtn_code = code.length ? code.split(":")[1].trim() : "";

    if (isProcessFastPaymentSuccess) {
    //if (rtn_code && rtn_code.toLowerCase() === "bg-0") {

      // create charge order params
      const CreateChargeOrderParameters = {
        SessionId: CacheId,
        ServiceOrderId: ServiceOrderId,
        ServiceRequestId: ServiceRequestId,
        isSURClaim: true,
        ChargeOrder: {
          PaymentMethodType: "CRE",
          ChargeOrderStatus: "PREAUTH",
          AddressId: selectedPickupAddress.AddressId,
          AdditionalChargeAuth: "false",
          ChargeOrderCardBrand: cardType,
          ChargeOrderCardType: "CREDIT",
          PCIToken: PCIToken,
          CardCheckNumber: parseInt(cvv),
          CardHolderFirstName: customerName.firstName,
          CardHolderLastName: customerName.lastName,
          ExpYear: expiryYear,
          ExpMonth: expiryMonth,
          ZipCode: PostalCode || "64000",
          EncryptedPanData: PanData,
          ReferenceId: ReferenceId,
          IsPromotionDiscount: false,
          ServiceOrderId: ServiceOrderId,
        },
      };

      // charge Order api
      // const chargeOrderResponse = await dispatch(
      //   chargeOrder(CreateChargeOrderParameters)
      // ).catch((err) => dispatch(serviceUnavailable()));

      // // not allowing to go ahead if response is empty
      // if (isEmpty(chargeOrderResponse)) {
      //   return;
      // }
      let chargeOrderResponse = null;
      let retrycount = 0;
      while (retrycount < 2) {
        chargeOrderResponse = await dispatch(chargeOrder(CreateChargeOrderParameters))
          .catch((err) => { return null }
          );

        if (chargeOrderResponse === null) {
          retrycount++;
        } else {
          retrycount = 2;
        }
      }
      // not allowing to go ahead if response is empty
      if (isEmpty(chargeOrderResponse)) {
        dispatch(serviceUnavailable())
        return;
      }

      if (chargeOrderResponse.CreateChargeOrderResults &&
        chargeOrderResponse.CreateChargeOrderResults.Holds &&
        chargeOrderResponse.CreateChargeOrderResults.Holds.length > 0) {
        
        if (chargeOrderResponse.CreateChargeOrderResults.Holds.find(e => e.HoldType === "IMEIMSMTCHD") ||
          chargeOrderResponse.CreateChargeOrderResults.Holds.find(e => e.HoldType === "MDLMSMTCHD")) {
          await dispatch(setChatReason("IMEI/Model Mismatch Hold"));
          dispatch(udpateChatInputVisibility(true));
        } else {
          dispatch(
            addJourneyMessages([
              createMessage("DEVICE_CONFIRMATION", "system", {
                showComponent: "deviceHold",
              }),
            ])
          );
        }
    
        return;
      }

      // Update Api progress
      await dispatch(
        updateApiProgress(
          API_PROGRESS.PAYMENT_SUCCESS,
          100,
          COMPONENTS.PAYMENT,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      dispatch(updateVisitor({ lastActivity: ACTIVITY.PAYMENT }));
      const initial = getStore().journeyMessages.flowProgress.percentage;
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.title,
          PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.count,
          initial
        )
      );
      await dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.CardValidatedSuccessfully",
          }),
          createMessage("TEXT", "system", {
            key: "SystemMessage.ReviewRepairRequest",
          }),
          createMessage("REPAIR_DETAILS", "system", ""),
        ])
      );
    } else {
      await dispatch(
        updateApiProgress(
          API_PROGRESS.PAYMENT_SUCCESS,
          100,
          COMPONENTS.PAYMENT,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      await dispatch(
        addJourneyMessages([
          createMessage("PAYMENT", "system", {
            showComponent: "IncorrectCardDetails",
          }),
        ])
      );
      if (paymentVerificationAttempt < 3) {
        dispatch(showCCComponent());
      } else if (!ccFailedResolvedByChat) {
        await dispatch(setChatReason("Invalid Card"));
        dispatch(udpateChatInputVisibility(true));
      } else {
        // show COD option
        await dispatch(
          addJourneyMessages([
            createSystemMessage("PAYMENT", { showComponent: "COD_FOR_CREDIT_CARD" }),
          ])
        );
      }
    }
  }
};

export const submitPaymentDetailsNew = (
  paymentMethod,
  name,
  cardNumber,
  expiryMonth,
  expiryYear,
  cvv,
  cardType,
  textPrint
) => async (dispatch, getStore) => {
  await dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", textPrint),
      createMessage("TEXT", "system", {
        key: "SystemMessage.ValidatingCardDetails",
      }),
    ])
  );
  await dispatch(
    updateApiProgress(API_PROGRESS.PAYMENT, 40, COMPONENTS.PAYMENT, 0)
  );

  const { CacheId } = getStore().session.sessionData.Configurations;
  let paymentVerificationAttempt =
    getStore().claim.payment.paymentVerificationAttempt || 0;
  paymentVerificationAttempt++;

  await dispatch({
    type: setPaymentVerificationAttempt.toString(),
    payload: paymentVerificationAttempt,
  });

  const repairOption = getStore().journeyMessages.repairOption;
  const isWalkIn = repairOption === "WALKIN";
  const PostalCode = isWalkIn ? getStore().validation.verification.PostalCode : (getStore().serviceRequest.serviceRequestDetails.PostalCode || getStore().claim.schedule?.registeredAddress?.PostalCode);
  let ServiceOrderId;
  const enquiryOption = getStore().journeyMessages.enquiryOption;

  // check for Service Order Id
  if (!isEmpty(getStore().claim.schedule.serviceOrder)) {
    ServiceOrderId = getStore().claim.schedule.serviceOrder
      .CreateServiceOrderResults.ServiceOrderId;
  } else if (enquiryOption === EnumEnquiryModules.ResumeRequest) {
    // get service order from determine incident response as service order is called before
    ServiceOrderId = getStore().serviceRequest.determineIncidentDetails
      .ServiceOrder.ServiceOrderId;
  }

  const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;
  const { selectedPickupAddress } = getStore().claim.schedule;
  // const { EnrolledAddress } = getStore().claim?.deviceConfirmation?.selectedAgreement
  const { ccFailedResolvedByChat } = getStore().claim.payment;
  const customerName = getFormattedUserName(name);
  const client = getStore().app.client;
  const isSingtel = client === CLIENT.SINGTEL;
  const { PCIToken, Encryptionkey, ReferenceId } = getStore().claim?.payment?.pciToken?.SecurityToken

  if (isSingtel) { //BGV3
    let ChargeOrder;
    if (paymentMethod.toUpperCase() === "CREDITCARD") {
      let ccformask, ccmask;

      ccformask = getMaskedCCNum(cardNumber);
      ccmask = ccformask.replace(/(\w{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
    
      dispatch(saveCCDetails(ccmask, name));

      let PanData = window.encodeURIComponent(
        do_encrypt(cardNumber, cvv, Encryptionkey)
      )

      dispatch(savePaymentMethod("CRE"));

      ChargeOrder = {
        PaymentMethodType: "CRE",
        ChargeOrderStatus: "PREAUTH",
        AddressId: selectedPickupAddress.AddressId,
        AdditionalChargeAuth: "false",
        ChargeOrderCardBrand: cardType,
        ChargeOrderCardType: "CREDIT",
        PCIToken: PCIToken,
        CardCheckNumber: 1,
        CardHolderFirstName: customerName.firstName,
        CardHolderLastName: customerName.lastName,
        ExpYear: expiryYear,
        ExpMonth: expiryMonth,
        ZipCode: PostalCode || "819663",
        EncryptedPanData: PanData,
        ReferenceId: ReferenceId,
        IsPromotionDiscount: false,
        ServiceOrderId: ServiceOrderId,
      };
    } else if (paymentMethod.toUpperCase() === "PAYPAL") {
      dispatch(savePaymentMethod("PYPL"));

      ChargeOrder = {
        PaymentMethodType: "PYPL",
        ChargeOrderStatus: "PREAUTH",
        AddressId: selectedPickupAddress.AddressId,
        AdditionalChargeAuth: "false",
        ChargeOrderCardBrand: "PaypalAccount",
        ChargeOrderCardType: "PYPL",
        PCIToken: PCIToken,
        CardCheckNumber: 1,
        CardHolderFirstName: "",
        CardHolderLastName: "",
        ExpYear: "",
        ExpMonth: "",
        ZipCode: PostalCode || "819663",
        EncryptedPanData: "",
        ReferenceId: ReferenceId,
        IsPromotionDiscount: false,
        ServiceOrderId: ServiceOrderId,
      };
    }

    // charge Order api
    const CreateChargeOrderParameters = {
      SessionId: CacheId,
      ServiceOrderId: ServiceOrderId,
      ServiceRequestId: ServiceRequestId,
      isSURClaim: true,
      ChargeOrder: ChargeOrder,
    };

    // charge Order api
    // const chargeOrderResponse = await dispatch(
    //   chargeOrderV2(CreateChargeOrderParameters)
    // ).catch((err) => dispatch(serviceUnavailable()));

    // // not allowing to go ahead if response is empty
    // if (isEmpty(chargeOrderResponse)) {
    //   return;
    // }
    let chargeOrderResponse = null;
    let retrycount = 0;
    while (retrycount < 2) {
      chargeOrderResponse = await dispatch(chargeOrderV2(CreateChargeOrderParameters))
        .catch((err) => { return null }
        );

      if (chargeOrderResponse === null) {
        retrycount++;
      } else {
        retrycount = 2;
      }
    }
    // not allowing to go ahead if response is empty
    if (isEmpty(chargeOrderResponse)) {
      dispatch(serviceUnavailable())
      return;
    }

    if (chargeOrderResponse.CreateChargeOrderResults &&
      chargeOrderResponse.CreateChargeOrderResults.Holds &&
      chargeOrderResponse.CreateChargeOrderResults.Holds.length > 0) {

      if (chargeOrderResponse.CreateChargeOrderResults.Holds.find(e => e.HoldType === "IMEIMSMTCHD") ||
        chargeOrderResponse.CreateChargeOrderResults.Holds.find(e => e.HoldType === "MDLMSMTCHD")) {
        await dispatch(setChatReason("IMEI/Model Mismatch Hold"));
        dispatch(udpateChatInputVisibility(true));
      } else {
        dispatch(
          addJourneyMessages([
            createMessage("DEVICE_CONFIRMATION", "system", {
              showComponent: "deviceHold",
            }),
          ])
        );
      }

      return;
    }

    // Update Api progress
    await dispatch(
      updateApiProgress(
        API_PROGRESS.PAYMENT_SUCCESS,
        100,
        COMPONENTS.PAYMENT,
        40
      )
    );
    await sleep(1000);
    await dispatch(clearApiProgress());

    dispatch(updateVisitor({ lastActivity: ACTIVITY.PAYMENT }));
    const initial = getStore().journeyMessages.flowProgress.percentage;
    dispatch(
      updateFlowProgress(
        PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.title,
        PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.count,
        initial
      )
    );
    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.CardValidatedSuccessfully",
        }),
        createMessage("TEXT", "system", {
          key: "SystemMessage.ReviewRepairRequest",
        }),
        createMessage("REPAIR_DETAILS", "system", ""),
      ])
    );
  } else { //Celcom BGV3
    if (paymentMethod.toUpperCase() === "CREDITCARD") {
      let ccformask, ccmask;

      ccformask = getMaskedCCNum(cardNumber);
      ccmask = ccformask.replace(/(\w{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "");
    
      dispatch(saveCCDetails(ccmask, name));

    } else if (paymentMethod.toUpperCase() === "PAYPAL") {
  
    }
    let PanData = window.encodeURIComponent(
      do_encrypt(cardNumber, cvv, Encryptionkey)
    )

    //fast-payment SDK start
    console.log("process.env.REACT_APP_BG_TRANSACTION_ENV", process.env.REACT_APP_BG_TRANSACTION_ENV)
    const paymentClient = new PaymentClient({
      env:
        process.env.REACT_APP_BG_TRANSACTION_ENV === "PROD"
          ? Env["prod-apac"]
          : Env["qa-apac"],
    });

    const { mdn, EmailAddress } = getStore().validation.inputData;

    const billingContact = {
      name: {
        first: customerName.firstName,
        last: customerName.lastName,
      },
      address: {
        address1: undefined,
        address2: undefined,
        city: undefined,
        state: undefined,
        country: "MY",
        zip: PostalCode || "64000",
      },
      locale: {
        countryCodeISO3166: "MY",
        languageCodeISO639: "en",
      },
      contactInfo: {
        phone: mdn,
        email: EmailAddress || "",
      },
    };

    const creditCardInfo = {
      number: cardNumber,
      securityCode: cvv,
      expiration: {
        month: expiryMonth,
        year: expiryYear,
      },
    };

    let processFastPaymentResponse = null;

    try {
      await paymentClient.addSession(PCIToken, {
        encryptionKey: Encryptionkey,
        appName: "ENRPORTAL",
        currency: "MYR",
      });
      await paymentClient.addBillingContactInfo(billingContact);
      await paymentClient.addCreditCardInfo(creditCardInfo);

      processFastPaymentResponse = await paymentClient.processPayment();
      console.log("processFastPayment Response:", processFastPaymentResponse);
    } catch (err) {
      console.log("processFastPayment Error:", err)

      let loggedErrorResponse = "";
      try {
        loggedErrorResponse = err.toString();
      } catch (err) {
        console.log("error:", err)
      }
      const message = createMessage("TEXT", "system", loggedErrorResponse);
      await dispatch(sendAppsyncMessage({ ...message, content: message.data }));
    }

    let isProcessFastPaymentSuccess = !isEmpty(processFastPaymentResponse);

    if (isProcessFastPaymentSuccess) {
      const CreateChargeOrderParameters = {
        SessionId: CacheId,
        ServiceOrderId: ServiceOrderId,
        ServiceRequestId: ServiceRequestId,
        isSURClaim: true,
        ChargeOrder: {
          PaymentMethodType: "CRE",
          ChargeOrderStatus: "PREAUTH",
          AddressId: selectedPickupAddress.AddressId,
          AdditionalChargeAuth: "false",
          ChargeOrderCardBrand: cardType,
          ChargeOrderCardType: "CREDIT",
          PCIToken: PCIToken,
          CardCheckNumber: parseInt(cvv),
          CardHolderFirstName: customerName.firstName,
          CardHolderLastName: customerName.lastName,
          ExpYear: expiryYear,
          ExpMonth: expiryMonth,
          ZipCode: PostalCode || "64000",
          EncryptedPanData: PanData,
          ReferenceId: ReferenceId,
          IsPromotionDiscount: false,
          ServiceOrderId: ServiceOrderId,
        },
      };

      // charge Order api
      // const chargeOrderResponse = await dispatch(
      //   chargeOrder(CreateChargeOrderParameters)
      // ).catch((err) => dispatch(serviceUnavailable()));

      // // not allowing to go ahead if response is empty
      // if (isEmpty(chargeOrderResponse)) {
      //   return;
      // }
      let chargeOrderResponse = null;
      let retrycount = 0;
      while (retrycount < 2) {
        chargeOrderResponse = await dispatch(chargeOrder(CreateChargeOrderParameters))
          .catch((err) => { return null }
          );

        if (chargeOrderResponse === null) {
          retrycount++;
        } else {
          retrycount = 2;
        }
      }
      // not allowing to go ahead if response is empty
      if (isEmpty(chargeOrderResponse)) {
        dispatch(serviceUnavailable())
        return;
      }

      if (chargeOrderResponse.CreateChargeOrderResults &&
        chargeOrderResponse.CreateChargeOrderResults.Holds &&
        chargeOrderResponse.CreateChargeOrderResults.Holds.length > 0) {

        if (chargeOrderResponse.CreateChargeOrderResults.Holds.find(e => e.HoldType === "IMEIMSMTCHD") ||
          chargeOrderResponse.CreateChargeOrderResults.Holds.find(e => e.HoldType === "MDLMSMTCHD")) {
          await dispatch(setChatReason("IMEI/Model Mismatch Hold"));
          dispatch(udpateChatInputVisibility(true));
        } else {
          dispatch(
            addJourneyMessages([
              createMessage("DEVICE_CONFIRMATION", "system", {
                showComponent: "deviceHold",
              }),
            ])
          );
        }

        return;
      }

      // Update Api progress
      await dispatch(
        updateApiProgress(
          API_PROGRESS.PAYMENT_SUCCESS,
          100,
          COMPONENTS.PAYMENT,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      dispatch(updateVisitor({ lastActivity: ACTIVITY.PAYMENT }));
      const initial = getStore().journeyMessages.flowProgress.percentage;
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.title,
          PROGRESS_DATA.CONFIRM_REQUEST_DETAILS.count,
          initial
        )
      );
      await dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.CardValidatedSuccessfully",
          }),
          createMessage("TEXT", "system", {
            key: "SystemMessage.ReviewRepairRequest",
          }),
          createMessage("REPAIR_DETAILS", "system", ""),
        ])
      );
    } else {
      await dispatch(
        updateApiProgress(
          API_PROGRESS.PAYMENT_SUCCESS,
          100,
          COMPONENTS.PAYMENT,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      await dispatch(
        addJourneyMessages([
          createMessage("PAYMENT", "system", {
            showComponent: "IncorrectCardDetails",
          }),
        ])
      );
      if (paymentVerificationAttempt < 3) {
        dispatch(showCCComponent());
      } else if (!ccFailedResolvedByChat) {
        await dispatch(setChatReason("Invalid Card"));
        dispatch(udpateChatInputVisibility(true));
      } else {
        // show COD option
        await dispatch(
          addJourneyMessages([
            createSystemMessage("PAYMENT", { showComponent: "COD_FOR_CREDIT_CARD" }),
          ])
        );
      }
    }
  }
};

export const showPaymentOption = (textPrint) => (dispatch, getStore) => {

  const initial = getStore().journeyMessages.flowProgress.percentage;
    dispatch(
      updateFlowProgress(
        PROGRESS_DATA.PAYMENT.title,
        PROGRESS_DATA.PAYMENT.count,
        initial
      )
    );

  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", textPrint),
      createMessage("PAYMENT", "system", { showComponent : "ShowPaymentOptions" })
    ])
  );
}

export const showCCComponent = () => (dispatch, getStore) => {
  // call PCI toekn again
  // const { CacheId } = getStore().session.sessionData.Configurations;
  // dispatch(getPciToken(CacheId));

  dispatch(
    addJourneyMessages([
      createMessage("PAYMENT", "system", {
        showComponent: "SubmitCardDetails",
      }),
    ])
  );
}

export const saveCCDetails = (ccmask, ccname) => async (dispatch) => {
  await dispatch({
    type: setCCDetails.toString(),
    payload: { ccmask, ccname },
  });
  return ccmask;
};

export const updatePaymentResolvedByChat = (value = true) => async (
  dispatch
) => {
  dispatch({
    type: setPaymentResolvedByChat.toString(),
    payload: value,
  });
};

export const updateCCAuthResolvedByChat = (value = true) => async (
  dispatch
) => {
  dispatch({
    type: setCCAuthResolvedByChat.toString(),
    payload: value,
  });
};
