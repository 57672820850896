import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CLIENT, LANGUAGE } from "../helpers/constants";
import { isEmpty } from "../helpers/formatUtils";
import Config from "../modules/Appsync/Config";

const WPPage = () => {
  const { client, page, lang } = useParams();
  let pageLink = "";
  let pageTitle = "";
  const clientName =
    client.toUpperCase() === CLIENT.MY_CELCOM.toUpperCase()
      ? CLIENT.CELCOM
      : client;

  const redirectToAsurion = () => {
    window.location.href = "https://www.asurion.com/";
  };

  switch (page) {
    case "faq":
      pageLink =
        !isEmpty(lang) && lang === LANGUAGE.BAHASA
          ? Config[clientName].WPAPIServer.faqEndPoint_bs
          : Config[clientName].WPAPIServer.faqEndPoint;
      pageTitle = "FAQ";
      break;
    case "prepare":
      pageLink =
        !isEmpty(lang) && lang === LANGUAGE.BAHASA
          ? Config[clientName].WPAPIServer.prepareEndPoint_bs
          : Config[clientName].WPAPIServer.prepareEndPoint;
      pageTitle = "Prepare your device";
      break;
    case "terms-of-use":
      pageLink =
        !isEmpty(lang) && lang === LANGUAGE.BAHASA
          ? Config[clientName].WPAPIServer.termsOfUseEndPoint_bs
          : Config[clientName].WPAPIServer.termsOfUseEndPoint;
      pageTitle = "Website Terms Of Use";
      break;
    case "terms-and-condition":
      pageLink =
        !isEmpty(lang) && lang === LANGUAGE.BAHASA
          ? Config[clientName].WPAPIServer.termsandConditionEndPoint_bs
          : Config[clientName].WPAPIServer.termsandConditionEndPoint;
      pageTitle = "Terms and Condition";
      break;
    default:
      redirectToAsurion();
      break;
  }

  useEffect(() => {
    if (
      !(
        client.toUpperCase() === CLIENT.SINGTEL.toUpperCase() ||
        client.toUpperCase() === CLIENT.MY_CELCOM.toUpperCase()
      )
    ) {
      redirectToAsurion();
    }
  }, []);

  return (
    <div>
      <iframe
        title={pageTitle}
        // src={`${Config[clientName].WPAPIServer.baseUrl}${pageLink}`}
        src={`${'https://kb-prod.sea.soluto.asurion.com'}${pageLink}`}
        width="100%"
        className="block h-screen w-full"
        frameBorder="0"
      />
    </div>
  );
};

export default WPPage;
