
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import { v4 as uuid } from "uuid";
import browser from "browser-detect";
import fetch from "./../../helpers/fetch";

import Config from "./Config";
import {CreateVisitorMutation} from "./Queries";
import {setVisitor} from "./state/reducers";
import CONSTANTS from '../../helpers/constants';

const getAppSyncConfig = (config) => ({
  aws_appsync_graphqlEndpoint: config.AppSync.EndPoint,
  aws_appsync_region: config.AppSync.Region,
  Analytics: {
    disabled: true, // OPTIONAL - disable Analytics if true
  },
  Auth: {
    identityPoolId: config.Cognito.IdentityPoolId,
    region: config.Cognito.Region,
    userPoolId: config.Cognito.UserPoolId,
    userPoolWebClientId: config.Cognito.WebClientId,
  },
  ApiKey: config.Twilio.ApiGatewayKey,
})

const createVisitor = () => {
    Amplify.configure(getAppSyncConfig(Config));
    const getCognitoId = async () => Auth.currentCredentials().then(response => response.identityId);
    const getIpAddress = async () => {
      return fetch("https://api64.ipify.org/?format=json", "GET", {}, true)
        .then((response) => response?.ip || "127.0.0.1")
        .catch(() => "127.0.0.1");
    };
    const createVisitor = async (visitor) => {
      return API.graphql(graphqlOperation(CreateVisitorMutation, { input: visitor }));
    };

    const getUniqueId = () => uuid().replace(/-/g, '').toUpperCase();

    return async (dispatch, getStore) => {
      //create request body and call above create visitor query...and store the values in redux state
      const [visitorCognitoId, ipAddress] = await Promise.all([getCognitoId(), getIpAddress()]);
      const {
        SessionData: {
          ClientId: clientId,
          LanguageCode: langCode,
          ClientName: carrierName,
          InteractionId: interactionId,
        },
        InteractionStatus: interactionType,
      } = getStore().session.interactionData.Interaction;
      const visitorRes =  await createVisitor({
        visitorCognitoId: visitorCognitoId,
        visitorId: getUniqueId(),
        userName: "User",
        journeyStatus: CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.STARTED,
        clientId: clientId || getUniqueId(),
        languageCode: langCode || "en-US",
        browserAgent: JSON.stringify(browser()),
        ipAddress: ipAddress,
        startTimestamp: new Date(),
        mdn: null,
        interactionType: "",
        source: "Screen Repair",
        interactionId: interactionId,
        carrierName: carrierName,
        chatAssisted: 0,
        lastActivity: "Welcome to Screen Repair Serivce",
        caseNumber: null,
        lastUpdatedTime: new Date(),
      });

      //sending it to redux
      dispatch(setVisitor(visitorRes.data.createVisitor));

      return;
    }
};

export default createVisitor;