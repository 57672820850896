import { callProcessIncidentApi } from "../reducers/termsAndConditionsReducer";

const processIncidentApi = (
  CacheId,
  CustomerCaseId,
  ServiceRequestId,
  caseType,
  selectedAsset,
  screenRepairQa,
  failureDescriptiveText
) => ({
  type: callProcessIncidentApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/incidentidentification/v1/incident/create`,
    method: "POST",
    body: {
      ProcessIncidentParameters: {
        SessionId: CacheId,
        CustomerCaseId: CustomerCaseId,
        ServiceRequestId: ServiceRequestId,
        Incident: {
          FailureDescriptiveText: failureDescriptiveText,
          IncidentType: caseType,
        },
        AssetDetails: {
          AssetCatalogId: selectedAsset.AssetCatalog.AssetCatalogId,
          SerialNumber: selectedAsset.IMEI,
        },
        ScreenRepairQA: screenRepairQa,
      },
    },
  },
});

export default processIncidentApi;
