import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { isIWTermsConfirmed } from "../state/operators/deviceConfirmationOperators";
import BaseLabel from "../../../base/BaseLabel";
import { TextLight } from "../../../base/BaseText";
import { Translate, useTranslate } from "../../../helpers/languageUtils"
import { CLIENT } from "../../../helpers/constants";
import { useSelector } from "react-redux";
import { INCIDENT_BATTERY_REPLACEMENT } from "../../../components/EnquiryOptions/Constants";

const ConfirmIWTerms = () => {
  const t  = useTranslate();
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);

  const handleSubmit = (isConfirm) => {
    const textPrint = isConfirm ? t(`YesButton`) : t(`NoButton`);
    setDisabled(true);
    dispatch(isIWTermsConfirmed(isConfirm, textPrint));
  };

  const client = useSelector((state) => state.app.client);
  const isSingtel = client === CLIENT.SINGTEL;
  const { ClientProductSkuNbr } = useSelector((state) => state.validation?.verifyAgreement?.VerifyAgreementsResults?.Agreement);
  const isSingtelStarter = isSingtel && (ClientProductSkuNbr && ClientProductSkuNbr.toLowerCase().indexOf("starter") > -1)
  const { caseType } = useSelector((state) => state.journeyMessages);

  return (
    <BaseCard>
      {!isSingtelStarter
        ?
        <>
          {caseType === INCIDENT_BATTERY_REPLACEMENT
            ? (
              <BaseLabel>
                <Translate i18nKey="IW_Confirmation.ContentBatteryReplacement">
                  <TextLight>text</TextLight>
                </Translate>{" "}
              </BaseLabel>

            )
            : (
              <BaseLabel>
                <Translate i18nKey="IW_Confirmation.Content">
                  <TextLight>text</TextLight>
                </Translate>{" "}
              </BaseLabel>
            )}
        </>
        : (
          <BaseLabel>
            <Translate i18nKey="IW_Confirmation.ContentBatteryReplacement">
              <TextLight>text</TextLight>
            </Translate>{" "}
          </BaseLabel>
        )
      }
      
      <BaseButtonGroup>
        <BaseButton
          text={t("NoButton")}
          onClick={() => handleSubmit(false)}
          disabled={disabled}
          className="px-16"
        />
        <BaseButton
          text={t("YesButton")}
          onClick={() => handleSubmit(true)}
          disabled={disabled}
          className="px-16"
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmIWTerms;
