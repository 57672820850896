import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import CONSTANTS, {
  API_PROGRESS,
  COMPONENTS,
  PROGRESS_DATA,
  ACTIVITY,
  CLIENT,
} from "../../../../helpers/constants";
import {
  INCIDENT_SCREEN_REPAIR,
  INCIDENT_SCREEN_REPAIR_WARRANTY,
  INCIDENT_BATTERY_REPLACEMENT
} from "../../../../components/EnquiryOptions/Constants";
import {
  updateApiProgress,
  clearApiProgress,
  updateFlowProgress,
  serviceUnavailable,
} from "../../../../actions/flowWindow";
import {
  sleep,
  addJourneyMessages,
} from "../../../../helpers/addJourneyMessages";
import createMessage, { createSystemMessage } from "../../../../helpers/createMessage";
import { confirmIfRequestCollected } from "./cancelRequestOperator";
import { authorizeCreditCardApi, submitOrder, submitOrderV2 } from "../actions/paymentApi";
import updateVisitor from "../../../Appsync/updateVisitor";
import { getFormattedUserName, isEmpty } from "../../../../helpers/formatUtils";
import { changeWalkInSchedule } from "./RepairOptionsOperator";
import { updateAuthorizeCreditCardFailedAttempt } from "../reducers/paymentReducer";
import { showCCComponent } from "./paymentOperator";
import { setChatReason } from "../../../Appsync/state/reducers";
import { udpateChatInputVisibility } from "../../../Appsync/state/operators";
import { changePURScheduleFlow } from "./scheduleOperator";

import { initializePickupSchedule } from "./scheduleOperator";
import moment from "moment";
import {
  setIsAfterCutoffError,
} from "../reducers/scheduleReducer";

export const confirmRequestDetailsSubmission = (
  textPrint,
  paymentMethod,
  repairOption
) => async (dispatch, getStore) => {
  const enquiryOption = getStore().journeyMessages.enquiryOption;
  const client = getStore().app.client;
  const isSingtel = client === CLIENT.SINGTEL;

  switch (enquiryOption) {
    case EnumEnquiryModules.CancelRequest:
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "user", textPrint),
          createMessage("CANCEL_REPAIR", "system", {
            showComponent: "ConfirmRequestCancel",
          }),
        ])
      );
      break;
    case EnumEnquiryModules.ResumeRequest:
    case EnumEnquiryModules.InWarrantyRequest:
    case EnumEnquiryModules.NewRequest:
    case EnumEnquiryModules.NewRequestBatteryReplacement:
      const { caseType } = getStore().journeyMessages;
      const { isCampaignTimingEligible, selectedPickupDate, selectedPickupSlot } = getStore().claim.schedule;
      const is1DayTAT = true;//extend 1 day TAT after campaign

      if (isSingtel && (isCampaignTimingEligible)) {
        if (caseType === INCIDENT_SCREEN_REPAIR ||
          caseType === INCIDENT_SCREEN_REPAIR_WARRANTY ||
          caseType === INCIDENT_BATTERY_REPLACEMENT) {

          const format = 'hh:mm:ss';
          const TimeBefore12NN = moment(process.env.REACT_APP_SUR_CAMPAIGN_CUTOFF_TIME_1_BEFORE || '11:45', format);
          // const TimeBefore12NN = moment('00:00', format); // TEST ONLY
          const TimeBefore2PM = moment(process.env.REACT_APP_SUR_CAMPAIGN_CUTOFF_TIME_2_END || '13:45', format);
          const nowtime = moment();

          if (
            (moment(selectedPickupDate).isSame(nowtime, 'day') &&
            selectedPickupSlot === '12pm - 2pm' &&
            nowtime.isAfter(TimeBefore12NN)) ||
            (moment(selectedPickupDate).isSame(nowtime, 'day') &&
            selectedPickupSlot === '2pm - 6pm' &&
            nowtime.isAfter(TimeBefore2PM))
            ) {
              await sleep(1000);

              dispatch(
                addJourneyMessages([
                  createMessage("TEXT","system","Sorry. The selected pick-up schedule is no longer valid. Please choose a new pick-up schedule.")
                ])
              );

              await sleep(1000);

              dispatch(setIsAfterCutoffError(true));
              dispatch(initializePickupSchedule());

              let selectedComponent = "confirmPickupAddress";
              let selectedModule = "PICKUP_AND_DELIVERY";
              
              dispatch(
                addJourneyMessages([
                  createMessage(selectedModule, "system", { showComponent: selectedComponent }),
                ])
              );

              break;
          } else {
            dispatch(setIsAfterCutoffError(false));
          }
        }
      } else {
        if (isSingtel && (caseType === INCIDENT_SCREEN_REPAIR ||
          caseType === INCIDENT_SCREEN_REPAIR_WARRANTY)) {

          /*isBefore7_45AM
          isBefore1_45PM
          return ["9am - 12pm", "12pm - 4pm"]*/

          const format = 'hh:mm:ss';
          const TimeBefore8AM = moment(process.env.REACT_APP_SUR_CAMPAIGN_CUTOFF_TIME_1_BEFORE || '07:45', format);
          const TimeBefore2PM = moment(process.env.REACT_APP_SUR_CAMPAIGN_CUTOFF_TIME_2_END || '13:45', format);
          const nowtime = moment();

          if (
            (moment(selectedPickupDate).isSame(nowtime, 'day') &&
            selectedPickupSlot === '9am - 12pm' &&
            nowtime.isAfter(TimeBefore8AM)) ||
            (moment(selectedPickupDate).isSame(nowtime, 'day') &&
            selectedPickupSlot === '12pm - 4pm' &&
            nowtime.isAfter(TimeBefore2PM))
            ) {
              await sleep(1000);

              dispatch(
                addJourneyMessages([
                  createMessage("TEXT","system","Sorry. The selected pick-up schedule is no longer valid. Please choose a new pick-up schedule.")
                ])
              );

              await sleep(1000);

              dispatch(setIsAfterCutoffError(true));
              dispatch(initializePickupSchedule());

              let selectedComponent = "confirmPickupAddress";
              let selectedModule = "PICKUP_AND_DELIVERY";
              
              dispatch(
                addJourneyMessages([
                  createMessage(selectedModule, "system", { showComponent: selectedComponent }),
                ])
              );

              break;
          } else {
            dispatch(setIsAfterCutoffError(false));
          }
        }
      }

      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "user", textPrint),
          createMessage("TEXT", "system", {
            key: "SystemMessage.RequestSubmitted",
          }),
        ])
      );
      await dispatch(
        updateApiProgress(
          API_PROGRESS.REQUEST_SUBMIT,
          40,
          COMPONENTS.CONFIRM_REQUEST_DETAILS,
          0
        )
      );

      const { CacheId } = getStore().session.sessionData.Configurations;

      if(!isSingtel && !isEmpty(paymentMethod) && paymentMethod === "CRE") {

        const repairOption = getStore().journeyMessages.repairOption;
        const isWalkIn = repairOption === "WALKIN";

        const PostalCode = isWalkIn ? getStore().validation.verification.PostalCode : ( getStore().serviceRequest.serviceRequestDetails.PostalCode || getStore().claim.schedule?.registeredAddress?.PostalCode );
        const { ServiceRequestId, CustomerCaseId } = getStore().serviceRequest.serviceRequestDetails;
        let ServiceOrderId ;

        if (!isEmpty(getStore().claim.schedule.serviceOrder)) {
          ServiceOrderId = getStore().claim.schedule.serviceOrder
            .CreateServiceOrderResults.ServiceOrderId;
        } else if (enquiryOption === EnumEnquiryModules.ResumeRequest) {
          // get service order from determine incident response as for resume case
          ServiceOrderId = getStore().serviceRequest.determineIncidentDetails
            .ServiceOrder.ServiceOrderId;
        }

        const { selectedStore } = getStore().claim.walkIn;
        const { selectedPickupAddress } = getStore().claim.schedule;
        const { ccname, chargeOrder } = getStore().claim.payment;

        const customerName = getFormattedUserName(ccname);

        let AuthorizeCreditCardRequest = {
          SessionId: CacheId,
          FirstName: customerName.firstName,
          LastName: customerName.lastName,
          AddressLine1: isWalkIn ? selectedStore.ADDRESS_LINE_1 : selectedPickupAddress.Address1,
          AddressLine2: isWalkIn ? selectedStore.ADDRESS_LINE_2 : selectedPickupAddress.Address2,
          CityName: isWalkIn ? selectedStore.CITY_NAME : selectedPickupAddress.City,
          StateProvinceCode: isWalkIn ? selectedStore.STATE_PROVINCE_NAME : selectedPickupAddress.StateProvinceCode,
          PostalCode: PostalCode,
          CuntryCode: isSingtel ? "SGP" : "MY",
          AuthorizationAmount: chargeOrder.CreateChargeOrderResults.TotalFee.toString(),
          CustomerCaseId: CustomerCaseId,
          ServiceOrderId: ServiceOrderId,
          ServiceRequestId: ServiceRequestId
        };
  
        // call authorize cc api
        const authorizeCCResponse = await dispatch(
          authorizeCreditCardApi(AuthorizeCreditCardRequest)
        ).catch((err) => dispatch(serviceUnavailable()));

        // not allowing to go ahead if response is empty or status is failed
        if (
          isEmpty(authorizeCCResponse) ||
          isEmpty(authorizeCCResponse.AuthorizeCreditCardResult) ||
          isEmpty(authorizeCCResponse.AuthorizeCreditCardResult.Status) ||
          authorizeCCResponse.AuthorizeCreditCardResult.Status !== "Success"
        ) {

          await dispatch(clearApiProgress());

          let { authorizeCreditCardFailedAttempt, ccAuthFailedResolvedByChat } = getStore().claim.payment;

          authorizeCreditCardFailedAttempt++;
          dispatch(updateAuthorizeCCFailedAttempt(authorizeCreditCardFailedAttempt))

          if (authorizeCreditCardFailedAttempt < 3) {
            await dispatch(
              addJourneyMessages([
                createMessage("PAYMENT", "system", {
                  showComponent: "IncorrectCardDetails",
                }),
              ])
            );
            // redirect to cc page
            dispatch(showCCComponent());
          } else if (!ccAuthFailedResolvedByChat) {
            // connect to agent
            await dispatch(setChatReason("CC Auth failure"));
            dispatch(udpateChatInputVisibility(true));
          } else {
            // show COD option
            await dispatch(
              addJourneyMessages([
                createSystemMessage("PAYMENT", { showComponent: "COD_FOR_CREDIT_CARD" }),
              ])
            );
          }

          return;
        }
      }

      // let submitOrderResponse = null;

      // if (isSingtel) { //BGV3
      //   // Call Submit Request API
      //   submitOrderResponse = await dispatch(
      //     submitOrderV2(CacheId)
      //   ).catch((err) => dispatch(serviceUnavailable()));
      // } else { //BGV1
      //   // Call Submit Request API
      //   submitOrderResponse = await dispatch(
      //     submitOrder(CacheId)
      //   ).catch((err) => dispatch(serviceUnavailable()));
      // }

      // // not allowing to go ahead if response is empty
      // if (isEmpty(submitOrderResponse)) {
      //   return;
      // }
      let submitOrderResponse = null;
      let retrycount = 0;
      while (retrycount < 2) {
        submitOrderResponse = await dispatch(submitOrderV2(CacheId))
          .catch((err) => { return null }
          );

        if (submitOrderResponse === null) {
          retrycount++;
        } else {
          retrycount = 2;
        }
      }
      // not allowing to go ahead if response is empty
      if (isEmpty(submitOrderResponse)) {
        dispatch(serviceUnavailable())
        return;
      }

      dispatch(
        updateVisitor({
          lastActivity: ACTIVITY.SUBMIT_REQUEST,
          journeyStatus: CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.COMPLETED,
          // journeyCmpltd: true
        })
      );

      await dispatch(
        updateApiProgress(
          API_PROGRESS.REQUEST_SUBMIT_SUCCESS,
          100,
          COMPONENTS.CONFIRM_REQUEST_DETAILS,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());
      const initial = getStore().journeyMessages.flowProgress.percentage;
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.FEEDBACK.title,
          PROGRESS_DATA.FEEDBACK.count,
          initial
        )
      );

      // for singtel we are asking user for feedback
      if (isSingtel) {

        dispatch(
          addJourneyMessages([
            createMessage("IMPORTANT_NOTE", "system", {}),
            createMessage("TEXT", "system", {
              key: "SystemMessage.RequestSMSConfirmation",
            }),
            createMessage("TEXT", "system", {
              key: "SystemMessage.InviteToSurvey",
            }),
            createMessage("NPS", "system", {
              showComponent: "RequestForSurvey",
            }),
          ])
        );

      } else {
        dispatch(
          addJourneyMessages([
            createMessage("IMPORTANT_NOTE", "system", {}),
            createMessage("TEXT", "system", {
              key: "SystemMessage.RequestSMSConfirmation",
            }),
          ])
        );
      }
      break;
    case EnumEnquiryModules.ChangeSchedule:
      dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));

      if (repairOption === "WALKIN") {
        dispatch(changeWalkInSchedule())
      } else if (repairOption === "PUR") {
        dispatch(changePURScheduleFlow());
      }
      break;
    default:
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "user", "Request details confirmed"),
        ])
      );
      break;
  }
};

export const cancelRepairDetailsConfirmation = (cancelText) => (dispatch, getStore) => {
  dispatch(
    addJourneyMessages([createMessage("TEXT", "user", cancelText)])
  );

  dispatch(confirmIfRequestCollected("", "Cancel-Confirmation page"));
};

export const showEnquiryOptions = (cancelText) => (dispatch, getStore) => {
  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", cancelText),
      createMessage("ENQUIRY_OPTIONS", "system", {}),
    ])
  );
}

export const updateAuthorizeCCFailedAttempt = (authorizeCCFailedAttempt) => async (dispatch) => {
  await dispatch({
    type: updateAuthorizeCreditCardFailedAttempt.toString(),
    payload: authorizeCCFailedAttempt,
  });
  return authorizeCCFailedAttempt;
};