import {
  saveDeviceMakeAndModel,
  callVerifyImeiApi,
  saveIsDeviceAppleSamsung
} from "../reducers/deviceConfirmationReducer";

export const setDeviceMakeAndModel = (deviceData, isAppleSamsung = false) => (dispatch) => {
  dispatch({ type: saveDeviceMakeAndModel.toString(), payload: deviceData });
  dispatch({ type: saveIsDeviceAppleSamsung.toString(), payload: isAppleSamsung });
};

export const verifyImeiApi = ({
  cacheId,
  assetInstance,
  imei,
  imeiVerificationFailedAttempt = 0,
}) => ({
  type: callVerifyImeiApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/incidentidentification/v1/verifyimei",
    method: "POST",
    body: {
      VerifyIMEIParameters: {
        IMEI: imei,
        IMEIVerificationFailedAttempt: imeiVerificationFailedAttempt,
        AssetInstance: assetInstance,
        SessionId: cacheId,
      },
    },
  },
});
