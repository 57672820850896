import React, { useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseCheckbox from "../../../base/BaseCheckbox/BaseCheckbox";
import BaseInformationBox from "../../../base/BaseInformationBox";
import { TextMedium, TextLight } from "../../../base/BaseText";

import ContractIcon from "../../../images/icon-contract.svg";
import { SingtelTerms, CelcomTerms, SingtelTermsBatteryReplacement } from "./termsConfig";
import { submitUserAcceptanceOnTerms } from "../state/operators/termsAndConditionOperator";
import { CLIENT } from "../../../helpers/constants";
import { isEmpty } from "../../../helpers/formatUtils";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";
import moment from "moment";
import { INCIDENT_BATTERY_REPLACEMENT } from "../../../components/EnquiryOptions/Constants";

const TermsAndConditions = () => {
  const { caseType } = useSelector((state) => state.journeyMessages);
  const t = useTranslate();
  const dispatch = useDispatch();

  const [status, setStatus] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [iAgree, setIAgree] = useState(false);

  const client = useSelector((state) => state.app.client);
  const isSingtel = client === CLIENT.SINGTEL;
  const { ClientProductSkuNbr } = useSelector((state) => state.validation?.verifyAgreement?.VerifyAgreementsResults?.Agreement);
  const isSingtelStarter = isSingtel && (ClientProductSkuNbr && ClientProductSkuNbr.toLowerCase().indexOf("starter") > -1)
  
  const feeData = useSelector((state) => state.claim.payment.serviceFee);
  const fee = isEmpty(feeData) ? isSingtel ? "99" : "399" : feeData.TotalAmountWithDiscount || feeData.TotalAmount;
  const currency = isSingtel ? "SGD" : "RM";
  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );
  const isInWarranty = enquiryOption === EnumEnquiryModules.InWarrantyRequest;

  const terms = isSingtel
    ? (isSingtelStarter
      ? SingtelTermsBatteryReplacement(fee)
      : caseType === INCIDENT_BATTERY_REPLACEMENT ? SingtelTermsBatteryReplacement(fee) : SingtelTerms)
    : CelcomTerms;

  const transKey = isSingtelStarter 
  ? "TermsAndConditionsBatteryReplacement" 
  : caseType === INCIDENT_BATTERY_REPLACEMENT ? "TermsAndConditionsBatteryReplacement" : "TermsAndConditions";

  const celcomCampaignStartDate = moment(process.env.REACT_APP_CELCOM_CAMPAIGN_START || '2024-07-01', "YYYY-MM-DD");
  const celcomCampaignEndDate = moment(process.env.REACT_APP_CELCOM_CAMPAIGN_END || '2024-10-01', "YYYY-MM-DD");
  const isCelcomCampaignInactive = moment().isBefore(celcomCampaignStartDate, "day") || moment().isSameOrAfter(celcomCampaignEndDate, "day");

  const handleSubmitTC = (status) => {
    const textPrint = t(`${transKey}.${status}Text`);
    dispatch(submitUserAcceptanceOnTerms(status, textPrint, isInWarranty));
    setStatus(status);
    setDisabled(true);
  };

  return (
    <BaseCard>
      <BaseIcon icon={ContractIcon} name="contract" center />
      <BaseLabel>
        <Translate i18nKey={`${transKey}.Title`}>
          <TextMedium>Do you agree with our Terms and Conditions?</TextMedium>
        </Translate>
      </BaseLabel>
      <BaseInformationBox className="h-25 overflow-y-auto">
        {!isInWarranty && 
          <div className="my-5">
            <div className="text-justify">
              • 
              <Translate i18nKey={`"Payment.${isSingtelStarter 
                ? "TitleBatteryReplacement" 
                : caseType === INCIDENT_BATTERY_REPLACEMENT ? "TitleBatteryReplacement" : "Title"}`}>
                <TextMedium>
                  {`${isSingtelStarter 
                    ? "The Battery Replacement Fee" 
                    : caseType === INCIDENT_BATTERY_REPLACEMENT ? "The Battery Replacement Fee" : "The Repair Fee"} is`}{` `}{{currency}}{` `}{{fee}}.
                </TextMedium>
              </Translate>
            </div>
            {(!isSingtel && !isCelcomCampaignInactive) && 
              (<div className="text-justify">
                •
                <TextMedium>
                  {t("HomeBody.Promo.DiscountAppliedNote")}
                </TextMedium>
              </div>)
            }
          </div>
        }
        {terms.map((term, i) => (
          <div key={i} className="my-5">
            {/* <div className="mb-1">
              <TextMedium>{term.title}</TextMedium>
            </div> */}
            <div className="mb-1">
              <Translate i18nKey={`${transKey}.Label`}>
                <TextMedium>{term.title}</TextMedium>
              </Translate>
            </div>
            <div className="text-justify">
              {(isSingtelStarter || caseType === INCIDENT_BATTERY_REPLACEMENT) ? (
                <>
                  {term.content}</>
              ) : (
                <Translate i18nKey={`${transKey}.Content`}>
                  <TextLight>{term.content}</TextLight>
                </Translate>
              )}
            </div>
          </div>
        ))}
      </BaseInformationBox>
      <BaseCheckbox
        className="w-26r"
        id="termsAndConditionCB"
        value={iAgree}
        onChange={() => setIAgree(!iAgree)}
        label={t(`${transKey}.CheckboxContent`)}
      />
      <BaseButtonGroup>
        <BaseButton
          text={t("DeclineButton")}
          disabled={disabled}
          onClick={() => handleSubmitTC("Decline")}
          clicked={status === "Decline"}
        />
        <BaseButton
          className={classNames({ "opacity-25": !iAgree })}
          text={t("AcceptButton")}
          disabled={disabled || !iAgree}
          onClick={() => handleSubmitTC("Accept")}
          clicked={status === "Accept"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default TermsAndConditions;
