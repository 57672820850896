import React, {Component} from 'react';
import { useDispatch, useSelector} from 'react-redux';

//import useReduxModule from "./../../helpers/useReduxModule";

import View from './View';

export default function TrackDelivery(props) {
  const dispatch = useDispatch();
  //const isUserValidated = useSelector((state) => );
  return (
    <View {...props} />
  )
}
