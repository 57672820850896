import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";

import Home from "./pages/Home/Home";
import WPPage from "./pages/WPPage";

import TagManager from "react-gtm-module";
import { CLIENT } from "./helpers/constants";
import Config from "./modules/Appsync/Config";

let clientName = window.location.pathname.replace("/", "");
clientName =
  clientName.toUpperCase() === CLIENT.MY_CELCOM ? CLIENT.CELCOM : clientName;

const tagManagerArgs = {
  gtmId:
    clientName === CLIENT.SINGTEL
      ? Config["singtel"].GTM.gtmId
      : Config["celcom"].GTM.gtmId,
};

function App() {

  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    if (window.location.href.toString().includes("/my"))
      document.title = "Repair Service Portal";
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/singtel" exact component={Home} />
          <Route path="/my" exact component={Home} />
          <Route exact path="/:client/:page" component={WPPage} />
          <Route exact path="/:client/:page/:lang" component={WPPage} />
          <Route
            path="/"
            component={() => {
              window.location.href = "https://www.asurion.com/";
            }}
          />
        </Switch>
      </Router>
    </Provider>
  );
}

export function getComponentForStaticHtml(Component, message) {
  return (
    <Provider store={store}>
      <Component {...message} {...message.data} />
    </Provider>
  );
}

export default App;
