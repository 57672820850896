import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseIconLabel from "../../../base/BaseIconLabel/BaseIconLabel";
import { TextLight } from "../../../base/BaseText";
import IncorrectAddressIcon from "../../../images/icon-incorrect-map.svg";
import LocationIcon from "../../../images/icon-location.svg";
import { confirmRegisteredAddress } from "../state/operators/scheduleOperator";
import { Translate, useTranslate } from "../../../helpers/languageUtils";

const IncorrectAddress = ({ isPickup }) => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const address = useSelector(
    (state) => state.claim.schedule.registeredAddress
  );

  const [disabled, setDisabled] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState("");

  const getAddressText = (address) => {
    const { Address1, Address2, Address3, City, PostalCode } = address;
    return `${Address1} ${Address2} ${Address3} ${City} ${PostalCode}`;
  };

  const handleConfirmAdress = (status) => {
    const textPrint = t(`${status}Button`);
    setConfirmationStatus(status);
    setDisabled(true);
    dispatch(confirmRegisteredAddress(status, isPickup, textPrint));
  };

  return (
    <BaseCard>
      <BaseIcon icon={IncorrectAddressIcon} name="Tracking" center />
      <BaseLabel>
        <Translate i18nKey="IncorrectMessage.Address">
          <TextLight>
            Unfortunately, the address you’ve provided is not allowed. Shall we
            proceed with your registered address?
          </TextLight>
        </Translate>
      </BaseLabel>
      <BaseIconLabel className="my-4 w-24r" icon={LocationIcon}>
        {getAddressText(address)}
      </BaseIconLabel>
      <BaseButtonGroup>
        <BaseButton
          text={t("NoButton")}
          onClick={() => handleConfirmAdress("No")}
          disabled={disabled}
          clicked={confirmationStatus === "No"}
        />
        <BaseButton
          text={t("YesButton")}
          onClick={() => handleConfirmAdress("Yes")}
          disabled={disabled}
          clicked={confirmationStatus === "Yes"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

IncorrectAddress.propTypes = {
  isPickup: PropTypes.bool,
};

IncorrectAddress.defaultProps = {
  isPickup: true,
};

export default IncorrectAddress;
