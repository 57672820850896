import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import BaseCard from "../../base/BaseCard/BaseCard";
import BaseIcon from "../../base/BaseIcon/BaseIcon";
import NricIcon from "../../images/icon-nric.svg";
import BaseLabel from "../../base/BaseLabel";
import BaseButtonGroup from "../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../base/BaseButton/BaseButton";
import BaseSeparatedInput from "../../base/BaseSeparatedInput/BaseSeparatedInput";
import { TextBold, TextLight, TextRegular } from "../../base/BaseText";
import BaseInformationBox from "../../base/BaseInformationBox";
import { validateNric } from "./state/operators";
import { isEmpty } from "../../helpers/formatUtils";
import generateUniqueId from "../../helpers/generateUniqueId";
import BaseInput from "../../base/BaseInput/BaseInput";
import { Translate, useTranslate } from "../../helpers/languageUtils";
import { CLIENT, FIELD_IDS } from "../../helpers/constants";

export default function AskNric() {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [nricCode, setNricCode] = useState();
  const [disabled, setDisabled] = useState(false);
  const [uniqueId, setUniqueId] = useState("");

  const client = useSelector((state) => state.app.client);
  const acceptFullNric = client === CLIENT.CELCOM ? true : false;

  const handleSubmitNric = () => {
    dispatch(validateNric(nricCode));
    setDisabled(true);
  };

  useEffect(() => {
    setUniqueId(generateUniqueId());
  }, []);

  const shouldntSubmit =
    disabled ||
    isEmpty(nricCode) ||
    (client === CLIENT.CELCOM ? nricCode.length < 5 : nricCode.length !== 4);

  return (
    <div>
      <BaseCard>
        <BaseIcon icon={NricIcon} name="Nric Icon" center />
        <BaseLabel>
          <Translate
            i18nKey="AskNric.Title"
            components={{ light: <TextLight />, bold: <TextBold /> }}
          />
        </BaseLabel>
        <BaseLabel>
          <Translate i18nKey="AskNric.Label"></Translate>
        </BaseLabel>
        {acceptFullNric ? (
          <BaseInput
            id={FIELD_IDS.NRIC.replace("{{client}}", client)}
            placeholder={t(`AskNric.Placeholder`)}
            medium
            value={nricCode}
            onChange={setNricCode}
            autoFocus
            maxlength={15}
            disabled={disabled}
            name={`Nric-${uniqueId}`}
            onEnterPress={shouldntSubmit ? () => {} : handleSubmitNric}
          />
        ) : (
          <BaseSeparatedInput
            id={FIELD_IDS.NRIC.replace("{{client}}", client)}
            value={nricCode}
            length={4}
            onChange={setNricCode}
            onEnterPress={shouldntSubmit ? () => {} : handleSubmitNric}
            disabled={disabled}
            name={`Nric-${uniqueId}`}
          />
        )}
        <BaseInformationBox>
          <Translate
            i18nKey="AskNric.Information"
            components={{ light: <TextLight />, regular: <TextRegular /> }}
          />
        </BaseInformationBox>
        <BaseButtonGroup>
          <BaseButton
            id={FIELD_IDS.SUBMIT_NRIC.replace("{{client}}", client)}
            name="Submit"
            text={t(`SubmitButton`)}
            onClick={handleSubmitNric}
            disabled={shouldntSubmit}
            clicked={disabled}
            focused
          />
        </BaseButtonGroup>
      </BaseCard>
    </div>
  );
}
