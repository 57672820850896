import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  PROGRESS_DATA,
  API_PROGRESS,
  COMPONENTS,
  ACTIVITY,
  CLIENT,
  INCIDENT_PATH_TYPE,
} from "../../../../helpers/constants";
import {
  updateFlowProgress,
  updateApiProgress,
  clearApiProgress,
  serviceUnavailable,
} from "../../../../actions/flowWindow";
import { updateContactDetails } from "../actions/contactsApi";
import updateVisitor from "../../../Appsync/updateVisitor";
import { getPciToken } from "../actions/paymentApi";
import { getMaskedMdn, getMaskedEmail, isEmpty } from "../../../../helpers/formatUtils";
import { setContactDetails } from "../../../Validation/state/reducers";
import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import { submitSelectedPaymentOption } from "./paymentOperator";
import { confirmationOfDetails } from "./scheduleOperator";

export const submitConfirmationOfContactDetails = (
  status,
  code,
  textPrint
) => async (dispatch, getStore) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));

  const isMobile = code === "ConfirmMobileNumber";
  const isCorrect = status === "Yes";
  let {
    phoneNumber: PhoneNumber,
    emailAddress: EmailAddress,
  } = getStore().validation.inputData;
  const { CacheId } = getStore().session.sessionData.Configurations;
  const fee = getStore().claim.payment.serviceFee.TotalAmount;
  const discountedfee = getStore().claim.payment.serviceFee.TotalAmountWithDiscount;

  const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;
  const client = getStore().app.client;
  const enquiryOption = getStore().journeyMessages.enquiryOption;

  const { type : incidentPathType,  serviceRequestDetails}  = getStore().serviceRequest;

  const { WarrantyType } = 
  incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST &&
  serviceRequestDetails.Actions !== "CaptureIncidentDetails"
      ? getStore().serviceRequest.serviceRequestDetails
      : getStore().claim.termsAndConditions.processIncident.CreateIncidentResults
          .CustomerCase.ServiceRequest

  const isInWarranty = enquiryOption === EnumEnquiryModules.InWarrantyRequest || WarrantyType === "IW";

  let showComponent = "";
  let module = "CONTACT_CONFIRMATION";
  let msg = "",
    msg_success = "";

  if (isMobile) {
    msg = API_PROGRESS.UPDATE_CONTACT_NUMBER;
    msg_success = API_PROGRESS.UPDATE_CONTACT_NUMBER_SUCCESS;
  } else {
    msg = API_PROGRESS.UPDATE_CONTACT_EMAIL;
    msg_success = API_PROGRESS.UPDATE_CONTACT_EMAIL_SUCCESS;
  }

  if (isCorrect) {
    await dispatch(updateApiProgress(msg, 40, COMPONENTS.UPDATE_CONTACT));
    // const contactResponse = await dispatch(
    //   updateContactDetails(CacheId, ServiceRequestId, PhoneNumber, EmailAddress)
    // ).catch((err) => dispatch(serviceUnavailable()));

    // // not allowing to go ahead if response is empty
    // if (isEmpty(contactResponse)) {
    //   return;
    // }
    let contactResponse = null;
    let retrycount = 0;
    while (retrycount < 2) {
      contactResponse = await dispatch(updateContactDetails(CacheId, ServiceRequestId, PhoneNumber, EmailAddress))
      .catch((err) => { return null }
      );

      if (contactResponse === null) {
        retrycount++;
      } else {
        retrycount = 2;
      }
    }
    // not allowing to go ahead if response is empty
    if (isEmpty(contactResponse)) {
      dispatch(serviceUnavailable())
      return;
    }

    await dispatch(
      updateApiProgress(msg_success, 100, COMPONENTS.UPDATE_CONTACT, 40)
    );

    /**
     * Updating shipping order API sequence
     * Now shippingOrder will be called after contactPoint API success
     */
    // !isMobile && await dispatch(confirmationOfDetails());
    if (!isMobile) {
      let res = await dispatch(confirmationOfDetails());
      if (!res) return;
    }

    await sleep(1000);
    await dispatch(clearApiProgress());
  }

  const isEmailAvailable = !isEmpty(
    getStore().validation.verification.EmailAddress
  ) && 
  getStore().validation.verification.EmailAddress !== "EMAIL_NOT_AVAILABLE";

  if (isMobile) {
    // showComponent = isCorrect
    //   ? client === CLIENT.SINGTEL
    //     ? "ChangeEmail"
    //     : "ConfirmEmail"
    //   : "ChangeMobileNumber";
    showComponent = isCorrect
      ? "ChangeEmail"
      : "ChangeMobileNumber";

  } else {
    showComponent = isCorrect
      ? client === CLIENT.SINGTEL
        ? "SubmitCardDetails"
        : "ShowPaymentOptions"
      : "ChangeEmail";
    module = isCorrect ? "PAYMENT" : module;
    if (isCorrect && isInWarranty) {
      dispatch(submitSelectedPaymentOption("COD"));
      return;
    } else if (isCorrect) {
      if (showComponent === "SubmitCardDetails") {
        dispatch(getPciToken(CacheId, (discountedfee || fee))).catch((err) =>
          dispatch(serviceUnavailable())
        );
      }

      await dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.PaymentProcessing",
          }),
        ])
      );
      const initial = getStore().journeyMessages.flowProgress.percentage;
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.PAYMENT.title,
          PROGRESS_DATA.PAYMENT.count,
          initial
        )
      );
    }
  }
  dispatch(
    addJourneyMessages([createMessage(module, "system", { showComponent })])
  );
};

export const submitChangesInContactDetails = (contactData, isMobile) => async (
  dispatch,
  getStore
) => {
  let maskedData = contactData;
  if (isMobile) {
    maskedData = getMaskedMdn(contactData);
  }
  else {
    maskedData = getMaskedEmail(contactData);
  }
  dispatch(addJourneyMessages([createMessage("TEXT", "user", maskedData)]));

  const { CacheId } = getStore().session.sessionData.Configurations;
  const client = getStore().app.client;
  const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;
  let {
    phoneNumber: PhoneNumber,
    emailAddress: EmailAddress,
  } = getStore().validation.inputData;
  const enquiryOption = getStore().journeyMessages.enquiryOption;

  const { type : incidentPathType,  serviceRequestDetails}  = getStore().serviceRequest;

  const { WarrantyType } = 
  incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST &&
  serviceRequestDetails.Actions !== "CaptureIncidentDetails"
      ? getStore().serviceRequest.serviceRequestDetails
      : getStore().claim.termsAndConditions.processIncident.CreateIncidentResults
          .CustomerCase.ServiceRequest

  const isInWarranty = enquiryOption === EnumEnquiryModules.InWarrantyRequest || WarrantyType === "IW";

  const isEmailAvailable = !isEmpty(
    getStore().validation.verification.EmailAddress
  ) && 
  getStore().validation.verification.EmailAddress !== "EMAIL_NOT_AVAILABLE";

  let showComponent = "";
  let module = "CONTACT_CONFIRMATION";
  let msg = "",
    msg_success = "",
    component = "";
  if (isMobile) {
    // showComponent = client === CLIENT.SINGTEL ? "ChangeEmail" : "ConfirmEmail";
    showComponent = "ChangeEmail";
    PhoneNumber = contactData;
    msg = API_PROGRESS.UPDATE_CONTACT_NUMBER;
    msg_success = API_PROGRESS.UPDATE_CONTACT_NUMBER_SUCCESS;
    component = COMPONENTS.UPDATE_CONTACT;
  } else {
    showComponent =
      client === CLIENT.SINGTEL ? "SubmitCardDetails" : "ShowPaymentOptions";
    module = "PAYMENT";
    EmailAddress = contactData;
    msg = API_PROGRESS.UPDATE_CONTACT_EMAIL;
    msg_success = API_PROGRESS.UPDATE_CONTACT_EMAIL_SUCCESS;
    component = COMPONENTS.UPDATE_CONTACT;
  }
  await dispatch(updateApiProgress(msg, 40, component));
  // const contactResponse = await dispatch(
  //   updateContactDetails(CacheId, ServiceRequestId, PhoneNumber, EmailAddress)
  // ).catch((err) => dispatch(serviceUnavailable()));

  // // not allowing to go ahead if response is empty
  // if (isEmpty(contactResponse)) {
  //   return;
  // }
  let contactResponse = null;
  let retrycount = 0;
  while (retrycount < 2) {
    contactResponse = await dispatch(updateContactDetails(CacheId, ServiceRequestId, PhoneNumber, EmailAddress))
    .catch((err) => { return null }
    );

    if (contactResponse === null) {
      retrycount++;
    } else {
      retrycount = 2;
    }
  }
  // not allowing to go ahead if response is empty
  if (isEmpty(contactResponse)) {
    dispatch(serviceUnavailable())
    return;
  }

  await dispatch(updateContactDetailsData(PhoneNumber, EmailAddress));
  dispatch(
    updateVisitor({
      lastActivity: isMobile
        ? ACTIVITY.UPDATE_CONTACT_NUMBER
        : ACTIVITY.UPDATE_CONTACT_EMAIL,
    })
  );

  /**
     * Updating shipping order API sequence
     * Now shippingOrder will be called after contactPoint API success
     */
  // !isMobile && await dispatch(confirmationOfDetails());
  if (!isMobile) {
    let res = await dispatch(confirmationOfDetails());
    if (!res) return;
  }

  if (module === "PAYMENT") {
    if (isInWarranty) {
      dispatch(submitSelectedPaymentOption("COD"));
      await dispatch(updateApiProgress(msg_success, 100, component, 40));
      await sleep(1000);
      await dispatch(clearApiProgress());
      return;
    }
    if (showComponent === "SubmitCardDetails") {
      const { CacheId } = getStore().session.sessionData.Configurations;
      const fee = getStore().claim.payment.serviceFee.TotalAmount;
      const discountedfee = getStore().claim.payment.serviceFee.TotalAmountWithDiscount;
      dispatch(getPciToken(CacheId, (discountedfee || fee))).catch((err) =>
        dispatch(serviceUnavailable())
      );
    }

    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.PaymentProcessing",
        }),
      ])
    );
    const initial = getStore().journeyMessages.flowProgress.percentage;
    dispatch(
      updateFlowProgress(
        PROGRESS_DATA.PAYMENT.title,
        PROGRESS_DATA.PAYMENT.count,
        initial
      )
    );
  }
  await dispatch(updateApiProgress(msg_success, 100, component, 40));
  await sleep(1000);
  await dispatch(clearApiProgress());
  dispatch(
    addJourneyMessages([createMessage(module, "system", { showComponent })])
  );
};

export const updateContactDetailsData = (phone = "", email = "") => async (
  dispatch,
  getStore
) => {
  let phoneNumber = phone,
    emailAddress = email;
  const { PhoneNumber, EmailAddress } = getStore().validation.agreement[0];
  if (isEmpty(phone)) {
    phoneNumber = PhoneNumber;
  }
  if (isEmpty(email)) {
    emailAddress = EmailAddress;
  }
  dispatch({
    type: setContactDetails.toString(),
    payload: {
      phoneNumber,
      emailAddress,
    },
  });
};
