import React, { useState } from "react";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";

import TrackingIcon from "../../../images/icon-tracking.svg";
import { TextLight } from "../../../base/BaseText";
import { useDispatch } from "react-redux";
import {
  confirmIfRequestCollected,
  discardCancelRequest,
} from "../state/operators/cancelRequestOperator";
import { Translate, useTranslate } from "../../../helpers/languageUtils";

const ConfirmRequestCancel = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState("");

  const handleSubmitCancelRequest = (status) => {
    const textPrint = t(`${status}Button`);

    setDisabled(true);
    setStatus(status);
    if (status === "Yes") {
      dispatch(confirmIfRequestCollected(textPrint));
    } else {
      dispatch(discardCancelRequest(textPrint));
    }
  };

  return (
    <BaseCard>
      <BaseIcon icon={TrackingIcon} name="Tracking" center />
      <BaseLabel>
        <Translate i18nKey="ConfirmRequestCancel.Title">
          <TextLight>
            Are you sure you want to cancel your Screen Repair Request?
          </TextLight>
        </Translate>
      </BaseLabel>
      <BaseButtonGroup>
        <BaseButton
          text={t("NoButton")}
          onClick={() => handleSubmitCancelRequest("No")}
          disabled={disabled}
          clicked={status === "No"}
        />
        <BaseButton
          text={t("YesButton")}
          onClick={() => handleSubmitCancelRequest("Yes")}
          disabled={disabled}
          clicked={status === "Yes"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmRequestCancel;
