import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextMedium } from "../../../base/BaseText";

import ContactConfig from "./contactConfirmationConfig";
import { submitConfirmationOfContactDetails } from "../state/operators/contactsOperator";
import { Translate, useTranslate } from "../../../helpers/languageUtils";

const ConfirmContactDetails = ({ code }) => {
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState("");
  const t = useTranslate();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.validation.verification);
  const { mdn } = useSelector((state) => state.validation.inputData);

  const handleSubmitConfirmation = (status) => {
    const textPrint = t(`${status}Button`);
    setDisabled(true);
    setStatus(status);
    dispatch(submitConfirmationOfContactDetails(status, code, textPrint));
  };

  return (
    <BaseCard>
      <BaseIcon icon={ContactConfig[code].icon} name="Contact" center />
      <BaseLabel>
        <Translate i18nKey={`ContactDetails.${code}.title`}>
          {ContactConfig[code].title}
        </Translate>
      </BaseLabel>
      <BaseLabel>
        <TextMedium>
          {code === "ConfirmMobileNumber"
            ? `${data.enrolledAsset.CountryCallingCode} ${mdn}`
            : data.EmailAddress}
        </TextMedium>
      </BaseLabel>
      <BaseButtonGroup>
        <BaseButton
          text={t("ChangeButton")}
          disabled={disabled}
          clicked={status === "Change"}
          onClick={() => handleSubmitConfirmation("Change")}
        />
        <BaseButton
          text={t("YesButton")}
          disabled={disabled}
          clicked={status === "Yes"}
          onClick={() => handleSubmitConfirmation("Yes")}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ConfirmContactDetails.defaultProps = {
  code: "ConfirmMobileNumber",
};

ConfirmContactDetails.propTypes = {
  code: PropTypes.string.isRequired,
};

export default ConfirmContactDetails;
