import React from "react";
import IssuesIcon from "../../../images/icon-tech-issues.svg";
import { TextLight, TextMedium } from "../../../base/BaseText";
import IncorrectScreen from "../../../components/IncorrectScreen";
import { useSelector } from "react-redux";
import { Translate } from "../../../helpers/languageUtils";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";

const RequestCancelled = () => {
  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );

  const caseNumber = useSelector((state) =>
    enquiryOption === EnumEnquiryModules.CancelRequest
      ? state.serviceRequest.determineIncidentDetails.CustomerCaseNumber
      : state.serviceRequest.serviceRequestDetails.CustomerCaseNumber
  );
  
  return (
    <IncorrectScreen icon={IssuesIcon} name="issues">
      <Translate i18nKey="RequestCancelled.Title">
        <TextLight>
          Your Screen Repair Request case{" "}
          <TextMedium>{{ caseNumber }}</TextMedium> has been cancelled. Thank
          you for using Screen Repair Service.
        </TextLight>
      </Translate>
    </IncorrectScreen>
  );
};

export default RequestCancelled;
