import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CloseIcon from "../../images/icon-close-green.svg";
import { isEmpty } from "../../helpers/formatUtils";

const BasePopup = ({ show, title, onClose, children, global, className }) => {
  const extraClasses = !isEmpty(className) ? className.split(" ") : [];

  return (
    <div
      className={classNames(
        "app-BasePopup",
        { "app-BasePopup-hidden": !show },
        { "app-BasePopup-global": global }
      )}
    >
      <div
        className={classNames(
          "app-BasePopup-content",
          ...extraClasses
        )}
      >
        <div
          className="app-BasePopup-content__icon-close"
          onClick={onClose}
        >
          <img src={CloseIcon} alt="Close Popup" />
        </div>
        {title && (
          <div className="app-BasePopup-content__title">{title}</div>
        )}
        {children}
      </div>
    </div>
  );
};

BasePopup.defaultProps = {
  show: false,
  global: false,
};

BasePopup.propTypes = {
  show: PropTypes.bool.isRequired,
  global: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default BasePopup;
