import React, { useState } from "react";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { useDispatch } from "react-redux";
import { checkIsExistingCustomer } from "../state/operators/generalEnquiryOperator";
import { useTranslate } from "../../../helpers/languageUtils";

const IsExistingCustomer = () => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [selection, setSelection] = useState("");
  const t = useTranslate();

  const handleSubmit = (answer) => {
    setSelection(answer);
    dispatch(checkIsExistingCustomer(answer));
    setDisabled(true);
  };

  return (
    <BaseCard>
      <BaseLabel>{t("GeneralEnquiry.IsExistingCustomer.Title")}</BaseLabel>
      <BaseButtonGroup>
        <BaseButton
          text={t("NoButton")}
          onClick={() => handleSubmit("No")}
          disabled={disabled}
          clicked={selection === "No"}
        />
        <BaseButton
          text={t("YesButton")}
          onClick={() => handleSubmit("Yes")}
          disabled={disabled}
          clicked={selection === "Yes"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default IsExistingCustomer;
