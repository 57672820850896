import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { TextLight } from "../BaseText";

const BaseCheckbox = ({
  id,
  label,
  value,
  onChange,
  className,
  disabled,
  ...restProps
}) => {
  const extraClasses = className ? className.split(" ") : [];
  return (
    <div
      className={classNames(
        "app-BaseCheckbox",
        { "opacity-50": disabled },
        ...extraClasses
      )}
    >
      <div>
        <input
          className="app-BaseCheckbox__input"
          {...restProps}
          type="checkbox"
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      <label htmlFor={id} className="app-BaseCheckbox__label">
        <TextLight>{label}</TextLight>
      </label>
    </div>
  );
};

BaseCheckbox.defaultProps = {
  disabled: false,
};

BaseCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default BaseCheckbox;
