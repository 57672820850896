import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage, { createSystemMessage, createUserMessage } from "../../../../helpers/createMessage";
import CONSTANTS, {
  DEVICE,
  API_PROGRESS,
  COMPONENTS,
  PROGRESS_DATA,
  ACTIVITY,
  CLIENT,
  INCIDENT_PATH_TYPE,
} from "../../../../helpers/constants";
import { setDeviceMakeAndModel, verifyImeiApi } from "../actions/actions";
import {
  updateApiProgress,
  clearApiProgress,
  updateFlowProgress,
  serviceUnavailable,
} from "../../../../actions/flowWindow";
import { enquiryApi, getMakeModelList } from "../../../../actions/cowrapperapi";
import {
  saveDeviceMakeOptions,
  saveDeviceModelOptions,
  setAgreementAsset,
} from "../reducers/deviceConfirmationReducer";
import { udpateChatInputVisibility } from "../../../Appsync/state/operators";
import { setChatReason } from "../../../Appsync/state/reducers";
import { createServiceRequest, determineRequest, caseGraphApi } from "../../../../actions/serviceRequestApi";
import { EnumEnquiryModules, PROGRAM_NAME, PROGRAM_NAME_BATT } from "../../../../components/EnquiryOptions/Constants";
import updateVisitor from "../../../Appsync/updateVisitor";
import { confirmIfRequestCollected } from "./cancelRequestOperator";
import { isEmpty } from "../../../../helpers/formatUtils";
import { verifyAgreementApi } from "../../../Validation/state/actions";
import { setLastCompletedCase } from "../../../../reducers/serviceRequestReducer";
import { INCIDENT_BATTERY_REPLACEMENT } from "../../../../components/EnquiryOptions/Constants";

export const proceedForDeviceConfirmation = (text) => async (dispatch, getStore) => {
  // const { replacedAsset } = getStore().validation.verification;

  // let showComponent = "";

  // if (isEmpty(replacedAsset)) {
  //   showComponent = "confirmDevice";
  // } else {
  //   showComponent = "displayDevices";
  // }

  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", text),
    ])
  );

  const {
    flowProgress: { percentage: initial },
    enquiryOption,
  } = getStore().journeyMessages;
  await dispatch(
    updateFlowProgress(
      PROGRESS_DATA.VERIFY_IMEI.title,
      PROGRESS_DATA.VERIFY_IMEI.count,
      initial
    )
  );
  if (
    [
      EnumEnquiryModules.NewRequest,
      EnumEnquiryModules.NewRequestBatteryReplacement,
      EnumEnquiryModules.InWarrantyRequest,
    ].includes(enquiryOption)
  ) {
    await dispatch(
      updateApiProgress(
        API_PROGRESS.CREATE_SR,
        40,
        COMPONENTS.CONFIRM_DEVICE,
        0
      )
    );

    const { CacheId } = getStore().session.sessionData.Configurations;

    const createSRResponse = await dispatch(
      createServiceRequest(CacheId)
    ).catch((err) => dispatch(serviceUnavailable()));

    // not allowing to go ahead if response is empty
    if (isEmpty(createSRResponse)) {
      return;
    }

    // After API call
    await dispatch(
      updateApiProgress(
        API_PROGRESS.CREATE_SR_SUCCESS,
        100,
        COMPONENTS.CONFIRM_DEVICE,
        40
      )
    );
    await sleep(1000);
    await dispatch(clearApiProgress());

    dispatch(
      updateVisitor({
        lastActivity: ACTIVITY.NEW_REQUEST,
        journeyStatus: CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.INPROGRESS,
        // journeyCmpltd: false
        caseNumber:
          createSRResponse.ServiceRequestDetails.CreateServiceRequestResults
            .CustomerCaseNumber,
      })
    );

  }

  dispatch(
    addJourneyMessages([
      createMessage("DEVICE_CONFIRMATION", "system", {
        showComponent: "verifyImei",
      }),
    ])
  );

};

export const isDeviceMakeAccepted = (deviceMake, isSingtel) => {
  
  if(isSingtel) {
    return (
      deviceMake.toUpperCase() === DEVICE.APPLE ||
      deviceMake.toUpperCase() === DEVICE.SAMSUNG
    );
  }
  
  return (
    deviceMake.toUpperCase() === DEVICE.APPLE ||
    deviceMake.toUpperCase() === DEVICE.SAMSUNG ||
    deviceMake.toUpperCase() === DEVICE.HUAWEI ||
    deviceMake.toUpperCase() === DEVICE.OPPO ||
    deviceMake.toUpperCase() === DEVICE.HONOR || 
    deviceMake.toUpperCase() === DEVICE.REALME
  );
};

export const isDeviceMakeXiaomi = (deviceMake) => {
  return false
  // return (
  //   deviceMake.toUpperCase() === DEVICE.XIAOMI
  // );
};

export const selectAgreementAsset = (
  selectedAgreement, selectedAsset
) => async (dispatch, getStore) => {
  const { Name: deviceMake } = selectedAsset.Make;
  const client = getStore().app.client;
  const programName  = getStore().claim?.payment?.serviceFees?.ProgramName;
  const isSingtel = client === CLIENT.SINGTEL;
  // const isAppleSamsung = isDeviceMakeAccepted(deviceMake, isSingtel) || (PROGRAM_NAME.includes(programName) && isSingtel);
  let isAppleSamsung = isDeviceMakeAccepted(deviceMake, isSingtel) || ((PROGRAM_NAME.includes(programName) || PROGRAM_NAME_BATT.includes(programName)) && isSingtel);
  if (isSingtel && isDeviceMakeXiaomi(deviceMake)) {
    isAppleSamsung = false;
  }
 
  // dispatch(setDeviceMakeAndModel(selectedAsset));
  dispatch(setAgreementAsset({ selectedAgreement, selectedAsset }));

  dispatch(updateVisitor({ lastActivity: ACTIVITY.CONFIRM_DEVICE }));

  if (isAppleSamsung) {

    const { CacheId } = getStore().session.sessionData.Configurations;
    const {
      inputData: { firstName },
    } = getStore().validation;

    await dispatch(
      updateApiProgress(
        API_PROGRESS.ASK_NAME,
        40,
        COMPONENTS.CONFIRM_DEVICE,
        0
      )
    );

    // verify new asset and agrement selected
    const verifyAgreementRes = await dispatch(
      verifyAgreementApi({
        cacheId: CacheId,
        AgreementId: selectedAgreement.AgreementId,
      })
    ).catch((err) => {
      dispatch(serviceUnavailable())
    });

    if (isEmpty(verifyAgreementRes)) {
      await dispatch(clearApiProgress());
      return;
    }

    let determineResponse = await dispatch(
      determineRequest(CacheId)
    ).catch((err) => dispatch(serviceUnavailable()));

    // not allowing to go ahead if response is empty
    if (isEmpty(determineResponse)) {
      return;
    }

    let caseGraphResponse = await dispatch(
      caseGraphApi(CacheId)
    ).catch((err) => {
      console.log("Case Graph API error:", err)
      return null;
    });

    console.log("caseGraphResponse", caseGraphResponse);

    if (caseGraphResponse && caseGraphResponse.CustomerCaseGraphResponse && 
      caseGraphResponse.CustomerCaseGraphResponse.CustomerCases && 
      caseGraphResponse.CustomerCaseGraphResponse.CustomerCases.length > 0) {
      let selectedAgreementCustomerCases = caseGraphResponse.CustomerCaseGraphResponse.CustomerCases.filter(
        (customerCase) => {
          console.log(customerCase)
          return (customerCase.AgreementId === selectedAgreement.AgreementId &&
            (customerCase.IncidentType.toLowerCase().indexOf("screen repair") > -1 || customerCase.IncidentType.toLowerCase().indexOf("battery replacement") > -1) &&
            customerCase.CustomerCaseStatus === "CMPLTD" &&
            (customerCase.ServiceRequests &&
              customerCase.ServiceRequests.length &&
              customerCase.ServiceRequests[0] &&
              customerCase.ServiceRequests[0].ShippingOrder &&
              customerCase.ServiceRequests[0].ShippingOrder.ShippingStatus === "SHIP"))
        }
      )

      console.log("selectedAgreementCustomerCases", selectedAgreementCustomerCases);

      let casesToSort = [];

      if (selectedAgreementCustomerCases && selectedAgreementCustomerCases.length > 0) {
        casesToSort = [...selectedAgreementCustomerCases]
        casesToSort.sort((a, b) => new Date(a.ServiceRequests[0].ShippingOrder.ShipmentDate) - new Date(b.ServiceRequests[0].ShippingOrder.ShipmentDate));
      }

      if (casesToSort.length) {
        await dispatch(setLastCompletedCase(casesToSort[0]))
      }
    }

    // After API call
    await dispatch(
      updateApiProgress(
        API_PROGRESS.ASK_NAME_SUCCESS,
        100,
        COMPONENTS.CONFIRM_DEVICE,
        40
      )
    );
    await sleep(1000);
    await dispatch(clearApiProgress());

    const { Type: incidentPathType, IncidentType: incidentType } = determineResponse.IncidentPath;

    if (incidentPathType === INCIDENT_PATH_TYPE.WAIT_FOR_HOLD) {
      await dispatch(
        updateApiProgress(
          API_PROGRESS.ASK_NRIC_SUCCESS,
          100,
          COMPONENTS.ASK_NRIC,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      // device hold
      dispatch(
        addJourneyMessages([
          createMessage("DEVICE_CONFIRMATION", "system", {
            showComponent: "deviceHold",
          }),
        ])
      );

      return;
    }

    const initial = getStore().journeyMessages.flowProgress.percentage;
    await dispatch(
      updateFlowProgress(
        PROGRESS_DATA.ENQUIRY_OPTIONS.title,
        PROGRESS_DATA.ENQUIRY_OPTIONS.count,
        initial
      )
    );

    if (isSingtel) {
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.HowCanWeAssist",
            values: { firstName },
          }),
          createMessage("ENQUIRY_OPTIONS", "system", {}),
        ])
      );
    } else { //Celcom
      if (incidentPathType === INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED) {
        if (incidentType &&
          (incidentType.toUpperCase() === "SWAP" ||
            incidentType.toUpperCase() === "MALFUNCTION" ||
            incidentType.toUpperCase() === "REPLACEMENT")
        ) {
          dispatch(
            addJourneyMessages([
              createMessage("CANCEL_REPAIR", "system", { showComponent: "ConfirmDPRequestCancel" }),
            ])
          );
        } else {
          dispatch(
            addJourneyMessages([
              createMessage("TEXT", "system", {
                key: "SystemMessage.HowCanWeAssist",
                values: { firstName },
              }),
              createMessage("ENQUIRY_OPTIONS", "system", {}),
            ])
          );
        }
      } else {
        dispatch(
          addJourneyMessages([
            createMessage("TEXT", "system", {
              key: "SystemMessage.HowCanWeAssist",
              values: { firstName },
            }),
            createMessage("ENQUIRY_OPTIONS", "system", {}),
          ])
        );
      }
    }
  } else {
    dispatch(
      addJourneyMessages([
        createMessage("DEVICE_CONFIRMATION", "system", {
          showComponent: "deviceNotEligible",
        }),
      ])
    );
  }
};

export const isRegisteredDeviceConfirmed = (
  isDeviceRegistered,
  deviceData,
  textPrint,
  fromDeviceList = false
) => async (dispatch, getStore) => {
  // const { enrolledAsset } = getStore().validation.verification;
  const { Name: deviceMake } = deviceData.Make;
  const programName  = getStore().claim?.payment?.serviceFees?.ProgramName;
  let decision;
  let showComponent;
  const client = getStore().app.client;
  const isSingtel = client === CLIENT.SINGTEL;

  const isAppleSamsung = isDeviceMakeAccepted(deviceMake, isSingtel);
  if (isDeviceRegistered) {
    decision = fromDeviceList
      ? `${deviceData.AssetInstance} ${deviceData.Make.Name} ${deviceData.AssetCatalog.AssetCatalogName}`
      : "Yes";
    showComponent = ( ((PROGRAM_NAME.includes(programName) || PROGRAM_NAME_BATT.includes(programName)) && isSingtel) || isAppleSamsung ) ? "verifyImei" : "deviceNotEligible";
    
    if (isSingtel && isDeviceMakeXiaomi(deviceMake)) {
      showComponent = "deviceNotEligible";
    }

    dispatch(setDeviceMakeAndModel(deviceData, isAppleSamsung));
    dispatch(updateVisitor({ lastActivity: ACTIVITY.CONFIRM_DEVICE }));
  } else {
    decision = "No";
    showComponent = "chooseEnrolledDevice";
  }

  dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));

  if (showComponent === "verifyImei") {
    const {
      flowProgress: { percentage: initial },
      enquiryOption,
    } = getStore().journeyMessages;
    const { CacheId } = getStore().session.sessionData.Configurations;
    await dispatch(
      updateFlowProgress(
        PROGRESS_DATA.VERIFY_IMEI.title,
        PROGRESS_DATA.VERIFY_IMEI.count,
        initial
      )
    );
    if (
      [
        EnumEnquiryModules.NewRequest,
        EnumEnquiryModules.NewRequestBatteryReplacement,
        EnumEnquiryModules.InWarrantyRequest,
      ].includes(enquiryOption)
    ) {
      await dispatch(
        updateApiProgress(
          API_PROGRESS.CREATE_SR,
          40,
          COMPONENTS.CONFIRM_DEVICE,
          0
        )
      );

      const createSRResponse = await dispatch(
        createServiceRequest(CacheId)
      ).catch((err) => dispatch(serviceUnavailable()));

      // not allowing to go ahead if response is empty
      if (isEmpty(createSRResponse)) {
        return;
      }

      // After API call
      await dispatch(
        updateApiProgress(
          API_PROGRESS.CREATE_SR_SUCCESS,
          100,
          COMPONENTS.CONFIRM_DEVICE,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      dispatch(
        updateVisitor({
          lastActivity: ACTIVITY.NEW_REQUEST,
          journeyStatus: CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.INPROGRESS,
          // journeyCmpltd: false
          caseNumber:
            createSRResponse.ServiceRequestDetails.CreateServiceRequestResults
              .CustomerCaseNumber,
        })
      );
    }
  }
  dispatch(
    addJourneyMessages([
      createMessage("DEVICE_CONFIRMATION", "system", {
        showComponent,
      }),
    ])
  );
};

export const submitChosenEnrolledDevice = (deviceData, textPrint) => async (
  dispatch,
  getStore
) => {
  let decision = "cancel";
  let data;
  let showComponent;

  if (deviceData.imei) {
    // decision = `${deviceData.make.Make} ${deviceData.model.PopularName}`;
    decision = `${deviceData.model.PopularName}`;

    dispatch(addJourneyMessages([createMessage("TEXT", "user", decision)]));

    const client = getStore().app.client;
    const programName  = getStore().claim?.payment?.serviceFees?.ProgramName;
    const isSingtel = client === CLIENT.SINGTEL;

    const isAppleSamsung = isDeviceMakeAccepted(deviceData.make.Make, isSingtel) || (isSingtel && (PROGRAM_NAME.includes(programName) || PROGRAM_NAME_BATT.includes(programName)));

    const ClientProductSkuNbr = getStore().validation?.verifyAgreement?.VerifyAgreementsResults?.Agreement?.ClientProductSkuNbr;
    const isSingtelStarter = isSingtel && (ClientProductSkuNbr && ClientProductSkuNbr.toLowerCase().indexOf("starter") > -1)
    const { caseType } = getStore().journeyMessages;
    
    showComponent = isAppleSamsung
      ? isSingtel
        ? isSingtelStarter
          ? "BatteryTriage"
          : caseType === INCIDENT_BATTERY_REPLACEMENT ? "BatteryTriage" : "CameraWorking"
        : "PowerOn"
      : "deviceNotEligible";

    if (isSingtel && isDeviceMakeXiaomi(deviceData.make.Make)) {
      showComponent = "deviceNotEligible";
    }

    const {
      flowProgress: { percentage: initial },
      enquiryOption,
    } = getStore().journeyMessages;
    const { CacheId } = getStore().session.sessionData.Configurations;

    if (
      [
        EnumEnquiryModules.NewRequest,
        EnumEnquiryModules.NewRequestBatteryReplacement,
        EnumEnquiryModules.InWarrantyRequest,
      ].includes(enquiryOption)
    ) {
      await dispatch(
        updateApiProgress(
          API_PROGRESS.CREATE_SR,
          40,
          COMPONENTS.CONFIRM_DEVICE,
          0
        )
      );

      const createSRResponse = await dispatch(
        createServiceRequest(CacheId)
      ).catch((err) => dispatch(serviceUnavailable()));

      // not allowing to go ahead if response is empty
      if (isEmpty(createSRResponse)) {
        return;
      }

      // After API call
      await dispatch(
        updateApiProgress(
          API_PROGRESS.CREATE_SR_SUCCESS,
          100,
          COMPONENTS.CONFIRM_DEVICE,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      dispatch(
        updateVisitor({
          lastActivity: ACTIVITY.NEW_REQUEST,
          journeyStatus: CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.INPROGRESS,
          // journeyCmpltd: false
          caseNumber:
            createSRResponse.ServiceRequestDetails.CreateServiceRequestResults
              .CustomerCaseNumber,
        })
      );
    }

    if (showComponent === "CameraWorking" || showComponent === "PowerOn" || showComponent === "BatteryTriage" ) {
      let deviceDetails = {
        AssetCatalog: {
          AssetCatalogId: deviceData.model.CatalogId,
          AssetCatalogName: deviceData.model.AssetCatalogName,
        },
        Make: {
          MakeId: deviceData.make.MakeId,
          Name: deviceData.make.Make,
        },
        Model: {
          ModelId: deviceData.model.ModelId,
          Name: deviceData.model.Model,
        },
        IMEI: deviceData.imei,
      };

      dispatch(setDeviceMakeAndModel(deviceDetails, isAppleSamsung));

      if (isSingtel) {
        dispatch(
          addJourneyMessages([
            createMessage("DEVICE_CONFIRMATION", "system", {
              showComponent: "confirmIWTerms",
            }),
          ])
        );
      } else {
        await dispatch(
          updateFlowProgress(
            PROGRESS_DATA.TROUBLESHOOT.title,
            PROGRESS_DATA.TROUBLESHOOT.count,
            initial
          )
        );
        dispatch(
          addJourneyMessages([
            createMessage("TEXT", "system", {
              key: "SystemMessage.AnswerFollowingQuestion",
            }),
            createMessage("TEXT", "system", {
              key: "SystemMessage.DeviceWillBeDiagnosed",
            }),
            createMessage("TROUBLESHOOTING", "system", {
              showComponent,
            }),
          ])
        );
      }

      return;
    } else {
      // device in-eligible
      dispatch(
        addJourneyMessages([
          createMessage("DEVICE_CONFIRMATION", "system", {
            showComponent: "deviceNotEligible",
          }),
        ])
      );
      dispatch(confirmIfRequestCollected("", "device-ineligible"));
    }
  } else {
    showComponent = "ENQUIRY_OPTIONS";
    data = {};
    const userText = createMessage("TEXT", "user", textPrint);
    dispatch(
      addJourneyMessages([
        userText,
        createMessage(showComponent, "system", data),
      ])
    );
  }
};

export const verifyDeviceImei = (imei) => async (dispatch, getStore) => {
  // Before API call
  const { CacheId } = getStore().session.sessionData.Configurations;
  const {
    imeiVerification,
    selectedAsset: { AssetInstance: assetInstance },
  } = getStore().claim.deviceConfirmation;
  const imeiVerificationFailedAttempt = imeiVerification
    ? imeiVerification.IMEIVerificationFailedAttempt
    : 0;
  const client = getStore().app.client;
  const isSingtel = client === CLIENT.SINGTEL;
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", imei)]));
  await dispatch(
    updateApiProgress(API_PROGRESS.VERIFY_IMEI, 40, COMPONENTS.VERIFY_IMEI, 0)
  );

  // API call
  const response = await dispatch(
    verifyImeiApi({
      imei,
      cacheId: CacheId,
      assetInstance,
      imeiVerificationFailedAttempt,
    })
  ).catch((err) => dispatch(serviceUnavailable()));

  // not allowing to go ahead if response is empty
  if (isEmpty(response)) {
    return;
  }

  const {
    VerificationOutcome,
    IMEIVerificationFailedAttempt,
  } = response.VerifyIMEIResults;

  // After API call
  await dispatch(
    updateApiProgress(
      API_PROGRESS.VERIFY_IMEI_SUCCESS,
      100,
      COMPONENTS.VERIFY_IMEI,
      40
    )
  );
  await sleep(1000);
  await dispatch(clearApiProgress());
  if (VerificationOutcome) {
    dispatch(updateVisitor({ lastActivity: ACTIVITY.VERIFY_IMEI }));

    if (isSingtel) {
      dispatch(
        addJourneyMessages([
          createMessage("DEVICE_CONFIRMATION", "system", {
            showComponent: "confirmIWTerms",
          }),
        ])
      );
    } else {
      const initial = getStore().journeyMessages.flowProgress.percentage;
      await dispatch(
        updateFlowProgress(
          PROGRESS_DATA.TROUBLESHOOT.title,
          PROGRESS_DATA.TROUBLESHOOT.count,
          initial
        )
      );
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.AnswerFollowingQuestion",
          }),
          createMessage("TEXT", "system", {
            key: "SystemMessage.DeviceWillBeDiagnosed",
          }),
          createMessage("TROUBLESHOOTING", "system", {
            showComponent: isSingtel ? "CameraWorking" : "PowerOn",
          }),
        ])
      );
    }
  } else {
    if (IMEIVerificationFailedAttempt < 3) {
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.IncorrectImei",
          }),
          createMessage("DEVICE_CONFIRMATION", "system", {
            showComponent: "verifyImei",
          }),
        ])
      );
    } else {
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.IncorrectImeiTerminate",
          }),
        ])
      );
      await dispatch(setChatReason("Invalid Imei"));
      dispatch(udpateChatInputVisibility(true));
    }
  }
};

export const getDeviceMakeModelOptions = (makeId) => async (
  dispatch,
  getStore
) => {
  const { ClientId } = getStore().session.sessionData.Configurations;
  if (makeId) {
    const models = await dispatch(getMakeModelList(ClientId, makeId));
    await dispatch(saveDeviceModelOptions(models));
  } else {
    const makes = await dispatch(getMakeModelList(ClientId));
    await dispatch(saveDeviceMakeOptions(makes));
  }
  return;
};

export const initiateIMEIChat = () => async (dispatch) => {
  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", {
        key: "VerifyImei.DontKnowLink",
      }),
    ])
  );
  await dispatch(setChatReason("I Don't Know My IMEI"));
  dispatch(udpateChatInputVisibility(true));
};

export const isIWTermsConfirmed = (isIWTermsConfirmed, textPrint) => async (
  dispatch,
  getStore
) => {
  const client = getStore().app.client;
  const isSingtel = client === CLIENT.SINGTEL;
  const ClientProductSkuNbr = getStore().validation?.verifyAgreement?.VerifyAgreementsResults?.Agreement?.ClientProductSkuNbr;
  const isSingtelStarter = isSingtel && (ClientProductSkuNbr && ClientProductSkuNbr.toLowerCase().indexOf("starter") > -1)
  const { caseType } = getStore().journeyMessages;

  dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));

  if (isIWTermsConfirmed) {
    const initial = getStore().journeyMessages.flowProgress.percentage;
    await dispatch(
      updateFlowProgress(
        PROGRESS_DATA.TROUBLESHOOT.title,
        PROGRESS_DATA.TROUBLESHOOT.count,
        initial
      )
    );

    if (isSingtelStarter) {
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "BatteryReplacement.AnswerFollowingQuestion",
          }),
          createMessage("TEXT", "system", {
            key: "BatteryReplacement.DeviceWillBeDiagnosed",
          }),
          createMessage("TROUBLESHOOTING", "system", {
            showComponent: "BatteryTriage",
          }),
        ])
      );
    } else {
      if (caseType === INCIDENT_BATTERY_REPLACEMENT) {
        dispatch(
          addJourneyMessages([
            createMessage("TEXT", "system", {
              key: "BatteryReplacement.AnswerFollowingQuestion",
            }),
            createMessage("TEXT", "system", {
              key: "BatteryReplacement.DeviceWillBeDiagnosed",
            }),
            createMessage("TROUBLESHOOTING", "system", {
              showComponent: "BatteryTriage",
            }),
          ])
        );
      } else {
        dispatch(
          addJourneyMessages([
            createMessage("TEXT", "system", {
              key: "SystemMessage.AnswerFollowingQuestion",
            }),
            createMessage("TEXT", "system", {
              key: "SystemMessage.DeviceWillBeDiagnosed",
            }),
            createMessage("TROUBLESHOOTING", "system", {
              showComponent: "CameraWorking",
            }),
          ])
        );
      }      
    }
  } else {
    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.IWTermsCancel",
        }),
      ])
    );
    dispatch(confirmIfRequestCollected("", "Cancel-IW-Terms page"));
  }
};

export const updateEnrolledDevice = (deviceData, systemMessage) => async (
  dispatch,
  getStore
) => {
  const firstName = getStore()?.validation?.inputData?.firstName || 'User';
  const {
        interactionData: {
          Interaction: {
            SessionData: { ClientId, ClientChannelId, ClientName },
          },
        },
      } = getStore().session;
  // const { ClientId, ClientChannelId } = getStore().session.sessionData.Configurations;
  const CustomerContact = getStore().validation.inputData.mdn || "";
  const { exchangeType } = getStore().journeyMessages
  const inquiryDesc = `Exchange Option: ${exchangeType},  Make: ${deviceData.make}, Model: ${deviceData.model}, imei: ${deviceData.imei} `

  const params = {
    files: [deviceData.file],
    firstName: firstName,
    lastName: "",
    mdn: CustomerContact,
    clientId: ClientId,
    clientChannelId: ClientChannelId,
    customerContact: CustomerContact,
    customerCaseNumber: null,
    selectedInquiryType: "Screen Repair Channel",
    selectedSubInquiryType: 'Device Mismatch',
    CallDriverCategory: 'Device Mismatch',
    inquiryDesc,
    UnnecessaryInquiry: false,
    resolveStatus: false,
    priority: "High",
    clientName: ClientName,
  }
  dispatch(enquiryApi(params));
  await dispatch(addJourneyMessages(createSystemMessage('TEXT', systemMessage)))
}
