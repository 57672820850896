import React, { useState } from "react";
import { TextLight, TextMedium } from "../base/BaseText";
import BaseIcon from "../base/BaseIcon/BaseIcon";
import CloseIcon from "../images/icon-close-green.svg";
import CarrierLogo from "../images/logo.svg";
import BaseLabel from "../base/BaseLabel";
import { Translate, useTranslate } from "../helpers/languageUtils";
import BaseCard from "../base/BaseCard/BaseCard";
import BaseButtonGroup from "../base/BaseButton/BaseButtonGroup";
import BaseButton from "../base/BaseButton/BaseButton";
import { useDispatch } from "react-redux";
import { closeAgentBusy, udpateChatInputVisibility } from "../modules/Appsync/state/operators";
import { setChatReason } from "../modules/Appsync/state/reducers";

const ServiceUnavailable = ({ onClose }) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState("");

  const handleCloseClick = () => {
    dispatch(closeAgentBusy(t("CloseButton")));
    setDisabled(true);
    setStatus("No");
  };

  const handleChatButton = () => {
    setDisabled(true);
    setStatus("Yes");
    dispatch(setChatReason("Technical Error"));
    dispatch(udpateChatInputVisibility(true));
  };

  return (
    <BaseCard>
      {/* <div className="w-full">
        <div className="cursor-pointer p-4" onClick={onClose}>
          <img className="ml-auto mr-0" src={CloseIcon} alt="Close" />
        </div>
      </div> */}
      <BaseIcon icon={CarrierLogo} name="logo" center />
      <BaseLabel className="mt-6 mx-auto font-DINPro-Regular text-center">
        <Translate i18nKey="ServiceUnavailable.PrimaryHeader">
          <TextLight>503 Service Unavailable</TextLight>
        </Translate>
      </BaseLabel>
      <BaseLabel className="my-2 mx-auto font-DINPro-Regular text-center">
        <Translate i18nKey="ServiceUnavailable.Content">
          <TextLight>Sorry for inconvience!</TextLight>
        </Translate>
      </BaseLabel>
      <BaseLabel className="my-2 mx-4 font-DINPro-Regular text-center">
        <Translate i18nKey="ServiceUnavailable.Content1">
          <TextLight>
            We were unable to process your request due to a technical issue.
          </TextLight>
        </Translate>
      </BaseLabel>
      {/* <BaseLabel className="mx-auto text-3xl font-DINPro-Regular text-center">
        <Translate i18nKey="ServiceUnavailable.Content2">
          <TextLight>Please try after sometime</TextLight>
        </Translate>
      </BaseLabel> */}
      <BaseLabel className="mx-4 font-DINPro-Regular text-center">
        <Translate i18nKey="ServiceUnavailable.Content3">
          <TextLight>
            Thank you for contacting Screen Repair Service!
          </TextLight>
        </Translate>
      </BaseLabel>

      <BaseButtonGroup className="my-2">
        <BaseButton
          text={t("CloseButton")}
          disabled={disabled}
          onClick={handleCloseClick}
          clicked={status === "No"}
        />
        <BaseButton
          text={t("ServiceUnavailable.ChatButton")}
          disabled={disabled}
          onClick={handleChatButton}
          clicked={status === "Yes"}
        />
      </BaseButtonGroup>

    </BaseCard>
  );
};

export default ServiceUnavailable;
