import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import VerifyPhoneIcon from "../../../images/icon-verify-phone.svg";
import { ReactComponent as HuaweiPhoneIcon } from "../../../images/image-phone-huawei.svg";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextLight, TextMedium } from "../../../base/BaseText";
import { isRegisteredDeviceConfirmed } from "../state/operators/deviceConfirmationOperators";
import { isEmpty, toPascalCase } from "../../../helpers/formatUtils";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";
import { CLIENT, INCIDENT_PATH_TYPE } from "../../../helpers/constants";

const ConfirmRegisteredDevice = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const assetData = useSelector(
    (state) => state.validation.verification.enrolledAsset
  );

  const client = useSelector((state) => state.app.client);
  const isSingtel = client === CLIENT.SINGTEL;

  const feeData = useSelector((state) => state.claim.payment.serviceFee);
  const fee = isEmpty(feeData) ? isSingtel ? "99" : "399" : feeData.TotalAmountWithDiscount || feeData.TotalAmount;
  const currency = isSingtel ? "SGD" : "RM";
  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );
  const { type : incidentPathType,  serviceRequestDetails}  = useSelector((state) => state.serviceRequest);

  const { WarrantyType } = useSelector((state) =>
    incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST &&
    serviceRequestDetails.Actions !== "CaptureIncidentDetails"  
      ? state.serviceRequest.serviceRequestDetails
      : state.claim.termsAndConditions.processIncident.CreateIncidentResults
          .CustomerCase.ServiceRequest
  );

  const isInWarranty = enquiryOption === EnumEnquiryModules.InWarrantyRequest || WarrantyType === "IW";

  const registeredPhone = `${toPascalCase(assetData.Make.Name)} ${
    assetData.AssetCatalog.AssetCatalogName
  }`;
  const [disabled, setDisabled] = useState(false);
  const [isCorrectDevice, setIsCorrectDevice] = useState("");

  const handleSubmit = (isDeviceRegistered) => {
    const status = isDeviceRegistered ? "Yes" : "No";
    setIsCorrectDevice(status);
    setDisabled(true);
    const textPrint = t(`${status}Button`);
    dispatch(
      isRegisteredDeviceConfirmed(isDeviceRegistered, assetData, textPrint)
    );
  };

  return (
    <BaseCard>
      <BaseIcon icon={VerifyPhoneIcon} name="Verify Phone" center />
      <BaseLabel>
        <Translate i18nKey="ConfirmRegisteredDevice.Title">
          <TextLight>
            Please confirm whether
            <br />
            the following is your Registered device?
          </TextLight>
        </Translate>
      </BaseLabel>
      <div className="flex mx-auto my-4">
        <div className="mr-4">
          <HuaweiPhoneIcon />
        </div>
        <div className="py-2 my-auto w-20r">
          <div className="text-lg">
            <TextLight>{registeredPhone}</TextLight>
          </div>
          <div className="text-xl py-2">
            <Translate i18nKey="ConfirmRegisteredDevice.RegisteredDevice">
              <TextMedium>REGISTERED DEVICE</TextMedium>
            </Translate>
          </div>
        </div>
      </div>
      {!isInWarranty && (
        <div className="text-center m-4 text-default">
          <Translate i18nKey="Payment.Title">
            <TextMedium>
              Screen Repair Fee of {{fee}}
            </TextMedium>
          </Translate>
        </div>
      )}
      <BaseButtonGroup>
        <BaseButton
          text={t("ConfirmRegisteredDevice.Button.No")}
          onClick={() => handleSubmit(false)}
          disabled={disabled}
          clicked={isCorrectDevice === "No"}
        />
        <BaseButton
          text={t("ConfirmRegisteredDevice.Button.Yes")}
          onClick={() => handleSubmit(true)}
          disabled={disabled}
          clicked={isCorrectDevice === "Yes"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmRegisteredDevice;
