import React from "react";
import PropTypes from "prop-types";
import ChooseEnrolledDevice from "./ChooseEnrolledDevice";
import SelectExchangeOption from "./SelectExchangeOption";

const View = ({ showComponent }) => {
  return (
    <>
      {showComponent === "chooseEnrolledDevice" && <ChooseEnrolledDevice />}
      {showComponent === "selectExchangeOption" && <SelectExchangeOption />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
};

export default View;
