import React, { useState } from "react";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseLabel from "../../../base/BaseLabel";
import BaseButton from "../../../base/BaseButton/BaseButton";

import TrackingIcon from "../../../images/icon-tracking.svg";
import { TextLight } from "../../../base/BaseText";
import { useDispatch, useSelector } from "react-redux";
import {
    cancelDPRequest,
} from "../state/operators/cancelRequestOperator";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import Config from "../../Appsync/Config";

const ConfirmDPRequestCancel = () => {
    const t = useTranslate();
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [status, setStatus] = useState("");
    const client = useSelector((state) => state.app.client);
    const srStatus = useSelector((state) => state?.serviceRequest?.determineIncidentDetails?.ServiceRequestStatus)

    const handleSubmitCancelRequest = (status) => {
        const textPrint = t(`ExistingDPRequest.ButtonCancel`);
        setDisabled(true);
        setStatus(status);
        dispatch(cancelDPRequest(textPrint));
    };

    const handleMoveToSmartSupport = () => {
        setDisabled(true);
        window.open(Config[client].SmartSupportLink);
    };

    return (
        <BaseCard>
            {/*<BaseIcon icon={TrackingIcon} name="Tracking" center />*/}
            <BaseLabel>
                <Translate i18nKey="ExistingDPRequest.Title">
                    <TextLight>
                        Sorry, you have an ongoing request for Swap under Celcom Phone Care.
                    </TextLight>
                </Translate>
            </BaseLabel>
            {srStatus === "WORKING" &&
                <BaseLabel>
                    <Translate i18nKey="ExistingDPRequest.Subtitle">
                        <TextLight>
                            Do you wish to cancel your Swap Request and proceed to submit a request for repair instead?
                        </TextLight>
                    </Translate>
                </BaseLabel>
            }
            <BaseButton
                text={t("ExistingDPRequest.ButtonResume")}
                onClick={handleMoveToSmartSupport}
                disabled={disabled}
                clicked={status === "No"}
                className="w-full"
            />
            {srStatus === "WORKING" &&
                <BaseButton
                    text={t("ExistingDPRequest.ButtonCancel")}
                    onClick={() => handleSubmitCancelRequest("DP")}
                    disabled={disabled}
                    clicked={status === "Yes"}
                    className="w-full"
                />
            }
        </BaseCard>
    );
};

export default ConfirmDPRequestCancel;
