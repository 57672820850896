import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { COMPONENTS } from "../../helpers/constants";
import { isEmpty } from "../../helpers/formatUtils";

const BaseProgressBar = ({
  uniqueId,
  percentage,
  initial,
  type,
  className,
}) => {
  const extraClasses = className ? className.split(" ") : [];
  const [progressBarId, setProgressBarId] = useState("");
  const [pc, setPc] = useState(0);
  let progress = initial;

  const progressInterval = setInterval(() => {
    if (!isEmpty(progressBarId)) {
      const element = document.getElementById(progressBarId);
      if (element && progress < pc) {
        progress++;
        document.getElementById(progressBarId).style.width = progress + "%";
      }
    }
  }, 10);

  useEffect(() => {
    if (percentage > 0) {
      setPc(percentage);
    }
  }, [percentage]);

  useEffect(() => {
    return () => {
      clearInterval(progressInterval);
    };
  });

  useEffect(() => {
    setProgressBarId(`app-progressBar__${uniqueId}`);
  }, []);

  return (
    <div
      className={classNames(
        "app-BaseProgressBar",
        {
          "app-BaseProgressBar-none": pc === 0,
        },
        ...extraClasses
      )}
    >
      <div
        id={progressBarId}
        className={classNames(
          {
            "app-BaseProgressBar__progress-blue":
              type === COMPONENTS.API_PROGRESS,
          },
          {
            "app-BaseProgressBar__progress":
              type === COMPONENTS.FLOW_PROGRESS,
          }
        )}
      ></div>
    </div>
  );
};

BaseProgressBar.defaultProps = {
  percentage: 0,
  initial: 0,
};

BaseProgressBar.propTypes = {
  uniqueId: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  initial: PropTypes.number,
  type: PropTypes.string,
};

export default BaseProgressBar;
