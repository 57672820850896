import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import PickUpAddressIcon from "../../../images/icon-address.svg";
import CelcomDeliveryIcon from "../../../images/icon-delivery-celcom.svg";
import { TextLight } from "../../../base/BaseText";
import { confirmationOfAddress } from "../state/operators/scheduleOperator";
import BaseTable, {
  BaseTR,
  BaseTH,
  BaseTD,
} from "../../../base/BaseTable/BaseTable";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { CLIENT } from "../../../helpers/constants";
import { displayRepairOption } from "../state/operators/RepairOptionsOperator";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";

const ConfirmAddress = ({ isPickup }) => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const scheduleData = useSelector((state) => state.claim.schedule);
  const which = isPickup ? "Pick-up" : "Return";
  const client = useSelector((state) => state.app.client);
  const isSingtel = client === CLIENT.SINGTEL;

  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );
  const isChangeSchedule = enquiryOption === EnumEnquiryModules.ChangeSchedule;

  const [disabled, setDisabled] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState("");

  const tablePickUpHeaders = [
    { key: "pickupAddress", text: t("ConfirmAddress.LabelforPickup") },
  ];

  const tablePickUpData = [
    {
      pickupAddress: scheduleData.selectedPickupAddress,
    },
  ];

  const tableDeliveryHeaders = [
    { key: "returnAddress", text: t("ConfirmAddress.LabelforReturn") },
  ];

  const tableDeliveryData = [
    {
      returnAddress: scheduleData.selectedDeliveryAddress,
    },
  ];

  const getAddressText = (address) => {
    const { Address1, Address2, Address3, City, PostalCode, StateProvinceCode } = address;
    return `${Address1} ${Address2} ${Address3} ${
      isSingtel ? City : StateProvinceCode
    } ${PostalCode}`;
  };

  const handleConfirmAdress = (status) => {
    const textPrint = t(`${status}Button`);
    setConfirmationStatus(status);
    setDisabled(true);
    const postalCode = isPickup
      ? scheduleData.selectedPickupAddress.PostalCode
      : scheduleData.selectedDeliveryAddress.PostalCode;
    dispatch(
      confirmationOfAddress(
        status,
        isPickup,
        postalCode,
        textPrint
      )
    );
  };

  const goBack = () => {
    setDisabled(true);
    dispatch(displayRepairOption(t("GoBackButton")));
  };

  return (
    <BaseCard>
      <BaseIcon
        icon={isPickup ? PickUpAddressIcon : CelcomDeliveryIcon}
        name="Tracking"
        center
      />
      <BaseLabel>
        <Translate
          i18nKey={
            isChangeSchedule
              ? "ConfirmAddress.ChangeTitle"
              : "ConfirmAddress.Title"
          }
        >
          <TextLight>Please confirm the {{ which }} of your device</TextLight>
        </Translate>
      </BaseLabel>
      <BaseTable title={t(`ConfirmAddress.Table.${which}Title`)}>
        {isPickup
          ? tablePickUpHeaders.map((header) => (
              <BaseTR key={header.key}>
                <BaseTH>{header.text}</BaseTH>
                {tablePickUpData.map((data, i) => (
                  <BaseTD key={`${header.key}_${i}`}>
                    {getAddressText(data[header.key])}
                  </BaseTD>
                ))}
              </BaseTR>
            ))
          : tableDeliveryHeaders.map((header) => (
              <BaseTR key={header.key}>
                <BaseTH>{header.text}</BaseTH>
                {tableDeliveryData.map((data, i) => (
                  <BaseTD key={`${header.key}_${i}`}>
                    {getAddressText(data[header.key])}
                  </BaseTD>
                ))}
              </BaseTR>
            ))}
      </BaseTable>
      <BaseButtonGroup>
        <BaseButton
          text={t("ChangeButton")}
          onClick={() => handleConfirmAdress("Change")}
          disabled={disabled}
          clicked={confirmationStatus === "Change"}
        />
        <BaseButton
          text={isChangeSchedule ? t("CancelButton") : t("ConfirmButton")}
          onClick={() =>
            handleConfirmAdress(isChangeSchedule ? "Cancel" : "Confirm")
          }
          disabled={disabled}
          clicked={
            confirmationStatus === (isChangeSchedule ? "Cancel" : "Confirm")
          }
        />
      </BaseButtonGroup>
      {!isSingtel && isPickup && !isChangeSchedule && (
        <div
          className={` ${
            disabled
              ? "cursor-not-allowed items-center justify-around text-center m-4 "
              : "cursor-pointer items-center justify-around text-center m-4 "
          }`}
          disabled={disabled}
          onClick={!disabled && goBack}
        >
          <a className="text-srgreen-secondary text-xl">{t("GoBackButton")}</a>
        </div>
      )}
    </BaseCard>
  );
};

ConfirmAddress.propTypes = {
  isPickup: PropTypes.bool,
};

ConfirmAddress.defaultProps = {
  isPickup: true,
};

export default ConfirmAddress;
