import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "./../images/icon-close-white.svg";
import { TextLight, TextMedium } from "../base/BaseText";
import BaseProgressBar from "../base/BaseProgressBar/BaseProgressBar";
import { useSelector } from "react-redux";
import { CLIENT, COMPONENTS, INCIDENT_PATH_TYPE } from "../helpers/constants";
import { Translate, useTranslate } from "../helpers/languageUtils";

const HeaderProgress = ({ text, percentage, initial = 0, isSingtel }) => {

  const incidentPathType = useSelector((state) => state.serviceRequest.type);
  const isNoActionRequired =
    incidentPathType === INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED;

  const t = useTranslate();
  return (
    <>
      { isNoActionRequired ? <div></div> : 
      percentage > 0 && percentage < 100 ? (
        <div className="flex flex-row w-full">
          <div className="text-5xl mr-4">
            {percentage}
            <span className="text-2xl pl-1 text-center">%</span>
          </div>
          <div className="pt-2 w-full pr-4">
            <div className="text-xl">
              <TextMedium>{t(`ProgressData.${text}.title`)}</TextMedium>
            </div>
            <BaseProgressBar
              className="h-2 mt-2 mb-0 w-full"
              percentage={percentage}
              initial={initial}
              type={COMPONENTS.FLOW_PROGRESS}
              uniqueId={COMPONENTS.FLOW_PROGRESS}
            />
          </div>
        </div>
      ) : (percentage === 100 && isSingtel) ? (
        "Rate our Screen Repair Service"
      ) : (
        <Translate i18nKey="FlowHeader.Title">
          <TextMedium>Welcome to Screen Repair Portal</TextMedium>
        </Translate>
      )}
    </>
  );
};

const FlowHeader = ({ onClose }) => {
  const flowProgressData = useSelector(
    (state) => state.journeyMessages.flowProgress
  );

  const client = useSelector(
    (state) => state.app.client
  );

  const isSingtel = client === CLIENT.SINGTEL;

  return (
    <div className="app__flow-header">
      <div className="app__flow-content">
        <HeaderProgress {...flowProgressData} isSingtel = {isSingtel} />
      </div>
      <div className="app__flow-exit-button" onClick={onClose}>
        <img src={CloseIcon} alt="Close" />
      </div>
    </div>
  );
};

FlowHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FlowHeader;
