// Packages
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
// Base Components
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseInformationBox from "../../../base/BaseInformationBox";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextDanger, TextLight, TextMedium } from "../../../base/BaseText";
// Icons
import CalendarCheckedIcon from "../../../images/icon-calendar-selected.svg";
// Operator
import { confirmationOfDateAndTime } from "../state/operators/scheduleOperator";
import { getReadableDate, getFormmatedDate, getDeliverySlot } from "../../../helpers/dateUtils";
import BaseTable, {
  BaseTR,
  BaseTH,
  BaseTD,
} from "../../../base/BaseTable/BaseTable";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";
import {CLIENT} from "../../../helpers/constants"
import { INCIDENT_BATTERY_REPLACEMENT } from "../../../components/EnquiryOptions/Constants";

const ConfirmDateTime = ({ isPickup }) => {
  const { caseType } = useSelector((state) => state.journeyMessages);
  const dispatch = useDispatch();
  const t = useTranslate();
  const scheduleData = useSelector((state) => state.claim.schedule);
  const which = isPickup ? "Pick-up" : "Return";

  const [disabled, setDisabled] = useState(false);
  const [dateStatus, setDateStatus] = useState("");

  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );
  const isChangeSchedule = enquiryOption === EnumEnquiryModules.ChangeSchedule;

  const determinDetails = useSelector(
    (state) => state.serviceRequest.determineIncidentDetails
  );

  const client = useSelector((state) => state.app.client);

  const tablePickUpHeaders = [
    { key: "pickupDate", text: t("ChangeDateTime.LabelforPickupDate") },
    { key: "pickupTime", text: t("ChangeDateTime.LabelforPickupTime") },
  ];

  const tablePickUpData = [
    {
      pickupDate: isChangeSchedule
        ? `${getFormmatedDate(determinDetails.ShippingOrderOutbound.ExpectedDeliveryDate, "d MMM yyyy")}`
        : `${getFormmatedDate(scheduleData.selectedPickupDate, "d MMM yyyy")}`,
      pickupTime: isChangeSchedule
        ? determinDetails.ShippingOrderOutbound.DeliveryWindow === "16H"
          ? "12pm - 4pm"
          : determinDetails.ShippingOrderOutbound.DeliveryWindow === "12H"
            ? "9am - 12pm"
            : determinDetails.ShippingOrderOutbound.DeliveryWindow === "14H"
              ? determinDetails.ShippingOrderOutbound.ShippingMethodType === "Same Day"
                ? "12pm - 2pm"
                : "10am - 2pm"
              : `${scheduleData.selectedPickupSlot}`
        : `${scheduleData.selectedPickupSlot}`,
    },
  ];

  const tableDeliveryHeaders = [
    { key: "returnDate", text: t("ChangeDateTime.LabelforReturnDate") },
    { key: "returnTime", text: t("ChangeDateTime.LabelforReturnTime") },
  ];

  const tableDeliveryData = [
    {
      returnDate: isChangeSchedule
        ? `${getFormmatedDate(
            determinDetails.ShippingOrderInbound.ExpectedDeliveryDate,
            "d MMM yyyy"
          )}`
        : `${getFormmatedDate(
            scheduleData.selectedDeliveryDate,
            "d MMM yyyy"
          )}`,
      returnTime: isChangeSchedule
        ? getDeliverySlot()[0]
        : `${scheduleData.selectedDeliverySlot}`,
    },
  ];

  const handleSubmitPickupDateTime = (status) => {
    const textPrint = t(`${status}Button`);
    setDisabled(true);
    setDateStatus(status);
    dispatch(confirmationOfDateAndTime(status, isPickup, textPrint));
  };

  const isCampaignTimingEligible = useSelector(
    (state) => state.claim.schedule.isCampaignTimingEligible
  );
  const returnSLA = client === CLIENT.SINGTEL && isCampaignTimingEligible ? "1-2 business days" : "2 business days"

  const isSingtel = client === CLIENT.SINGTEL;
  const { ClientProductSkuNbr } = useSelector((state) => state.validation?.verifyAgreement?.VerifyAgreementsResults?.Agreement);
  const isSingtelStarter = isSingtel && (ClientProductSkuNbr && ClientProductSkuNbr.toLowerCase().indexOf("starter") > -1)
  
  return (
    <BaseCard>
      <BaseIcon icon={CalendarCheckedIcon} name="Calendar" center />
      <BaseLabel>
        <Translate
          i18nKey={
            isChangeSchedule
              ? "ConfirmDateTime.ChangeTitle"
              : "ConfirmDateTime.Title"
          }
        >
          <TextLight>
            Please confirm the {{ which }} Schedule of your device
          </TextLight>
        </Translate>
      </BaseLabel>
      <BaseTable title={t(`ConfirmDateTime.Table.${which}Title`)}>
        {isPickup
          ? tablePickUpHeaders.map((header) => (
              <BaseTR key={header.key}>
                <BaseTH>{header.text}</BaseTH>
                {tablePickUpData.map((data, i) => (
                  <BaseTD key={`${header.key}_${i}`}>{data[header.key]}</BaseTD>
                ))}
              </BaseTR>
            ))
          : tableDeliveryHeaders.map((header) => (
              <BaseTR key={header.key}>
                <BaseTH>{header.text}</BaseTH>
                {tableDeliveryData.map((data, i) => (
                  <BaseTD key={`${header.key}_${i}`}>{data[header.key]}</BaseTD>
                ))}
              </BaseTR>
            ))}
      </BaseTable>

      <BaseInformationBox>
        <Translate i18nKey={isSingtelStarter 
          ? "ConfirmDateTime.InformationBatteryReplacement" 
          : caseType === INCIDENT_BATTERY_REPLACEMENT ? "ConfirmDateTime.InformationBatteryReplacement" : "ConfirmDateTime.Information"}>
          <TextLight>
            Please note that the <TextMedium>Screen Repair service</TextMedium>
            will be completed in <TextMedium>{{returnSLA}}</TextMedium> from
            the date the device is picked up.
          </TextLight>
        </Translate>
        {isPickup && client === CLIENT.SINGTEL && <TextDanger>
          <Translate i18nKey="ConfirmDateTime.AdditionalInfo" />
          {/* <Translate i18nKey="ConfirmDateTime.AdditionalInfoNoXiaomi" /> */}
        </TextDanger>}
      </BaseInformationBox>
      <BaseButtonGroup>
        <BaseButton
          text={t("ChangeButton")}
          onClick={() => handleSubmitPickupDateTime("Change")}
          disabled={disabled}
          clicked={dateStatus === "Change"}
        />
        <BaseButton
          text={isChangeSchedule ? t("CancelButton") : t("KeepButton")}
          onClick={() =>
            handleSubmitPickupDateTime(isChangeSchedule ? "Cancel" : "Keep")
          }
          disabled={disabled}
          clicked={dateStatus === (isChangeSchedule ? "Cancel" : "Keep")}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ConfirmDateTime.propTypes = {
  isPickup: PropTypes.bool,
};

ConfirmDateTime.defaultProps = {
  isPickup: true,
};

export default ConfirmDateTime;
