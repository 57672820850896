// Packages
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Base Components
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import PaymentIcon from "../../../images/icon-payment-method.svg";
import BaseInformationBox from "../../../base/BaseInformationBox";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextLight, TextMedium } from "../../../base/BaseText";
import { submitSelectedPaymentOption } from "../state/operators/paymentOperator";
import { Translate, useTranslate } from "../../../helpers/languageUtils";

const PaymentOptions = ({ isPickup }) => {
  const dispatch = useDispatch();
  const t = useTranslate();

  const fee = useSelector(
    (state) => state.claim.payment.serviceFee.TotalAmount
  );

  const discountedfee = useSelector(
    (state) => state.claim.payment.serviceFee.TotalAmountWithDiscount
  );

  const [disabled, setDisabled] = useState(false);
  const [paymentOption, setPaymentOption] = useState("");

  const handleSelectedPaymentOption = (option, optionLabel) => {
    setDisabled(true);
    setPaymentOption(option);
    dispatch(submitSelectedPaymentOption(option, optionLabel));
  };

  return (
    <BaseCard>
      <BaseIcon icon={PaymentIcon} name="Payment" center />
      <BaseLabel>
        <Translate i18nKey="PaymentOptions.Title">
          <TextLight>
            Please note that the total amount charged to you will be
            <TextMedium> ${{ fee: discountedfee || fee }}</TextMedium> (inclusive of SST, if
            applicable)
          </TextLight>
        </Translate>
      </BaseLabel>
      <BaseInformationBox>
        <Translate i18nKey="PaymentOptions.Information">
          <TextLight>
            For <TextMedium>Cash on delivery (COD)</TextMedium>, Please prepare
            exact amount of cash to receive the device from our courier service.
            from the date the device is picked up.
          </TextLight>
        </Translate>
      </BaseInformationBox>
      <BaseButtonGroup>
        <BaseButton
          text={t("PaymentOptions.Button.Card")}
          onClick={() =>
            handleSelectedPaymentOption("CRE", t("PaymentOptions.Button.Card"))
          }
          disabled={disabled}
          clicked={paymentOption === "CRE"}
          className="px-2 text-1.3"
        />
        <BaseButton
          text={t("PaymentOptions.Button.COD")}
          onClick={() =>
            handleSelectedPaymentOption("COD", t("PaymentOptions.Button.COD"))
          }
          disabled={disabled}
          clicked={paymentOption === "COD"}
          className="px-2 text-1.3"
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default PaymentOptions;
