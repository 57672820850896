import { callStandardizeAddressApi, callUpdateShippingApi, callGetShippingApi, callServiceOrderApi, callCreateRepairRequestApi, callUpdatePURScheduleApi } from "../reducers/scheduleReducer";

export const standardizeAddressApi = (
  CacheId,
  isEnrolledAddress,
  addressObj = {},
  addressRetryAttempt = false
) => ({
  type: callStandardizeAddressApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/incidentidentification/v1/standardiseaddress`,
    method: "POST",
    body: {
      QASParams: {
        SessionId: CacheId,
        AddressRetryAttempt: addressRetryAttempt,
        Address: addressObj,
        IsEnrolled: isEnrolledAddress,
      },
    },
  },
});

export const serviceOrderApi = (CreateServiceOrderParameters) => ({
  type: callServiceOrderApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/physicalassetfulfillment/v1/serviceorder`,
    method: "POST",
    body: {
      CreateServiceOrderParameters
    },
  },
});

export const createRepairRequestApi = (CreateRepairRequestParameters) => ({
  type: callCreateRepairRequestApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/screenrepair/v1/repairrequest/create`,
    method: "POST",
    body: {
      CreateRepairRequestParameters
    },
  },
});

export const getShippingAddressApi = (CacheId) => ({
  type: callGetShippingApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/physicalassetfulfillment/v1/shippingorder/get`,
    method: "POST",
    body: {
      GetShippingOrderParameters: {
        SessionId: CacheId,
      },
    },
  },
});

export const updateShippingAddressApi = (UpdateShippingOrderParameters) => ({
  type: callUpdateShippingApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/physicalassetfulfillment/v1/shippingorder`,
    method: "POST",
    body: {
      UpdateShippingOrderParameters
    },
  },
});

export const updatePURScheduleApi = (CreateRepairScheduleParameters) => ({
  type: callUpdatePURScheduleApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/screenrepair/v1/schedule`,
    method: "POST",
    body: {
      CreateRepairScheduleParameters
    },
  },
});
