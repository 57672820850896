import { combineReducers } from "redux";
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import { configureStore } from '@reduxjs/toolkit';
import staticReducers from './reducers';
import apiService from "./middlewares/apiService";
import appsyncMessage from "./middlewares/appsyncMessage";

function createReducer(asyncReducers) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers
  });
}

const middleware = [...getDefaultMiddleware(), apiService, appsyncMessage];
// const middleware = (getDefaultMiddleware) => getDefaultMiddleware().concat([apiService, appsyncMessage])

const store = configureStore({
  reducer: createReducer(),
  middleware
});
store.asyncReducers = {};
store.injectReducer = (key, asyncReducer) => {
  store.asyncReducers[key] = asyncReducer;
  store.replaceReducer(createReducer(store.asyncReducers));
};


/* if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
} */

export default store;