import { setChatConnect, setChatInputVisibility } from "./reducers";
import { addJourneyMessages, sleep } from "../../../helpers/addJourneyMessages";
import createMessage from "../../../helpers/createMessage";
import { updateFlowProgress, endFlow } from "../../../actions/flowWindow";
import { CLIENT, PROGRESS_DATA } from "../../../helpers/constants";
import { validateNric } from "../../Validation/state/operators";
import { isEmpty } from "../../../helpers/formatUtils";
import { showCCComponent, updateCCAuthResolvedByChat, updatePaymentResolvedByChat } from "../../RepairClaim/state/operators/paymentOperator";

export const updateChatConnect = (status) => ({
  type: setChatConnect.toString(),
  payload: status,
});

export const udpateChatInputVisibility = (status) => ({
  type: setChatInputVisibility.toString(),
  payload: status,
});

export const resumeRepairFlow = (code) => async (dispatch, getStore) => {
  const { firstName } = getStore().validation.inputData;
  const { status, chatReason } = getStore().chat;
  if (isEmpty(status) && isEmpty(chatReason)) return;
  switch (code) {
    case "Resolved":
      const { chatReason } = getStore().chat;
      if (chatReason === "Invalid Name") {
        dispatch(
          addJourneyMessages([
            createMessage("TEXT", "system", {
              key: "SystemMessage.AskForNric",
              values: { firstName },
            }),
            createMessage("VALIDATION", "system", { showComponent: "AskNric" }),
          ])
        );
      } else if (chatReason === "Invalid Nric") {
        dispatch(validateNric("AGENT_VERIFIED", true));
      } else if (
        chatReason === "Invalid Imei" ||
        chatReason === "I Don't Know My IMEI"
      ) {
        const initial = getStore().journeyMessages.flowProgress.percentage;
        const client = getStore().app.client;
        const isSingtel = client === CLIENT.SINGTEL;
        await dispatch(
          updateFlowProgress(
            PROGRESS_DATA.TROUBLESHOOT.title,
            PROGRESS_DATA.TROUBLESHOOT.count,
            initial
          )
        );
        dispatch(
          addJourneyMessages([
            createMessage("TEXT", "system", {
              key: "SystemMessage.AnswerFollowingQuestion",
            }),
            createMessage("TEXT", "system", {
              key: "SystemMessage.DeviceWillBeDiagnosed",
            }),
            createMessage("TROUBLESHOOTING", "system", {
              showComponent: isSingtel ? "CameraWorking" : "PowerOn",
            }),
          ])
        );
      } else if (
        chatReason === "Invalid Card" ||
        chatReason === "CC Auth failure"
      ) {
        chatReason === "Invalid Card"
          ? dispatch(updatePaymentResolvedByChat())
          : dispatch(updateCCAuthResolvedByChat());
        dispatch(showCCComponent());
      } else if (
        chatReason === "General Inquiry" ||
        chatReason === "In-WarrantyRequest" ||
        chatReason === "ChangeSchedule" ||
        chatReason === "CancelRequest"
      ) {
        dispatch(
          addJourneyMessages([createMessage("ENQUIRY_OPTIONS", "system", {})])
        );
      } else if (chatReason === "Technical Error") {
        await dispatch(
          addJourneyMessages([
            createMessage("TEXT", "system", {
              key: "SystemMessage.ThankYourForUsingService",
            }),
          ])
        );
      } else if (chatReason === "IMEI/Model Mismatch Hold") {
        await dispatch(
          addJourneyMessages([
            createMessage("DEVICE_CONFIRMATION", "system", {
              showComponent: "deviceHold",
            }),
          ])
        );
      }
      return;
    case "User Disconnected":
      dispatch(
        addJourneyMessages([
          createMessage("CHAT", "system", {
            showComponent: "UserDisconnectedMessage",
          }),
        ])
      );
      return;
    case "Agent Disconnected":
      dispatch(
        addJourneyMessages([
          createMessage("CHAT", "system", {
            showComponent: "AgentDisconnectedMessage",
          }),
        ])
      );
      return;
    default:
      dispatch(
        addJourneyMessages([
          createMessage("CHAT", "user", {
            showComponent: "UnResolved",
          }),
        ])
      );
      break;
  }
};

export const closeAgentBusy = (status) => async (dispatch) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));

  await sleep(3000);
  dispatch(endFlow());
};

export const retryChat = (status) => (dispatch) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));
  dispatch(udpateChatInputVisibility(true));
};
