import React from "react";
import PropTypes from "prop-types";

const BaseLabel = ({ children, className, ...restProps }) => {
  return (
    <div className={`app-BaseLabel${className ? ` ${className}` : ''}`} {...restProps}>
      {children}
    </div> 
  );
}

BaseLabel.propTypes = {
  className: PropTypes.string
};

export default BaseLabel;
