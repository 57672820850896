import {
  toggleFlowWindow,
  setApiProgress,
  resetApiProgress,
  setShowThankYouNote,
  setFlowProgress,
  resetFlowProgress,
  setShowServiceUnavailable,
} from "../reducers/journeyMessagesReducer";
import { COMPONENTS } from "../helpers/constants";
import { resetStore as chatReset } from "../modules/Appsync/state/reducers";
import { resetStore as geReset } from "../modules/RepairClaim/state/reducers/generalEnquiryReducer";
import { resetStore as validationReset } from "../modules/Validation/state/reducers";
import { resetStore as scheduleReset } from "../modules/RepairClaim/state/reducers/scheduleReducer";
import { resetStore as contactsReset } from "../modules/RepairClaim/state/reducers/contactsReducer";
import { resetStore as paymentReset } from "../modules/RepairClaim/state/reducers/paymentReducer";
import { resetStore as deviceReset } from "../modules/RepairClaim/state/reducers/deviceConfirmationReducer";
import { resetStore as termsReset } from "../modules/RepairClaim/state/reducers/termsAndConditionsReducer";
import { resetStore as troubleshootReset } from "../modules/RepairClaim/state/reducers/troubleshootingReducer";
import { resetStore as npsReset } from "../modules/RepairClaim/state/reducers/npsReducer";
import { resetStore as walkInReset } from "../modules/RepairClaim/state/reducers/walkInReducer";
import { addJourneyMessages } from "../helpers/addJourneyMessages";
import { createSystemMessage } from "../helpers/createMessage";

export const toggleFlowWindowVisibility = (show) => ({
  type: toggleFlowWindow.toString(),
  payload: show,
});

export const resetReduxStore = () => (dispatch) => {
  dispatch({ type: chatReset.toString() });
  dispatch({ type: geReset.toString() });
  dispatch({ type: validationReset.toString() });
  dispatch({ type: scheduleReset.toString() });
  dispatch({ type: contactsReset.toString() });
  dispatch({ type: paymentReset.toString() });
  dispatch({ type: deviceReset.toString() });
  dispatch({ type: termsReset.toString() });
  dispatch({ type: troubleshootReset.toString() });
  dispatch({ type: npsReset.toString() });
  dispatch({ type: walkInReset.toString() });
};

export const updateApiProgress = (text, percentage, uniqueId, initial = 0) => ({
  type: setApiProgress.toString(),
  payload: {
    text,
    percentage,
    initial,
    uniqueId,
    type: COMPONENTS.API_PROGRESS,
  },
});

export const clearApiProgress = () => ({
  type: resetApiProgress.toString(),
});

export const updateFlowProgress = (text, percentage, initial = 0) => ({
  type: setFlowProgress.toString(),
  payload: {
    text,
    percentage,
    initial,
  },
});

export const clearFlowProgress = () => ({
  type: resetFlowProgress.toString(),
});

export const endFlow = () => (dispatch) => {
  dispatch({
    type: setShowThankYouNote.toString(),
    payload: true,
  });
};

export const serviceUnavailable = () => async (dispatch) => {
  // dispatch({
  //   type: setShowServiceUnavailable.toString(),
  //   payload: true,
  // });
  await dispatch(clearApiProgress());
  dispatch(
    addJourneyMessages([
      createSystemMessage("SERVICE_UNAVAILABLE"),
    ])
  );
};
