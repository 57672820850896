import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import RequirementsIcon from "../../../images/icon-requirements.svg";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseInformationBox from "../../../base/BaseInformationBox";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import { TextLight, TextMedium } from "../../../base/BaseText";
import { proceedForDeviceConfirmation } from "../state/operators/deviceConfirmationOperators";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";
import { useSelector } from "react-redux";
import { CLIENT } from "../../../helpers/constants";
import { INCIDENT_BATTERY_REPLACEMENT } from "../../../components/EnquiryOptions/Constants";

const PrepareRequirements = () => {
  const { caseType } = useSelector((state) => state.journeyMessages);
  const t = useTranslate();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const submitRequirementPrepared = () => {
    dispatch(proceedForDeviceConfirmation(t("PrepareRequirements.ProceedBtn")));
    setDisabled(true);
  };

  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );

  let type = '';

  switch (enquiryOption) {
    case EnumEnquiryModules.NewRequest:
      type = t("EnquiryOptions.NewRequest");
      break;
    case EnumEnquiryModules.InWarrantyRequest:
      type = t("EnquiryOptions.In-WarrantyRequest");
      break;
    case EnumEnquiryModules.ResumeRequest:
      type = t("EnquiryOptions.ResumeRequest");
      break;
    case EnumEnquiryModules.NewRequestBatteryReplacement:
      type = t("StarterEnquiryOptions.NewRequestBatteryReplacement")
      break;
    default:
      type = t("EnquiryOptions.NewRequest");
  }

  const client = useSelector((state) => state.app.client);
  const isSingtel = client === CLIENT.SINGTEL;
  const { ClientProductSkuNbr } = useSelector((state) => state.validation?.verifyAgreement?.VerifyAgreementsResults?.Agreement);
  const isSingtelStarter = isSingtel && (ClientProductSkuNbr && ClientProductSkuNbr.toLowerCase().indexOf("starter") > -1)

  return (
    <div className="app-PrepareRequirements">
      <BaseCard>
        <BaseIcon icon={RequirementsIcon} name="Prepare Requirements" center />
        <BaseLabel>
          <TextMedium>{t("PrepareRequirements.Title")}</TextMedium>
        </BaseLabel>
        {!isSingtelStarter
          ? (
            <BaseLabel>
              <Translate i18nKey="PrepareRequirements.Labels.1">
                <TextLight>
                  You selected <TextMedium>{{ type }}</TextMedium>
                  .<br /> It will take 8 mins to finish this procedure.
                </TextLight>
              </Translate>
            </BaseLabel>
          )
          : (
            <BaseLabel>
              <Translate i18nKey="PrepareRequirements.Labels.1BatteryReplacement">
                <TextLight>
                  You selected <TextMedium>{{ type }}</TextMedium>
                  .<br /> It will take 8 mins to finish this procedure.
                </TextLight>
              </Translate>
            </BaseLabel>
          )
        }
        
        <BaseLabel>
          <Translate i18nKey="PrepareRequirements.Labels.2">
            <TextLight>
              Prepare the following requirements
              <br /> before we proceed to the next step.
            </TextLight>
          </Translate>
        </BaseLabel>
        <BaseInformationBox>
          <ul className="list-disc list-inside">
            <li>
              <Translate i18nKey="PrepareRequirements.Information.1">
                Have your device <TextMedium> IMEI number</TextMedium>
              </Translate>
            </li>
            <li>
              <Translate i18nKey="PrepareRequirements.Information.2">
                Please prepare your <TextMedium>credit card</TextMedium> or
                <TextMedium>debit card details</TextMedium>.
              </Translate>
            </li>
          </ul>
        </BaseInformationBox>
        <BaseButtonGroup>
          <BaseButton
            text={t("PrepareRequirements.ProceedBtn")}
            onClick={submitRequirementPrepared}
            disabled={disabled}
            clicked={disabled}
            autoFocus
          />
        </BaseButtonGroup>
      </BaseCard>
    </div>
  );
};

export default PrepareRequirements;
