import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ICPaymentMethodIcon from "../../../images/icon-payment-method.svg";
import { useEffect } from "react";
import { addJourneyMessages, sleep } from "../../../helpers/addJourneyMessages";
import { createUserMessage } from "../../../helpers/createMessage";
import { TextMedium, TextRegular } from "../../../base/BaseText";
import { useTranslate } from "../../../helpers/languageUtils";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { submitSelectedPaymentOption } from "../state/operators/paymentOperator";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseLabel from "../../../base/BaseLabel";

const CODForCC = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const handleClick = () => {
    setDisabled(true);
    dispatch(submitSelectedPaymentOption("COD", t("ProceedButton")));
  };

  const handleCloseClick = () => {
    setDisabled(true);
    dispatch(
      addJourneyMessages([createUserMessage("TEXT", t("CancelButton"))])
    );

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <BaseCard>
      <BaseIcon icon={ICPaymentMethodIcon} name="Payment Method" center />
      <BaseLabel>
        <TextRegular>{t("COD_FOR_CREDIT_CARD.Content")}</TextRegular>
      </BaseLabel>{" "}
      <br />
      <BaseButtonGroup>
        <BaseButton
          disabled={disabled}
          onClick={handleCloseClick}
          text={t("CancelButton")}
        />
        <BaseButton
          disabled={disabled}
          onClick={handleClick}
          text={t("ProceedButton")}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default CODForCC;
