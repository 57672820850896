import { createSlice } from "@reduxjs/toolkit";

let initialState = {};

const generalEnquiryReducer = createSlice({
  name: "generalEnquiry",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    callCreateGeneralEnquiryApi(state, action) {
      state = {};
    },
    callCreateGeneralEnquirySuccess(state, action) {
      state = action.payload;
    },
    callCreateGeneralEnquiryFailure(state, action) {
      state.error = action.payload;
    },
    callCreateChatEnquiryApi(state, action) {
      state.chatInquiry = action.payload;
    },
    callCreateChatEnquiryApiSuccess(state, action) {
      state.chatInquiry = action.payload;
    },
    callCreateChatEnquiryApiFailure(state, action) {
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = generalEnquiryReducer;
export const { resetStore, callCreateGeneralEnquiryApi, callCreateChatEnquiryApi} = actions;
export default reducer;
