import React, { useState } from "react";
import { validateName } from "./state/operators";
import { useDispatch, useSelector } from "react-redux";
import BaseCard from "./../../base/BaseCard/BaseCard";
import BaseIcon from "../../base/BaseIcon/BaseIcon";
import IdCardIcon from "./../../images/icon-id-card.svg";
import BaseLabel from "../../base/BaseLabel";
import BaseButtonGroup from "./../../base/BaseButton/BaseButtonGroup";
import BaseButton from "./../../base/BaseButton/BaseButton";
import BaseInput from "./../../base/BaseInput/BaseInput";
import { isEmpty } from "../../helpers/formatUtils";
import { FIELD_IDS, REGEX } from "../../helpers/constants";
import { useTranslate, Translate } from "../../helpers/languageUtils";

export default function AskName() {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [disabled, setDisabled] = useState(false);

  const client = useSelector((state) => state.app.client);

  const handleFirstName = (val) => {
    if (!val.match(REGEX.Text)) {
      setFirstName(val);
    }
  };

  const handleLastName = (val) => {
    if (!val.match(REGEX.Text)) {
      setLastName(val);
    }
  };

  const handleSubmit = () => {
    dispatch(validateName(firstName, lastName));
    setDisabled(true);
  };

  const shouldntSubmit = disabled || isEmpty(firstName) || isEmpty(lastName);

  return (
    <div>
      <BaseCard>
        <BaseIcon icon={IdCardIcon} name="Icon ID Card" center />
        <BaseLabel>
          <Translate i18nKey="AskName.Title" />
        </BaseLabel>
        <BaseLabel className="text-left my-0 mt-4 pl-3">
          <span className="font-extrabold">
            <Translate i18nKey="AskName.FirstNameLabel" />
          </span>
        </BaseLabel>
        <BaseInput
          id={FIELD_IDS.FIRST_NAME.replace("{{client}}", client)}
          placeholder={t(`AskName.FirstNamePlaceholder`)}
          value={firstName}
          medium
          onChange={handleFirstName}
          autoFocus
          disabled={disabled}
        />
        <BaseLabel className="text-left my-0 mt-4 pl-3">
          <span className="font-extrabold">
            <Translate i18nKey="AskName.LastNameLabel" />
          </span>
        </BaseLabel>
        <BaseInput
          id={FIELD_IDS.LAST_NAME.replace("{{client}}", client)}
          placeholder={t("AskName.LastNamePlaceholder")}
          value={lastName}
          medium
          onChange={handleLastName}
          disabled={disabled}
          onEnterPress={shouldntSubmit ? () => {} : handleSubmit}
        />
        <BaseButtonGroup>
          <BaseButton
            id={FIELD_IDS.SUBMIT_NAME.replace("{{client}}", client)}
            name="Submit"
            text={t("SubmitButton")}
            onClick={handleSubmit}
            disabled={shouldntSubmit}
            clicked={disabled}
            focused
          />
        </BaseButtonGroup>
      </BaseCard>
    </div>
  );
}
