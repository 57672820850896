import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseDropdown from "../../../base/BaseDropdown/BaseDropdown";
import BaseInput from "../../../base/BaseInput/BaseInput";
import VerifyPhoneIcon from "../../../images/icon-verify-phone.svg";
import { TextRegular } from "../../../base/BaseText";
import {
  submitChosenEnrolledDevice,
  getDeviceMakeModelOptions,
} from "../state/operators/deviceConfirmationOperators";
import { isEmpty } from "../../../helpers/formatUtils";
import { Translate, useTranslate } from "../../../helpers/languageUtils";

const ChooseEnrolledDevice = () => {
  const t = useTranslate();
  const dispatch = useDispatch();

  const deviceData = useSelector((state) => state.claim.deviceConfirmation);
  const { makeOptions, modelOptions } = deviceData;

  const [disabled, setDisabled] = useState(false);
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [imei, setImei] = useState("");
  const [isDeviceChosen, setIsDeviceChosen] = useState("");

  const handleChosenDevice = (isChosen) => {
    let deviceData = isChosen
      ? {
          make: makeOptions.find((m) => m.Make === make),
          model: modelOptions.find((m) => m.ModelId === model),
          imei,
        }
      : {};
    setIsDeviceChosen(isChosen ? "Submit" : "Cancel");
    dispatch(submitChosenEnrolledDevice(deviceData, t("CancelButton")));
    setDisabled(true);
  };

  useEffect(() => {
    dispatch(getDeviceMakeModelOptions());
  }, []);

  const handleMakeChange = (makeId) => {
    dispatch(getDeviceMakeModelOptions(makeId));
    setMake(makeId);
    setModel("");
    setImei("");
  };

  const handleModelChange = (modelId) => {
    setModel(modelId);
    setImei("");
  };

  const handleImeiChange = (value) => {
    if (!isNaN(value)) setImei(value);
  };

  return (
    <BaseCard>
      <BaseIcon icon={VerifyPhoneIcon} name="Choose device icon" center />
      <BaseLabel>
        <Translate i18nKey="ChooseEnrolledDevice.Title">
          <TextRegular>
            Please choose the correct
            <br /> enrolled device from this list
          </TextRegular>
        </Translate>
      </BaseLabel>
      <BaseLabel className="my-0 mt-4 pl-6 text-left">
        <span className="font-extrabold">
          <Translate i18nKey="ChooseEnrolledDevice.Label.DeviceMake" />
        </span>
      </BaseLabel>
      <BaseDropdown
        placeHolder={t("ChooseEnrolledDevice.Input.SelectMake")}
        value={make}
        onChange={handleMakeChange}
        options={makeOptions}
        disabled={disabled || isEmpty(makeOptions)}
        identifier="Make"
        printer="Make"
      />
      <BaseLabel className="my-0 mt-4 pl-6 text-left">
        <span className="font-extrabold">
          <Translate i18nKey="ChooseEnrolledDevice.Label.DeviceModel" />
        </span>
      </BaseLabel>
      <BaseDropdown
        placeHolder={t("ChooseEnrolledDevice.Input.SelectModel")}
        value={model}
        onChange={handleModelChange}
        options={modelOptions}
        disabled={disabled || isEmpty(modelOptions)}
        identifier="ModelId"
        printer="PopularName"
      />
      <BaseLabel className="my-0 mt-4 pl-6 text-left">
        <span className="font-extrabold">
          <Translate i18nKey="ChooseEnrolledDevice.Label.Imei" />
        </span>
      </BaseLabel>
      <BaseInput
        placeholder={t("ChooseEnrolledDevice.Input.EnterImei")}
        value={imei}
        onChange={handleImeiChange}
        className="w-25 mt-2 mb-4"
        maxLength={15}
        disabled={disabled || isEmpty(module)}
      />
      <BaseButtonGroup className="mt-6">
        <BaseButton
          text={t("CancelButton")}
          onClick={() => handleChosenDevice(false)}
          clicked={isDeviceChosen === "Cancel"}
          disabled={disabled}
        />
        <BaseButton
          text={t("SubmitButton")}
          onClick={() => handleChosenDevice(true)}
          clicked={isDeviceChosen === "Submit"}
          disabled={
            disabled || isEmpty(make) || isEmpty(model) || isEmpty(imei) || imei.length !== 15
          }
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ChooseEnrolledDevice;
