import React from "react";
import { TextMedium } from "../../base/BaseText";
import { Link } from "react-router-dom";
import { Translate } from "../../helpers/languageUtils";
import { CLIENT, LANGUAGE } from "../../helpers/constants";

const Footer = ({ client, language }) => {
  const privacyUrl =
    client === CLIENT.SINGTEL
      ? "https://www.asurion.com.sg/eng/privacy-policy/"
      : language === LANGUAGE.BAHASA
      // ? "https://www.celcom.com.my/sites/default/files/pdf/celcom_privacy_notice.pdf"
      // : "https://www.celcom.com.my/sites/default/files/pdf/celcom_privacy_notice.pdf";
      ? "https://www.celcomdigi.com/ms/terms-conditions/general"
      : "https://www.celcomdigi.com/terms-conditions/general";
      
      const isSingtel = client === CLIENT.SINGTEL;
  return (
    <footer className="text-2xl bg-black font-DINPro-Regular"
    //  style={{backgroundColor: isSingtel ? "#E6E6EB" : "black"}}
    style={{backgroundColor: isSingtel ? "black" : "#001871"}}
     >
      <div className="w-4/5 p-2 py-8 pt-10 ml-auto mr-auto text-white">
        <div>
          <ul className="flex pb-6 leading-4r">
            <li className="mr-8" 
            // style={{color: isSingtel ? "#333F48" : "white",fontSize: isSingtel ? "18px" : undefined,fontWeight:isSingtel ? "bold" : "normal"}}
            style={{color: "white", fontSize: isSingtel ? "18px" : undefined, fontWeight: isSingtel ? "bold" : "normal"}}
            >
              <Translate i18nKey="HomeBody.Footer.Privacy.Heading">
                <TextMedium>Privacy Policy</TextMedium>
              </Translate> 
            </li>
          </ul>
          <div className="text-srgray-tertiary text-1.3 font-DINPro-Light" 
          // style={{color: isSingtel ? "#6E767D" : "white",fontSize: isSingtel ? "14px" : undefined}}
          style={{color: "white", fontSize: isSingtel ? "14px" : undefined}}
          >
            <Translate i18nKey="HomeBody.Footer.Privacy.Information">
              <p>
                By using the services found on this website, you consent to NEW
                Asurion Singapore Pte Ltd. collecting, using and/or disclosing
                your personal data in accordance with the Asurion Privacy Policy
                <br />
              </p>
            </Translate>
            <TextMedium>
              <a
                href={privacyUrl}
                rel="noopener noreferrer"
                target="_blank"
                className="cursor-pointer hover:text-srgreen-secondary"
              >
                <Translate i18nKey="HomeBody.Footer.Privacy.Link" />
              </a>
            </TextMedium>
          </div>
        </div>
        <br></br>
        <hr className="" style={{borderColor: isSingtel ? "#A5AAAF" : "#E6E6EB"}}></hr>
        <div className="pt-8 ">
          <div>
            <ul className="flex pb-6 leading-4r">
              <li className="mr-8" 
              // style={{color: isSingtel ? "#333F48" : "white", fontWeight: isSingtel ? "bold" : "normal", fontSize: isSingtel ? "14px" : undefined}}
              style={{color: "white", fontWeight: isSingtel ? "bold" : "normal", fontSize: isSingtel ? "14px" : undefined}}
              >
                <Translate i18nKey="HomeBody.Footer.Heading">
                 <div style={{fontSize:isSingtel ? "14px" : undefined}}> 
                 <TextMedium>Legal Notices</TextMedium> 
                 </div>
                </Translate>
              </li>
            </ul>
            <ul className="pb-8 leading-10">
              <li className="text-xl">
                {isSingtel ? (
                  <Link
                    to={`${client}/terms-and-condition/${language}`}
                    target="_blank"
                  >
                    <div className={`cursor-pointer hover:text-srgreen-secondary ${isSingtel ? "text-default" : ""}`}
                      // style={{color: isSingtel ? "#333F48" : "white"}}
                      style={{ color: "white" }}
                    >
                      <Translate i18nKey="HomeBody.Footer.TermsAndCondition">
                        Terms and Conditions
                      </Translate>
                    </div>
                  </Link>
                ) : (
                  <a
                    className="cursor-pointer hover:text-srgreen-secondary"
                    href={privacyUrl}
                    target="_blank"
                    style={{ color: "white" }}
                  >
                    <Translate i18nKey="HomeBody.Footer.TermsAndCondition">
                        Terms and Conditions
                      </Translate>
                  </a>
                )}                
              </li>
              <li className="text-xl">
                <Link to={`${client}/terms-of-use/${language}`} target="_blank">
                  <div className={`cursor-pointer hover:text-srgreen-secondary ${isSingtel ? "text-default" :""}`} 
                  // style={{color: isSingtel ? "#333F48" : "white"}}
                  style={{color: "white"}}
                  >
                    <Translate i18nKey="HomeBody.Footer.TermsOfUse">
                      Website Terms of Use
                    </Translate>
                  </div>
                </Link>
              </li>
              <li className="text-xl">
                <Link to={`${client}/faq/${language}`} target="_blank">
                  <div className={`cursor-pointer hover:text-srgreen-secondary ${isSingtel ? "text-default" :""}`} 
                  // style={{color: isSingtel ? "#333F48" : "white"}}
                  style={{color: "white"}}
                  >
                    <Translate i18nKey="HomeBody.Footer.FAQ">FAQ</Translate>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div className={`text-srgray-tertiary ${isSingtel ? "text-default" : "text-1.3"}  font-DINPro-Light mb-12`} 
          // style={{color: isSingtel ? "#6E767D" : "white"}}
          style={{color: "white"}}
          >
            <Translate i18nKey="HomeBody.Footer.Information">
              <p>
                ©Asurion 2020. All Rights Reserved. The Asurion trademarks and
                logos are the property of Asurion, LLC. All rights reserved.
                Apple® and iPhone® are registered trademarks of Apple Inc. All
                other trademarks, service marks, and product brands that appear
                on this website are not owned by Asurion and are the property of
                their respective owners. Asurion is not affiliated with,
                sponsored by, or endorsed by Apple or any of the respective
                owners of the other trademarks, service marks and/or product
                brands cited.
                <br />
              </p>
            </Translate>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
