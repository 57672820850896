import React, { useState } from "react";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
// import agentIcon from "../../../images/icon-chat-agent.svg";
import agentIcon from "../../../images/img-agent.png";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { useDispatch } from "react-redux";
import { closeAgentBusy } from "../state/operators";
import BaseCard from "../../../base/BaseCard/BaseCard";
import { TextLight, TextMedium } from "../../../base/BaseText";
import BaseLabel from "../../../base/BaseLabel";
import { useTranslate } from "../../../helpers/languageUtils";

const AgentDisconnectedMessage = () => {
  const t = useTranslate();
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleCloseClick = () => {
    dispatch(closeAgentBusy(t("CloseButton")));
    setDisabled(true);
  };

  return (
    <BaseCard>
      <BaseIcon className="w-24 h-24" icon={agentIcon} name="Agent" center />
      <BaseLabel>
        <TextMedium>{t("Chat.TechnicalIsuue.Title")}</TextMedium>
      </BaseLabel>
      <div className="text-center text-default leading-7 text-srgray-primary">
        <TextLight>{t("Chat.TechnicalIsuue.Content")}</TextLight>
      </div>
      <BaseButtonGroup className="mt-6">
        <BaseButton
          text={t("CloseButton")}
          onClick={handleCloseClick}
          disabled={disabled}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default AgentDisconnectedMessage;
