import React, { useState } from "react";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import DeviceNotEligibleIcon from "../../../images/icon-device-not-eligible.svg";
import { TextMedium } from "../../../base/BaseText";
import BaseButton from "../../../base/BaseButton/BaseButton";
import Config from "../../Appsync/Config";
import { useSelector } from "react-redux";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { CLIENT } from "../../../helpers/constants";
import { INCIDENT_BATTERY_REPLACEMENT } from "../../../components/EnquiryOptions/Constants";

const DeviceNotEligible = () => {
  const { caseType } = useSelector((state) => state.journeyMessages);
  const [disabled, setDisabled] = useState(false);
  const t = useTranslate();
  const client = useSelector((state) => state.app.client);
  const isSingtel = client === CLIENT.SINGTEL;
  const ClientProductSkuNbr = useSelector((state) => state.validation?.verifyAgreement?.VerifyAgreementsResults?.Agreement?.ClientProductSkuNbr);
  const isSingtelStarter = isSingtel && (ClientProductSkuNbr && ClientProductSkuNbr.toLowerCase().indexOf("starter") > -1)
  const titleKey = isSingtelStarter 
  ? "TitleBatteryReplacement" 
  : caseType === INCIDENT_BATTERY_REPLACEMENT ? "TitleBatteryReplacement" : "Title";  

  const handleMoveToSmartSupport = () => {
    setDisabled(true);
    window.open(Config[client].SmartSupportLink);
  };

  return (
    <BaseCard>
      <BaseIcon
        icon={DeviceNotEligibleIcon}
        name="Device Not Eligible Icon"
        center
      />
      <BaseLabel className="text-1.3">
        <Translate i18nKey={`DeviceNotEligible.${titleKey}`}>
          <TextMedium>
            Sorry, your device is not eligible for Screen Repair. You may
            consider making a Service Request under Singtel MobileSwop.
          </TextMedium>
        </Translate>
      </BaseLabel>
      <BaseButton
        text={t("DeviceNotEligible.MoveToBtn")}
        onClick={handleMoveToSmartSupport}
        disabled={disabled}
        clicked={disabled}
        className="text-1.3"
        autoFocus
      />
    </BaseCard>
  );
};

export default DeviceNotEligible;
