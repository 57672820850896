import MIXPANEL from "./mixpanelActions";

const SendMixpanelEvent = (eventName, props = {}, callback) => {
    return async (dispatch, getStore) => {
        let attributes = { ...props };
        console.log('mixpanel' + eventName);
        try {
            MIXPANEL.track(eventName, attributes, callback);
        } catch (err) {
            console.log(err);
        }
    }
}

export default SendMixpanelEvent;