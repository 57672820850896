import React, { useState } from "react";
import classNames from "classnames";
import { TextBold } from "./BaseText";

const BaseTooltip = ({ title, text, children }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className="relative inline-block outline-none"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
      <div
        className={classNames(
          "right-1 p-4 rounded-sm absolute z-10 w-21r leading-7 shadow-md bg-gray-200 text-black text-1.3",
          {
            "visible absolute": hover,
            "invisible absolute": !hover,
          }
        )}
      >
        <TextBold>{title}</TextBold>
        <br />
        {text}
      </div>
    </div>
  );
};

export default BaseTooltip;
