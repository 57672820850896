import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseLabel from "../../../base/BaseLabel";
import BaseInput from "../../../base/BaseInput/BaseInput";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";

import ContactConfig from "./contactConfirmationConfig";
import { submitChangesInContactDetails } from "../state/operators/contactsOperator";
import { isEmpty } from "../../../helpers/formatUtils";
import { CLIENT, REGEX } from "../../../helpers/constants";
import { Translate, useTranslate } from "../../../helpers/languageUtils";

const ChangeContactDetails = ({ code }) => {
  const isMobile = code === "ChangeMobileNumber";
  const client = useSelector((state) => state.app.client);
  const [disabled, setDisabled] = useState(false);
  const [contact, setContact] = useState("");
  const t = useTranslate();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const textPrint = t("SubmitButton");
    setDisabled(true);
    dispatch(submitChangesInContactDetails(contact, isMobile, textPrint));
  };

  const handleChangeContact = (input) => {
    if (isMobile && isNaN(input)) {
      return;
    } else {
      setContact(input);
    }
  };

  const isInputValid = () => {
    if (isMobile) {
      return contact.length >= 8;
    } else {
      return REGEX.Email.test(contact);
    }
  };

  const shouldntSubmit = disabled || isEmpty(contact) || !isInputValid();

  return (
    <BaseCard>
      <BaseIcon icon={ContactConfig[code].icon} name="Contact" center />
      <BaseLabel>
        <Translate i18nKey={`ContactDetails.${code}.title`}>
          {ContactConfig[code].title}
        </Translate>
      </BaseLabel>
      <BaseInput
        placeholder={t(`ContactDetails.${code}.input`)}
        value={contact}
        onChange={(val) => handleChangeContact(val.trim())}
        maxLength={isMobile ? (client === CLIENT.CELCOM ? 11 : 8) : 100}
        medium
        autoFocus
      />
      <BaseButtonGroup>
        <BaseButton
          text={t("SubmitButton")}
          disabled={shouldntSubmit}
          clicked={disabled}
          onClick={handleSubmit}
          focused={true}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ChangeContactDetails.defaultProps = {
  code: "ChangeMobileNumber",
};

ChangeContactDetails.propTypes = {
  code: PropTypes.string.isRequired,
};

export default ChangeContactDetails;
