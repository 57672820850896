import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  endFlow,
  updateApiProgress,
  clearApiProgress,
} from "../../../../actions/flowWindow";
import updateVisitor from "../../../Appsync/updateVisitor";
import {
  ACTIVITY,
  API_PROGRESS,
  COMPONENTS,
} from "../../../../helpers/constants";
import { getSurvey, submitSurvey } from "../actions/suveryApi";

export const submitRequestForSurvey = () => async (dispatch) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", "Rate service")]));
  const module = API_PROGRESS.GET_SURVEY;
  const module_success = API_PROGRESS.GET_SURVEY_SUCCESS;
  const component = COMPONENTS.REQUEST_FEEDBACK;
  await dispatch(updateApiProgress(module, 40, component));
  await dispatch(getSurveyQuestions());
  await dispatch(updateApiProgress(module_success, 100, component, 40));
  await sleep(1000);
  await dispatch(clearApiProgress());
  dispatch(
    addJourneyMessages([
      createMessage("NPS", "system", { showComponent: "SubmitRating" }),
    ])
  );
};

export const submitRatingFromUser = (
  emoji,
  rating,
  questionId,
  surveyId
) => async (dispatch) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", emoji)]));
  dispatch(updateVisitor({ lastActivity: ACTIVITY.NPS_RATING }));
  const module = API_PROGRESS.SUBMIT_RATING;
  const module_success = API_PROGRESS.SUBMIT_RATING_SUCCESS;
  const component = COMPONENTS.SUBMIT_RATING;
  await dispatch(updateApiProgress(module, 40, component));
  await dispatch(submitSurveyAnswers(surveyId, questionId, rating));
  await dispatch(updateApiProgress(module_success, 100, component, 40));
  await sleep(1000);
  await dispatch(clearApiProgress());
  dispatch(
    addJourneyMessages([
      createMessage("NPS", "system", { showComponent: "SubmitFeedback" }),
    ])
  );
};

export const submitFeedbackFromUser = (
  surveyId,
  questionId,
  feedback
) => async (dispatch) => {
  dispatch(updateVisitor({ lastActivity: ACTIVITY.NPS_FEEDBACK }));
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", feedback)]));
  const module = API_PROGRESS.SUBMIT_FEEDBACK;
  const module_success = API_PROGRESS.SUBMIT_FEEDBACK_SUCCESS;
  const component = COMPONENTS.SUBMIT_FEEDBACK;
  await dispatch(updateApiProgress(module, 40, component));
  await dispatch(submitSurveyAnswers(surveyId, questionId, feedback));
  await dispatch(updateApiProgress(module_success, 100, component, 40));
  await sleep(1000);
  await dispatch(clearApiProgress());
  await sleep(5000);
  await dispatch(endFlow());
};

export const getSurveyQuestions = () => async (dispatch, getStore) => {
  const { CacheId } = getStore().session.sessionData.Configurations;
  const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;
  const {
    SessionData: { ClientId: clientId, LanguageCode: langCode },
  } = getStore().session.interactionData.Interaction;
  const triggerPoint = "SR_Creation",
    surveyType = "CSAT";
  await dispatch(
    getSurvey(
      clientId,
      ServiceRequestId,
      langCode,
      triggerPoint,
      surveyType
    )
  );
};

export const submitSurveyAnswers = (surveyId, questionId, answer) => async (
  dispatch,
  getStore
) => {
  const { visitorId } = getStore().chat.visitor;
  const {
    SessionData: { ClientId },
    InteractionId: interactionId,
  } = getStore().session.interactionData.Interaction;
  const { mdn } = getStore().validation.inputData;
  const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;
  const result = [
    {
      QuestionId: questionId,
      Answer: answer,
    },
  ];
  await dispatch(
    submitSurvey(
      visitorId,
      surveyId,
      ClientId,
      ServiceRequestId,
      mdn,
      interactionId,
      result
    )
  );
};
