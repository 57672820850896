import React from "react";
import { useSelector } from "react-redux";
import { TextLight } from "../../../base/BaseText";
import IncorrectCardIcon from "../../../images/icon-incorrect-card.svg";
import IncorrectScreen from "../../../components/IncorrectScreen";
import { Translate } from "../../../helpers/languageUtils";

const IncorrectCardDetails = () => {
  const paymentVerificationAttempt = useSelector(
    (state) => state.claim.payment.paymentVerificationAttempt
  );
  const messageKey =
    paymentVerificationAttempt > 3 ? "PaymentTerminate" : "Payment";

  return (
    <IncorrectScreen icon={IncorrectCardIcon} name="Incorrect Card">
      <Translate i18nKey={`IncorrectMessage.${messageKey}`}>
        <TextLight>
          We are unable to validate the card information provided. Please enter
          the details again or try using another card.
        </TextLight>
      </Translate>
    </IncorrectScreen>
  );
};

export default IncorrectCardDetails;
