const Config = {
  AppSync: {
    EndPoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    Region: process.env.REACT_APP_GRAPHQL_REGION,
    AuthenticationType: process.env.REACT_APP_GRAPHQL_AUTHENTICATION_TYPE,
  },
  Twilio: {
    ApiGatewayKey: process.env.REACT_APP_TWILIO_API_GATEWAY_KEY,
    TaskName: process.env.REACT_APP_TWILIO_TASK_NAME,
    EndPoint: process.env.REACT_APP_TWILIO_TASK_ENDPOINT,
    Region: process.env.REACT_APP_TWILIO_TASK_REGION,
    WorkflowId: process.env.REACT_APP_WORKFLOWS_ID,
    Timeout: 60,
    ChannelName: "chat",
    language: "en-US",
  },
  Cognito: {
    IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOLID,
    Region: process.env.REACT_APP_COGNITO_REGION,
    UserPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
    WebClientId: process.env.REACT_APP_COGNITO_USERPOOLWEBCLIENTID,
  },
  singtel: {
    WPAPIServer: {
      baseUrl: process.env.REACT_APP_WP_BASE_ENDPOINT,
      faqEndPoint: process.env.REACT_APP_WP_FAQ_ENDPOINT_SINGTEL,
      prepareEndPoint: process.env.REACT_APP_WP_PREPARE_ENDPOINT_SINGTEL,
      termsandConditionEndPoint: process.env.REACT_APP_WP_TANDC_ENDPOINT_SINGTEL,
      termsOfUseEndPoint: process.env.REACT_APP_WP_TOU_ENDPOINT_SINGTEL,
      mixpanel: process.env.REACT_APP_TOKEN_ID
    },
    Chat: {
      SkillId: "SINGTEL-SUR-CHAT",
    },
    Carrier: {
      Client: "Singtel",
      Country: "Singapore"
    },
    SmartSupportLink: process.env.REACT_APP_SMART_SUPPORT_LINK_SINGTEL,
    GTM: {
      gtmId: process.env.REACT_APP_GTM_ID
    }
  },
  celcom: {
    WPAPIServer: {
      baseUrl: process.env.REACT_APP_WP_BASE_ENDPOINT,
      faqEndPoint: process.env.REACT_APP_WP_FAQ_ENDPOINT_CELCOM,
      faqEndPoint_bs: process.env.REACT_APP_WP_FAQ_ENDPOINT_CELCOM_BS,
      prepareEndPoint: process.env.REACT_APP_WP_PREPARE_ENDPOINT_CELCOM,
      prepareEndPoint_bs: process.env.REACT_APP_WP_PREPARE_ENDPOINT_CELCOM_BS,
      termsandConditionEndPoint: process.env.REACT_APP_WP_TANDC_ENDPOINT_CELCOM,
      termsandConditionEndPoint_bs: process.env.REACT_APP_WP_TANDC_ENDPOINT_CELCOM_BS,
      termsOfUseEndPoint: process.env.REACT_APP_WP_TOU_ENDPOINT_CELCOM,
      termsOfUseEndPoint_bs: process.env.REACT_APP_WP_TOU_ENDPOINT_CELCOM_BS,
    },
    Chat: {
      SkillId: "CELCOM-SUR-CHAT",
    },
    Carrier: {
      Client: "Celcom",
      Country: "Malaysia"
    },
    SmartSupportLink: process.env.REACT_APP_SMART_SUPPORT_LINK_CELCOM,
    GTM: {
      gtmId: process.env.REACT_APP_GTM_ID_CELCOM
    }
  },
  TaskService: {
    baseUrlSEA: process.env.REACT_APP_TS_BASE_ENDPOINT_SEA,
    apiKey: process.env.REACT_APP_API_KEY_ASURION,
    env: process.env.REACT_APP_ENV
  }
};

export default Config;
