import React from "react";

const BaseButtonGroup = ({ children, className, ...restProps }) => {
    return (
        <div className={`app-BaseButtonGroup${className ? ` ${className}` : ''}`} {...restProps}>
            {children}
        </div>
    );
};

export default BaseButtonGroup;
