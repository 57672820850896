import initializeApi from "./initializeApi";
import interactionApi from "./interactionApi";
import { addJourneyMessages } from "./../helpers/addJourneyMessages";
import createMessage from "./../helpers/createMessage";
import createVisitor from "../modules/Appsync/createVisitor";
import { serviceUnavailable } from "./flowWindow";
import { isEmpty } from "../helpers/formatUtils";
import Config from "../modules/Appsync/Config";
import { LANGUAGE } from "../helpers/constants";
import getBusinessHours from "./businessHoursApi";
export const initProcessFlow = () => async (dispatch, getStore) => {
  // const session = getStore().session;
  // const {CacheId, UserName = "User"} = session && session.sessionData && session.sessionData.Configurations;
  //call initialize api and interaction api
  const client = getStore().app.client;
  const language = getStore().app.language;
  const langText = language === LANGUAGE.BAHASA ? "ms-MY" : language === LANGUAGE.ENGLISH ? "en-US" : language;
  const CLIENT_NAME = Config[client].Carrier.Client;
  let initializeResponse = await dispatch(initializeApi(CLIENT_NAME, langText)).catch((err) =>
    dispatch(serviceUnavailable())
  );

  // not allowing to go ahead if response is empty
  if (isEmpty(initializeResponse)) {
    return;
  }

  const { CacheId, UserName = "User" } = initializeResponse.Configurations;
  await dispatch(interactionApi(CacheId, UserName, langText)).catch((err) =>
    dispatch(serviceUnavailable())
  );

  await dispatch(getBusinessHours(CLIENT_NAME)).catch((err) =>
    dispatch(serviceUnavailable())
  );

  await dispatch(createVisitor()).catch((err) =>
    dispatch(serviceUnavailable())
  );

  //configure appsync and twilio and then show journey message
  dispatch(
    addJourneyMessages([
      createMessage("WELCOME_TO_SERVICE", "system"),
      createMessage("VALIDATION", "system", { showComponent: "mdn" }),
    ])
  );
};
