import {
  callDetermineRequestApi,
  callCreateServiceRequestApi,
  callCancelServiceRequestApi,
  callResumeServiceRequestApi,
  callCaseGraphApi
} from "../reducers/serviceRequestReducer";

export const determineRequest = (cacheId) => ({
  type: callDetermineRequestApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/servicerequest/v1/determine",
    method: "POST",
    body: {
      DetermineIncidentPaths: {
        SessionId: cacheId,
      },
    },
  },
});

export const createServiceRequest = (cacheId) => ({
  type: callCreateServiceRequestApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/servicerequest/v1/create",
    method: "POST",
    body: {
      CreateServiceRequestParams: {
        SessionId: cacheId,
      },
    },
  },
});

export const cancelServiceRequest = (cacheId, requestId, note, SubReason, customerCaseId, serviceOrderId, cancelAction) => ({
  type: callCancelServiceRequestApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/servicerequest/v1/cancel",
    method: "POST",
    body: {
      CancelServiceRequestParams: {
        SessionId: cacheId,
        ServiceRequestId: requestId,
        NoteText: note,
        Operation: "Cancel",
        Reason: "Cancelled by user",
        RequestedBy: "Customer",
        SubReason: SubReason ? SubReason : "Cancelled by user",
        CancelEntities: "ALL",
        UpdatedBy: "Customer",
        ReturnEntity: true,
        CustomerCaseId: customerCaseId,
        ServiceOrderId: serviceOrderId,
        CancelAction: cancelAction
      },
    },
  },
});

export const resumeServiceRequest = (cacheId, requestId) => ({
  type: callResumeServiceRequestApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/servicerequest/v1/resume",
    method: "POST",
    body: {
      ResumeServiceRequestParameters: {
        SessionId: cacheId,
        ServiceRequestId: requestId,
      },
    },
  },
});

export const caseGraphApi = (
  sessionId) => ({
  type: callCaseGraphApi.toString(),
  meta: {
      async: true,
      blocking: true,
      path: "/caseadministration/v1/customercasegraph",
      method: "POST",
      body: {
          CustomerCaseGraphInput: {
              SessionId: sessionId
          }
      },
  },
});
