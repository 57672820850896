import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  PROGRESS_DATA,
  ACTIVITY,
  API_PROGRESS,
  COMPONENTS,
  CLIENT,
} from "../../../../helpers/constants";
import {
  updateFlowProgress,
  updateApiProgress,
  clearApiProgress,
  serviceUnavailable,
} from "../../../../actions/flowWindow";
import updateVisitor from "../../../Appsync/updateVisitor";
import { initializePickupSchedule } from "./scheduleOperator";
import {
  getFulFillmentApi,
  setFulFillmentApi,
} from "../../../../actions/fulfillment";
import { confirmIfRequestCollected } from "./cancelRequestOperator";
import processIncidentApi from "../actions/processIncident";
import { isEmpty } from "../../../../helpers/formatUtils";
import { getServiceFee } from "../../../../actions/cowrapperapi";

import { 
  INCIDENT_FAULT_REPAIR,
  INCIDENT_SCREEN_REPAIR,
  INCIDENT_SCREEN_REPAIR_WARRANTY, 
  INCIDENT_BATTERY_REPLACEMENT
} from "../../../../components/EnquiryOptions/Constants";

const hideProgressBar = () => async (dispatch, getStore) => {
  await dispatch(
    updateApiProgress(
      API_PROGRESS.TROUBLESHOOT_SUCCESS,
      100,
      COMPONENTS.TERMS_N_CONDITIONS,
      40
    )
  );
  await sleep(1000);
  await dispatch(clearApiProgress());
};

export const submitUserAcceptanceOnTerms = (status, textPrint, isInWarranty) => async (
  dispatch,
  getStore
) => {
  const isCustomerAgreed = status === "Accept";
  let text = "";
  if (isCustomerAgreed) {
    text = "I accept Terms & Conditions";

    dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));

    const { selectedAsset } = getStore().claim.deviceConfirmation;
    const { CacheId } = getStore().session.sessionData.Configurations;
    const { triageDetails, failureDescriptiveText } = getStore().claim.troubleshoot;
    const { caseType } = getStore().journeyMessages;

    const { isDeviceAppleSamsung = false } = getStore().claim?.deviceConfirmation;

    console.log("termsAndConditionOperator isDeviceAppleSamsung", isDeviceAppleSamsung)


    const {
      ServiceRequestId,
      CustomerCaseId,
    } = getStore().serviceRequest.serviceRequestDetails;
    const client = getStore().app.client;
    const isSingtel = client === CLIENT.SINGTEL;
    let clientCaseType = isSingtel ? caseType : INCIDENT_FAULT_REPAIR;

    console.log("submitUserAcceptanceOnTerms getStore().app.client", getStore().app.client)
    console.log("submitUserAcceptanceOnTerms caseType", caseType)
    console.log("submitUserAcceptanceOnTerms clientCaseType", clientCaseType)

    const ClientProductSkuNbr = getStore().validation?.verifyAgreement?.VerifyAgreementsResults?.Agreement?.ClientProductSkuNbr;
    const isSingtelStarter = isSingtel && (ClientProductSkuNbr && ClientProductSkuNbr.toLowerCase().indexOf("starter") > -1)

    let details = {
      key: "TERMS_CHECKED",
      Ans: true,
    };

    const screenRepairQa = triageDetails.concat(details);

    await dispatch(
      updateApiProgress(
        isSingtelStarter 
        ? API_PROGRESS.TROUBLESHOOTBATTERY 
        : caseType === INCIDENT_BATTERY_REPLACEMENT ? API_PROGRESS.TROUBLESHOOTBATTERY : API_PROGRESS.TROUBLESHOOT,
        40,
        COMPONENTS.TERMS_N_CONDITIONS
      )
    );

    const singtelStarterBatteryReplacementQA = [
      {
        "key": "BatteryIndicator",
        "value": true,
        "question": "Is your device battery health less than 80%?"
      },
      {
        "key": "DeviceBloating",
        "value": true,
        "question": "Is your device exposed to any liquid or is bent or bloated?"
      },
      {
        "key": "PhysicalDamage",
        "value": true,
        "question": "Is your device screen cracked or physically damaged?"
      },
      {
        "key": "LiquidExposure",
        "value": true,
        "question": "Is your device exposed to any liquid or is bent or bloated?"
      },
      {
        "key": "PowerOn",
        "value": true,
        "question": "Is your device able to be powered on?"
      },
      {
        "key": "CameraWorking",
        "value": true,
        "question": "Is your device's rear camera functioning?"
      },
      {
        "key": "TERMS_CHECKED",
        "Ans": true
      }
    ]

    const repairQA = isSingtelStarter 
    ? singtelStarterBatteryReplacementQA 
    : caseType === INCIDENT_BATTERY_REPLACEMENT ? singtelStarterBatteryReplacementQA : screenRepairQa;

    const processIncidentResponse = await dispatch(
      processIncidentApi(
        CacheId,
        CustomerCaseId,
        ServiceRequestId,
        clientCaseType,
        selectedAsset,
        repairQA,
        failureDescriptiveText
      )
    ).catch((err) => dispatch(serviceUnavailable()));

    // not allowing to go ahead if response is empty
    if (isEmpty(processIncidentResponse)) {
      return;
    }

    let {
      CustomerCase,
      CustomerCase: {
        Eligibility: {
          EligibilityOutcome = "",
          EligibilityCriteriaStatus = "",
        } = {},
        ServiceRequest: {
          WarrantyType = ""
        } = {} ,
      } = {},
    } = processIncidentResponse.CreateIncidentResults;

    if (
      EligibilityOutcome === "Rejected" ||
      EligibilityOutcome === "Void" ||
      EligibilityOutcome === "VOID" || 
      // for celcom we check manually here for IW request
      // if IW selected and WarrantyType in response is not IW then request is in-eligible 
      (isInWarranty && WarrantyType !== "IW")
    ) {
      await dispatch(
        updateApiProgress(
          isSingtelStarter 
          ? API_PROGRESS.TROUBLESHOOTBATTERY_SUCCESS 
          : caseType === INCIDENT_BATTERY_REPLACEMENT ? API_PROGRESS.TROUBLESHOOTBATTERY_SUCCESS : API_PROGRESS.TROUBLESHOOT_SUCCESS,
          100,
          COMPONENTS.TERMS_N_CONDITIONS,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      // device in-eligible

      dispatch(
        addJourneyMessages([
          createMessage("DEVICE_CONFIRMATION", "system", {
            showComponent: "deviceNotEligible",
            textPrint,
          }),
        ])
      );
    } else if (CustomerCase.Holds.length > 0) {
      await dispatch(
        updateApiProgress(
          isSingtelStarter 
          ? API_PROGRESS.TROUBLESHOOTBATTERY_SUCCESS 
          : caseType === INCIDENT_BATTERY_REPLACEMENT ? API_PROGRESS.TROUBLESHOOTBATTERY_SUCCESS : API_PROGRESS.TROUBLESHOOT_SUCCESS,
          100,
          COMPONENTS.TERMS_N_CONDITIONS,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      // device hold
      dispatch(
        addJourneyMessages([
          createMessage("DEVICE_CONFIRMATION", "system", {
            showComponent: "deviceHold",
            textPrint,
          }),
        ])
      );
    } else {
      // let encodedPerilString = encodeURIComponent(`\"${INCIDENT_FAULT_REPAIR.toUpperCase()}\",\"${INCIDENT_SCREEN_REPAIR.toUpperCase()}\",\"${INCIDENT_SCREEN_REPAIR_WARRANTY.toUpperCase()}\"`);
      let encodedPerilString = encodeURIComponent(`\"${caseType.toUpperCase()}\"`);
      dispatch(getServiceFee(CacheId, ServiceRequestId, encodedPerilString));

      let showComponent, module, flowTitle, flowCount;

      // if (client === CLIENT.SINGTEL) {
      //   let getFulFillmentApiResponse = await dispatch(
      //     getFulFillmentApi(CacheId, ServiceRequestId)
      //   ).catch((err) => dispatch(serviceUnavailable()));

      //   // not allowing to go ahead if response is empty
      //   if (isEmpty(getFulFillmentApiResponse)) {
      //     return;
      //   }

      //   let setFulFillmentApiResponse = await dispatch(
      //     setFulFillmentApi(CacheId, ServiceRequestId, "PUR")
      //   ).catch((err) => dispatch(serviceUnavailable()));

      //   // not allowing to go ahead if response is empty
      //   if (isEmpty(setFulFillmentApiResponse)) {
      //     return;
      //   }

      //   await dispatch(initializePickupSchedule());

      //   module = "PICKUP_AND_DELIVERY";
      //   showComponent = "changePickupAddress"

      //   flowTitle = PROGRESS_DATA.NEW_PICKUP_ADDRESS.title
      //   flowCount = PROGRESS_DATA.NEW_PICKUP_ADDRESS.count

      // } else {
      //   module = "SELECT_REPAIR_OPTION";
      //   showComponent = "SelectRepairOption";

      //   flowTitle = PROGRESS_DATA.SELECT_REPAIR_OPTIONS.title;
      //   flowCount = PROGRESS_DATA.SELECT_REPAIR_OPTIONS.count;
      // }

      let getFulFillmentApiResponse = await dispatch(
        getFulFillmentApi(CacheId, ServiceRequestId)
      ).catch((err) => dispatch(serviceUnavailable()));

      // not allowing to go ahead if response is empty
      if (isEmpty(getFulFillmentApiResponse)) {
        return;
      }

      let setFulFillmentApiResponse = await dispatch(
        setFulFillmentApi(CacheId, ServiceRequestId, "PUR")
      ).catch((err) => dispatch(serviceUnavailable()));

      // not allowing to go ahead if response is empty
      if (isEmpty(setFulFillmentApiResponse)) {
        return;
      }

      module = "SELECT_REPAIR_OPTION";
      showComponent = "SelectRepairOption";

      flowTitle = PROGRESS_DATA.SELECT_REPAIR_OPTIONS.title;
      flowCount = PROGRESS_DATA.SELECT_REPAIR_OPTIONS.count;

      await dispatch(
        updateApiProgress(
          isSingtelStarter 
          ? API_PROGRESS.TROUBLESHOOTBATTERY_SUCCESS 
          : caseType === INCIDENT_BATTERY_REPLACEMENT ? API_PROGRESS.TROUBLESHOOTBATTERY_SUCCESS : API_PROGRESS.TROUBLESHOOT_SUCCESS,
          100,
          COMPONENTS.TERMS_N_CONDITIONS,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      dispatch(
        addJourneyMessages([
          createMessage(module, "system", {
            showComponent,
          }),
        ])
      );

      const initial = getStore().journeyMessages.flowProgress.percentage;
      dispatch(updateFlowProgress(flowTitle, flowCount, initial));
      dispatch(updateVisitor({ lastActivity: ACTIVITY.TERMS_N_CONDITIONS }));
    }
  } else {
    text = "I decline Terms & Conditions";

    dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));

    dispatch(confirmIfRequestCollected("", "T&C declined"));
  }
};
