import React from "react";
import { TextLight, TextMedium } from "../base/BaseText";
import BaseIcon from "../base/BaseIcon/BaseIcon";
import CloseIcon from "../images/icon-close-green.svg";
import ThankYouImageSingtel from "../images/image-thank-you.svg";
import ThankYouImageCelcom from "../images/image-thank-you-celcom.svg";
import CarrierLogo from "../images/logo.svg";
import { Translate, useTranslate } from "../helpers/languageUtils";
import { CLIENT } from "../helpers/constants";
import { useSelector } from "react-redux";
import BaseButtonGroup from "../base/BaseButton/BaseButtonGroup";
import BaseButton from "../base/BaseButton/BaseButton";

const ThankYouNote = ({ onClose }) => {
  const t = useTranslate();
  const client = useSelector((state) => state.app.client);
  const thankyouImage =
    client === CLIENT.SINGTEL ? [ThankYouImageSingtel] : [ThankYouImageCelcom];
  return (
    <div className="w-full h-full bg-srgray-thankYou flex flex-col overflow-auto">
      
      <BaseIcon icon={CarrierLogo} name="Starhub" center />
      <div className="mx-auto text-3xl mt-8 text-center">
        <Translate i18nKey="ThankYouNote">
          <TextLight>
            Thank you for using
            <br /> <TextMedium> Screen Repair Service! </TextMedium>
          </TextLight>
        </Translate>
      </div>
      <BaseButtonGroup className="mt-6">
        <BaseButton
          text={t("CloseButton")}
          onClick={onClose}
        />
      </BaseButtonGroup>
      <img className="w-full p-0 bottom-0 object-cover" src={thankyouImage} />
    </div>
  );
};

export default ThankYouNote;
