const CONSTANTS = {
  INTERACTION_TYPE: "ONLINE",
  INTERACTION_STATUS: "INPRGRS",
  CHANNEL_NAME: "ONLINE",
  CLIENT_PROFILE_ID: "6040",
  EXTERNAL_SYSTEM_NAME: "ConsoleOne",
  INTERACTION_LINE_REASON: "STRTSR",
  ONLINE_JOURNEY_STATUS_LIST: {
    STARTED: "Started",
    COMPLETED: "Completed",
    INPROGRESS: "In Progress",
    FAILURE: "Failure",
  },
  ENGAGEMENT_TYPE: "SUR",
  REQUEST_TYPE: "ScreenRepair",
};

export default CONSTANTS;

export const API_PROGRESS = {
  ASK_MDN: "ASK_MDN",
  ASK_MDN_SUCCESS: "ASK_MDN_SUCCESS",
  ASK_NAME: "ASK_NAME",
  ASK_NAME_SUCCESS: "ASK_NAME_SUCCESS",
  ASK_NRIC: "ASK_NRIC",
  ASK_NRIC_SUCCESS: "ASK_NRIC_SUCCESS",
  VERIFY_IMEI: "VERIFY_IMEI",
  VERIFY_IMEI_SUCCESS: "VERIFY_IMEI_SUCCESS",
  CREATE_SR: "CREATE_SR",
  CREATE_SR_SUCCESS: "CREATE_SR_SUCCESS",
  VERIFY_ADDRESS: "VERIFY_ADDRESS",
  VERIFY_ADDRESS_SUCCESS: "VERIFY_ADDRESS_SUCCESS",
  UPDATE_PICKUP_ADDRESS: "UPDATE_PICKUP_ADDRESS",
  UPDATE_PICKUP_ADDRESS_SUCCESS: "UPDATE_PICKUP_ADDRESS_SUCCESS",
  UPDATE_DELIVERY_ADDRESS: "UPDATE_DELIVERY_ADDRESS",
  UPDATE_DELIVERY_ADDRESS_SUCCESS: "UPDATE_DELIVERY_ADDRESS_SUCCESS",
  UPDATE_SCHEDULE: "UPDATE_SCHEDULE",
  UPDATE_SCHEDULE_SUCCESS: "UPDATE_SCHEDULE_SUCCESS",
  CONFIRM_ADDRESS: "CONFIRM_ADDRESS",
  CONFIRM_ADDRESS_SUCCESS: "CONFIRM_ADDRESS_SUCCESS",
  TROUBLESHOOT: "TROUBLESHOOT",
  TROUBLESHOOT_SUCCESS: "TROUBLESHOOT_SUCCESS",
  TROUBLESHOOTBATTERY: "TROUBLESHOOTBATTERY",
  TROUBLESHOOTBATTERY_SUCCESS: "TROUBLESHOOTBATTERY_SUCCESS",
  PAYMENT: "PAYMENT",
  PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
  COD: "COD",
  COD_SUCCESS:"COD_SUCCESS",
  REQUEST_SUBMIT: "REQUEST_SUBMIT",
  REQUEST_SUBMIT_SUCCESS: "REQUEST_SUBMIT_SUCCESS",
  UPDATE_CONTACT_NUMBER: "UPDATE_CONTACT_NUMBER",
  UPDATE_CONTACT_NUMBER_SUCCESS: "UPDATE_CONTACT_NUMBER_SUCCESS",
  UPDATE_CONTACT_EMAIL: "UPDATE_CONTACT_EMAIL",
  UPDATE_CONTACT_EMAIL_SUCCESS: "UPDATE_CONTACT_EMAIL_SUCCESS",
  RESUME_REQUEST: "RESUME_REQUEST",
  RESUME_REQUEST_SUCCESS: "RESUME_REQUEST_SUCCESS",
  CREATE_ENQUIRY: "CREATE_ENQUIRY",
  CREATE_ENQUIRY_SUCCESS: "CREATE_ENQUIRY_SUCCESS",
  GET_SURVEY: "GET_SURVEY",
  GET_SURVEY_SUCCESS: "GET_SURVEY_SUCCESS",
  SUBMIT_RATING: "SUBMIT_RATING",
  SUBMIT_RATING_SUCCESS: "SUBMIT_RATING_SUCCESS",
  SUBMIT_FEEDBACK: "SUBMIT_FEEDBACK",
  SUBMIT_FEEDBACK_SUCCESS: "SUBMIT_FEEDBACK_SUCCESS",
  GET_STORE_LIST: "GET_STORE_LIST",
  GET_STORE_LIST_SUCCESS: "GET_STORE_LIST_SUCCESS",
  GET_APPOINTMENT_DETAILS: "GET_APPOINTMENT_DETAILS",
  GET_APPOINTMENT_DETAILS_SUCCESS: "GET_APPOINTMENT_DETAILS_SUCCESS",
  UPDATE_WALKIN_SCHEDULE: "UPDATE_WALKIN_SCHEDULE",
  UPDATE_WALKIN_SCHEDULE_SUCCESS: "UPDATE_WALKIN_SCHEDULE_SUCCESS"
};

export const COMPONENTS = {
  ASK_MDN: "ASK_MDN",
  ASK_NAME: "ASK_NAME",
  ASK_NRIC: "ASK_NRIC",
  ENQUIRY_OPTIONS: "ENQUIRY_OPTIONS",
  CONFIRM_DEVICE: "CONFIRM_DEVICE",
  VERIFY_IMEI: "VERIFY_IMEI",
  API_PROGRESS: "API_PROGRESS",
  FLOW_PROGRESS: "FLOW_PROGRESS",
  UPDATE_ADDRESS: "UPDATE_ADDRESS",
  UPDATE_SCHEDULE: "UPDATE_SCHEDULE",
  CONFIRM_ADDRESS: "CONFIRM_ADDRESS",
  TROUBLESHOOT: "TROUBLESHOOT",
  PAYMENT: "PAYMENT",
  CONFIRM_REQUEST_DETAILS: "CONFIRM_REQUEST_DETAILS",
  TERMS_N_CONDITIONS: "TERMS_N_CONDITIONS",
  CONFIRM_CONTACT: "CONFIRM_CONTACT",
  CONFIRM_SCHEDULE: "CONFIRM_SCHEDULE",
  UPDATE_CONTACT: "UPDATE_CONTACT",
  CREATE_ENQUIRY: "CREATE_ENQUIRY",
  REQUEST_FEEDBACK: "REQUEST_FEEDBACK",
  SUBMIT_RATING: "SUBMIT_RATING",
  SUBMIT_FEEDBACK: "SUBMIT_FEEDBACK",
  STORE_LIST: "STORE_LIST"
};

export const PROGRESS_DATA = {
  ASK_MDN: {
    title: "ASK_MDN",
    count: 0,
  },
  ASK_NAME: {
    title: "ASK_NAME",
    count: 15,
  },
  ASK_NRIC: {
    title: "ASK_NRIC",
    count: 30,
  },
  ENQUIRY_OPTIONS: {
    title: "ENQUIRY_OPTIONS",
    count: 48,
  },
  PREPARE_REQUIREMENTS: {
    title: "PREPARE_REQUIREMENTS",
    count: 48,
  },
  CONFIRM_DEVICE: {
    title: "CONFIRM_DEVICE",
    count: 38,
  },
  VERIFY_IMEI: {
    title: "VERIFY_IMEI",
    count: 58,
  },
  TROUBLESHOOT: {
    title: "TROUBLESHOOT",
    count: 63,
  },
  TERMS_N_CONDITIONS: {
    title: "TERMS_N_CONDITIONS",
    count: 70,
  },
  SELECT_REPAIR_OPTIONS: {
    title: "SELECT_REPAIR_OPTIONS",
    count: 70,
  },
  WALK_IN: {
    title: "WALK_IN",
    count: 75,
  },
  PUR: {
    title: "PUR",
    count: 75,
  },
  SELECT_APPOINTMENT: {
    title: "SELECT_APPOINTMENT",
    count: 80,
  },
  CONFIRM_APPOINTMENT: {
    title: "CONFIRM_APPOINTMENT",
    count: 85,
  },
  CONFIRM_PICKUP_ADDRESS: {
    title: "CONFIRM_PICKUP_ADDRESS",
    count: 80,
  },
  NEW_PICKUP_ADDRESS: {
    title: "NEW_PICKUP_ADDRESS",
    count: 80,
  },
  CONFIRM_PICKUP_DATE_TIME: {
    title: "CONFIRM_PICKUP_DATE_TIME",
    count: 80,
  },
  NEW_PICKUP_DATE_TIME: {
    title: "NEW_PICKUP_DATE_TIME",
    count: 80,
  },
  CONFIRM_RETURN_ADDRESS: {
    title: "CONFIRM_RETURN_ADDRESS",
    count: 83,
  },
  NEW_RETURN_ADDRESS: {
    title: "NEW_RETURN_ADDRESS",
    count: 83,
  },
  CONFIRM_RETURN_DATE_TIME: {
    title: "CONFIRM_RETURN_DATE_TIME",
    count: 83,
  },
  CHANGE_RETURN_DATE_TIME: {
    title: "CHANGE_RETURN_DATE_TIME",
    count: 83,
  },
  CONFIRM_SCHEDULE: {
    title: "CONFIRM_SCHEDULE",
    count: 88,
  },
  CONFIRM_CONTACT: {
    title: "CONFIRM_CONTACT",
    count: 93,
  },
  PAYMENT: {
    title: "PAYMENT",
    count: 96,
  },
  CONFIRM_REQUEST_DETAILS: {
    title: "CONFIRM_REQUEST_DETAILS",
    count: 98,
  },
  REQUEST_SUBMITTED: {
    title: "REQUEST_SUBMITTED",
    count: 100,
  },
  FEEDBACK: {
    title: "FEEDBACK",
    count: 100,
  },
};

export const DEVICE = {
  APPLE: "APPLE",
  SAMSUNG: "SAMSUNG",
  HUAWEI: "HUAWEI",
  OPPO: "OPPO",
  XIAOMI: "XIAOMI",
  REALME: "REALME",
  HONOR: "HONOR"
};

export const CARD = {
  VISA: "VISA",
  MASTER: "MASTER",
  AMEX: "AMEX",
};

export const REGEX = {
  Email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  Visa: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
  Amex: /^(?:3[47][0-9]{13})$/,
  MasterCard: /^(?:5[1-5][0-9]{14})$/,
  Text: /[^\u0E00-\u0E7Fa-zA-Z' ]|^'|'$|''/g,
};

export const IFRAME = {
  EVENT_NAME: "message",
  OPEN_CHAT: "openchat",
};

export const ACTIVITY = {
  ASK_MDN: "MDN Verified",
  ASK_NAME: "Name Verified",
  ASK_NRIC: "NRIC Verified",
  ENQUIRY_OPTIONS: "Enquiry option selected",
  CREATE_ENQUIRY: "New Enquiry created",
  CONFIRM_DEVICE: "Device confirmed",
  VERIFY_IMEI: "IMEI Verified",
  UPDATE_PICKUP_ADDRESS: "Pickup Address updated",
  UPDATE_DELIVERY_ADDRESS: "Delivery Address updated",
  UPDATE_PICKUP_SCHEDULE: "Pickup Schedule date/time updated",
  UPDATE_DELIVERY_SCHEDULE: "Delivery Schedule date/time updated",
  CONFIRM_SCHEDULE: "Pickup Return schedule confirmed",
  TROUBLESHOOT: "Troubleshoot questions submitted",
  PAYMENT: "Payment initiated",
  CONFIRM_REQUEST_DETAILS: "Repair request details confirmed",
  TERMS_N_CONDITIONS: "Agreed to terms and conditions",
  CONFIRM_CONTACT_NUMBER: "Contact number confirmed",
  UPDATE_CONTACT_NUMBER: "Contact number updated",
  CONFIRM_CONTACT_EMAIL: "Email Address confirmed",
  UPDATE_CONTACT_EMAIL: "Email Address updated",
  NEW_REQUEST: "Service Request Created",
  SUBMIT_REQUEST: "Service Request Submitted",
  CANCEL_REQUEST: "Service Request Cancelled",
  RESUME_REQUEST: "Service Request Resumed",
  NPS_RATING: "NPS Rating Submitted",
  NPS_FEEDBACK: "Feedbacl Submitted",
  CANCEL_DP_REQUEST: "DP Service Request Cancelled",
};

export const CLIENT = {
  SINGTEL: "singtel",
  CELCOM: "celcom",
  MY_CELCOM: "MY",
};

export const LANGUAGE = {
  ENGLISH: "en",
  BAHASA: "my",
};

export const INCIDENT_PATH_TYPE = {
  START_SERVICE_REQUEST: "StartServiceRequest",
  RESUME_SERVICE_REQUEST: "ResumeServiceRequest",
  WAIT_FOR_HOLD: "WaitForHoldResolution",
  NO_ACTION_REQUIRED: "NoActionRequired",
  NOT_ELIGIBLE: "NOTELIGIBLE"
};

export const INCEDENT_TYPES = {
  SWAP: "SWAP",
  REPLACEMENT: "REPLACEMENT",
  MALFUNCTION: "MALFUNCTION",
  GENERAL_ENQUIRY: "GeneralEnquiry",
  SCREEN_REPAIR: "SCREEN REPAIR",
  FAULT_REPAIR: "FAULT REPAIR",
  BATTERY_REPLACEMENT: "BATTERY REPLACEMENT"
}

export const FIELD_IDS = {
  MDN: "txt_sur_{{client}}_mdn_id",
  SUBMIT_MDN: "btn_sur_{{client}}_submit_mdn_id",
  FIRST_NAME: "txt_sur_{{client}}_first_name_id",
  LAST_NAME: "txt_sur_{{client}}_last_name_id",
  SUBMIT_NAME: "btn_sur_{{client}}_submit_name_id",
  NRIC: "txt_sur_{{client}}_nric_id",
  SUBMIT_NRIC: "btn_sur_{{client}}_submit_nric_id",
};
