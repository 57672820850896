import React from 'react'
import { Img } from "react-image";
import noImg from '../../images/device.svg';
import noImgCelcom from '../../images/v2/home/celcom/base-phone.svg';
import PropTypes from "prop-types";

export default function BaseImg({ make = '', model = '', isSingtel = true }) {
    const deviceImgName = make.toUpperCase() + "_" + model.toUpperCase() + "_S";
    const deviceImg = 'https://consoleone-ui-prod.consoleone.asurion.com/' + deviceImgName + ".jpg";

    const defaultImgToUse = isSingtel ? noImg : noImgCelcom;

    return <Img src={[deviceImg, defaultImgToUse]}></Img>
}

BaseImg.prototype = {
    make: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
}
