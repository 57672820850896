import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseCheckbox from "../../../base/BaseCheckbox/BaseCheckbox";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseInformationBox from "../../../base/BaseInformationBox";
import BaseInput from "../../../base/BaseInput/BaseInput";
import BaseLabel from "../../../base/BaseLabel";
import { TextLight, TextMedium, TextRegular } from "../../../base/BaseText";
import BaseTextarea from "../../../base/BaseTextarea/BaseTextarea";
import { isEmpty } from "../../../helpers/formatUtils";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import ChecklistIcon from "../../../images/icon-apps.svg";
import { submitfailureDescriptiveText } from "../state/operators/troubleshootingOperator";

const TroubleshootChecklist = () => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const [disable, setDisable] = useState(true);
  const [freezeWindow, setFreezeWindow] = useState(false);
  const [checklistValues, setChecklistValues] = useState([]);
  const [otherIssue, setOtherIssue] = useState("");
  const [showOtherIssueBox, setShowOtherIssueBox] = useState(false);

  useEffect(() => {
    const checklists = t("Troubleshoot.CheckList.Items", {
      returnObjects: true,
    });
    setChecklistValues(checklists.map((e) => ({ label: e, checked: false })));
  }, []);

  const handleOnCheckListClick = (e) => {
    const newList = [...checklistValues];
    const newElement = { ...newList[e.target.id] };
    newElement.checked = e.target.checked;
    newList[e.target.id] = newElement;
    setChecklistValues([...newList]);

    if (isEmpty(newList.filter((e) => e.checked)) && isEmpty(otherIssue))
      setDisable(true);
    else setDisable(false);
  };

  const handleNext = (textPrint) => {
    setDisable(true);
    setFreezeWindow(true);
    const selectedChecklistValues = checklistValues
      .filter((e) => e.checked)
      .map((e) => e.label)
      .join("|");

    const failureDescriptiveText = !isEmpty(selectedChecklistValues) ? !isEmpty(otherIssue) ? selectedChecklistValues.concat("|", otherIssue) : selectedChecklistValues : otherIssue;

    console.log(`failureDescriptiveText is ${failureDescriptiveText}`);

    dispatch(submitfailureDescriptiveText(failureDescriptiveText, textPrint));

  };

  const openOtherIssueBox = () => {
    setShowOtherIssueBox(!showOtherIssueBox);
  };

  const handleOtherIssueChange = (value) => {
    setOtherIssue(value);
    if (isEmpty(value)) setDisable(true);
    else setDisable(false);
  };

  return (
    <BaseCard>
      <BaseIcon icon={ChecklistIcon} name="Checklist" center />

      <BaseLabel className={"my-1"}>
        <Translate i18nKey={`Troubleshoot.CheckList.Title`}>
          <TextMedium></TextMedium>
        </Translate>
      </BaseLabel>

      <BaseLabel className={"my-1"}>
        <Translate i18nKey={`Troubleshoot.CheckList.SubTitle`}>
          <TextMedium></TextMedium>
        </Translate>
      </BaseLabel>

      <div className="mx-auto w-full survey-checklist">
        {checklistValues.map((e, i) => (
          <div className="flex justify-between">
            <div className="my-auto text-xl">
              <TextRegular>{e.label}</TextRegular>
            </div>
            <BaseCheckbox
              className={"m-0 p-0"}
              key={e.label}
              id={i}
              value={e.checked}
              onChange={(e) => handleOnCheckListClick(e)}
              disabled={freezeWindow}
            />
          </div>
        ))}
      </div>
      <div
        className={`text-center my-4 text-srgreen-primary text-default underline cursor-pointer ${
          freezeWindow ? "pointer-events-none" : ""
        }`}
        onClick={() => openOtherIssueBox()}
      >
        <TextMedium>{t("Troubleshoot.CheckList.OtherIssue")}</TextMedium>
      </div>

      {showOtherIssueBox && (
        <div>
          <BaseLabel className={"my-1 text-left"}>
            <Translate i18nKey={`Troubleshoot.CheckList.OtherIssueTitle`}>
              <TextMedium></TextMedium>
            </Translate>
          </BaseLabel>

          <BaseTextarea
            name="otherIssues"
            placeholder={t("Troubleshoot.CheckList.OtherIssuePlaceholder")}
            value={otherIssue}
            disabled={freezeWindow}
            onChange={handleOtherIssueChange}
          />

          <BaseInformationBox>
            <Translate i18nKey="Troubleshoot.CheckList.Note">
              <TextLight>
                <TextLight></TextLight>
                <TextMedium>Note:</TextMedium>
              </TextLight>
            </Translate>
          </BaseInformationBox>
        </div>
      )}

      <BaseButtonGroup>
        <BaseButton
          onClick={() => handleNext(t("ConfirmButton"))}
          disabled={disable}
          clicked={disable}
          text={t("ConfirmButton")}
        ></BaseButton>
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default TroubleshootChecklist;
