import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { helpNeededForInquiry } from "../state/operators/generalEnquiryOperator";
import Loader from "../../../components/Loader/Loader";
import { sleep } from "../../../helpers/addJourneyMessages";
import Config from "../../Appsync/Config";
import { useTranslate } from "../../../helpers/languageUtils";
import { LANGUAGE } from "../../../helpers/constants";

const GeneralEquiryOptions = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [selection, setSelection] = useState("");
  const client = useSelector((state) => state.app.client);
  const language = useSelector((state) => state.app.language);

  const faqPath =
    language === LANGUAGE.BAHASA
      ? Config[client].WPAPIServer.faqEndPoint_bs
      : Config[client].WPAPIServer.faqEndPoint;
  const faqUrl = `${Config[client].WPAPIServer.baseUrl}${faqPath}`;

  const handleSubimt = (answer) => {

    const textPrint = t(`${answer}Button`);

    setSelection(answer);
    dispatch(helpNeededForInquiry(answer, textPrint));
    setDisabled(true);
  };

  const Loading = () => (
    <div className="h-30r">
      <Loader className="mx-auto my-40" />
    </div>
  );

  useEffect(() => {
    const closeLoader = async () => {
      await sleep(5000);
      setIsLoading(false);
    };
    closeLoader();
  });

  return (
    <BaseCard className="p-0 pb-8">
      {isLoading && <Loading />}
      <iframe
        src={faqUrl}
        width="100%"
        className={classNames("h-30r block", {
          hidden: isLoading,
        })}
        frameBorder="0"
        allow-scripts="true"
      />
      <BaseLabel>{t("GeneralEnquiry.Options.Title")}</BaseLabel>
      <BaseButtonGroup>
        <BaseButton
          onClick={() => handleSubimt("No")}
          text={t("NoButton")}
          disabled={disabled}
          clicked={selection === "No"}
        />
        <BaseButton
          onClick={() => handleSubimt("Yes")}
          text={t("YesButton")}
          disabled={disabled}
          clicked={selection === "Yes"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default GeneralEquiryOptions;
