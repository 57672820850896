import React from "react";
import { TextMedium } from "../../../base/BaseText";

export const SingtelTerms = [
  {
    title: "Under the Screen Repair service:",
    content: (
      <>
        <TextMedium>1.</TextMedium> Subject to these terms and conditions, NEW
        Asurion Singapore Pte. Ltd. (“<TextMedium>Asurion</TextMedium>”), will
        carry out a repair or replacement of the screen of your mobile device (“
        <TextMedium>Screen Repair</TextMedium>”) and, if any other repairs are
        required, the Screen Repair will not be carried out on your mobile
        device.
        <br />
        <br />
        <TextMedium>2.</TextMedium> You must be an active subscriber of Singtel
        Mobile Singapore Pte. Ltd’s (“<TextMedium>Singtel</TextMedium>”)
        MobileSwop, MobileSwopUp, MobileSwop Unlimited or MobileSwop Unlimited
        Premium device protection programs (“<TextMedium>MobileSwop</TextMedium>
        ”) and the international mobile equipment identity number (“
        <TextMedium>IMEI</TextMedium>”) of your mobile device must be registered
        and active under Singtel MobileSwop.
        <br />
        <br />
        <TextMedium>3.</TextMedium> The Screen Repair fee is S$99 (inclusive of
        GST) (“
        <TextMedium>Repair Fee</TextMedium>”).
        <br />
        <br />
        <TextMedium>4.</TextMedium> Your mobile device will be collected and
        returned to you at the same indicated location and at the indicated
        time(s). You accept and acknowledge that the timing of the return of
        your mobile device is subject to the availability of replacement parts
        and availability of our delivery personnel. Our delivery personnel will
        verify your identity at the time of collection and return of your mobile
        device and will seek your written acknowledgement and acceptance at both
        times. You may be charged an additional fee if you are not present when
        we attempt to collect your mobile device from you or at the time we
        attempt to return your mobile device to you at the indicated times. A
        Screen Repair is only available in Singapore.
        <br />
        <br />
        <TextMedium>5.</TextMedium> Upon receipt of your mobile device, the
        suitability of your mobile device will be assessed for Screen Repair. We
        reserve the right not to proceed with the Screen Repair for any reason,
        whether relating to make, model and/or condition of the device or
        otherwise. In such event, your mobile device will not be accepted for
        repair and/or the unrepaired mobile device will be returned to you with
        the Repair Fee refunded to you in full if you have paid for it.
        <br />
        <br />
        <TextMedium>6.</TextMedium> Your mobile device (including the operating
        system and hardware) must not be modified in any manner. Prior to the
        handover of your mobile device for repair, you must:
        <br />
        <div className="pl-4">
          <table className="border-0">
            <tbody>
              <tr>
                <td className="align-top pr-2">(a)</td>
                <td>
                  delete all user content and data from your mobile device. You
                  acknowledge and accept that in carrying out the Screen Repair,
                  all mobile device data and user content will be deleted and
                  the mobile device will be reset to its factory default
                  settings;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">(b)</td>
                <td>
                  disable all personal device locks (including FindMyiPhone) on
                  your mobile device; and
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">(c)</td>
                <td>
                  remove any accessories, stylus, SIM cards, SD cards or screen
                  protectors from your mobile device and must not provide any of
                  these to us.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <TextMedium>7.</TextMedium> Your Screen Repair comes with a 3 months
        warranty for the screen of your mobile device only, as repaired or
        replaced, against manufacturer malfunctions that starts from the date of
        return of your mobile device to you.
        <br />
        <br />
        <TextMedium>8. </TextMedium> You may only make 1 request for a Screen
        Repair in any rolling 12 months period, with such period commencing from
        the time of your first request for a Screen Repair.
        <br />
        <br />
        <TextMedium>9. </TextMedium> You have provided your consent for the
        collection, use or disclosure of your personal data in connection with
        the Screen Repair in accordance with Asurion’s prevailing{" "}
        <a
          href="https://www.asurion.com.sg/eng/privacy-policy/"
          target="_blank"
          className="underline text-blue-700"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
        .
        <br />
        <br />
        <TextMedium>10. </TextMedium> You must not provide us with any accessory
        for your mobile device when requesting a Screen Repair.
        <br />
        <br />
        <TextMedium>11. </TextMedium> Asurion shall not be liable for:
        <br />
        <div className="pl-4">
          <table className="border-0">
            <tbody>
              <tr>
                <td className="align-top pr-2">(a)</td>
                <td>
                  any loss of data and/or user content on your mobile device and
                  any misuse of data and/or user content on your mobile device
                  arising from your failure to delete the same;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">(b)</td>
                <td>
                  any loss associated with timing for collection of your mobile
                  device or return of your mobile device following a Screen
                  Repair;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">(c)</td>
                <td>
                  any damage sustained to your mobile device arising from the
                  inspection and/or diagnostics of your mobile device or the
                  Screen Repair service generally;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">(d)</td>
                <td>
                  any loss or damage to any accessory, stylus, SIM card or SD
                  card; and
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">(e)</td>
                <td>
                  any damage sustained to your mobile device in transit from
                  time of collection from you until the return of the mobile
                  device to you.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <TextMedium>12. </TextMedium> These terms and conditions, the service
        and/or price may be revised from time to time and your use of the
        service shall constitute your acceptance of these terms and conditions
        and their revisions.
        <br />
      </>
    ),
  },
];

export const CelcomTerms = [
  {
    title: "Under the Screen Repair service:",
    content: (
      <>
        <TextMedium>1.</TextMedium> Subject to these terms and conditions,
        Asurion Technology Malaysia Sdn Bhd (“<TextMedium>Asurion</TextMedium>
        ”)will source a repair or replacement of the screen of your mobile
        device (“<TextMedium>Screen Repair</TextMedium>”) and, if any other
        repairs are required, the Screen Repair will not be carried out on your
        mobile device. Asurion will appoint authorised service centres to carry
        out the Screen Repair (“<TextMedium>Repair Centre</TextMedium>”).
        <br />
        <br />
        <TextMedium>2.</TextMedium> To qualify for this screen repair service:
        <br />
        <div className="pl-4">
          <table className="border-0">
            <tbody>
              <tr>
                <td className="align-top pr-2">1. </td>
                <td>
                  you must be an active customer of CelcomDigi Berhad (Formerly known as Digi.Com Berhad) [Registration No. 199701009694 (425190-X)] being of a category listed as qualifying customer;
                  and
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">2. </td>
                <td>
                  your mobile device make and model must be listed as a
                  supported device (Asurion reserves its rights to modify this
                  list at any time),
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        on the Screen Repair portal at (“
        <a
          href="https://service2u.asurion.com/my"
          target="_blank"
          className="underline text-blue-700"
          rel="noopener noreferrer"
        >
          Screen Repair Portal
        </a>
        ”). Additionally, the international mobile equipment identity number of
        your mobile device must be registered and active with Celcom.
        <br />
        <br />
        <TextMedium>3.</TextMedium> The Screen Repair fee is as per the table
        below (“<TextMedium>Repair Fee</TextMedium>”):
        <table className="border-0 text-base">
          <tbody>
            <tr>
              <td><TextMedium>RRP of your mobile device</TextMedium></td>
              <td>RM 0-500</td>
              <td>RM 501-1000</td>
              <td>RM 1001-1500</td>
              <td>RM 1501-3000</td>
              <td>RM 3001-6000</td>
              <td>Above RM 6000</td>
            </tr>
            <tr>
              <td><TextMedium>Repair Fee</TextMedium></td>
              <td>30</td>
              <td>60</td>
              <td>110</td>
              <td>220</td>
              <td>350</td>
              <td>500</td>
            </tr>
          </tbody>
        </table>
        <br />
        Please note that the Repair Fee excludes 6% service tax, which will be
        separately itemised in our invoice and payable by you (if applicable).
        <br />
        <br />
        <TextMedium>4.</TextMedium> You can only make a Screen Repair request at
        the Screen Repair Portal. During the course of making your Screen Repair
        request online, your mobile device’s suitability to access a Screen
        Repair will be confirmed, you may have your mobile device collected and
        returned to you at the indicated location and at the indicated time(s).
        If you select this option, you accept and acknowledge that the timing of
        the return of your mobile device is subject to the availability of
        replacement parts and availability of our delivery personnel. Our
        delivery personnel will verify your identity at the time of collection
        and return of your mobile device by requesting that you present your
        Malaysian national ID card, or foreigner identification being passport,
        work permit and certificate of residence (“ID”) and will seek your
        written acknowledgement and acceptance at both times. You may be charged
        an additional fee if you are not present when we attempt to collect your
        mobile device from you or at the time we attempt to return your mobile
        device to you at the indicated times. Upon collection, your mobile
        device will be delivered to the Repair Centre.
        <br />
        <br />
        <TextMedium>5.</TextMedium> Access to Screen Repair is available in
        Malaysia only.
        <br />
        <br />
        <TextMedium>6.</TextMedium> Upon receipt of your mobile device by the
        Repair Centre, the suitability of your mobile device will be assessed
        for Screen Repair. We reserve the right not to proceed with the Screen
        Repair for any reason, whether relating to make, model and/or condition
        of the device or otherwise. In such event, your mobile device will not
        be accepted for repair and/or the unrepaired mobile device will be
        returned to you (with the Repair Fee refunded to you in full if you have
        paid for it).
        <br />
        <br />
        <TextMedium>7.</TextMedium> Your mobile device (including the operating
        system and hardware) must not be modified in any manner. Prior to the
        handover of your mobile device for repair, you must:
        <br />
        <div className="pl-4">
          <table className="border-0">
            <tbody>
              <tr>
                <td className="align-top pr-2">1. </td>
                <td>
                  delete all user content and data from your mobile device. You
                  acknowledge and accept that in carrying out the Screen Repair,
                  all mobile device data and user content will be deleted and
                  the mobile device will be reset to its factory default
                  settings;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">2. </td>
                <td>
                  disable all personal device locks (including Find My iPhone)
                  on your mobile device; and
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">3. </td>
                <td>
                  remove any accessories, stylus, SIM cards, SD cards or screen
                  protectors from your mobile device and must not provide any of
                  these to us or the Repair Centre, if applicable.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <br />
        <TextMedium>8. </TextMedium> Your Screen Repair comes with a 90 days
        warranty for the screen of your mobile device only, as repaired or
        replaced, against manufacturer malfunctions that starts from the date of
        return of your mobile device to you.
        <br />
        <br />
        <TextMedium>9. </TextMedium> You may only make 1 request for a Screen
        Repair per device and the request must be made within 24 months of the
        date of the launch of your mobile device in Malaysia.
        <br />
        <br />
        <TextMedium>10. </TextMedium> You have provided your consent for the
        collection, use or disclosure of your personal data in connection with
        the Screen Repair in accordance with Celcom’s prevailing
        <a
          href="https://www.asurion.com.sg/eng/privacy-policy/"
          target="_blank"
          className="underline text-blue-700"
          rel="noopener noreferrer"
        >
          personal data policy[KP1]
        </a>
        .
        <br />
        <br />
        <TextMedium>11. </TextMedium> Celcom, Asurion and the Repair Centre
        shall not be liable for:
        <br />
        <div className="pl-4">
          <table className="border-0">
            <tbody>
              <tr>
                <td className="align-top pr-2">1. </td>
                <td>
                  any loss of data and/or user content on your mobile device and
                  any misuse of data and/or user content on your mobile device
                  arising from your failure to delete the same;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">2. </td>
                <td>
                  any loss monetary or economic losses, including loss of
                  business or loss of profits associated with the timing for
                  collection of your mobile device or return of your mobile
                  device following a Screen Repair;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">3. </td>
                <td>
                  any damage sustained to your mobile device due a to
                  pre-existing defect which was uncovered during the inspection
                  and/or diagnostics of your mobile device;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">4. </td>
                <td>
                  any loss or damage to any mobile device accessory, stylus, SIM
                  card or SD card arising from your failure to remove the same;
                  and
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">5. </td>
                <td>
                  any damage unknown to us and not caused by us sustained to
                  your mobile device in transit from time of collection from you
                  until the return of the mobile device to you.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <TextMedium>12. </TextMedium> These terms and conditions, the service
        and/or price may be revised from time to time and your use of the
        service shall constitute your acceptance of these terms and conditions
        and their revisions.
        <br />
      </>
    ),
  },
];

export const SingtelTermsBatteryReplacement = (fee = "79") => {return [
  {
    title: "Terms and Conditions of the Repair Service (Battery Replacement)",
    content: (
      <>
        <TextMedium>1.</TextMedium> Subject to these terms and conditions, 
        NEW Asurion Singapore Pte. Ltd. (“<TextMedium>Asurion</TextMedium>”), will carry out a replacement 
        of the battery of your mobile device (“<TextMedium>Battery Replacement</TextMedium>”) (the “<TextMedium>Service</TextMedium>”) and, 
        if any other repairs are required other than the Battery Replacement, the Battery 
        Replacement will not be carried out on your mobile device.
        <br />
        <br />
        <TextMedium>2.</TextMedium> You must be an active subscriber of Singtel Mobile 
        Singapore Pte. Ltd’s (“<TextMedium>Singtel</TextMedium>”) MobileSwop Starter device program (“<TextMedium>Device Program</TextMedium>”) 
        and the international mobile equipment identity number (“<TextMedium>IMEI</TextMedium>”) of your mobile device 
        must be registered and active under Singtel Device Programs (“<TextMedium>Mobile Device</TextMedium>”). 
        For the avoidance of doubt, wearable devices and tablets shall be excluded from this 
        Battery Replacement Service.
        <br />
        <br />
        <TextMedium>3.</TextMedium> The fee for the Service (“<TextMedium>Repair Fee</TextMedium>”) is ${`${fee}`} (inclusive of GST).
        <br />
        <br />
        <TextMedium>4.</TextMedium> Your Mobile Device will be collected and returned to you 
        at the same indicated location and at the indicated time(s). You accept and acknowledge 
        that the timing of the return of your Mobile Device is subject to the availability of 
        replacement parts and availability of our delivery personnel. Our delivery personnel will 
        verify your identity at the time of collection and return of your Mobile Device and will 
        seek your written acknowledgement and acceptance at both times. You may be charged an additional 
        fee if you are not present when we attempt to collect your Mobile Device from you or at the 
        time we attempt to return your Mobile Device to you at the indicated times. The Service is only 
        available in Singapore.
        <br />
        <br />
        <TextMedium>5.</TextMedium> Upon receipt of your Mobile Device, the suitability of your 
        Mobile Device will be assessed for the Service.  We reserve the right not to proceed with 
        the Service for any reason, whether relating to make, model and/or condition of the device 
        or otherwise. In such event, your Mobile Device will not be accepted for repair and/or 
        the unrepaired Mobile Device will be returned to you with the Repair Fee refunded to you 
        in full if you have paid for it.
        <br />
        <br />

        <TextMedium>6.</TextMedium> Without prejudice to the generality of paragraph 5 above, 
        the following mobile devices are not suitable for Battery Replacement, mobile devices:
        <br />
        <div className="pl-4">
          <table className="border-0">
            <tbody>
              <tr>
                <td className="align-top pr-2" style={{ width: "25px" }}>(a)</td>
                <td>
                with a battery capacity of 80% or more (for iPhones) or not graded as “bad” (for Samsung mobile devices); and
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2" style={{ width: "25px" }}>(b)</td>
                <td>
                  which have a bloated or swollen battery
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />

        <TextMedium>7.</TextMedium> Your mobile device (including the operating
        system and hardware) must not be modified in any manner. Prior to the
        handover of your mobile device for repair, you must:
        <br />
        <div className="pl-4">
          <table className="border-0">
            <tbody>
              <tr>
                <td className="align-top pr-2" style={{ width: "25px" }}>(a)</td>
                <td>
                  delete all user content and data from your mobile device. You
                  acknowledge and accept that in carrying out the Service,
                  all mobile device data and user content will be deleted and
                  the mobile device will be reset to its factory default
                  settings;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2" style={{ width: "25px" }}>(b)</td>
                <td>
                  disable all personal device locks (including FindMyiPhone) on
                  your mobile device; and
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2" style={{ width: "25px" }}>(c)</td>
                <td>
                  remove any accessories, stylus, SIM cards, SD cards or screen
                  protectors from your mobile device and must not provide any of
                  these to us.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <TextMedium>8.</TextMedium> Your Battery Replacement comes with a free 12 months 
        warranty for any manufacturing malfunctions or defects that starts from the date of 
        return of your Mobile Device to you. A Battery Replacement may contain original or 
        non-original manufacturer parts and any issues caused by this Battery Replacement 
        may affect your manufacturer’s warranty coverage. Manufacturing malfunctions or 
        defects does not include battery degradation.
        <br />
        <br />
        <TextMedium>9. </TextMedium> You may only make 1 request for Battery Replacement 
        in any rolling 12 months period in respect of your Mobile Device, with such period 
        commencing from the time of your first request for a Battery Replacement.
        <br />
        <br />
        <TextMedium>10. </TextMedium> You have provided your consent for the
        collection, use or disclosure of your personal data in connection with
        the Service in accordance with Asurion’s prevailing{" "}
        <a
          href="https://www.asurion.com.sg/eng/privacy-policy/"
          target="_blank"
          className="underline text-blue-700"
          rel="noopener noreferrer"
        >
          privacy policy
        </a>
        .
        <br />
        <br />
        <TextMedium>11. </TextMedium> You must not provide us with any accessory
        for your mobile device when requesting the Service.
        <br />
        <br />
        <TextMedium>12. </TextMedium> Asurion shall not be liable for:
        <br />
        <div className="pl-4">
          <table className="border-0">
            <tbody>
              <tr>
                <td className="align-top pr-2" style={{ width: "25px" }}>(a)</td>
                <td>
                  any loss of data and/or user content on your mobile device and
                  any misuse of data and/or user content on your mobile device
                  arising from your failure to delete the same;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2" style={{ width: "25px" }}>(b)</td>
                <td>
                  any loss associated with timing for collection of your mobile
                  device or return of your mobile device following a Battery Replacement;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2" style={{ width: "25px" }}>(c)</td>
                <td>
                  any damage sustained to your mobile device arising from the
                  inspection and/or diagnostics of your mobile device or the
                  Battery Replacement service generally;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2" style={{ width: "25px" }}>(d)</td>
                <td>
                  any loss or damage to any accessory, stylus, SIM card or SD
                  card; and
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2" style={{ width: "25px" }}>(e)</td>
                <td>
                  any damage sustained to your mobile device in transit from
                  time of collection from you until the return of the mobile
                  device to you.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <TextMedium>13. </TextMedium> These terms and conditions, the service
        and/or price may be revised from time to time and your use of the
        service shall constitute your acceptance of these terms and conditions
        and their revisions.
        <br />
      </>
    ),
  },
]};
