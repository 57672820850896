const ENQUIRY_OPTIONS = {
  Options: [
    {
      name: "CreateNewRequest",
      label: "Create a new Screen Repair Request",
      options: [
        {
          name: "NewRequest",
          label: "New Screen Repair Request",
        },
      ],
    },
    {
      name: "In-WarrantyRequest",
      label: "Create an In-Warranty Request",
    },
    {
      name: "ResumeRequest",
      label: "Resume an existing Screen Repair Request",
    },
    {
      name: "ChangeSchedule",
      label: "Change Screen Repair Schedule",
    },
    {
      name: "CancelRequest",
      label: "Cancel Screen Repair Request",
    },
    {
      name: "GeneralEnquiry",
      label: "General and Warranty Enquiry",
    },
  ],
};

export const NEW_ENQUIRY_OPTIONS = [
  {
    name: "NewRequest Battery",
    label: "Battery Replacement",
  },
]

export const STARTER_ENQUIRY_OPTIONS = {
  Options: [
    {
      name: "CreateNewRequest",
      label: "Create a new Repair Request",
      options: [
        {
          name: "NewRequestBatteryReplacement",
          label: "Battery Replacement",
        },
      ],
    },
    {
      name: "In-WarrantyRequest",
      label: "Create an In-Warranty Request",
    },
    {
      name: "ResumeRequest",
      label: "Resume an existing Repair Request",
    },
    {
      name: "ChangeSchedule",
      label: "Change Repair Schedule",
    },
    {
      name: "CancelRequest",
      label: "Cancel Repair Request",
    },
    {
      name: "GeneralEnquiry",
      label: "General and Warranty Enquiry",
    },
  ],
};

export const BATT_REPLACEMENT_CAMPAIGN_ENQUIRY_OPTIONS = {
  Options: [
    {
      name: "CreateNewRequest",
      label: "Create a new Repair Request",
      options: [
        {
          name: "NewRequest",
          label: "New Screen Repair Request",
        },,
        {
          name: "NewRequestBatteryReplacement",
          label: "Battery Replacement",
        },
      ],
    },
    {
      name: "In-WarrantyRequest",
      label: "Create an In-Warranty Request",
    },
    {
      name: "ResumeRequest",
      label: "Resume an existing Repair Request",
    },
    {
      name: "ChangeSchedule",
      label: "Change Repair Schedule",
    },
    {
      name: "CancelRequest",
      label: "Cancel Repair Request",
    },
    {
      name: "GeneralEnquiry",
      label: "General and Warranty Enquiry",
    },
  ],
};

export const INCIDENT_SCREEN_REPAIR = "Screen Repair";
export const INCIDENT_SCREEN_REPAIR_WARRANTY = "Screen Repair Warranty";
export const INCIDENT_FAULT_REPAIR = "Fault Repair";
// export const INCIDENT_FAULT_REPAIR_WARRANTY = "Fault Repair Warranty";
export const INCIDENT_BATTERY_REPLACEMENT = "Battery Replacement";

export const EnumEnquiryModules = {
  CreateNewRequest: "CreateNewRequest",
  NewRequest: "NewRequest",
  InWarrantyRequest: "In-WarrantyRequest",
  ResumeRequest: "ResumeRequest",
  ChangeSchedule: "ChangeSchedule",
  CancelRequest: "CancelRequest",
  GeneralEnquiry: "GeneralEnquiry",
  NewRequestBatteryReplacement: 'NewRequestBatteryReplacement',
};

export const PROGRAM_NAME = [
  "MobileSwop Unlimited Premium Family (12 mths)",
  "MobileSwop Unlimited Premium Family (24 mths)",
  "MobileSwop Family (Supplementary Device)",
];

export const PROGRAM_NAME_BATT = [
  "MobileSwop Starter",
  "MobileSwop Unlimited Premium (12 mths term)",
  "MobileSwop Unlimited Premium (24 mths term)",
  "MobileSwop Unlimited Premium",
  "MobileSwop Family",
  "MobileSwop Family (Supplementary Device)",
  "MobileSwop Unlimited Premium Family (12 mths)",
  "MobileSwop Unlimited Premium Family (24 mths)",
  "Exceptional MobileSwop Unlimited Premium (12 mths)",
  "Exceptional MobileSwop Unlimited Premium (24 mths)"
];

export const PERIL_TYPES = {
  [INCIDENT_SCREEN_REPAIR]: INCIDENT_SCREEN_REPAIR,
  [INCIDENT_SCREEN_REPAIR_WARRANTY]: INCIDENT_SCREEN_REPAIR_WARRANTY,
  [INCIDENT_BATTERY_REPLACEMENT]: "Battery Replacement",
}

export default ENQUIRY_OPTIONS;
