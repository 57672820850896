import React from "react";
import PropTypes from "prop-types";
import AgentConnected from "./AgentConnected";
import AgentDisconnected from "./AgentDisconnected";
import NoAgentsAvailable from "./NoAgentsAvailable";
import AgentsBusy from "./AgentsBusy";
import UserDisconnectedMessage from "./UserDisconnectedMessage";
import ChatUnResolved from "./ChatUnResolved";
import AgentDisconnectedMessage from "./AgentDisconnectedMessage";
import ChatRetry from "./ChatRetry";

const View = ({ showComponent }) => {
  return (
    <>
      {showComponent === "AgentConnected" && <AgentConnected />}
      {showComponent === "AgentDisconnected" && <AgentDisconnected />}
      {showComponent === "NoAgentsAvailable" && <NoAgentsAvailable />}
      {showComponent === "AgentsBusy" && <AgentsBusy />}
      {showComponent === "UserDisconnectedMessage" && <UserDisconnectedMessage />}
      {showComponent === "AgentDisconnectedMessage" && <AgentDisconnectedMessage />}
      {showComponent === "UnResolved" && <ChatUnResolved />}
      {showComponent === "ChatRetry" && <ChatRetry />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired
};

export default View;
