// Packages
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
// Base Components
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseInformationBox from "../../../base/BaseInformationBox";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextLight, TextMedium, TextRegular } from "../../../base/BaseText";
// Icons
import CalendarCheckedIcon from "../../../images/icon-calendar-selected.svg";
// Operator
import { confirmationOfDateAndTime } from "../state/operators/scheduleOperator";
import { getReadableDate, getFormmatedDate } from "../../../helpers/dateUtils";
import BaseTable, {
  BaseTR,
  BaseTH,
  BaseTD,
} from "../../../base/BaseTable/BaseTable";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { confirmationOfAppointment } from "../state/operators/RepairOptionsOperator";

const ConfirmAppointment = () => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const walkInData = useSelector((state) => state.claim.walkIn);
  const date = walkInData.selectedAppointmentDate;
  const slot = walkInData.selectedAppointmentSlot;

  const [disabled, setDisabled] = useState(false);
  const [dateStatus, setDateStatus] = useState("");

  const tableAppointmentHeaders = [
    {
      key: "appointmentDate",
      text: t("WalkIn.ConfirmAppointment.labelAppoinmentDate"),
    },
    {
      key: "appointmentTime",
      text: t("WalkIn.ConfirmAppointment.labelAppointmentTime"),
    },
  ];

  const tableAppointmentData = [
    {
      // appointmentDate: `${getFormmatedDate(
      //   walkInData.selectedAppointmentDate,
      //   "d MMM yyyy"
      // )}`,
      appointmentDate: `${walkInData.selectedAppointmentDate}`,
      appointmentTime: `${walkInData.selectedAppointmentSlot}`,
    },
  ];

  const handleSubmitPickupDateTime = (status) => {

    let textPrint;

    if(status === "Keep") {
     textPrint = t(`ConfirmButton`);
    } else {
     textPrint = t(`${status}Button`);
    }
    
    setDisabled(true);
    setDateStatus(status);
    dispatch(confirmationOfAppointment(textPrint, status));
  };

  return (
    <BaseCard>
      <BaseIcon icon={CalendarCheckedIcon} name="Calendar" center />
      <BaseLabel>
        <Translate i18nKey="WalkIn.ConfirmAppointment.title">
          <TextRegular>Please confirm your appointment slot below</TextRegular>
        </Translate>
      </BaseLabel>
      <BaseTable title={t(`WalkIn.ConfirmAppointment.tableTitle`)}>
        {tableAppointmentHeaders.map((header) => (
          <BaseTR key={header.key}>
            <BaseTH>{header.text}</BaseTH>
            {tableAppointmentData.map((data, i) => (
              <BaseTD key={`${header.key}_${i}`}>{data[header.key]}</BaseTD>
            ))}
          </BaseTR>
        ))}
      </BaseTable>

      <BaseButtonGroup>
        <BaseButton
          text={t("ChangeButton")}
          onClick={() => handleSubmitPickupDateTime("Change")}
          disabled={disabled}
          clicked={dateStatus === "Change"}
        />
        <BaseButton
          text={t("ConfirmButton")}
          onClick={() => handleSubmitPickupDateTime("Keep")}
          disabled={disabled}
          clicked={dateStatus === "Keep"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmAppointment;
