import React from "react";
import PropTypes from "prop-types";
import ConfirmRequestCancel from "./ConfirmRequestCancel";
import ConfirmDPRequestCancel from "./ConfirmDPRequestCancel";
import RequestCancelled from "./RequestCancelled";

const View = ({ showComponent }) => {
  return (
    <>
      {showComponent === "ConfirmRequestCancel" && <ConfirmRequestCancel />}
      {showComponent === "ConfirmDPRequestCancel" && <ConfirmDPRequestCancel />}
      {showComponent === "requestCancelled" && <RequestCancelled />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
};

export default View;
