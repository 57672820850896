import React from "react";
import PropTypes from "prop-types";
import Troubleshoot from "./Troubleshoot";
import TroubleshootChecklist from "./TroubleshootChecklist";
import BatteryTriage from "./BatteryTriage";

const View = ({ showComponent }) => {
  return (
    <>
      {showComponent === "CameraWorking" && <Troubleshoot code="CameraWorking" />}
      {showComponent === "PowerOn" && <Troubleshoot code="PowerOn" />}
      {showComponent === "LiquidExposure" && <Troubleshoot code="LiquidExposure" />}
      {showComponent === "PhysicalDamage" && <Troubleshoot code="PhysicalDamage" />}
      {showComponent === "ActivtionLockOff" && <Troubleshoot code="ActivtionLockOff" />}
      {showComponent === "TroubleshootChecklist" && <TroubleshootChecklist />}
      {showComponent === "BatteryTriage" && <BatteryTriage />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
};

export default View;
