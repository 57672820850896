import React from "react";
import SmsIcon from "../../../images/icon-sms.svg";
import EmailIcon from "../../../images/icon-email.svg";
import { TextLight } from "../../../base/BaseText";

const ConfirmMobileNumber = {
  icon: SmsIcon,
  title: (
    <TextLight>
      Would you like to send delivery notification SMS to your registered mobile
      number?
    </TextLight>
  ),
};

const ConfirmEmail = {
  icon: EmailIcon,
  title: (
    <TextLight>
      Would you like to send delivery notification email to this registered
      email address?
    </TextLight>
  ),
};

const ChangeMobileNumber = {
  icon: SmsIcon,
  title: (
    <TextLight>
      Please provide your mobile number for notifications of the status of your
      repair request.
    </TextLight>
  ),
  input: "Enter alternate mobile number",
};

const ChangeEmail = {
  icon: EmailIcon,
  title: (
    <TextLight>
      Please provide your email address for notifications of the status of your
      repair request.
    </TextLight>
  ),
  input: "Enter alternate email address",
};

export default {
  ConfirmMobileNumber,
  ConfirmEmail,
  ChangeMobileNumber,
  ChangeEmail,
};
