import React, { useEffect, useState } from "react";
import AskUsIcon from "./../../images/icon-ask-us.svg";

const WelcomeToService = () => {
  const [currentDate, setCurrentDate] = useState();

  useEffect(() => {
    setCurrentDate(new Date());
  }, []);

  return (
    <>
      <time className="app__message-time">
        {currentDate && `${currentDate.toDateString()} ${currentDate.toLocaleTimeString()}`}
      </time>
      {/* <div className="app__welcomeToSupport-container">
        <img src={AskUsIcon} alt="Ask Us" />
        <span className="app__welcomeToSupport-message">
          Welcome to
          <br /> Screen Repair Service
        </span>
      </div> */}
    </>
  );
};

export default WelcomeToService;
