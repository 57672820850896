import { TextMedium } from "../../../base/BaseText";
import React from 'react'
import { useState } from "react";
import { useDispatch } from "react-redux";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { useTranslate } from "../../../helpers/languageUtils";
import { addJourneyMessages } from "../../../helpers/addJourneyMessages";
import { createSystemMessage, createUserMessage } from "../../../helpers/createMessage";
import { setExchangeType } from "../../../reducers/journeyMessagesReducer";

export default function ExchangeOptions({ selectedimei }) {
    const [selected, setSelected] = useState('');
    const dispatch = useDispatch();
    const t = useTranslate();

    const exchangeOptions = t("ExchangeOption.ExchangeOptions",{ returnObjects: true });

    const handleClick = (e) => {
        setSelected(e.target.innerText)
        dispatch(setExchangeType(e.target.innerText))
        dispatch(addJourneyMessages(createUserMessage('TEXT', e.target.innerText)))
        dispatch(addJourneyMessages(createSystemMessage("CHOOSE_EXCHANGE_OPTIONS", { showComponent: "chooseEnrolledDevice", selectedimei })))
    }

    return (
        <div>
            <div className="text-center mt-6 text-default">
                <TextMedium>{t("ExchangeOption.title")}</TextMedium>
            </div>
            <ul>
                {exchangeOptions.map(e => <li key={e}>
                    <BaseButton
                        className="w-full mt-4"
                        disabled={!!selected}
                        clicked={selected === e}
                        onClick={handleClick}
                        text={e}
                    >
                    </BaseButton>
                </li>)}
            </ul>
        </div>
    )
}