import React from "react";
import classNames from "classnames";
import BaseLink from "../../base/BaseLink/BaseLink";
import { useDispatch, useSelector } from "react-redux";
import { setAppLanguage } from "../../operators/homeOperator";
import { isEmpty } from "../../helpers/formatUtils";

const LanguageSelector = ({ className }) => {
  const extraClasses = className ? className.split(" ") : [];
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language);

  const changeLanguage = (lang) => {
    dispatch(setAppLanguage(lang));
  };

  return (
    <div
      className={classNames(
        "active:text-srgreen-secondary text-default text-white",
        ...extraClasses
      )}
    >
      <BaseLink
        className={classNames("celcomdigi", {
          "text-white": language !== "my",
          "text-white font-bold": language === "my",
        })}
        onClick={() => changeLanguage("my")}
      >
        Bahasa Malaysia
      </BaseLink>
      <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
      <BaseLink
        className={classNames("", {
          "text-white": language !== "en",
          "text-white font-bold": language === "en",
        })}
        onClick={() => changeLanguage("en")}
      >
        English
      </BaseLink>
    </div>
  );
};

export default LanguageSelector;
