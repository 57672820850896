import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate, Translate } from "../../helpers/languageUtils";
import BaseCard from "../../base/BaseCard/BaseCard";
import BaseInput from "../../base/BaseInput/BaseInput";
import BaseLabel from "../../base/BaseLabel";
import BaseInformationBox from "../../base/BaseInformationBox";
import BaseButtonGroup from "../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../base/BaseButton/BaseButton";
import BasePopup from "../../base/BasePopup/BasePopup";
import { validateMdn } from "./state/operators";
import { TextMedium, TextLight } from "../../base/BaseText";
import { isEmpty } from "../../helpers/formatUtils";
import BaseDropdown from "../../base/BaseDropdown/BaseDropdown";
import ReCAPTCHA from "react-google-recaptcha";
import { CLIENT, FIELD_IDS } from "../../helpers/constants";

const AskMdn = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [mdn, setMdn] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [freezeWindow, setFreezeWindow] = useState(false);

  const [disabled, setDisabled] = useState(false);
  const [serviceProvider, setServiceProvider] = useState("Singtel");

  const recaptchaRef = React.createRef();
  const grecaptchaObject = window.grecaptcha;

  const client = useSelector((state) => state.app.client);

  const mdnLength = client === CLIENT.SINGTEL ? 8 : 11;

  // For QA recaptcha bypass
  const isEnvQA = process.env?.REACT_APP_ENV === 'QA';

  let token = "";

  const serviceProviderOptions = [
    {
      id: 1,
      text: client === CLIENT.SINGTEL ? "Singtel" : "Celcom",
    },
  ];

  const handleMdnChange = (value) => {
    if (!isNaN(value.trim())) setMdn(value.trim());
  };

  const submitMdn = async () => {
    let token = null;
    if (!isEnvQA){
      token = await recaptchaRef.current.executeAsync();
    }
    if (!token && !isEnvQA) {
      grecaptchaObject.execute();
    } else {
      dispatch(validateMdn(mdn));
      setFreezeWindow(true);
    }
  };

  // const onCaptchaLoad = () => {
  //   console.log('Captcha loaded.')
  // }

  const verifyCaptcha = (res) => {
    token = res;
    console.log(`captcha token is ${token}`);
  };

  // ReCAPTCHA Expired
  // const expireCaptcha = () => {};

  const shouldSubmit = freezeWindow || isEmpty(mdn) || mdn.length !== mdnLength || client === CLIENT.CELCOM ? mdn.length < 8 : mdn.length > 8;

  return (
    <>
      <div className="app-AskMdn">
        <BaseCard>
          <BaseLabel>
            <div className="text-default -mx-3">
              <Translate i18nKey="AskMdn.Label">
                To ensure a smooth experience, <br />
                please
                <span
                  className="text-srgreen-secondary font-semibold cursor-pointer"
                  onClick={() => setShowPopup(true)}
                >
                  check that you are eligible to access this <br />
                  Screen Repair Service
                </span>
                and please have ready:
              </Translate>
            </div>
          </BaseLabel>
          <BaseInformationBox>
            <ul className="list-disc list-inside">
              <li>
                <Translate i18nKey="AskMdn.Information.1">
                  Your <TextMedium>National ID/NRIC/FIN number</TextMedium>
                </Translate>
              </li>
              <li>
                <Translate i18nKey="AskMdn.Information.2">
                  Device <TextMedium>make, model</TextMedium> and
                  <TextMedium>IMEI</TextMedium> number
                </Translate>
              </li>
            </ul>
          </BaseInformationBox>
          <BaseLabel className="text-left my-0 mt-4 pl-6">
            <span className="font-extrabold">
              <Translate i18nKey="AskMdn.InputLabel" />
            </span>
          </BaseLabel>
          <BaseInput
            id={FIELD_IDS.MDN.replace("{{client}}", client)}
            value={mdn}
            placeholder={t("AskMdn.InputPlaceholder")}
            onChange={handleMdnChange}
            small
            maxLength={mdnLength}
            disabled={freezeWindow}
            autoFocus
            onEnterPress={shouldSubmit ? () => {} : submitMdn}
          />
          <BaseLabel className="my-0 mt-4 pl-6 text-left">
            <span className="font-extrabold">
              <Translate i18nKey="AskMdn.ServiceProviderLabel" />
            </span>
          </BaseLabel>
          <BaseDropdown
            placeHolder={t("AskMdn.PlaceholderServiceProvider")}
            value={serviceProvider}
            onChange={setServiceProvider}
            options={serviceProviderOptions}
            disabled={freezeWindow}
          />
          <div className="hidden">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={"6LeG5MoZAAAAAACp8Cr1jqocJuYV9TmyEChYOh4P"}
              // render="explicit"
              // onCaptchaLoad={onCaptchaLoad}
              onChange={verifyCaptcha}
              // onExpired={expireCaptcha}
              size="invisible"
              grecaptcha={grecaptchaObject}
            />
          </div>
          <BaseButtonGroup>
            <BaseButton
              id={FIELD_IDS.SUBMIT_MDN.replace("{{client}}", client)}
              name="Submit"
              text={t(`AskMdn.LetsBegin`)}
              onClick={submitMdn}
              clicked={freezeWindow}
              disabled={shouldSubmit}
              focused
            />
          </BaseButtonGroup>
        </BaseCard>
      </div>
      {showPopup && (
        <BasePopup
          show={true}
          title={t("AskMdn.Popup.Title")}
          onClose={() => setShowPopup(false)}
        >
          <ul className="list-disc list-inside font-DINPro-Light text-xl">
            <li className="my-4">
              <Translate i18nKey="AskMdn.Popup.1">
                <TextLight>
                  Access to this Screen Repair Service is limited to MobileSwop
                  customers of Singtel.
                </TextLight>
              </Translate>
            </li>
            <li className="my-4">
              <Translate i18nKey="AskMdn.Popup.2">
                Eligibility to access the Screen Repair Service will be verified
                by reference to the following MobileSwop enrollment details:
              </Translate>
            </li>
            <ul className="list-disc list-inside font-DINPro-Light text-xl ml-4">
              <li className="my-2">
                <Translate i18nKey="AskMdn.Popup.2_1">
                  <TextMedium>name, mobile number</TextMedium> and <TextMedium>National ID/NRIC/FIN/Passport number;</TextMedium> and
                </Translate>
              </li>
            </ul>
            <ul className="list-disc list-inside font-DINPro-Light text-xl ml-4">
              <li>
                <Translate i18nKey="AskMdn.Popup.2_2">
                  the <TextMedium>IMEI, make and model</TextMedium> of device.
                </Translate>
              </li>
            </ul>
            <li className="my-4">
              <Translate i18nKey="AskMdn.Popup.3">
                <TextLight>
                  Singtel MobileSwop Unlimited is provided by Singtel Mobile
                  Singapore Pte Ltd and is a separate service to this Screen
                  Repair Service which is provided by NEW Asurion Singapore Pte
                  Ltd.
                </TextLight>
              </Translate>
            </li>
          </ul>
        </BasePopup>
      )}
    </>
  );
};

export default AskMdn;
