import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import ImeiIcon from "../../../images/icon-phone-imei.svg";
import { TextLight, TextMedium, TextRegular } from "../../../base/BaseText";
import BaseSeparatedInput from "../../../base/BaseSeparatedInput/BaseSeparatedInput";
import BaseLink from "../../../base/BaseLink/BaseLink";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseInformationBox from "../../../base/BaseInformationBox";
import {
  verifyDeviceImei,
  initiateIMEIChat,
} from "../state/operators/deviceConfirmationOperators";
import { isEmpty } from "../../../helpers/formatUtils";
import generateUniqueId from "../../../helpers/generateUniqueId";
import BaseInput from "../../../base/BaseInput/BaseInput";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { CLIENT } from "../../../helpers/constants";

const VerifyImei = () => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const [imei, setImei] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [showInformation, setShowInformation] = useState(false);
  const [uniqueId, setUniqueId] = useState("");

  const client = useSelector((state) => state.app.client);
  const showSeparatedInput = client === CLIENT.CELCOM ? true : false;

  const handleIMEIChange = (value) => {
    if (!isNaN(value.trim())) setImei(value.trim());
  };

  const handleSubmitImei = () => {
    dispatch(verifyDeviceImei(imei));
    setDisabled(true);
  };

  const doNotKnowImei = () => {
    dispatch(initiateIMEIChat());
    setDisabled(true);
  };

  useEffect(() => {
    setUniqueId(generateUniqueId());
  }, []);

  const shouldntSubmit = disabled || isEmpty(imei) || imei.length < 6;

  return (
    <BaseCard>
      <BaseIcon icon={ImeiIcon} name="IMEI" center />
      <BaseLabel>
        <Translate i18nKey="VerifyImei.Title">
          <TextLight>
            Please enter <TextMedium> LAST 6 digits</TextMedium>
            <br /> of your device's <TextMedium>IMEI number</TextMedium>
          </TextLight>
        </Translate>
      </BaseLabel>
      <BaseLabel className="my-0 mt-4 pl-6 text-left">
        <span className="font-extrabold">
          <Translate i18nKey="VerifyImei.Label" />
        </span>
      </BaseLabel>
      {showSeparatedInput ? (
        <BaseSeparatedInput
          name={`ImeiVerify-${uniqueId}`}
          value={imei}
          length={6}
          onChange={setImei}
          disabled={disabled}
          onEnterPress={shouldntSubmit ? () => {} : handleSubmitImei}
          autoFocus
        />
      ) : (
        <BaseInput
          name={`ImeiVerify-${uniqueId}`}
          value={imei}
          placeholder={"Ex: 888888"}
          onChange={handleIMEIChange}
          small
          maxLength={6}
          disabled={disabled}
          autoFocus
          onEnterPress={shouldntSubmit ? () => {} : handleSubmitImei}
        />
      )}
      {showInformation && (
        <BaseInformationBox>
          <p>
            <Translate i18nKey="VerifyImei.Information.Heading">
              <TextRegular>Dial the IMEI code</TextRegular>
            </Translate>
          </p>
          <p>
            <Translate i18nKey="VerifyImei.Information.Content">
              <TextLight>
                You can retrieve the IMEI/MEID number on virtually any phone by
                dialing in the universal code.{" "}
                <TextRegular>Dial *#06#</TextRegular>.
              </TextLight>
            </Translate>
          </p>
        </BaseInformationBox>
      )}
      <BaseButtonGroup>
        <BaseButton
          name="Submit"
          text={t("SubmitButton")}
          onClick={handleSubmitImei}
          disabled={shouldntSubmit}
          clicked={disabled}
          focused
        />
      </BaseButtonGroup>
      <BaseLink
        className="text-center my-3 text-default"
        disabled={disabled}
        onClick={doNotKnowImei}
      >
        {t("VerifyImei.DontKnowLink")}
      </BaseLink>
    </BaseCard>
  );
};

export default VerifyImei;
