import React from "react";
import BaseProgressBar from "../base/BaseProgressBar/BaseProgressBar";
import { useTranslate } from "../helpers/languageUtils";

const ApiProgress = ({ text, ...restProps }) => {

  const t = useTranslate();
  const { percentage } = restProps;

  return percentage !== 0 ? (
    <div
      id="app-ApiProgress"
      className="text-center text-xl my-12"
    >
      {t(`ApiProgress.${text}`)}
      <BaseProgressBar {...restProps} />
    </div>
  ) : (
    <></>
  );
};

export default ApiProgress;
