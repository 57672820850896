import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { sanitize, isEmpty } from "../../helpers/formatUtils";
import { TextMedium } from "../../base/BaseText";

const BaseInput = ({
  value,
  onChange,
  onEnterPress,
  prefix,
  Suffix,
  placeholder,
  small,
  medium,
  full,
  className,
  disabled,
  errorText,
  id,
  ...restProps
}) => {
  const extraClasses = className ? className.split(" ") : [];
  const isError = !isEmpty(errorText);

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 && onEnterPress) onEnterPress();
  };

  const handleInputChange = (e) => {
    const sanitnizedValue = sanitize(e.target.value);
    onChange(sanitnizedValue, e);
  };

  return (
    <div className="w-full">
      <div
        className={classNames(
          "app-BaseInput",
          { "app-BaseInput-small": small },
          { "app-BaseInput-medium": medium },
          { "app-BaseInput-full": full },
          { "opacity-50": disabled },
          { "border-red-600": isError },
          ...extraClasses
        )}
      >
        {prefix && (
          <span className="app-BaseInput__prefix">{prefix}</span>
        )}
        <input
          id={id}
          className="app-BaseInput__input"
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          placeholder={placeholder}
          prefix={prefix}
          disabled={disabled}
          {...restProps}
        />
        {!isError && Suffix && (
          <div className="app-BaseInput__suffix">{<Suffix />}</div>
        )}
        {isError && (
          <div className="rounded-50% bg-red-600 text-white w-10 text-center">
            <TextMedium>!</TextMedium>
          </div>
        )}
      </div>
      {isError && (
        <div
          className={classNames("app-BaseInput__label", {
            "text-red-700": isError,
          })}
        >
          <TextMedium>{errorText}</TextMedium>
        </div>
      )}
    </div>
  );
};

BaseInput.defaultProps = {
  disabled: false,
  errorText: "",
};

BaseInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  Suffix: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnterPress: PropTypes.func,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  id: PropTypes.string
};

export default BaseInput;
