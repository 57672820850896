import React from "react";
import { useSelector } from "react-redux";
import { TextLight } from "../../base/BaseText";
import IncorrectNameIcon from "../../images/icon-incorrect-name.svg";
import IncorrectScreen from "../../components/IncorrectScreen";
import { Translate } from "../../helpers/languageUtils";

const IncorrectName = () => {
  const nameFailedAttempt = useSelector(
    (state) => state.validation.agreement.NameFailedAttempt
  );
  const messageKey = nameFailedAttempt > 3 ? "NameTerminate" : "Name";

  return (
    <IncorrectScreen icon={IncorrectNameIcon} name="Incorrect Name">
      <Translate i18nKey={`IncorrectMessage.${messageKey}`}>
        <TextLight>
          We regret to inform you that the name you've provided doesn't match
          the details on our record. Can you provide your registered full name
          again?
        </TextLight>
      </Translate>
    </IncorrectScreen>
  );
};

export default IncorrectName;
