import React, { useState } from "react";
import PropTypes from "prop-types";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { useDispatch } from "react-redux";
import tsConfig from "./troubleshootConfig";
import { submitTroubleshootAnswer } from "../state/operators/troubleshootingOperator";
import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { CLIENT, LANGUAGE } from "../../../helpers/constants";
import { useSelector } from "react-redux";
import CelcomCameraIcon from "../../../images/icon-camera-celcom.svg";
import BaseLink from "../../../base/BaseLink/BaseLink";
import BasePopup from "../../../base/BasePopup/BasePopup";
import { TextLight, TextMedium } from "../../../base/BaseText";

const Troubleshoot = ({ code }) => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const client = useSelector((state) => state.app.client);
  const language = useSelector((state) => state.app.language);

  const isSingtel = client === CLIENT.SINGTEL;

  const articlePath = isSingtel ? "singtel_articles" : "celcom_articles";
  const articleLanguagePath = isSingtel
    ? "prepare-your-phone-for-a-screen-repair"
    : language === LANGUAGE.BAHASA
    ? "prepare-your-phone-for-screen-repair-bs/"
    : "prepare-your-phone-for-screen-repair/";
  const PrepareLink = `https://kb-prod.sea.soluto.asurion.com/${articlePath}/${articleLanguagePath}`;

  const handleSubmit = (status) => {
    const textPrint = t(`${status}Button`);
    setDisabled(true);
    setStatus(status);
    // remove <0> <1> from title
    let Que = t(`Troubleshoot.${code}.Title`)
      .replace(/[<0><0/><1><1/>]/g, "")
      .trim();
    dispatch(
      submitTroubleshootAnswer(
        Que,
        status,
        code,
        t(`Troubleshoot.${code}.Reason`),
        textPrint
      )
    );
  };

  return (
    <div>
      <BaseCard>
        <BaseIcon
          icon={
            !isSingtel && code === "CameraWorking"
              ? CelcomCameraIcon
              : tsConfig[code].icon
          }
          name="Camera"
          center
        />
        {code === "ActivtionLockOff" && (
          <BaseLabel>
            <Translate i18nKey={`Troubleshoot.${code}.Title`}>
              <TextLight>
                I have <TextMedium>turned off</TextMedium> all activation locks
                on my device (e.g. Find My iPhone, Google Lock, Samsung Knox,
                etc{" "}
                <BaseLink
                  onClick={() => setShowPopup(true)}
                  className="text-srgreen-secondary cursor-pointer"
                >
                  <TextMedium>Click here</TextMedium>
                </BaseLink>{" "}
                for guides).
              </TextLight>
            </Translate>
          </BaseLabel>
        )}
        {code !== "ActivtionLockOff" && (
          <BaseLabel>
            <Translate i18nKey={`Troubleshoot.${code}.Title`}>
              {tsConfig[code].title}
            </Translate>
          </BaseLabel>
        )}
        <BaseButtonGroup>
          <BaseButton
            text={t("NoButton")}
            onClick={() => handleSubmit("No")}
            disabled={disabled}
            clicked={status === "No"}
          />
          <BaseButton
            text={isSingtel ? t("ConfirmButton") : t("YesButton")}
            onClick={() => handleSubmit(isSingtel ? "Confirm" : "Yes")}
            disabled={disabled}
            clicked={status === "Confirm"}
          />
        </BaseButtonGroup>
      </BaseCard>
      <div className={showPopup ? "block" : "hidden"}>
        <BasePopup
          show={true}
          onClose={() => setShowPopup(false)}
          className="h-screen lg:h-90% w-screen lg:w-60% overflow-hidden pb-8"
          global
        >
          <iframe
            src={PrepareLink}
            width="100%"
            className="block h-85%"
            frameBorder="0"
            allow-scripts="true"
          />
          <BaseCard className="p-0 border-none my-0 shadow-none ">
            <BaseButtonGroup>
              <BaseButton
                text={t("CloseButton")}
                focused
                onClick={() => setShowPopup(false)}
                className="mt-8"
              />
            </BaseButtonGroup>
          </BaseCard>
        </BasePopup>
      </div>
    </div>
  );
};

Troubleshoot.propTypes = {
  code: PropTypes.string.isRequired,
};

export default Troubleshoot;
